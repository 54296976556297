import React, {useEffect} from "react";
import loadScript from "../../../../utilities/loadScript";

const HubspotContactForm = () => {
    useEffect(() => {

        loadScript('https://js.hsforms.net/forms/shell.js')
            .then(() => {
                const hubspotFormDiv = document.getElementById(hubspotFormDivId);
                if (hubspotFormDiv) {
                    hubspotFormDiv.innerHTML = "";
                }
                // @ts-ignore
                if (window.hbspt) {
                    // @ts-ignore
                    window.hbspt.forms.create({
                        region: "na1",
                        portalId: "7634775",
                        formId: "5bb7e538-c11e-472a-920f-45e61f5ac49a",
                        target: `#${hubspotFormDivId}`
                    })
                }
            })
            .catch(e => console.error(`error loading script: ${e}`));
        const hubspotFormDivId = "hubspotForm";

    }, []);

    return (
        <div>
            <div id="hubspotForm"/>
        </div>
    );

}

export default HubspotContactForm;
