import React, {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import {SaveCreditCardInput} from "../data/graphqlTypes";
import {USD} from "../data/graphql/getCountriesAndCurrencies";
import "./index.css"

type PropsType = {
    saveCardProfile: (input: SaveCreditCardInput) => Promise<void>
    setError: (error: string) => void;
    apiKey: string;
    institutionId: string;
}

export interface GetCloverToken {
    getCloverToken: () => Promise<void>;
}

interface CloverValidationResponse {
    error?: string;
    touched: boolean;
}

type CloverEvent = {
    CARD_NUMBER: CloverValidationResponse;
    CARD_DATE: CloverValidationResponse;
    CARD_CVV: CloverValidationResponse;
    CARD_POSTAL_CODE: CloverValidationResponse;
};

interface CloverCreateTokenResponse {
    errors: CloverEvent;
    token: string;
}

export const creditCardInputStyle = {
    'input': {
        'font-size': '18px',
        'height': '50px',
        'padding': '18px 6px',
        'border': '1px solid #aaa',
        'border-radius': '4px',

    },
    'input:focus': {
        'border': '1px solid #9139ff',
    },

};

const displayCloverValidation = (error: string | undefined, divId: string) => {

    const creditCardFormElement = document.getElementById(divId);
    const creditCardFormErrorDisplayElement = document.getElementById(`${divId}-errors`);
    if (!creditCardFormErrorDisplayElement) {
        console.error("can't locate error display element for divId: ", divId);
        return;
    }
    if (!!error) {
        creditCardFormErrorDisplayElement.innerText = error;
        creditCardFormElement?.classList.add("Error");
    } else {
        creditCardFormErrorDisplayElement.innerText = "";
        creditCardFormElement?.classList.remove("Error");
    }
}


let clover = {};
const CARD_NUMBER_DIV_ID = 'card-number';
const CARD_DATE_DIV_ID = 'card-date';
const CARD_CVV_DIV_ID = 'card-cvv';
const CARD_POSTAL_CODE_DIV_ID = 'card-postal-code';

export async function loadThirdPartyScript(src: string, id: string): Promise<HTMLScriptElement> {
    const cloverScript = document.getElementById(id);
    if (!!cloverScript) {
        cloverScript.remove();
    }
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = false;
    document.body.appendChild(script);
    return script;
}


function initializeField(cloverElements: any, divId: string, elementId: string, displayValidationFunction: (event: CloverEvent) => void) {
    const cardNumber = cloverElements.create(elementId, creditCardInputStyle);
    cardNumber.mount(`#${divId}`);
    cardNumber.addEventListener('blur', displayValidationFunction);
}


const CloverCreditCardForm = ((props: PropsType, ref: React.Ref<GetCloverToken>) => {

    const [initialized, setInitialized] = useState(false);
    const setErrorCallback = props.setError;

    const getCloverToken = async (): Promise<void> => {
        // @ts-ignore
        clover.createToken({isMultipayToken: true})
            .then(function (result: CloverCreateTokenResponse) {
                if (result.errors) {
                    displayCloverValidation(result.errors.CARD_NUMBER?.error, CARD_NUMBER_DIV_ID);
                    displayCloverValidation(result.errors.CARD_DATE?.error, CARD_DATE_DIV_ID);
                    displayCloverValidation(result.errors.CARD_CVV?.error, CARD_CVV_DIV_ID);
                    displayCloverValidation(result.errors.CARD_POSTAL_CODE?.error, CARD_POSTAL_CODE_DIV_ID);
                } else {
                    return saveNewCloverCreditCardToken(result);
                }
            });
    }

    useImperativeHandle(ref, () => ({getCloverToken}));

    const saveNewCloverCreditCardToken = async (response: CloverCreateTokenResponse): Promise<void> => {
        const input: SaveCreditCardInput = {
            cardCurrency: USD.currencyCode,
            token: response.token,
            institutionId: props.institutionId
        }
        await props.saveCardProfile(input);
    }

    const updateParentErrorStatus = useCallback((event: CloverEvent) => {
        if (event.CARD_NUMBER?.error ||
            event.CARD_CVV?.error ||
            event.CARD_DATE?.error ||
            event.CARD_POSTAL_CODE?.error) {
            setErrorCallback("Clover error");
        } else {
            setErrorCallback("");
        }
    }, [setErrorCallback]);

    const initializeCloverElements = useCallback(() => {
        // @ts-ignore
        clover = new Clover(props.apiKey);
        // @ts-ignore
        const elements = clover.elements();
        initializeField(elements, CARD_NUMBER_DIV_ID, 'CARD_NUMBER', function (event: CloverEvent) {
            updateParentErrorStatus(event);
            displayCloverValidation(event.CARD_NUMBER.error, CARD_NUMBER_DIV_ID);
        });
        initializeField(elements, CARD_DATE_DIV_ID, 'CARD_DATE', function (event: CloverEvent) {
            updateParentErrorStatus(event);
            displayCloverValidation(event.CARD_DATE.error, CARD_DATE_DIV_ID);
        });
        initializeField(elements, CARD_CVV_DIV_ID, 'CARD_CVV', function (event: CloverEvent) {
            updateParentErrorStatus(event);
            displayCloverValidation(event.CARD_CVV.error, CARD_CVV_DIV_ID);
        });
        initializeField(elements, CARD_POSTAL_CODE_DIV_ID, 'CARD_POSTAL_CODE', function (event: CloverEvent) {
            updateParentErrorStatus(event);
            displayCloverValidation(event.CARD_POSTAL_CODE.error, CARD_POSTAL_CODE_DIV_ID);
        });
    }, [updateParentErrorStatus, props.apiKey]);

    if (!initialized) {
        setInitialized(true);
        loadThirdPartyScript(`https://${process.env.REACT_APP_CLOVER_DOMAIN}/sdk.js`, "cloverJs")
            .then(script => {
                script.addEventListener('load', initializeCloverElements)
            });
    }

    return <>
        <form id="payment-form" className="PaymentForm">
            <fieldset className="FormGroup">
                <div className="FormRow">
                    <div id={CARD_NUMBER_DIV_ID} className="FieldCardNumber"/>

                </div>
                <div className="input-errors" id={`${CARD_NUMBER_DIV_ID}-errors`} role="alert"/>
                <div className="FormRow2">
                    <div id={CARD_DATE_DIV_ID} className="FieldThirdWidth"/>
                    <div id={CARD_CVV_DIV_ID} className="FieldThirdWidth"/>
                    <div id={CARD_POSTAL_CODE_DIV_ID} className="FieldThirdWidth2"/>
                </div>
                {/*<div className="FormRow3">*/}
                    <div className="input-errors" id={`${CARD_DATE_DIV_ID}-errors`} role="alert"/>
                    <div className="input-errors" id={`${CARD_CVV_DIV_ID}-errors`} role="alert"/>
                    <div className="input-errors" id={`${CARD_POSTAL_CODE_DIV_ID}-errors`} role="alert"/>
                {/*</div>*/}
            </fieldset>
        </form>
    </>

})

export default forwardRef<GetCloverToken, PropsType>(CloverCreditCardForm);