import {Button, CardContent, Collapse, Grid} from "@material-ui/core";
import React, {useState} from "react";
import {Accounts} from "./Account/Accounts";
import AccountAddUpdate from "./Account/AccountAddUpdate";
import {BankAccountDetail} from "../data/graphqlTypes";
import {makeStyles} from "@material-ui/core/styles";
import {PAYMENT_OPTION_STYLES} from "../PaymentOptions/ETranferInstructions";


type PropsType = {
    setAccount?: (accountId: BankAccountDetail | undefined) => void;
    currencyOfNewAccounts: string;
    accountId: string | undefined;
    setEditMode: (editingNow: boolean) => void;
    editMode: boolean;
    autoSelectCurrency?: string;
    selectedAccountCurrency: string | undefined;
    setIsPaymentOptionSaved?: any;
}

const useStyles = makeStyles(() => ({
    cardContainer: {
        padding: 12,
    }
}));
const DirectDebitAccounts = (props: PropsType) => {

    const paymentOptionStyle = PAYMENT_OPTION_STYLES();
    const classes = useStyles();
    const [displayAddAccount, setDisplayAddAccount] = useState<boolean>(false);

    function setAccount(account: BankAccountDetail | undefined) {
        if (props.setAccount) {
            props.setAccount(account);
        }
    }

    return <CardContent className={paymentOptionStyle.cardContent}>
        <Grid container direction="row" className={classes.cardContainer}>
            <Collapse in={!displayAddAccount}
                      unmountOnExit
                      mountOnEnter
                      style={{transformOrigin: '0 0 0'}}
                      {...(!displayAddAccount ? {timeout: 400} : {})}>
                <React.Fragment>
                    <Grid container>
                        <Accounts
                            setAccount={setAccount}
                            selectedAccountId={props.accountId}
                            setEditMode={props.setEditMode}
                            editMode={props.editMode}
                            autoSelectCurrency={props.autoSelectCurrency}
                            selectable={!!props.setAccount}
                        />
                    </Grid>

                </React.Fragment>

            </Collapse>
            {!props.editMode &&
                <Grid
                    container
                    justifyContent={"flex-end"}
                >
                    <Grid item>
                        <Button
                            variant={"text"}
                            onClick={() => setDisplayAddAccount(true)}
                        >
                            Add New Account
                        </Button>
                    </Grid>
                </Grid>
            }
            <Collapse in={displayAddAccount}
                      unmountOnExit
                      mountOnEnter
                      style={{transformOrigin: '0 0 0'}}
                      {...(displayAddAccount ? {timeout: 1000} : {})}>

                <AccountAddUpdate
                    setEditMode={props.setEditMode}
                    setAccount={setAccount}
                    defaultCurrency={props.currencyOfNewAccounts}
                    account={undefined}
                    closeAccountEditor={() => setDisplayAddAccount(false)}
                    setIsPaymentOptionSaved={props.setIsPaymentOptionSaved}
                />
            </Collapse>
        </Grid>
    </CardContent>
}

export default DirectDebitAccounts;