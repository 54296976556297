import {
    CalculateFeeForCreditCardInput,
    CalculateFeeResult,
    useCalculateFeesForCreditCardLazyQuery
} from "../data/graphqlTypes";
import {useCallback} from "react";
import {ApolloError} from "@apollo/client";


export type CalculateFeesForCreditCardHook = {
    data: CalculateFeeResult | undefined,
    error: ApolloError | undefined,
    loading: boolean,
    calculateFees: (input: CalculateFeeForCreditCardInput) => void
}

const useCalculateFeesForCreditCard = (): CalculateFeesForCreditCardHook => {
    const [calculateFeesForCreditCard,
        {
            data,
            error,
            loading
        }] = useCalculateFeesForCreditCardLazyQuery({
        fetchPolicy: "no-cache"
    });

    const calculateFeesFunction = useCallback(async (input: CalculateFeeForCreditCardInput): Promise<any> => {
        return calculateFeesForCreditCard({variables: {input: input}})
            .catch(() => {
                /* handled elsewhere */
            })
    }, [calculateFeesForCreditCard]);

    return {
        data: data?.calculateFeesForCreditCard,
        error,
        loading,
        calculateFees: calculateFeesFunction
    }
}

export default useCalculateFeesForCreditCard;