import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Button, colors, Grid, ListItem, ListItemAvatar, ListItemText, useMediaQuery,} from '@material-ui/core';
import SectionHeader from '../SectionHeader';
import IconAlternate from '../IconAlternate';
import {faCheck} from "@fortawesome/free-solid-svg-icons";


function handleSignUp() {
    window.location.href = 'https://www.payfromaway.io/contact-us';
  }


const useStyles = makeStyles(theme => ({
  listItemAvatar: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    alignSelf: 'flex-start',
  },
}));

const Faq = ({ data, className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={isMd ? 4 : 2}>
            {data.map((item: any, index: number) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ListItem disableGutters key={index} data-aos="fade-up">
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <IconAlternate
                      size="small"
                      shape="circle"
                      fontIcon={faCheck}
                      color={colors.amber}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.title}
                    secondary={item.text}
                    primaryTypographyProps={{
                      variant: 'h6',
                    }}
                    secondaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
        <br/>
          <SectionHeader
            title="Join the community."
            subtitle="Partner with PayFromAway as your HOA payments platform."
            align="center"
            ctaGroup={[
              <Button color="primary" variant="contained" size="large"
              onClick={handleSignUp}>
                Sign Up
              </Button>,
            ]}
            disableGutter
            data-aos="fade-up"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Faq;
