export const CREDIT_CARD_AUTO_PAY_TERMS = `The following Terms of Use apply to your use of PayFromAway’s Automated Payments functionality (“Automated Payments”): 

By signing up for Automated Payments, you authorize PayFromAway (powered by Shift Connect Ltd.) to initiate recurring charges to your bank, credit card, or debit card account that you specify during the enrollment process (collectively, “Designated Payment Method”), which PayFromAway will store for the duration of your Automated Payments. 

You authorize PayFromAway to automatically charge your Designated Payment Method for the amount you specify or otherwise agreed upon by you and PayFromAway’s merchant client - the recipient you are paying (“Participating Merchant”) from time to time when you sign up for Automated Payments. These charges may be for periodic recurring transactions, unscheduled transactions, or for changes to previously scheduled transactions, each as agreed upon between you and the Participating Merchant. If you have authorized an Automated Payment and your transaction involves a currency conversion, the transaction exchange rate (including PayFromAway’s currency conversion spread and any fees charged by PayFromAway’s service providers) in effect at the time the transaction is processed will be used. 

After you sign up for Automated Payments, all future payments will be automatically withdrawn from your specified account or charged to the designated credit or debit card on the schedule as agreed by you and the Participating Merchant, unless you terminate your participation in the manner described below. 

If you have a PayFromAway account, you may terminate your authorization for Automated Payments at any time by logging into your PayFromAway account at https://hoa.payfromaway.io/paymentSettings and canceling the scheduled Automated Payments.  

You must comply with any rules or requirements that are imposed by your financial institution or debit or credit card issuer related to pre-authorized electronic funds transfers and/or debit or credit card transactions. You are responsible for all fees charged by your financial institution associated with the pre-authorized payment option. 

To the fullest extent allowed by law, PayFromAway shall bear no liability or responsibility for any losses or damages of any kind that you may incur as a result of your use of the Automated Payment functionality. 

PayFromAway reserves the right to change these terms or terminate the Automated Payments program at any time. 

Automated Payments may not be available for residents of every country. Please contact PayFromAway or your Participating Merchant should you have any issues with the Automated Payments functionality. 

 `;


export const DIRECT_DEBIT_AUTO_PAY_TERMS = `The following Terms of Use apply to your use of PayFromAway’s Automated Payments functionality (“Automated Payments”): 

By signing up for Automated Payments, you authorize PayFromAway (powered by Shift Connect Ltd.) to initiate recurring charges to your bank, credit card, or debit card account that you specify during the enrollment process (collectively, “Designated Payment Method”), which PayFromAway will store for the duration of your Automated Payments. 

You authorize PayFromAway to automatically charge your Designated Payment Method for the amount you specify or otherwise agreed upon by you and PayFromAway’s merchant client - the recipient you are paying (“Participating Merchant”) from time to time when you sign up for Automated Payments. These charges may be for periodic recurring transactions, unscheduled transactions, or for changes to previously scheduled transactions, each as agreed upon between you and the Participating Merchant. If you have authorized an Automated Payment and your transaction involves a currency conversion, the transaction exchange rate (including PayFromAway’s currency conversion spread and any fees charged by PayFromAway’s service providers) in effect at the time the transaction is processed will be used. 

After you sign up for Automated Payments, all future payments will be automatically withdrawn from your specified account or charged to the designated credit or debit card on the schedule as agreed by you and the Participating Merchant, unless you terminate your participation in the manner described below. 

If you have a PayFromAway account, you may terminate your authorization for Automated Payments at any time by logging into your PayFromAway account at https://hoa.payfromaway.io/paymentSettings and canceling the scheduled Automated Payments.  

You must comply with any rules or requirements that are imposed by your financial institution or debit or credit card issuer related to pre-authorized electronic funds transfers and/or debit or credit card transactions. You are responsible for all fees charged by your financial institution associated with the pre-authorized payment option. 

To the fullest extent allowed by law, PayFromAway shall bear no liability or responsibility for any losses or damages of any kind that you may incur as a result of your use of the Automated Payment functionality. 

PayFromAway reserves the right to change these terms or terminate the Automated Payments program at any time. 

Automated Payments may not be available for residents of every country. Please contact PayFromAway or your Participating Merchant should you have any issues with the Automated Payments functionality. 

 `;

export const DIRECT_DEBIT_AUTO_PAY_TERMS_TITLE = `Direct Debit Auto Pay Terms & Conditions`;

export const CREDIT_CARD_AUTO_PAY_TERMS_TITLE = `Credit Card Auto Pay Terms & Conditions`;