import CloverCreditCardProcessorDisplay from "../../CreditCard/CloverCreditCardProcessorDisplay";
import {useContext} from "react";
import {HomeLocationContext} from "../../context/HomeLocationContext";
import {CreditCard, useGetPaymentProcessorConnectionDetailsQuery} from "../../data/graphqlTypes";
import ApolloGqlQueryDisplay from "../../data/ApolloGqlQueryDisplay";
import BlueSnapCreditCardProcessorDisplay from "../../CreditCard/BlueSnapCreditCardProcessorDisplay";

type PropsType = {
    closeCreditCardProcessor: () => void;
    setSelectedCreditCard?: (selectedCard: CreditCard) => void;
    institutionId: string;
    currency?: string;
    setIsPaymentOptionSaved?: any;
}

const CreditCardProcessorResolver = (props: PropsType): JSX.Element => {

    const homeLocation = useContext(HomeLocationContext);

    let getPaymentProcessorDetailsInput = {
        variables: {
            input: {
                residenceCountryCode: homeLocation.countryCode,
                institutionId: props.institutionId
            }
        }
    };
    const {
        data: paymentProcessorDetails,
        loading,
        error
    } = useGetPaymentProcessorConnectionDetailsQuery(getPaymentProcessorDetailsInput)

    let paymentProcessorConnectionDetails = paymentProcessorDetails?.getPaymentProcessorConnectionDetails;
    if (loading || error || !paymentProcessorConnectionDetails || !paymentProcessorConnectionDetails.paymentProcessorCode) {
        return <ApolloGqlQueryDisplay
            loading={loading}
            loadingMessage={"Loading credit card processor"}
            error={error}
        />
    }
    if (paymentProcessorConnectionDetails &&
        paymentProcessorConnectionDetails.paymentProcessorCode === "BLUESNAP") {
        return <BlueSnapCreditCardProcessorDisplay
            addCreditCardCallBack={props.closeCreditCardProcessor}
            cancelCallback={props.closeCreditCardProcessor}
            setSelectedCreditCard={props.setSelectedCreditCard}
            institutionId={props.institutionId}
            residenceCountryCode={homeLocation.countryCode}
            currency={props.currency}
            setIsPaymentOptionSaved={props.setIsPaymentOptionSaved}
        />
    }
    return <CloverCreditCardProcessorDisplay
        cancelCallback={props.closeCreditCardProcessor}
        addCreditCardCallBack={props.closeCreditCardProcessor}
        setSelectedCreditCard={props.setSelectedCreditCard}
        apiKey={paymentProcessorConnectionDetails.processorApiKey || ""}
        institutionId={props.institutionId}
        setIsPaymentOptionSaved={props.setIsPaymentOptionSaved}
    />
}
export default CreditCardProcessorResolver;