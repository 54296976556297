type NetworkError = Error
    | (Error & { response: Response; result: Record<string, any>; statusCode: number })
    | (Error & { response: Response; statusCode: number; bodyText: string })
    | undefined;

const BAD_BUY_SELL_COMBINATION_ERROR_CODE = "BAD_BUY_SELL_COMBINATION_ERROR_CODE";
const INVALID_GOOGLE_PLACE_REQUEST_ERROR_CODE = "INVALID_GOOGLE_PLACE_REQUEST_ERROR_CODE";
const TRANSACTION_NOT_SUPPORTED_ERROR_CODE = "TRANSACTION_NOT_SUPPORTED_ERROR_CODE";

export function isNetworkErrorWithCode(networkError: NetworkError, code: number = 401) {
    return networkError &&
        'statusCode' in networkError &&
        networkError.statusCode === code;
}

export function is401NetworkError(networkError: NetworkError) {
    return isNetworkErrorWithCode(networkError, 401);
}

export function isNetworkErrorWithMessageInvalidState(networkError: NetworkError) {
    const messageIsInvalidState = networkError &&
        'message' in networkError &&
        networkError.message === 'Invalid state';
    // console.error('messageIsInvalidState', messageIsInvalidState);
    return messageIsInvalidState;
}

export function isTransactionNotSupported(extensions?: any) {
    if (!extensions) {
        return false;
    }
    return extensions.code === TRANSACTION_NOT_SUPPORTED_ERROR_CODE;
}

export function isBadBuySellCombinationError(extensions?: any) {
    if (!extensions) {
        return false;
    }
    return extensions.code === BAD_BUY_SELL_COMBINATION_ERROR_CODE;
}

export function isInvalidGooglePlaceRequestError(extensions?: any) {
    if (!extensions) {
        return false;
    }
    return extensions.code === INVALID_GOOGLE_PLACE_REQUEST_ERROR_CODE;
}