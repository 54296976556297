import HoaInvoice from "./HoaInvoice";
import {Grid} from "@material-ui/core";
import {Link, useParams} from "react-router-dom";
import React from "react";


type Params = {
    invoiceUuid: string;
}

const PublicInvoice = () => {

    let {invoiceUuid}: Params = useParams();

    return<HoaInvoice
            invoiceUuid={invoiceUuid}
        />
}

export default PublicInvoice;