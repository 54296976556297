import {Country} from "../components/data/graphql/getCountriesAndCurrencies";


export function getCountryFromList(countries: Array<Country>, countryCode: string | undefined): Country | undefined {
    if (!countryCode) return undefined;
    const foundCountry = countries.find((country) => {
        return country.countryCode.toLowerCase() === countryCode.toLowerCase();
    });
    return foundCountry || undefined;
}
