import React, {useCallback, useEffect, useState} from "react";
import {CustomerInstitution, CustomerInstitutionInput} from "../data/graphqlTypes";
import {useUpdateUserInstitution} from "../context/UserInstitutionContext/updateUserInstitution";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import {PaymentReferenceField} from "./PaymentReferenceField";
import {useGetUserInstitutions} from "../context/UserInstitutionContext/useGetUserInstitutions";
import useAuthorization from "../auth/useAuthorization";


type PropsType = {
    initialValue?: string;
    fieldLabel: string;
    institutionId: string;
    setReferenceCallback: (paymentReference: string) => void
    validateNow: boolean;
}


function getReferenceFromUserInstitutions(userInstitutions: Array<CustomerInstitution | null> | undefined, institutionId: string) {
    const found = !!userInstitutions && userInstitutions.find(value => !!value && institutionId === value.id);
    if (found && found.units && found.units.length > 0) {
        return (found.units as string[]).join();
    }
}

const PaymentReference = (props: PropsType) => {

    const {isLoading, isAuthenticated} = useAuthorization();
    const [reference, setReference] = useState(props.initialValue || "");

    const {
        updateUserInstitution,
        updateUserInstitutionError,
        updateUserInstitutionLoading
    } = useUpdateUserInstitution();

    const institutionId = props.institutionId;
    const {
        userInstitutions,
        loading: getUserInstitutionLoading,
        error: getUserInstitutionError
    } = useGetUserInstitutions({institutionId: institutionId});

    const savePaymentReferenceOnServer = useCallback((newPaymentReference: string) => {
        const userInstitutionInput: CustomerInstitutionInput = {
            id: institutionId,
            units: [newPaymentReference]
        };
        if (!isLoading && isAuthenticated) {
            updateUserInstitution(userInstitutionInput)
                .catch(() => {
                }); // do nothing. error handled in the ApolloGqlQueryDisplay
        }
    }, [isAuthenticated, updateUserInstitution, institutionId, isLoading]);

    useEffect(() => {
        if (userInstitutions) {
            const referenceFromServer = getReferenceFromUserInstitutions(userInstitutions, institutionId)
            if (referenceFromServer) {
                setReference(referenceFromServer);
                props.setReferenceCallback(referenceFromServer);
            }
        }
    }, [userInstitutions, institutionId]);

    useEffect(() => {
        if(getUserInstitutionLoading || getUserInstitutionError){
            return;
        }
        if((!userInstitutions || userInstitutions.length < 1) && props.initialValue){
            savePaymentReferenceOnServer(props.initialValue);
        }
    }, [userInstitutions, props.initialValue]);

    const loading = updateUserInstitutionLoading || getUserInstitutionLoading;
    const error = updateUserInstitutionError || getUserInstitutionError;

    function savePaymentReference(newValue: string) {
        props.setReferenceCallback(newValue.trim());
        savePaymentReferenceOnServer(newValue.trim());
    }

    return (isAuthenticated && loading ?
        <ApolloGqlQueryDisplay
            loadingMessage={""}
            loading={loading}
            error={error}
        />
        :
        <PaymentReferenceField
            value={reference}
            savePaymentReference={savePaymentReference}
            label={props.fieldLabel}
            disabled={isAuthenticated && loading}
            validateNow={props.validateNow}
        />);
}


export default PaymentReference;
