import {BankAccountDetail, BankAccountStatus} from "../../data/graphqlTypes";
import {Avatar, Box, Card, CardContent, IconButton, Tooltip, Typography} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import EditIcon from "@material-ui/icons/Edit";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";


const styles = {
    root: {
        width: 175,
        height: 225,
        margin: 'auto',
        border: '2px solid',
        borderColor: '#000',
        borderRadius: 6,
    },
    media: {
        width: '100%',
        paddingBottom: '36.25%',
        clipPath: 'polygon(0 0, 100% 0%, 100% 84%, 0% 100%)',
        backgroundColor: '#f0f0f0'
    },
    content: {
        padding: 12,
        paddingBottom: 0,
        "&:lastChild": {
            paddingBottom: 0
        }
    },
    avatar: {
        width: 50,
        height: 50,
        border: '2px solid #fff',
        margin: '-48px 12px 0 16px',
        '& > img': {
            margin: 0,
        },
    },
};

type PropsType = {
    setAccount: (account: BankAccountDetail) => void;
    account: BankAccountDetail;
    selectable: boolean;
    selected: boolean;
    editAccount?: (account: BankAccountDetail) => void;
    currencyMatch?: boolean;
    deleteAccount?: () => void;
}

const BankAccountDisplay = (props: PropsType) => {

    function getBankAccount(account: BankAccountDetail): BankAccountDetail {
        return {
            accountNumber: account.accountNumber,
            routingCode: account.routingCode,
            currency: account.currency,
            routingType: account.routingType,
            id: account.id,
            status: BankAccountStatus.New
        };
    }

    const account = props.account;

    return <Card style={styles.root}>
        <div style={styles.media}
             onClick={() => props.setAccount(getBankAccount(account as BankAccountDetail))}/>
        <Avatar style={styles.avatar} src={"/Images/AvatarAccounts.png"}
                onClick={() => props.setAccount(getBankAccount(account as BankAccountDetail))}/>
        <CardContent style={styles.content}
                     onClick={() => props.setAccount(getBankAccount(account as BankAccountDetail))}>
            <Typography
                variant="h5">{account?.bankName}  </Typography>
            <Typography
                variant="subtitle2">{"Account #: "}{account?.accountNumber}  </Typography>
            <Typography
                variant="subtitle2">{"Routing Code / Transit #: "}{account?.routingCode}
            </Typography>
            <Typography
                variant="subtitle2">{"Account Type: "}{account?.routingType}
            </Typography>
            <Typography
                variant="subtitle2">
                {"Currency: "}{account?.currency}
                {!props.currencyMatch && <WarningIcon
                    fontSize={"small"}
                    color={"error"}
                />}

            </Typography>
        </CardContent>
        <Box px={1} pb={2} mt={3} ml={-1}>
            {
                !!props.editAccount &&
                <IconButton aria-label="edit" onClick={() => {
                    !!props.editAccount && props.editAccount(account as BankAccountDetail);
                }}>
                    <EditIcon color={"primary"}/>
                </IconButton>
            }
            {
                !!props.deleteAccount &&
                <Tooltip title="Delete">
                    <IconButton
                        aria-label="delete"
                        onClick={event => {
                            event.stopPropagation();
                            !!props.deleteAccount && props.deleteAccount();
                        }}
                    >
                        <DeleteIcon color={"primary"}/>
                    </IconButton>
                </Tooltip>
            }
            {props.selectable && props.currencyMatch &&
                <IconButton aria-label="select"
                            onClick={() => props.setAccount(getBankAccount(account as BankAccountDetail))}>
                    {props.selected ?
                        <CheckBoxIcon color={"primary"}/> :
                        <CheckBoxOutlineBlankIcon color={"primary"}/>
                    }
                </IconButton>
            }
        </Box>
    </Card>
}

export default BankAccountDisplay;