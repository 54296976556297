import {Field, FieldProps, Formik} from "formik";
import {TextField} from "@material-ui/core";
import React, {useCallback, useEffect, useRef} from "react";
import * as yup from "yup";
import {FormikProps} from "formik/dist/types";

interface PaymentReferenceData {
    paymentReference: string;
}
type PropsType = {
    label: string;
    value: string
    disabled: boolean;
    savePaymentReference: (newValue: string) => void;
    validateNow: boolean;
};

export const PaymentReferenceField = (props: PropsType) => {

    const label = props.label;
    const validationSchema = useCallback(() => yup.object().shape({
        paymentReference: yup.string().trim()
            .required(`${label} is required`)
            .max(200, `Max 200 characters.`)
    }), [label]);

    const formRef = useRef<FormikProps<PaymentReferenceData>>(null);
    const currentForm = formRef.current;
    const validateNow = props.validateNow;

    useEffect(() => {
        if (currentForm && validateNow){
            currentForm.validateForm().catch(reason => console.error(reason))
        }
    }, [currentForm, validateNow]);


    return <Formik<PaymentReferenceData>
        initialValues={{paymentReference: props.value}}
        onSubmit={values => {
            props.savePaymentReference(values.paymentReference.trim());
        }}
        validateOnBlur={true}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        innerRef={formRef}
    >
        {({isValid, dirty, submitForm, setFieldValue}) => {
            return <Field name="paymentReference">
                {({field, meta}: FieldProps) => {
                    return <TextField
                        label={label}
                        disabled={props.disabled}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        variant={"outlined"}
                        error={!!meta.error}
                        helperText={meta.error}
                        {...field}
                        onChange={(changeEvent) => {
                            const value = changeEvent.currentTarget.value;
                            setFieldValue("paymentReference", value);
                        }}
                        onBlur={() => {
                            if (isValid && dirty) {
                                submitForm()
                                    .catch(() => {/*Do nothing.  handled by the Parent (savePaymentReferenceOnServer()). */
                                    });
                            }
                        }}
                    />
                }}
            </Field>
        }}

    </Formik>
}
