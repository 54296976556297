import ApolloGqlQueryDisplay from "../components/data/ApolloGqlQueryDisplay";
import React, {useEffect, useState} from "react";
import {
    Invoice,
    InvoiceWithBalance,
    useGetInstitutionsByIdLazyQuery,
    useGetInvoiceQuery
} from "../components/data/graphqlTypes";
import {Box, Button, Card, Divider, Grid, Paper, Typography} from "@material-ui/core";
import InstitutionPageHeader from "../components/institution/InstitutionPageHeader";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import InvoiceView from "../components/Invoice/InvoiceView";
import {theme} from "../components/theme";
import {storeInstitutionIdInLocalStorage} from "../utilities/localstorage/InstitutionContextStore";


type PropsType = {
    invoiceUuid: string;
    setInvoice?: (invoice: Invoice) => void;
}

export const hoaInvoiceStyles = makeStyles(() => ({
    divider: {
        height: "2px",
    },
    cardContainer: {
        minWidth: 550,
        maxWidth: 800,
        height: 'auto',
        marginTop: '5vh',
        borderColor: 'transparent',
        backgroundColor: "#fff",
        padding: "10px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "2vh",
            minWidth: 250
        },
    },
    introFont: {
        fontSize: "24px",
        lineHeight: "38px",
        fontWeight: 600,
        marginTop: "12px",
        marginBottom: "24px",
        marginLeft: "36px",
        marginRight: "36px",
        color: "#4d3b71",
    },
    payNow: {
        padding: "20px 20px",
        margin: "24px 20px",
        backgroundColor: "#7a30d6",
        borderRadius: "4px",
        [theme.breakpoints.down("sm")]: {
            margin: "5px",
        },
    },
}));

const HoaInvoice = (props: PropsType) => {
    const classes = hoaInvoiceStyles()


    const [error, setError] = useState<string>();

    const
        {
            data: invoiceData,
            loading: invoiceLoading,
            error: invoiceError
        } = useGetInvoiceQuery({variables: {invoiceUuid: props.invoiceUuid}});

    const [
        getInstitutionsById,
        {
            data: institutionsData,
            loading: institutionsLoading,
            error: institutionError
        }] = useGetInstitutionsByIdLazyQuery();

    useEffect(() => {
        if (!institutionsData && !institutionsLoading && !!invoiceData?.invoice && !!invoiceData.invoice.institutionId) {
            getInstitutionsById({variables: {institutionIds: invoiceData.invoice.institutionId}})
        }
    }, [institutionsData, institutionsLoading, institutionError, invoiceData]);

    useEffect(() => {
        if (!!invoiceData && !!invoiceData.invoice && !!invoiceData.invoice.institutionId) {
            storeInstitutionIdInLocalStorage(invoiceData.invoice.institutionId);
        }
    }, [invoiceData]);

    useEffect(() => {
        if (!invoiceLoading && !invoiceError && !invoiceData?.invoice) {
            setError("Error loading invoice. Please try again or contacts support");
        }
    }, [invoiceLoading, invoiceError, invoiceData]);

    useEffect(() => {
        if (invoiceData?.invoice && props.setInvoice) {
            props.setInvoice(invoiceData.invoice as Invoice);
        }
    }, [invoiceData]);

    if (invoiceLoading || invoiceError || error) {
        return <ApolloGqlQueryDisplay
            loading={invoiceLoading}
            error={invoiceError || error}
            loadingMessage={"Loading invoice"}
        />
    }

    let invoiceWithOriginalBalance: InvoiceWithBalance | undefined = undefined;
    if (!!invoiceData) {
        invoiceWithOriginalBalance = {
            invoice: invoiceData.invoice as Invoice,
            balance: {
                amount: invoiceData?.invoice.amount,
                currencyCode: invoiceData?.invoice.currencyCode
            },
            transactionIds: []
        };
    }


    return <Paper>
        <Grid container direction='column' justifyContent="center" alignItems="center">
            <Card className={classes.cardContainer} raised={true}>
                {institutionsData?.institutions[0] &&
                    <InstitutionPageHeader
                        institution={institutionsData?.institutions[0]}
                    />
                }
                <Divider className={classes.divider}/>
                <Grid container direction={"column"} alignItems={"stretch"} alignContent={"center"}
                      justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Typography className={classes.introFont} align={"center"}>
                            {institutionsData?.institutions[0]?.name}, sent you an invoice
                        </Typography>

                    </Grid>

                    {!!invoiceWithOriginalBalance ?
                        <Grid item xs={12}>
                            <InvoiceView
                                invoiceWithBalance={invoiceWithOriginalBalance}
                            />
                        </Grid>
                        :
                        <></>
                    }

                    <Grid item xs={12}>
                        <Link
                            type={"submit"}
                            to={`/invoice/payment/${props.invoiceUuid}`}
                            style={{width: "100%",}}
                        >
                            <Box textAlign={"center"}>
                                <Button size={"small"} className={classes.payNow} fullWidth>
                                    Pay Now
                                </Button>
                            </Box>
                        </Link>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    </Paper>;

}

export default HoaInvoice;
