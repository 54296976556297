import {useKindeAuth} from "@kinde-oss/kinde-auth-react";


const useAuthorization = () => {
    const {
        isLoading,
        isAuthenticated,
        logout,
        login,
        getToken,
        user,
        error
    } = useKindeAuth();
    return {isLoading, isAuthenticated, logout, login, getToken, user, error}
}

export default useAuthorization;