import React from "react";
import {Loading} from "../layout";
import {ApolloError} from "@apollo/client";
import ErrorDisplay from "../ErrorDisplay";
import {Button, Grid} from "@material-ui/core";
import {buttonStyles} from "../input/TransactionCountryCurrencyAmounts";

function getUniqueErrorMessagesAsArray(error?: ApolloError | string | Array<string>): Array<string> | undefined {
    if (!error) return undefined;
    if (typeof error === 'string') return [error];
    if (Array.isArray(error)) return error;
    let errorMessages = [error.message];
    const graphQlErrors = error.graphQLErrors;
    if (graphQlErrors && graphQlErrors.length > 1) {
        errorMessages = Array.from(new Set(graphQlErrors.map(error => error.message)));
    }
    return errorMessages;
}

const ApolloGqlQueryDisplay = (props: {
    loading: boolean,
    loadingMessage?: string,
    successMessage?: string,
    error?: ApolloError | string | Array<string>,
    errorAcknowledgeButtonText?: string,
    errorAcknowledgeButtonCallback?: () => void
}) => {

    let success = props.successMessage;
    setTimeout(function () {
        success = "";
    }, 2000);

    if (success) {
        return (<div>{success}</div>);
    }
    let errorMessages = getUniqueErrorMessagesAsArray(props.error);

    return (
        <Grid container>
            {errorMessages && errorMessages.length > 0 ?
                <>
                    {

                        errorMessages.map(errorMessage =>
                            <Grid item xs={12} key={errorMessage}>
                                <ErrorDisplay
                                    key={errorMessage}
                                    error={errorMessage}
                                />
                            </Grid>
                        )
                    }
                    {props.errorAcknowledgeButtonCallback && props.errorAcknowledgeButtonText &&
                        <Grid item xs={12}>
                            <Button
                                variant={"text"}
                                style={buttonStyles.buttonAsLink}
                                onClick={props.errorAcknowledgeButtonCallback}
                            >{props.errorAcknowledgeButtonText}</Button>
                        </Grid>
                    }
                </>
                :
                props.loading && (
                    <Loading
                        message={props.loadingMessage}
                    />
                )

            }
        </Grid>
    );
};

export default ApolloGqlQueryDisplay;
