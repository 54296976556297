import React from "react";
import {Typography} from "@material-ui/core";

type PropsType = {
    message?: string;
}

const Loading = (props: PropsType) => (
    <div className="spinner">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width="32px"
            height="32px"
        >
            <path
                className="dashed"
                fill="none"
                strokeLinecap="square"
                strokeLinejoin="round"
                strokeWidth="20"
                d="M16.16 16.16h67.68v67.68H16.16Z"
            />
            <path
                className="dashed2"
                fill="none"
                strokeLinecap="square"
                strokeLinejoin="round"
                strokeWidth="20"
                d="M16.16 16.16h67.68v67.68H16.16Z"
            />
        </svg>
        <Typography>{props.message ? props.message : ""}</Typography>
    </div>
);

export default Loading;
