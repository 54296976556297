import React from "react";
import {Container, Fade, Grid, Typography} from "@material-ui/core";
import BalanceDisplay from "../components/autopay/BalanceDisplay";


const BalanceHistory = () => {


    return (
        <Fade in={true} timeout={1000}>
            {/*<Paper style={getBackgroundImage().paperContainer}>*/}
            <Container maxWidth={"lg"}>
                <Grid container direction={"row"} alignContent={"flex-start"} justifyContent={"flex-start"}>
                    <Grid item sm={10}>
                        <Typography variant={"h1"}>
                            Account Balance
                        </Typography>
                    </Grid>
                </Grid>
                <BalanceDisplay
                    accountPaymentCallback={(institutionCustomerBalanceId: string, autoPayAccountId: string): Promise<void> => {
                        return new Promise<void>((resolve) => {
                            console.log(`Payment Callback Triggered for balance ID ${institutionCustomerBalanceId} and account ID ${autoPayAccountId}`);
                            resolve();
                        });
                    }}
                />

            </Container>
            {/*</Paper>*/}
        </Fade>
    );
}

export default BalanceHistory;