import React, {useEffect, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import ApolloGqlQueryDisplay from "../components/data/ApolloGqlQueryDisplay";
import {getNewCustomerId} from "../utilities/localstorage/NewCustomerContextStore";
import {useCreateNewCustomerFromImportMutation} from "../components/data/graphqlTypes";
import {
    getUserInstitutionsAndUnits
} from "../components/context/UserInstitutionContext/getUnitsFromInstitution.graphql";
import {getCustomerProfile} from "../components/context/EntityContext/EntityGraphQL";
import {storeInstitutionIdInLocalStorage} from "../utilities/localstorage/InstitutionContextStore";


const CustomerImportSignUpCreateAccount = () => {

    const {institutionId} = useParams<{institutionId: string}>();

    storeInstitutionIdInLocalStorage(institutionId);
    const [redirect, setRedirect] = useState<string>();

    const customerId = getNewCustomerId();

    const [
        createNewCustomerFromImport,
        {
            loading,
            error
        }
    ] = useCreateNewCustomerFromImportMutation();

    useEffect(() => {
        if (customerId) {
            createNewCustomerFromImport({
                variables: {importedCustomerId: customerId, institutionId:  institutionId},
                refetchQueries: [
                    {query: getCustomerProfile},
                    {query: getUserInstitutionsAndUnits}
                ]
            })
                .then(result => {
                    if (result && result.data && result.data.createNewCustomerFromImport && result.data.createNewCustomerFromImport.customerId) {
                        // setRedirect("/userProfile");
                        setRedirect("/balanceHistory");
                    }
                })
                .catch(() => {/*Do nothing. handled elsewhere*/
                })
        }
    }, [customerId]);

    if (redirect) {
        return (
            <>
                <Redirect to={redirect}/>
            </>
        );
    }


    return <ApolloGqlQueryDisplay
        loadingMessage={`Creating your user profile`}
        error={error }
        loading={loading }
    />;

};

export default CustomerImportSignUpCreateAccount;
