import {BankSelect} from "../BankSelect";
import {Button, Grid} from "@material-ui/core";
import {Field, Formik, FormikHelpers, FormikProps} from "formik";
import {TextField} from "formik-material-ui";
import {ShiftModal} from "../../input/ShiftModal";
import ApolloGqlQueryDisplay from "../../data/ApolloGqlQueryDisplay";
import React, {useEffect, useRef, useState} from "react";
import {BankAccountDetail, RoutingCodeDetail} from "../../data/graphqlTypes";
import DirectDebitTermsBuilder from "../../PaymentOptions/DirectDebitTermsBuilder";
import * as yup from "yup";
import {getBankAccountInput, useUpsertAccount} from "./UpsertAccount";
import SelectCurrency from "../../input/SelectCurrency";
import {ALLOWED_PAYMENT_CURRENCIES} from "../../data/graphql/getCountriesAndCurrencies";

const validationSchema = yup.object().shape({
    bank: yup.object().required('Bank required.'),
    currency: yup.string().required('Currency required.'),
    accountNumber: yup.string().required('Account # is required')
        .max(20, 'Account # has a 20 character limit')
        .matches(/^[0-9a-zA-Z-]{1,20}$/, 'Invalid characters'),
});

interface Account {
    accountNumber: string;
    currency: string;
    bank: RoutingCodeDetail;
}

type PropsType = {
    setAccount: (account: BankAccountDetail) => void;
    defaultCurrency: string;
    account: BankAccountDetail | undefined;
    setEditMode: (editingNow: boolean) => void;
    closeAccountEditor: () => void;
    setIsPaymentOptionSaved?: (saved: boolean) => void;
}
const AccountAddUpdate = (props: PropsType) => {

    const [padTermsOpen, setPadTermsOpen] = useState(false);
    const formRef = useRef<FormikProps<Account>>(null);
    const {
        updateExistingAccount,
        createNewAccount,
        loading: upsertLoading,
        error: upsertError
    } = useUpsertAccount();

    const savePadTemplate = (currency: string,
                             accountNumber: string,
                             bankAccountId: string | undefined,
                             bank: RoutingCodeDetail
    ): Promise<BankAccountDetail> => {
        try {
            const bankAccountInput = getBankAccountInput(
                bankAccountId,
                currency,
                accountNumber,
                bank.address1,
                bank.city,
                bank.name,
                bank.province,
                bank.country,
                bank.postCode,
                bank.bankCode,
                bank.routingType,
                bank.routingCode
            );
            if (bankAccountId) {
                return updateExistingAccount(bankAccountInput);
            } else {
                return createNewAccount(bankAccountInput);
            }
        } catch (e) {
            console.error(e);
        }
        return Promise.reject('Unable to save changes.');
    }

    const onSubmit = async (values: Account, {setSubmitting}: FormikHelpers<Account>) => {
        try {
            const account = await savePadTemplate(values.currency, values.accountNumber, props.account?.id, values.bank);
            props.setAccount(account);
            props.setEditMode(false);
            setSubmitting(false);
            props.closeAccountEditor();
            if(!!props.setIsPaymentOptionSaved){
                props.setIsPaymentOptionSaved(true);
            }
            // setPadTermsOpen(false);
        } catch (e) {
            console.error(e);
        }
    };

    function cancelAddAccount() {
        props.setEditMode(false);
        props.closeAccountEditor();
    }

    useEffect(() => {
        props.setEditMode(true);
    }, []);

    const accountToEdit = props.account;
    return <Formik<Account>
        initialValues={{
            accountNumber: accountToEdit ? accountToEdit.accountNumber : "",
            currency: accountToEdit ? accountToEdit.currency : props.defaultCurrency,
            bank: {
                routingCode: accountToEdit?.routingCode,
            }
        } as Account}
        onSubmit={async (values, formikHelpers) => {
            try {
                await onSubmit(values, formikHelpers);
            } catch (e) {
                console.error(e);
            }

        }}
        innerRef={formRef}
        validationSchema={validationSchema}>
        {({values, isValid, dirty, submitForm, setFieldValue}) => {
            const termsBuilder = values.bank?.routingType ? new DirectDebitTermsBuilder(values.bank.routingType) : undefined;
            return <Grid container spacing={2} direction="column">
                <BankSelect
                    setSelectedBank={bank => {
                        setFieldValue("bank", bank);
                        // setSelectedBank(bank);
                    }}
                    originalBankId={""}
                    originalRoutingCode={accountToEdit?.routingCode}
                />
                <Grid item xs={12} sm={10} md={8}>
                    <SelectCurrency
                        setCurrencyCallBack={currency => setFieldValue('currency', currency.currencyCode)}
                        displayLabel={"Account Currency"}
                        allowedCurrencies={ALLOWED_PAYMENT_CURRENCIES}
                        currencyCode={values.currency}
                    />
                </Grid>
                <Grid item xs={12} sm={10} md={8}>
                    <Field
                        name={'accountNumber'}
                        fullWidth
                        variant={'outlined'}
                        disabled={!values.bank?.routingCode}
                        component={TextField}
                        label={"Account #"}
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>

                <Grid item xs={12} sm={10} md={8}>

                    {termsBuilder &&
                        <ShiftModal
                            open={padTermsOpen}
                            content={termsBuilder.buildTermsContent()}
                            title={termsBuilder.getTitle()}
                            accepted={async () => {

                                await submitForm()
                                    .catch(/* do nothing. errors caught in submitForm.*/);
                            }}
                            close={() => {
                                setPadTermsOpen(false);
                            }}
                            acceptButtonText={"Agree"}
                            closeButtonText={"Disagree"}
                            buttonGridXs={3}
                            buttonGridSm={2}
                            variant={undefined}
                        />
                    }
                </Grid>
                <Grid item xs={10}>
                    <Button variant="contained"
                            size="small"
                            type={"button"}
                            disabled={!(isValid && dirty)}
                            onClick={() => setPadTermsOpen(true)}
                    >{props.account?.id ? 'Update' : 'Save'}
                    </Button>

                    <Button
                        variant="contained"
                        size="small"
                        onClick={cancelAddAccount}
                    >
                        Cancel
                    </Button>
                </Grid>
                <ApolloGqlQueryDisplay
                    loading={upsertLoading}
                    loadingMessage={"Saving your bank account details"}
                    error={upsertError}
                />
            </Grid>
        }}
    </Formik>
}

export default AccountAddUpdate;