import {Institution, useGetInstitutionsByIdLazyQuery} from "../graphqlTypes";
import {ApolloError} from "@apollo/client";
import {useEffect} from "react";

type ReturnProps = {
    institution: Institution | undefined;
    loading: boolean;
    error: ApolloError | undefined;
    reload: (hoaCode: string) => void;
};


export const useGetInstitution = (hoaCode: string): ReturnProps => {
    const [getInstitutionsById, {data, loading, error}] = useGetInstitutionsByIdLazyQuery();

    useEffect(() => {
        if (!loading && !data && hoaCode && !error) {
            getInstitutionsById({variables: {institutionIds: [hoaCode]}});
        }
    }, [loading, data, hoaCode, getInstitutionsById, error]);

    const reload = (hoaCode: string) => {
        getInstitutionsById({variables: {institutionIds: [hoaCode]}});
    }

    return {
        institution: data && data.institutions.length > 0 ? data.institutions[0] as Institution  : undefined,
        loading,
        error,
        reload
    };
}
