import React from "react";
import {Typography} from "@material-ui/core";


const ErrorDisplay = (props: {
    error?: string,
}) => {
    return (
        <React.Fragment>
            {props.error &&
            <Typography
                key={props.error}
                variant={"caption"}
                color={"error"}
            >
                {props.error}
            </Typography>
            }
        </React.Fragment>
    );
};

export default ErrorDisplay;
