import {Button, Checkbox, FormControlLabel, Grid, IconButton, Link, Tooltip, Typography} from "@material-ui/core";
import React, {useCallback, useRef, useState} from "react";
import {buttonStyles} from "../input/TransactionCountryCurrencyAmounts";
import HttpsIcon from "@material-ui/icons/Https";
import {CreditCard, useGetBlueSnapCreditCardTokenQuery, useSaveBlueSnapCreditCardMutation,} from "../data/graphqlTypes";
import BlueSnapCreditCardForm, {GetBlueSnapToken} from "./BlueSnapCreditCardForm";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import {getBlueSnapCard} from "../data/graphql/creditCard.graphql";


type PropsType = {
    residenceCountryCode: string;
    addCreditCardCallBack: () => void;
    cancelCallback: () => void;
    setSelectedCreditCard?: (selectedCard: CreditCard) => void;
    institutionId: string;
    currency?: string;
    setIsPaymentOptionSaved?: any;
}

const BlueSnapCreditCardProcessorDisplay = (props: PropsType) => {

    const [blueSnapCardError, setBlueSnapCardError] = useState<string | Array<string>>("");
    const [checked, setChecked] = useState<boolean>(false);
    const [acceptTermsError, setAcceptTermsError] = useState<boolean>(false);
    const saveCardRef = useRef<GetBlueSnapToken>(null);
    const [addButtonClicked, setAddButtonClicked] = useState(false);


    const handleCheckboxChange = (event: any) => {
        setChecked(event.target.checked);
        setAcceptTermsError(false)
    };

    const {
        data: blueSnapCreditCardTokenData,
        loading: blueSnapCreditCardTokenLoading,
        error: blueSnapCreditCardTokenError
    } = useGetBlueSnapCreditCardTokenQuery({
        variables: {
            input: {
                residenceCountryCode: props.residenceCountryCode,
                institutionId: props.institutionId
            }
        },
        fetchPolicy: "no-cache"
    });
    const [saveBlueSnapCreditCard,
        {
            loading: saveCreditCardLoading,
            error: saveCreditCardError
        }
    ] = useSaveBlueSnapCreditCardMutation();

    const setSelectedCard = useCallback((card: CreditCard) => {
        if (!!props.setSelectedCreditCard) {
            props.setSelectedCreditCard(card);
        }
    }, [props.setSelectedCreditCard]);

    const saveCard = useCallback(async (currency: string) => {
        if (blueSnapCreditCardTokenData) {
            try {
                const creditCard = await saveBlueSnapCreditCard({
                    variables: {
                        input: {
                            cardCurrency: currency,
                            institutionId: props.institutionId,
                            token: blueSnapCreditCardTokenData?.getBlueSnapCreditCardToken.token
                        }
                    },
                    refetchQueries: [{
                        query: getBlueSnapCard,
                        variables: {
                            institutionId: props.institutionId
                        }
                    }],
                    awaitRefetchQueries: true,
                });
                props.addCreditCardCallBack();
                props.setIsPaymentOptionSaved(true);
                if (!!creditCard.data) {
                    setSelectedCard(creditCard.data.saveBlueSnapCreditCard as CreditCard);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, [props.addCreditCardCallBack, props.setIsPaymentOptionSaved, blueSnapCreditCardTokenData, props.institutionId]);

    const setFormError = useCallback((error: string | Array<string>) => {
        setAddButtonClicked(false);
        setBlueSnapCardError(error);
    }, [setAddButtonClicked, setBlueSnapCardError]);

    if (!blueSnapCreditCardTokenData) {
        return <ApolloGqlQueryDisplay
            loading={blueSnapCreditCardTokenLoading}
            loadingMessage={"Preparing..."}
            error={blueSnapCreditCardTokenError}
        />
    }

    return <Grid container direction="row" justifyContent="center"
                 alignItems="center">

        <BlueSnapCreditCardForm
            saveCreditCard={saveCard}
            setError={setFormError}
            blueSnapRequestComplete={() => setAddButtonClicked(false)}
            token={blueSnapCreditCardTokenData.getBlueSnapCreditCardToken.token}
            institutionId={props.institutionId}
            ref={saveCardRef}
            residenceCountryCode={props.residenceCountryCode}
            currency={props.currency}
        />
        <Grid item xs={12}>
            <FormControlLabel control={
                <Checkbox color="primary"/>}
                              checked={checked}
                              onChange={handleCheckboxChange}
                              label={
                                  <Link href={"https://www.payfromaway.io/credit-card-refund"} target="_blank"> I agree
                                      to the credit card terms and conditions </Link>
                              }/>
        </Grid>
        <ApolloGqlQueryDisplay
            loading={blueSnapCreditCardTokenLoading || saveCreditCardLoading}
            loadingMessage={"Saving Credit Card"}
            error={blueSnapCreditCardTokenError || blueSnapCardError || saveCreditCardError}/>
        {acceptTermsError &&
            <Grid item xs={12} >
                <Typography variant={"subtitle1"} style={{color:"red"}}> You have to agree to the terms and conditions </Typography>
            </Grid>
        }
        <Grid item xs={4} sm={6}>
            <Button
                variant={"text"}
                style={buttonStyles.buttonAsLink}
                onClick={() => props.cancelCallback()}>
                Cancel
            </Button>
        </Grid>
        <Grid item xs={8} sm={6}>
            <Button
                variant={"text"}
                disabled={addButtonClicked && !checked}
                style={buttonStyles.buttonAsLink}
                onClick={() => {
                    if (checked) {
                        setAcceptTermsError(false);
                        saveCardRef.current?.getToken();
                        setAddButtonClicked(true);
                    }else setAcceptTermsError(true)
                }}
            >Add Card</Button>
            <Tooltip title="Your credit card details go directly to BlueSnap for processing and storage.
                            PayFromAway never sees or stores your credit card details. BlueSnap is a PCI
                             compliant global payment processor."
            >
                <IconButton aria-label="Secure" style={{padding: 0}}>
                    <HttpsIcon/>
                </IconButton>
            </Tooltip>
        </Grid>
    </Grid>;

}
export default BlueSnapCreditCardProcessorDisplay;