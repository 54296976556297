import React, {useCallback, useEffect, useState} from "react";
import {Subject} from "rxjs";
import {TextField} from "@material-ui/core";
import {debounceTime} from "rxjs/operators";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";

const DEBOUNCE_DELAY = 1000;
const currencyAmount = new Subject<string>();

type CurrencyAmountInputPropsType = {
    currencyAmount: string,
    currencyAmountChangedCallback: (value: string) => void,
    displayLabel: string,
    currencyCode: string;
    errorText: string;
};


const CurrencyAmountInput = (props: CurrencyAmountInputPropsType) => {

    const currencyAmountChangedCallback = props.currencyAmountChangedCallback;
    const currencyCode = props.currencyCode;
    const currencyAmountNumber = String((+props.currencyAmount).toFixed(2));
    const [currentCurrencyAmount, setCurrentCurrencyAmount] = useState<string>(currencyAmountNumber);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const {currencyFormatter} = useGetCurrencyFormatter();
    const numberFormat = currencyFormatter(currencyCode || "CAD");
    const helperTextStyle = {color: 'red'};

    const publishAmountChanged = useCallback((value: string) => {
        const trimmedValue = value.trim();
        currencyAmount.next(trimmedValue);
        setCurrentCurrencyAmount(trimmedValue);
    }, [setCurrentCurrencyAmount]);

    const handleChangedAmount = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let value: string = e.currentTarget.value;
        if (parseFloat(value) < 0) {
            value = "0";
        }
        publishAmountChanged(value);
    }, [publishAmountChanged]);

    useEffect(() => {
        const subscription = currencyAmount.pipe(debounceTime(DEBOUNCE_DELAY)).subscribe(currencyAmountChangedCallback);
        return () => {
            subscription.unsubscribe();
        }
    }, [currencyAmount, currencyAmountChangedCallback])

    let formattedCurrentCurrencyAmount = currentCurrencyAmount ? numberFormat.format(Number(currentCurrencyAmount)) : "";
    return (<React.Fragment>
        {isEditing ? (
            <TextField
                fullWidth
                label={props.displayLabel}
                variant={"outlined"}
                type="number"
                value={currentCurrencyAmount}
                onChange={e => {
                    handleChangedAmount(e)
                }}
                InputLabelProps={{shrink: true}}
                autoFocus={true}
                error={!currencyAmount && !!props.errorText}
                onBlur={() => setIsEditing(false)}
                inputProps={{inputMode: 'decimal'}}
                helperText={<span style={helperTextStyle}>{props.errorText}</span>}
            />
        ) : (
            <TextField
                fullWidth
                label={props.displayLabel}
                variant={"outlined"}
                InputLabelProps={{shrink: true}}
                value={formattedCurrentCurrencyAmount}
                onFocus={() => setIsEditing(true)}
                autoFocus={true}
                inputProps={{inputMode: 'decimal'}}
                error={!currencyAmount || !!props.errorText}
                helperText={<span style={helperTextStyle}>{props.errorText}</span>}
            />
        )}
    </React.Fragment>);
}

export default CurrencyAmountInput;
