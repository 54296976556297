import {
    faClock,
    faFileInvoiceDollar, faHandshake,
    faLaptopHouse,
    faProjectDiagram,
    faUmbrellaBeach
} from '@fortawesome/free-solid-svg-icons';



export const services = [
    {
      icon: faClock,
      title: 'Added Efficiency',
      subtitle:
        "When you partner with Pay From Away for your HOA payment processing, you're taking a key step in the simplification of your accounting process.",
    },
    {
      icon: faProjectDiagram,
      title: 'A Holistic Payments Solution',
      subtitle:
        'We support you in everything from tracking your fees and incoming payments to reconciliation, payment scheduling, and fraud control.',
    },
    {
      icon: faUmbrellaBeach,
      title: 'Elevated Resident Experience',
      subtitle:
        "The happiness and ease of you and your residents is our main priority and our payments platform gives them the opportunity to relax and enjoy the luxurious experience you already provide.",
    },
  ];

  export const about = [
    {
      icon: faLaptopHouse,
      title: 'Property fees',
      subtitle:
        "Users are invited to instantly pay their property fees via our platform. The platform securely saves user info and offers regularly scheduled automated payments for added convenience.",
    },
    {
      icon: faFileInvoiceDollar,
      title: 'Additional expenses',
      subtitle:
        'Users can add additional expenses such as an associated golf membership, beach club fees, parking fees, or anything that may add to the all-around luxury of your offerings.',
    },
    {
      icon: faHandshake,
      title: 'Expert support',
      subtitle:
        "We know that some users are more comfortable with electronics and e-commerce than others. Our team is available via email, live chat, or phone to support your residents every step of the way.",
    },
  ];
  
    
  export const faq = [
    {
      id: 'faq-1',
      title: 'Data Security',
      text:
        'Your personal and financial information is safeguarded through industry-leading MFA, encryption, and privacy.',
    },
    {
      id: 'faq-2',
      title: 'Dashboard',
      text:
        'Visit your personal administrator’s dashboard to view, export, and print transaction history for your records.',
    },
    {
      id: 'faq-3',
      title: 'Custom link',
      text:
        'You will receive a unique link to your HOA’s custom web portal so you and your users can access it in one simple click.',
    },
    {
      id: 'faq-4',
      title: 'Recurring',
      text:
        'Your admin is able to schedule recurring monthly withdrawal dates for added efficiency.',
    },
    {
      id: 'faq-5',
      title: 'Fraud Control',
      text:
        'Association banking details are private, visible on your dashboard only, eliminating email spoofing and wire fraud.',
    },
    {
      id: 'faq-6',
      title: 'Multi-device',
      text:
        'You can easily access your HOA web portal and dashboard via your mobile device, tablet, or desktop.',
    },
    
  ];
  

  