import React, { CSSProperties, ReactNode } from "react";
import Navigation from "../navigation/ShiftAppNavBar";
import Footer from "./footer";

const navigationHeight = "75px";
const footerHeight = "110px";

const styles: {
    height: CSSProperties,
    content: CSSProperties
} = {
    height: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: "100vh",
        maxWidth: "100vw"
    },
    content: {
        flexGrow: 1,
        overflow: "auto",
        minHeight: `calc(100vh - ${navigationHeight} - ${footerHeight})`,
        // backgroundColor: "#f2f2f2",
    }
};

interface MyProps {
    children: ReactNode;
}

const Layout = (props: MyProps) => {
    return (
        <div style={styles.height}>
            <Navigation />
            <div style={styles.content}>
                {props.children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
