import {
    AutoPayConfiguration,
    InstitutionCustomerBalance,
    useGetAutoPayConfigurationsQuery,
    useGetCurrentBalancesQuery
} from "../data/graphqlTypes";
import {Grid} from "@material-ui/core";
import React from "react";
import AutoPayConfigurationDisplay from "../autopay/AutoPayConfigurationDisplay";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";


const AutoPayConfigurations = () => {


    const {
        data: autoPayData,
        loading: autoPayLoading,
        error: autoPayError
    } = useGetAutoPayConfigurationsQuery();

    const {
        data: currentBalancesData,
        error: currentBalancesError,
        loading: currentBalancesLoading
    } = useGetCurrentBalancesQuery();


    if (autoPayError || autoPayLoading) {
        return <ApolloGqlQueryDisplay
            loading={autoPayLoading || currentBalancesLoading}
            error={autoPayError || currentBalancesError}
        />
    }

    let autoPayConfigurations = autoPayData?.getAutoPayConfigurations;
    return <Grid container direction={"column"}
                 justifyContent={"space-evenly"}>
        {autoPayConfigurations && autoPayConfigurations.length > 0 && currentBalancesData &&
            autoPayConfigurations
                .map(autoPayConfig => autoPayConfig as AutoPayConfiguration)
                .sort((a, b) => a.institution.name > b.institution.name ? 1 : -1)
                .map(autoPayConfig =>
                    <AutoPayConfigurationDisplay
                        key={autoPayConfig.id}
                        autoPayConfig={autoPayConfig}
                        accountBalances={currentBalancesData?.getCurrentBalances as Array<InstitutionCustomerBalance> || []}
                        accountPaymentCallback={(institutionCustomerBalanceId, autoPayAccountId) => {
                            return Promise.reject("When is this called [" + institutionCustomerBalanceId + " -- " + autoPayAccountId + "]");
                        }}
                    />
                )
        }
    </Grid>
}

export default AutoPayConfigurations;
