import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {AutoPayConfiguration, Institution, InstitutionCustomerBalance} from "../data/graphqlTypes";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import {PaymentMethodCombined, useGetAvailablePaymentMethods} from "./useGetAvailablePaymentMethods";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import {makeStyles} from "@material-ui/core/styles";
import {parseISO} from "date-fns";
import {storeSelectedBalanceToLocalStorage} from "../../utilities/localstorage/BalanceLocalStore";
import {useHistory} from "react-router-dom";
import {Add} from "@material-ui/icons";
import {Customer} from "../context/EntityContext/useGetCustomer";

import UserProfile from "../input/UserProfile";
import {HomeLocationContextProvider} from "../context/HomeLocationContext";
import CreditCards from "../PaymentOptions/CreditCard/CreditCards";
import DirectDebitEditor from "../DirectDebit/DirectDebitEditor";
import {ShiftModal} from "../input/ShiftModal";
import {PAYMENT_OPTION_STYLES} from "../PaymentOptions/ETranferInstructions";

const useStyles = makeStyles((theme) => ({
        amount: {
            fontWeight: "bold",
        },
        balanceCard: {
            backgroundColor: "#f5f5f5",
            padding: "20px",
            borderRadius: "8px",
            margin: "10px",
            [theme.breakpoints.down('md')]: {
                margin: "10px, 50px",
            },
        },
        customButton: {
            '&:hover': {
                backgroundColor: 'transparent',
                color: "#4B1269"
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(0),
        },
        description: {
            margin: theme.spacing(0.5),
        },
        customerBalanceId: {
            position: "absolute",
            top: "10px",
            right: "10px",
        },
        balanceValue: {
            fontSize: "1.0rem",
            color: "#555",
            marginLeft: "10px",
            marginTop: "5px",
            fontWeight: "bold",
        },
        formControl: {
            maxWidth: '100%',

        },
        label: {
            backgroundColor: 'transparent',
            paddingLeft: '8px'
        },
        select: {
            [theme.breakpoints.down('xs')]: {
                fontSize: ".85rem"
            },
            fontSize: "1rem"
        },
        menuItems: {
            [theme.breakpoints.down('xs')]: {
                fontSize: ".7rem"
            },
            fontSize: "1rem"
        },
        imageLogo2: {
            maxHeight: "125px",
            maxWidth: "125px",
            borderColor: "transparent",
        },
        cardContainer: {
            minWidth: 200,
            maxWidth: 600,
            maxHeight: "fit-content",
            marginTop: '2vh',
            marginRight: '5px',
            marginLeft: '5px',
            marginBottom: '5vh',
            border: '0px solid #4D3B71',
            borderRadius: 12,
            [theme.breakpoints.up("md")]: {
                maxWidth: 800,
                minWidth: 800,
            },
            [theme.breakpoints.down("sm")]: {
                marginTop: '2vh',
            },
        },
        gridMargin: {
            marginBottom: '20px',
        },
        titleTypography: {
            marginBottom: "20px",
            marginLeft: "100px",
            marginRight: "100px",
            [theme.breakpoints.down("sm")]: {
                marginLeft: "5px",
                marginRight: "5px",
            },
        },
        titleTypography2: {
            color: '#913AFF',
            paddingBottom: '5px',
        },
        btnClose: {
            marginTop: '30px',
        }
    }
));

interface PropsType {
    balance: InstitutionCustomerBalance,
    dueDate: string,
    autoPayConfiguration?: AutoPayConfiguration,
    showAccounts: () => void,
    institution?: Institution,
    amountRemaining: number,
    accountPayment: (
        institutionCustomerBalanceId: string,
        paymentMethod2: PaymentMethodCombined,
        balanceInstitution: Institution | undefined,
        amountRemainig: number
    ) => Promise<void>,
    customer: Customer | undefined,
}

export interface SaveUserProfileRef {
    saveSubmitUserProfile: () => Promise<boolean>;
}

const BalanceCard = ({
                         balance,
                         dueDate,
                         autoPayConfiguration,
                         showAccounts,
                         accountPayment,
                         institution,
                         customer,
                         ...props
                     }: PropsType) => {
    const classes = useStyles();
    const cardClasses = PAYMENT_OPTION_STYLES();
    const currencyFormatter = useGetCurrencyFormatter();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodCombined>();
    const balanceAsOfDate = new Date(parseISO(balance.createdDate as string)).toLocaleDateString();
    const [openModal, setOpenModal] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [userProfileEditMode, setUserProfileEditMode] = useState(false);
    const userProfileRef = useRef<SaveUserProfileRef>(null);
    const customerExists = !!customer;

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleOpenPaymentMethodsModal = () => {
        setOpenPaymentModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    interface PaymentMethodsModalContentProps {
        setIsPaymentOptionSaved?: any;
    }

    useEffect(() => {
        if (!customerExists) {
            setOpenModal(true);
        }
    }, [customerExists]);

    const [isPaymentOptionSaved, setIsPaymentOptionSaved] = useState(false)

    useEffect(() => {
        if (isPaymentOptionSaved) {
            setOpenPaymentModal(false)
            setIsPaymentOptionSaved(false)
        }
    }, [isPaymentOptionSaved]);

    const PaymentMethodsModalContent = ({setIsPaymentOptionSaved}: PaymentMethodsModalContentProps) => (

        <Box sx={{minWidth: "80vw"}}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>

                <Grid item xs={11}>
                    <Typography variant="h2" align='center' className={classes.titleTypography2}>
                        Add A Payment Option
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.cardContainer}>
                    <Grid item className={classes.titleTypography}>
                        <Typography variant="h2">
                            Credit Cards
                        </Typography>
                    </Grid>

                    <Grid className={classes.gridMargin}>
                        {institution &&
                            <Card className={cardClasses.card}>
                                <CardContent>
                                    <Grid container direction="row" justifyContent="space-between"
                                          alignItems="center">
                                        <Grid item xs={3} sm={2}>
                                            <img
                                                src={institution.logoUrl}
                                                alt={institution.name}
                                                style={{maxWidth: '100%', height: 'auto'}}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <CreditCards institutionId={institution.institutionId}
                                                         setIsPaymentOptionSaved={setIsPaymentOptionSaved}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }
                    </Grid>

                    <Grid item xs={12} className={classes.titleTypography}>
                        <Typography variant="h2">
                            Direct Debit Accounts
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <DirectDebitEditor setIsPaymentOptionSaved={setIsPaymentOptionSaved}/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );

    const locationStateHistory = useHistory();

    const {
        data: paymentMethodData,
        error: paymentMethodError,
        loading: paymentMethodLoading,
        refetch: paymentMethodRefresh

    } = useGetAvailablePaymentMethods(balance.institutionId);
    useEffect(() => {
        if (paymentMethodData && paymentMethodData.length > 0) {
            setPaymentMethod(paymentMethodData[0]);
        }
    }, [paymentMethodData]);

    const setSelectedPaymentMethod = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedMethodName = event.target.value as string;
        const selectedPaymentMethod = paymentMethodData.find((method) => method.name === selectedMethodName);
        setPaymentMethod(selectedPaymentMethod);
    };
    const displayLoadingOrError = paymentMethodError || paymentMethodLoading;


    const payTotalButton = <><Button
        size={"large"}
        variant="outlined"
        fullWidth
        disabled={!paymentMethod || props.amountRemaining === 0}
        onClick={async () => {
            if (paymentMethod) {
                return await accountPayment(balance.institutionCustomerBalanceId, paymentMethod, institution, props.amountRemaining);
            }
            storeSelectedBalanceToLocalStorage(balance);
            locationStateHistory.push("/paypartialbalance")
        }}
    >
        Pay total
    </Button></>;
    return <HomeLocationContextProvider
        institution={institution}
    >
        <Paper key={balance.institutionCustomerBalanceId}
               className={classes.balanceCard}>
            <>
                <Grid container className={classes.header}>
                    {displayLoadingOrError &&
                        <Grid item xs={12}>
                            <ApolloGqlQueryDisplay
                                loading={paymentMethodLoading}
                                error={paymentMethodError}
                            />
                        </Grid>
                    }
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h2" display="inline" style={{fontWeight: 'bold', marginRight: 8}}>
                                Balance:
                            </Typography>
                            <Typography variant="h2" display="inline" className={classes.balanceValue}>
                                {currencyFormatter.currencyFormatter(balance.amount.currencyCode).format(props.amountRemaining)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component={"span"} variant={"h6"} display="inline" style={{marginRight: 8}}>
                                Balance as of:
                            </Typography>
                            <Typography component={"span"} variant={"h6"} display="inline">
                                {`${balanceAsOfDate}`}
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Divider variant={"fullWidth"} style={{marginBottom: "15px"}}></Divider>
                <Grid container>
                    <Grid item xs={12} className={classes.description}>
                        <Typography variant={"h6"}>{balance.institutionName}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.description}>
                        <Typography component={"span"} variant={"h6"}>Description:</Typography>
                        <Typography component={"span"}>{` ${balance.description}`}</Typography>
                    </Grid>
                    {!!balance.pullDate &&
                        <Grid container>
                            <Grid item xs={6} className={classes.description}>
                                <Typography component={"span"} variant={"h6"}>Due Date:</Typography>
                                <Typography component={"span"}>{` ${dueDate}`}</Typography>
                            </Grid>
                        </Grid>
                    }
                    {balance?.transactions && balance?.transactions.length > 0 &&
                        <>
                            <Grid item xs={12}>
                                <Typography component={"span"} variant={"h6"} className={classes.description}>
                                    Original Balance:
                                </Typography>
                                <Typography
                                    component={"span"}>{currencyFormatter.currencyFormatter(balance.amount.currencyCode).format(parseFloat(balance.amount.amount))}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component={"span"} variant={"h6"} className={classes.description}>
                                    Associated Transactions:
                                </Typography>
                                <Typography component={"span"}>
                                    {balance?.transactions.map(value => {
                                        return <Button key={value.id} size={"medium"} variant={"text"}
                                                       onClick={() => locationStateHistory.push(`/transactiondetails/${value.id}`)}
                                        >
                                            {value.id}
                                        </Button>;
                                    })}
                                </Typography>
                            </Grid>
                        </>
                    }
                    {(!!autoPayConfiguration?.bankAccount || !!autoPayConfiguration?.creditCard) && !!balance.pullDate ?

                        <Grid item xs={12} className={classes.description}>
                            <Typography component={"span"} style={{marginLeft: 16}}>Your all set for auto payments, your
                                payment will be processed:</Typography>
                            <Typography component={"span"} variant={"h6"}>{` ${dueDate}.`}</Typography>
                        </Grid>
                        :
                        props.amountRemaining !== 0 &&
                        <Grid item xs={12}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs>

                                    {!customer ? (
                                        <><Typography
                                            variant={"h6"}
                                            className={classes.description}
                                            color={"primary"}
                                            onClick={handleOpenModal}
                                        >
                                            Please Complete Your User Profile
                                        </Typography>
                                            <ShiftModal
                                                title={"Enter Your Personal Details To Proceed"}
                                                maxWidth={"md"}
                                                accepted={() => setOpenModal(false)}
                                                close={handleCloseModal}
                                                open={openModal}
                                                content={<Box>
                                                    <Grid container direction="row" justifyContent="center"
                                                          alignItems="center">
                                                        <Grid item xs={12}>
                                                            <UserProfile
                                                                hoaId={balance.institutionId}
                                                                ref={userProfileRef}
                                                                setEditMode={setUserProfileEditMode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                variant="text"
                                                                onClick={async () => {
                                                                    await userProfileRef.current?.saveSubmitUserProfile();
                                                                    handleCloseModal();
                                                                }}
                                                                disabled={!userProfileEditMode}
                                                            >
                                                                Save Profile
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>}/>
                                        </>) : (
                                        <>
                                            <Typography variant="h6" className={classes.description}>
                                                Payment Method:
                                            </Typography>
                                            <FormControl variant="outlined" className={classes.formControl} style={{
                                                width: 'auto',
                                                marginRight: 8,
                                                marginBottom: 8
                                            }}>
                                                {customer && paymentMethodData.length === 0 ?
                                                    <Tooltip
                                                        title="Add a payment method">
                                                        <IconButton onClick={handleOpenPaymentMethodsModal}
                                                                    color="primary"
                                                                    className={classes.customButton}
                                                                    style={{marginLeft: -2}}>
                                                            Please Add a Payment Method
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    <>
                                                        {paymentMethod && paymentMethodData.length === 1 &&
                                                            <Typography variant={"h6"} color={"primary"}
                                                                        style={{marginLeft: 12}}>{paymentMethod.name}
                                                                <Tooltip title="Add a payment method">
                                                                    <IconButton onClick={handleOpenPaymentMethodsModal}
                                                                                color="primary"
                                                                                style={{marginLeft: 2}}>
                                                                        <Add fontSize="inherit"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Typography>
                                                        }
                                                        {paymentMethod && paymentMethodData.length > 1 &&
                                                            <><Select
                                                                className={classes.select}
                                                                id="payment-method-select"
                                                                value={paymentMethod ? paymentMethod.name : ''}
                                                                onChange={(event) => {
                                                                    const selectedValue = event.target.value;
                                                                    if (selectedValue === "add-payment-method") {
                                                                        handleOpenPaymentMethodsModal();
                                                                    } else {
                                                                        setSelectedPaymentMethod(event);
                                                                    }
                                                                }}
                                                                displayEmpty
                                                            >
                                                                {paymentMethodData.map((method) => (
                                                                    <MenuItem className={classes.menuItems}
                                                                              key={method.name}
                                                                              value={method.name}>
                                                                        {method.name}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem className={classes.menuItems}
                                                                          key="add-payment-method"
                                                                          value="add-payment-method">
                                                                    <Box display="flex" alignItems="center">
                                                                        <Add fontSize="small" style={{marginRight: 8}}/>
                                                                        Add Payment Option
                                                                    </Box>
                                                                </MenuItem>
                                                            </Select></>
                                                        }
                                                    </>
                                                }
                                            </FormControl>
                                            {openPaymentModal &&
                                                <ShiftModal
                                                    title={""}
                                                    maxWidth={"xl"}
                                                    accepted={() => setOpenPaymentModal(false)}
                                                    close={() => {
                                                        setOpenPaymentModal(false);
                                                        window.location.reload();
                                                    }}
                                                    content={
                                                        <PaymentMethodsModalContent
                                                            setIsPaymentOptionSaved={async (saved: boolean) => {
                                                                setIsPaymentOptionSaved(saved);
                                                                await paymentMethodRefresh();
                                                            }}
                                                        />
                                                    }
                                                    open={true}
                                                    closeButtonText={'Close'}
                                                />
                                            }
                                        </>
                                    )}

                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Grid container={true} direction={"row"} justifyContent={"space-evenly"}>
                                    <Grid item xs={6}>

                                        {paymentMethod ? payTotalButton :
                                            <Tooltip title={"Requires at least one payment method"}>
                                                {payTotalButton}
                                            </Tooltip>
                                        }

                                    </Grid>
                                    <Grid item md={6}>
                                        <Button
                                            size={"large"}
                                            variant="outlined"
                                            fullWidth
                                            onClick={async () => {
                                                storeSelectedBalanceToLocalStorage(balance);
                                                locationStateHistory.push("/paypartialbalance")
                                            }}
                                            disabled={!paymentMethod || props.amountRemaining === 0}
                                        >
                                            Partial Payment
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </>
        </Paper>
    </HomeLocationContextProvider>
        ;
}

export default BalanceCard;