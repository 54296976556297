import React, {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {ApolloProvider} from "@apollo/client";
import {createApolloClient} from "../../../authorization/createApolloClient";
import jwtDecode, {JwtPayload} from "jwt-decode";
import {fromUnixTime} from "date-fns/fp";
import {isPast} from "date-fns/esm";
import {Loading} from "../../layout";
import useAuthorization from "../../auth/useAuthorization";

interface MyProps {
    children: ReactNode;
}

export function tokenIsActive(token: string | undefined): boolean {
    if (!token) {
        return false;
    }
    const decodedJwt = jwtDecode<JwtPayload>(token);
    if (decodedJwt.exp) {
        const expiryDate = fromUnixTime(decodedJwt.exp);
        return !isPast(expiryDate);
    }
    return false;
}

const ShiftApolloProvider = ({children}: MyProps): JSX.Element => {

    const {isLoading, isAuthenticated, getToken: getAccessTokenSilently} = useAuthorization()
    const [token, setToken] = useState<string>();

    const refreshTokenIfAuthenticated = useCallback(async (): Promise<string | void> => {
        if (isLoading) {
            return Promise.resolve();
        }
        if (isAuthenticated && !tokenIsActive(token)) {
            try {
                const token = await getAccessTokenSilently();
                setToken(token);
                return Promise.resolve(token);
            } catch (error) {
                console.error('Error getting access token', error);
                window.location.reload();
            }
        }
    }, [isLoading, isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        refreshTokenIfAuthenticated();
    }, [refreshTokenIfAuthenticated]);

    const stillLoadingToken = isAuthenticated && !tokenIsActive(token);
    if (isLoading || stillLoadingToken) {
        return <Loading
            message={"Authenticating"}
        />;
    }

    const client = createApolloClient(token, refreshTokenIfAuthenticated);

    return (
        <ApolloProvider
            client={client}
        >
            {children}
        </ApolloProvider>
    );
};

export default ShiftApolloProvider;
