import {TransactionStatusEventType} from "../data/graphqlTypes";

const translateTransactionStatus = (status: TransactionStatusEventType): string => {
    switch (status) {
        case TransactionStatusEventType.Cancelled:
            return "Cancelled";
        case TransactionStatusEventType.Pending:
            return "Pending";
        case TransactionStatusEventType.Completed:
            return "Complete";
        case TransactionStatusEventType.Nsf:
            return "Insufficient funds";
        case TransactionStatusEventType.InvalidAccount:
            return "Invalid account number";
        case TransactionStatusEventType.Chargeback:
            return "Payment chargeback";
        default:
            return "Status Unavailable";
    }
}
export default translateTransactionStatus;