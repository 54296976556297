import {Region} from "../components/data/graphqlTypes";

export function getRegionFromListByRegionCode(regions: Array<Region>, regionCode: string): Region | undefined {
    const foundRegion = regions.find((region) => {
        return region.regionCode.toLowerCase() === regionCode.toLowerCase();
    });
    // if(!foundRegion){
    //     throw new Error(`Could not find region for code: ${regionCode}`);
    // }
    return foundRegion;
}