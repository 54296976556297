import React, {useContext, useEffect, useState} from "react";
import {CurrentTransactionContext} from "./context/CurrentTransactionContext";
import PaymentStepper from "./PaymentStepper";
import {AccountRoutingType, BankAccountDetail, CreditCard, PaymentInput} from "./data/graphqlTypes";
import {useGetInstitution} from "./data/graphql/useGetInstitution";
import {useGetUserInstitutions} from "./context/UserInstitutionContext/useGetUserInstitutions";
import {useLastTransactionRetrieved} from "./input/useLastTransactionRetrieved";
import {storeInstitutionIdInLocalStorage} from "../utilities/localstorage/InstitutionContextStore";
import {isNaN} from "formik";
import ApolloGqlQueryDisplay from "./data/ApolloGqlQueryDisplay";
import {Grid} from "@material-ui/core";
import {InstitutionSearch} from "./institution/InstitutionSearch";
import TransactionCountryCurrencyAmounts from "./input/TransactionCountryCurrencyAmounts";
import TransactionSummary from "./input/TransactionSummary";
import {TRANSACTION_DETAILS_STEP, USER_PROFILE_STEP} from "../views/HoaPayment";
import {isDirectDebit} from "./PaymentOptions/accountAndRoutingTypeIsValid";


type PropsType = {
    hoaCode: string
}

export function getCorrectRoutingType(account: BankAccountDetail | undefined,
                                      routingType: AccountRoutingType | undefined) {
    if (!!account && isDirectDebit(routingType)) {
        return account.routingType;
    }
    return routingType;
}


const CurrentTransactionContextPaymentStepper = (props: PropsType) => {
    const {
        currentTransaction,
        currentTransactionCost,
        currentTransactionRate,
        currentTransactionId
    } = useContext(CurrentTransactionContext);

    const [activeStep, setActiveStep] = useState<number>(TRANSACTION_DETAILS_STEP);
    const [account, setAccount] = useState<BankAccountDetail>();
    const [routingType, setRoutingType] = useState<AccountRoutingType>()
    const [acceptRecurringPaymentTerms, setAcceptRecurringPaymentTerms] = useState<boolean>(false);
    const [creditCard, setCreditCard] = useState<CreditCard>();
    const [costToUser, setCostToUser] = useState(0);
    const [paymentInput, setPaymentInput] = useState<PaymentInput>({
        institutionId: "",
        payeeReceives: "",
        paymentReference: "",
        rateId: ""
    });

    useEffect(() => {
            if (currentTransaction
                && (currentTransaction.amount !== paymentInput.payeeReceives
                    || currentTransaction.paymentReference !== paymentInput.paymentReference)) {
                setPaymentInput(prevState => {
                    return {
                        ...prevState,
                        paymentReference: currentTransaction.paymentReference,
                        payeeReceives: currentTransaction.amount
                    }
                })
            }

        }, [currentTransaction]
    );

    const {
        institution,
        loading: institutionLoading,
        error: institutionLoadingError
    } = useGetInstitution(props.hoaCode);

    const {userInstitutions} = useGetUserInstitutions({institutionId: props.hoaCode});

    const {
        shouldRefreshRates,
        lastTransactionRetrieved,
        setLastTransaction,
        resetLastTransactionRetrieved
    } = useLastTransactionRetrieved();
    let rateExpiryInMinutes = lastTransactionRetrieved.currentTransactionRateValidFor;


    useEffect(() => {
        if (institution) {
            storeInstitutionIdInLocalStorage(institution.institutionId);
            setPaymentInput(prevState => {
                return {
                    ...prevState,
                    institutionId: institution.institutionId,
                }
            })
        }
    }, [institution]);

    useEffect(() => {
        if (paymentInput.rateId !== currentTransactionId) {
            setPaymentInput(prevState => {
                return {
                    ...prevState,
                    rateId: currentTransactionId,
                }
            })
        }
    }, [currentTransactionId]);

    useEffect(() => {
        if (userInstitutions) {
            const customerInstitutionWithAcceptedRecurring = userInstitutions.find(value => !!value && props.hoaCode === value.id && value.acceptRecurringPaymentTerms);
            if (!customerInstitutionWithAcceptedRecurring) {
                return;
            }
            setAcceptRecurringPaymentTerms(true);
        }
    }, [userInstitutions, props.hoaCode]);

    function setCostToUserCallback(cost: string) {
        if (isNaN(Number(cost))) {
            return;
        }
        setCostToUser(Number(cost));
    }

    function setAccountRoutingType(accountRoutingType: AccountRoutingType | undefined){
        setRoutingType(accountRoutingType);
        if(routingType?.toUpperCase() === "ETRANSFER") {
            setAcceptRecurringPaymentTerms(false);
            setAccount(undefined);
        }
    }

    const submitRoutingType = getCorrectRoutingType(account, routingType);

    if (institutionLoading || institutionLoadingError) {
        return <ApolloGqlQueryDisplay
            loading={institutionLoading}
            loadingMessage={"Loading"}
            error={institutionLoadingError}
        />
    }

    if (!institution) {
        return <Grid container justifyContent={"center"}>
            <InstitutionSearch
                hoaCode={props.hoaCode}/>
        </Grid>;
    }

    const transactionDetailsStep = <TransactionCountryCurrencyAmounts
        hoaCode={institution.institutionId}
        nextButtonAction={() => {
            setActiveStep(USER_PROFILE_STEP)
        }}
        costToUserChangedCallback={setCostToUserCallback}
        shouldRefreshRates={shouldRefreshRates}
        setLastTransactionRetrieved={setLastTransaction}
        lastTransactionRetrieved={lastTransactionRetrieved}
        institution={institution}
        resetLastTransactionRetrieved={resetLastTransactionRetrieved}
    />;

    let transactionSummary: JSX.Element | undefined = undefined;
    if (!!institution &&
        !!submitRoutingType &&
        !!currentTransactionRate &&
        !!currentTransactionCost &&
        !!currentTransaction.paymentReference &&
        !!currentTransaction.selectedAccountCurrency &&
        !!currentTransaction.targetCurrencyCode
    ) {
        transactionSummary = <TransactionSummary
            institution={institution}
            paymentMethod={submitRoutingType}
            editStep={() => setActiveStep(TRANSACTION_DETAILS_STEP)}
            creditCard={creditCard}
            selectedAccountCurrency={currentTransaction.selectedAccountCurrency}
            payorPaysAmount={String(currentTransactionCost)}
            targetCurrency={currentTransaction.targetCurrencyCode}
            payeeReceivesAmount={currentTransaction.amount}
            paymentReference={currentTransaction.paymentReference}
            rate={currentTransactionRate}
        />;
    }

    return <PaymentStepper
        institution={institution}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        account={account}
        setAccount={setAccount}
        routingType={routingType}
        setRoutingType={setAccountRoutingType}
        creditCard={creditCard}
        setCreditCard={setCreditCard}
        acceptRecurringPaymentTerms={acceptRecurringPaymentTerms}
        setAcceptRecurringPaymentTerms={setAcceptRecurringPaymentTerms}
        submitRoutingType={submitRoutingType}
        transactionSummary={transactionSummary}
        transactionDetails={transactionDetailsStep}
        rateExpiresInMinutes={rateExpiryInMinutes}
        costToUser={costToUser}
        enforceCurrency={currentTransaction.selectedAccountCurrency}
        paymentCurrency={currentTransaction.selectedAccountCurrency}
        targetCurrency={currentTransaction.targetCurrencyCode}
        paymentInput={paymentInput}
        invoiceId={undefined}
        institutionCustomerBalanceId={undefined}
    />

}

export default CurrentTransactionContextPaymentStepper;