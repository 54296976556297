import {AccountRoutingType} from "../data/graphqlTypes";

export type SelectedPaymentOptionType = {
    label: string;
    routingType: AccountRoutingType;
}
export const ETRANSFER = {
    label: 'E-Transfer',
    routingType: AccountRoutingType.Etransfer
}
export const DIRECT_DEBIT = {
    label: 'Direct Debit',
    routingType: AccountRoutingType.AchDebit
};
export const PAD = {
    label: 'Pre-Authorized Debit',
    routingType: AccountRoutingType.Pad
};
export const ACH_DEBIT = {
    label: 'Automated Clearing House Debit (ACH)',
    routingType: AccountRoutingType.AchDebit
}
export const SWIFT = {
    label: 'Wire Transfer',
    routingType: AccountRoutingType.Swift
}
export const CREDIT_CARD = {
    label: 'Credit Card',
    routingType: AccountRoutingType.CreditCard
}

export const getPaymentOptionLabelForRoutingType =(routingType: AccountRoutingType) => {
    return getPaymentOptionTypeForRoutingType(routingType).label;
}

export const getPaymentOptionTypeForRoutingType = (routingType: AccountRoutingType) => {
    switch(routingType){
        case AccountRoutingType.Etransfer:
            return ETRANSFER;
        case AccountRoutingType.Pad:
            return PAD;
        case AccountRoutingType.AchDebit:
            return ACH_DEBIT;
        case AccountRoutingType.Swift:
            return SWIFT;
        case AccountRoutingType.CreditCard:
            return CREDIT_CARD;
        default:
            throw new Error(`PaymentOptionType not found for ${routingType.toString()}`);
    }
}