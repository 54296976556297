import {useHistory, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {HoaPayment} from "../../views";

interface Params {
    hoaCode: string;
    amountToSend: string;
}

export function HoaLegacyDemoRedirect() {
    const {hoaCode, amountToSend}: Params = useParams();
    const history = useHistory();
    useEffect(() => {
        history.push(`/${hoaCode}/${amountToSend}`);
    },[history, hoaCode, amountToSend]);

    return <HoaPayment/>;
}