import {useEffect} from "react";
import {GetCustomerProfileQuery, useGetCustomerProfileLazyQuery} from "../../data/graphqlTypes";
import {ApolloError} from "@apollo/client";
import useAuthorization from "../../auth/useAuthorization";

export type Customer = {
    firstName: string;
    lastName: string;
    mobile: string;
    countryCode: string;
    regionCode: string;
    address: string;
    postalCode: string;
    city: string;

}

function getEntity(customerQueryResult: GetCustomerProfileQuery | undefined): Customer | undefined {
    const customer = customerQueryResult?.customer;
    if (!customer || !customer.firstName) {
        return undefined;
    }
    return {

        firstName: customer.firstName || '',
        lastName: customer.lastName || '',
        mobile: customer.mobile || '',
        countryCode: customer.countryCode || '',
        regionCode: customer.regionCode || "",
        address: customer.address || "",
        postalCode: customer.postalCode || "",
        city: customer.city || "",
    }
}

type GetCustomerType = {
    customer: Customer | undefined;
    loading: boolean;
    error: ApolloError | undefined;
    authError: Error | string | undefined
}

const useGetCustomer = (): GetCustomerType => {

    const {isAuthenticated, isLoading: isAuthLoading, error: authError} = useAuthorization();
    const [getCustomer,
        {
            data: customerData,
            loading: customerLoading,
            error: getCustomerError,
            called
        }] = useGetCustomerProfileLazyQuery();

    const needToSendQuery = isAuthenticated && !called;
    useEffect(() => {
        if (needToSendQuery) {
            getCustomer();
        }
    }, [getCustomer, needToSendQuery]);

    return {
        customer: getEntity(customerData),
        loading: isAuthLoading || customerLoading || needToSendQuery,
        error: getCustomerError,
        authError: authError
    };
}

export default useGetCustomer;
