import {InstitutionCustomerBalance} from "../../components/data/graphqlTypes";

const BALANCE_KEY = '27baee55-f0c7-4a9a-bea0-79b01b55bca4';

export function getSelectedBalance(): InstitutionCustomerBalance | undefined {
    const balanceAsString = localStorage.getItem(BALANCE_KEY) as string;
    return JSON.parse(balanceAsString) as InstitutionCustomerBalance;
}


export function storeSelectedBalanceToLocalStorage(institutionCustomerBalance: InstitutionCustomerBalance) {
    const balanceAsString = JSON.stringify(institutionCustomerBalance);
    localStorage.setItem(BALANCE_KEY, balanceAsString);
}
