import {Button, Grid,} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {CreditCard, useDeleteCreditCardMutation, useGetBlueSnapCardQuery} from "../../data/graphqlTypes";
import {buttonStyles} from "../../input/TransactionCountryCurrencyAmounts";
import ApolloGqlQueryDisplay from "../../data/ApolloGqlQueryDisplay";
import {ShiftModal} from "../../input/ShiftModal";
import CreditCardProcessorResolver from "./CreditCardProcessorResolver";
import {getBlueSnapCard} from "../../data/graphql/creditCard.graphql";
import {Currency} from "../../data/graphql/getCountriesAndCurrencies";
import CreditCardDisplay from "./CreditCardDisplay";


type PropsType = {
    institutionId: string;
    setSelectedCreditCard?: (selectedCard: CreditCard | undefined) => void;
    selectedCard?: CreditCard;
    autoSelectCurrency?: Currency;
    setIsPaymentOptionSaved?: any;
};


const CreditCards = (props: PropsType) => {


    const [showAddCreditCardModal, setShowAddCreditCardModal] = useState<boolean>(false);




    const [
        deleteCreditCard,
        {
            loading: deleteLoading,
            error: deleteError
        }
    ] = useDeleteCreditCardMutation();


    const
        {
            data: blueSnapCardData,
            loading: blueSnapCardLoading,
            error: blueSnapCardError
        } = useGetBlueSnapCardQuery({
            variables: {
                institutionId: props.institutionId
            }
        });

    const setSelectedCreditCard = useCallback((card: CreditCard) => {
        if (props.setSelectedCreditCard) {
            props.setSelectedCreditCard(card);
        }
    }, [props.setSelectedCreditCard]);

    const setSelectedCardIfCardReturned = useCallback((card: CreditCard | undefined | null) => {
        if (!!card && !props.selectedCard) {
            setSelectedCreditCard(card);
        }
    }, [setSelectedCreditCard, props.selectedCard]);


    const blueSnapCard = blueSnapCardData?.blueSnapCard;
    useEffect(() => {

        if (!props.autoSelectCurrency) {
            return;
        }
        if (!!blueSnapCard) {
            setSelectedCardIfCardReturned(blueSnapCard);
        }

    }, [props.autoSelectCurrency, setSelectedCardIfCardReturned, blueSnapCard]);

    if (blueSnapCardLoading || blueSnapCardError || deleteLoading || deleteError) {
        return <ApolloGqlQueryDisplay
            loading={blueSnapCardLoading || deleteLoading}
            loadingMessage={"Loading your credit card information"}
            error={blueSnapCardError || deleteError}/>
    }

    function deleteThisCard(card: CreditCard) {
        if (props.selectedCard?.creditCardId === card.creditCardId && !!props.setSelectedCreditCard) {
            props.setSelectedCreditCard(undefined);
        }
        deleteCreditCard({
            refetchQueries: [
                {
                    query: getBlueSnapCard, variables: {
                        institutionId: props.institutionId
                    }
                }

            ],
            variables: {creditCardId: card.creditCardId},
            awaitRefetchQueries: true
        })
            .catch(() => {/*do nothing. error handled elsewhere*/
            })
    }

    return <Grid container justifyContent={"flex-end"}>
        <Grid item xs={12}>
            {!blueSnapCardLoading && !!blueSnapCard &&
                <CreditCardDisplay
                    card={blueSnapCard}
                    selected={props.selectedCard?.creditCardId === blueSnapCard.creditCardId}
                    selectCard={() => !!props.setSelectedCreditCard && props.setSelectedCreditCard(blueSnapCard)}
                    deleteCard={() => deleteThisCard(blueSnapCard)}
                />
            }
        </Grid>
        <Grid item>
            <Button
                variant={"text"}
                style={buttonStyles.buttonAsLink}
                onClick={() => setShowAddCreditCardModal(true)}
            >Add new card</Button>
        </Grid>
        <ShiftModal
            title={"Add Credit Card"}
            open={showAddCreditCardModal}
            accepted={() => {
            }}
            close={() => {
                setShowAddCreditCardModal(false);
            }}
            content={
                <CreditCardProcessorResolver
                    closeCreditCardProcessor={() => setShowAddCreditCardModal(false)}
                    setSelectedCreditCard={setSelectedCreditCard}
                    institutionId={props.institutionId}
                    currency={props.autoSelectCurrency?.currencyCode}
                    setIsPaymentOptionSaved={props.setIsPaymentOptionSaved}
                />
            }
        />
    </Grid>;
}

export default CreditCards;
