import {getLoginPathsFromLocalStorageValue} from "../../utilities/localstorage/CurrentTransactionStore";
import {Redirect} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getPathForLastVisitedInstitutionId} from "../../utilities/localstorage/InstitutionContextStore";
import {useGetUserInstitutions} from "../context/UserInstitutionContext/useGetUserInstitutions";
import {NO_CODE} from "../../views/HoaPayment";
import {Loading} from "../layout";
import useAuthorization from "../auth/useAuthorization";

export function HoaPaymentRouter() {

    const {isAuthenticated, isLoading: authLoading} = useAuthorization();
    const {userInstitutions, loading: institutionLoading} = useGetUserInstitutions({});
    const lastVisitedInstitutionPath = getPathForLastVisitedInstitutionId();
    const {found, deepestPossiblePath} = getLoginPathsFromLocalStorageValue();
    const [path, setPath] = useState('');

    const loading = authLoading || institutionLoading;

    useEffect(() => {
        if (!path) {
            if (lastVisitedInstitutionPath) {
                setPath(lastVisitedInstitutionPath);
            } else if (found && isAuthenticated) {
                setPath(deepestPossiblePath);
            } else if (!loading && userInstitutions && userInstitutions.length > 0) {
                if (userInstitutions && userInstitutions.length > 1) {
                    setPath('/hoaselect');
                } else {
                    const userInstitutionId = userInstitutions[0] && userInstitutions[0].id;
                    setPath(`/payment/${userInstitutionId}`);
                }
            } else if (!loading && isAuthenticated) {
                setPath(`/payment/${NO_CODE}`);
            }
        }

    }, [lastVisitedInstitutionPath, found, deepestPossiblePath, isAuthenticated, userInstitutions, loading, path]);


    return path ? <Redirect to={path}/> : <Loading/>;
}
