import React, {createContext, useCallback, useEffect, useState} from "react";
import {Institution, Region, useGetRegionsQuery, useGetUserLocationQuery} from "../data/graphqlTypes";
import {getRegionFromListByRegionCode} from "../../utilities/useGetUsState";
import {CA, US} from "../data/graphql/getCountriesAndCurrencies";
import useGetCustomer from "./EntityContext/useGetCustomer";


export type LocationContext = {
    geoLocatedCountry: string;
    geoLocatedStateCode: string;
    countryCode: string;
    region: Region | undefined;
    isReady: () => boolean;
    getRegions: () => Array<Region>;
}

const NOT_LOADED = "not loaded";
export const defaultLocationContext: LocationContext = {
    geoLocatedCountry: NOT_LOADED,
    geoLocatedStateCode: NOT_LOADED,
    countryCode: "",
    region: undefined,
    isReady: () => false,
    getRegions: () => []
}


type PropsType = {
    institution?: Institution
    children: React.ReactNode;
};
const restrictCountryToCanadaOrUnitedStatesDefault = (country: string) => {
    return country === CA ? country : US;
}

export const HomeLocationContext = createContext<LocationContext>(defaultLocationContext);

export const HomeLocationContextProvider = (props: PropsType) => {
    const {
        customer,
        loading: entityLoading
    } = useGetCustomer();

    const {
        data: regionsData,
        loading: regionsLoading,
        error
    } = useGetRegionsQuery();

    const [locationContext, setLocationContext] = useState(defaultLocationContext);

    const {
        data: locationData
    } = useGetUserLocationQuery();

    const propsInstitution = props.institution;
    const defaultHomeCountry = props.institution?.defaultHomeCountry;

    const isReady = useCallback(() => {
        let ready = true;
        if (!propsInstitution || !defaultHomeCountry) ready = false;
        if (entityLoading) ready = false;
        if (locationContext.geoLocatedCountry === NOT_LOADED) ready = false;
        if (regionsLoading || error || !regionsData) return false;
        return ready;
    }, [propsInstitution,
        defaultHomeCountry,
        locationContext.geoLocatedCountry,
        regionsLoading,
        error,
        regionsData,
        entityLoading]);

    const getCountryCode = useCallback(() => {

        if (!isReady()) {
            throw new Error("getCountry is called too early. Please check locationContext.isReady() for true before calling this.");
        }
        let country = "";
        if (defaultHomeCountry) {
            country = defaultHomeCountry;
        }
        if (locationContext.geoLocatedCountry) {
            country = restrictCountryToCanadaOrUnitedStatesDefault(locationContext.geoLocatedCountry);
        }
        if (!!customer?.countryCode) {
            country = customer.countryCode;
        }
        return country;
    }, [customer?.countryCode, isReady, defaultHomeCountry, locationContext.geoLocatedCountry]);

    const getRegions = (): Array<Region> => {
        if (!isReady() || !regionsData?.regions) {
            throw new Error("getUsState is called too early. Please check location.isReady() for true before calling this.");
        }
        return regionsData.regions;
    }

    const getRegion = useCallback((): Region | undefined => {
        if (!isReady() || !regionsData?.regions) {
            throw new Error("getRegion is called too early. Please check location.isReady() for true before calling this.");
        }
        if (!!customer?.regionCode) {
            return getRegionFromListByRegionCode(regionsData.regions, customer.regionCode);
        }
        if (locationContext.geoLocatedStateCode) {
            return getRegionFromListByRegionCode(regionsData.regions, locationContext.geoLocatedStateCode);
        }
    }, [isReady, regionsData, customer?.regionCode, locationContext.geoLocatedStateCode]);
    const updateLocationContextWithCountryAndState = useCallback(() => {
        if (!isReady()) {
            return;
        }
        const countryCode = getCountryCode();
        const region = getRegion();
        if (countryCode !== locationContext.countryCode ||
            region?.regionCode !== locationContext.region?.regionCode
        ) {
            setLocationContext(prevState => {
                return {
                    ...prevState,
                    countryCode,
                    region: region
                }
            });
        }
    }, [getCountryCode, getRegion, locationContext.countryCode, locationContext.region, setLocationContext, isReady]);

    useEffect(() => {
        if (locationData && locationContext.geoLocatedCountry === NOT_LOADED) {
            setLocationContext(prevState => ({
                ...prevState,
                geoLocatedCountry: locationData.userLocation.countryCode || "",
                geoLocatedStateCode: locationData.userLocation.stateCode || ""
            }));
        }
    }, [locationData, setLocationContext, locationContext, updateLocationContextWithCountryAndState]);

    useEffect(() => {
        if (locationContext.geoLocatedCountry !== NOT_LOADED || locationContext.geoLocatedStateCode !== NOT_LOADED) {
            updateLocationContextWithCountryAndState();
        }
    }, [locationContext.geoLocatedCountry, locationContext.geoLocatedStateCode, updateLocationContextWithCountryAndState]);

    useEffect(() => {
        updateLocationContextWithCountryAndState();
    }, [customer?.countryCode, customer?.regionCode, updateLocationContextWithCountryAndState]);

    const completedContext: LocationContext = {
        ...locationContext,
        isReady,
        getRegions
    }

    return (
        <HomeLocationContext.Provider value={completedContext}>
            {props.children}
        </HomeLocationContext.Provider>
    );
}

