import {gql} from "@apollo/client";


gql`
    mutation deleteCreditCard($creditCardId: String!) {
        deleteCreditCard(creditCardId: $creditCardId) {
            cardDeleted
        }
    }
`;

gql`
    mutation saveCloverPaymentProcessorCardProfile($input: SaveCreditCardInput!) {
        saveCloverPaymentProcessorCard(input: $input){
            cardBrand
            expiryMonth
            expiryYear
            cardNumber
            cardCurrency
            creditCardId
        }
    }
`;
gql`
    mutation saveBlueSnapCreditCard($input: SaveCreditCardInput!) {
        saveBlueSnapCreditCard(input: $input){
            cardBrand
            expiryMonth
            expiryYear
            cardNumber
            cardCurrency
            creditCardId
        }
    }
`;

gql`
    query getBlueSnapCreditCardToken($input: PaymentProcessorResolverCriteria!) {
        getBlueSnapCreditCardToken(input: $input){
            token
        }
    }
`;


export const getBlueSnapCard = gql`
    query  getBlueSnapCard($institutionId: String!) {
        blueSnapCard: getBlueSnapCard(institutionId: $institutionId) {
            cardBrand
            expiryMonth
            expiryYear
            cardNumber
            cardCurrency
            creditCardId
        }
    }
`;

export const getPaymentProcessorDetails = gql`
    query getPaymentProcessorConnectionDetails($input: PaymentProcessorResolverCriteria!) {
        getPaymentProcessorConnectionDetails(input: $input) {
            paymentProcessorCode
            processorApiKey
        }
    }
`;

