export const CCN = 'ccn';
export const CVV = 'cvv';
export const EXP = 'exp';

class BlueSnapErrorTranslator {

    private INVALID_ENTRY_CODE: string = '10';
    private CC_NOT_SUPPORTED_CODE = '22013';

    public isFieldError(errorCode: string) {
        return errorCode === this.INVALID_ENTRY_CODE;
    }

    public getFieldErrorDescription(errorCode: string, tag: string): string {
        switch (tag) {
            case CCN:
                return 'Invalid credit card number';
            case CVV:
                return 'Invalid CVV number';
            case EXP:
                return 'Invalid expiry';
            default:
                return 'Invalid entry in unknown field. Please contact support.';
        }
    }

    public getGeneralErrorDescription(errorCode: string): string {
        switch (errorCode) {
            case this.CC_NOT_SUPPORTED_CODE:
                return 'Credit card not supported by PayFromAway';
            case '14040':
                return 'Token expired';
            case '14041':
                return 'Could not find token';
            case '14042':
                return 'Token is not associated with a payment method';
            case '400':
                return 'Session expired please refresh page to continue';
            case '403':
            case '404':
            case '500':
                return 'Internal server error please try again later';
            default:
                return 'Unknown credit card processing error. Please contact support.';
        }
    }
}

export default BlueSnapErrorTranslator;