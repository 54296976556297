import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Card, Grid, Typography} from "@material-ui/core";
import {HomeLocationContextProvider} from "../components/context/HomeLocationContext";
import {Institution, useGetInstitutionsByIdLazyQuery} from "../components/data/graphqlTypes";
import {getLastInstitutionId} from "../utilities/localstorage/InstitutionContextStore";
import UserProfile, {SaveUserProfileRef} from "../components/input/UserProfile";
import {useGetUserInstitutions} from "../components/context/UserInstitutionContext/useGetUserInstitutions";
import _ from "lodash";
import ApolloGqlQueryDisplay from "../components/data/ApolloGqlQueryDisplay";
import {Image} from "react-bootstrap";
import PaymentReference from "../components/PaymentOptions/PaymentReference";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {TabPanel, useSettingsStyles} from "./PaymentSettings";


interface Params {
    hoaCode: string;
}


const noInstitutionMessage = <Typography>
    Contact your HOA for the link needed to set up an account.
</Typography>;


const UserProfileSettings = () => {
    const classes = useSettingsStyles();
    const [visibleTab, setVisibleTab] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setVisibleTab(newValue);
    };
    let {hoaCode}: Params = useParams();
    const userProfileRef = useRef<SaveUserProfileRef>(null);
    const [userProfileEditMode, setUserProfileEditMode] = useState<boolean>(false);
    let lastInstitutionId = getLastInstitutionId();

    const {
        userInstitutions,
        error: userInstitutionError
    } = useGetUserInstitutions({institutionId: lastInstitutionId});

    const [
        getInstitutionsById,
        {
            data: institutionsData,
            error: institutionsError
        }] = useGetInstitutionsByIdLazyQuery();

    useEffect(() => {
        let institutionIds = [lastInstitutionId];
        if (userInstitutions && userInstitutions.length > 0) {
            const userInstitutionIds = userInstitutions.map(userInstitution => userInstitution.id);
            institutionIds = _.sortedUniq(_.concat(userInstitutionIds, institutionIds));
        }
        getInstitutionsById({variables: {institutionIds: institutionIds.filter(value => value !== null) as Array<string>}});
    }, [userInstitutions, lastInstitutionId]);

    if (!lastInstitutionId) {
        return noInstitutionMessage;
    }
    const institutions = institutionsData?.institutions;
    if (!institutions || institutions.length < 1) {
        return <ApolloGqlQueryDisplay
            loadingMessage={"Loading Institutions"}
            error={userInstitutionError || institutionsError}
            loading={true}
        />;
    }
    const lastInstitution = institutions.find(institution => institution && institution.institutionId === lastInstitutionId);

    if (!lastInstitution) {
        return noInstitutionMessage;
    }

    return (

        <HomeLocationContextProvider
            institution={lastInstitution}
        >
            <Grid container direction='column' justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Card variant={"elevation"} className={classes.cardContainer}>
                        <Tabs
                            className={classes.tabs}
                            value={visibleTab}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="User Profile"/>
                        </Tabs>
                        <TabPanel value={visibleTab} index={0}>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={12}>
                                    <UserProfile
                                        hoaId={lastInstitutionId}
                                        ref={userProfileRef}
                                        setEditMode={setUserProfileEditMode}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <>
                                        <Button
                                            variant={"text"}
                                            // style={buttonStyles.buttonAsLink}
                                            onClick={async () => await userProfileRef.current?.saveSubmitUserProfile()}
                                            disabled={!userProfileEditMode}
                                        >Save Profile</Button>
                                    </>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Card>
                </Grid>
            </Grid>
        </HomeLocationContextProvider>


    );
};

export default UserProfileSettings;
