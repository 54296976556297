import React, {useCallback, useState} from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import {useTransactionSubmit} from "../../operationsHooks/useTransactionSubmit";
import {useHistory} from "react-router-dom";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import {AccountRoutingType, BankAccountDetail, BookPaymentStatus, CreditCard, PaymentInput} from "../data/graphqlTypes";
import {ShiftModal} from "../input/ShiftModal";
import {HourGlassSVG} from "../theme/HourGlassSVG";

const TRANSACTION_DETAILS_PATH = `/transactiondetailswithautopay`;

type PropsType = {
    account: BankAccountDetail | undefined;
    routingType: AccountRoutingType,
    backButtonAction: () => void;
    acceptRecurringPaymentTerms: boolean;
    creditCard?: CreditCard;
    rateExpiredButtonAction: () => void;
    rateExpiryInMinutes: string;
    paymentInput: PaymentInput;
    invoiceId: string | undefined;
    institutionCustomerBalanceId?: string | undefined;

};

const RateExpiredModalContent = (rateExpiryInMinutes: string) => {
    return <>
        <Grid container justifyContent={"center"} alignContent={"center"} item xs={12}>
            <HourGlassSVG/>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={"h6"} align={"center"}>
                Our rates expire every {rateExpiryInMinutes} minutes, you will need to get a new rate to complete
                your transaction.
            </Typography>
        </Grid>
    </>
}

const TransactionConfirmation = (props: PropsType) => {
    const [refreshRateModal, setRefreshRateModal] = useState(false);
    const history = useHistory();
    const {
        submitTransaction,
        loading,
        error: submitTransactionError,
        validationError: submitTransactionValidationError
    } = useTransactionSubmit({
        acceptRecurringPaymentTerms: props.acceptRecurringPaymentTerms,
        account: props.account,
        routingType: props.routingType,
        creditCardId: props.creditCard?.creditCardId,
        paymentInput: props.paymentInput

    });

    function getBankAccountIdOrCreditCardId() {
        return props.routingType === AccountRoutingType.CreditCard ? props.creditCard?.creditCardId : props.account?.id;
    }

    const submitPayment = useCallback(() => {
        submitTransaction(props.invoiceId, props.institutionCustomerBalanceId)
            .then(result => {
                    if (result && result?.status === BookPaymentStatus.RateExpired) {
                        return setRefreshRateModal(true);
                    } else if (!!result && result?.status === BookPaymentStatus.Success && !!result.transaction) {
                        history.push(`${TRANSACTION_DETAILS_PATH}/${result.transaction.transactionId}/${props.routingType}/${getBankAccountIdOrCreditCardId()}`);
                    }
                }
            )
            .catch(() => {/*do nothing. Errors handled elsewhere.*/
            });
    }, [submitTransaction, props.invoiceId]);


    return <>
        <Button
            variant={"text"}
            onClick={props.backButtonAction}
            disabled={loading}
        >Back</Button>
        <Button
            variant={"text"}
            onClick={submitPayment}
            disabled={loading || !props.paymentInput.rateId || !!submitTransactionError}
        >Confirm</Button>
        <ApolloGqlQueryDisplay
            loading={loading}
            loadingMessage={"Submitting Payment."}
            error={submitTransactionError || submitTransactionValidationError}
        />
        <ShiftModal
            open={refreshRateModal}
            accepted={props.rateExpiredButtonAction}
            content={RateExpiredModalContent(props.rateExpiryInMinutes)}
            title={"Your rate has expired"}
            close={props.rateExpiredButtonAction}
            acceptButtonText={"Get New Rate"}
            maxWidth={"xs"}
            buttonGridXs={4}
            buttonGridSm={4}
        />

    </>
}

export default TransactionConfirmation;
