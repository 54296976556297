import React from "react";


type PropsType = {
    label: string;
    // feeDescription: string;
}

const PaymentOptionHeader = (props: PropsType) => {
    return <>
        {/*<Grid item xs={6}>*/}
        {props.label}
        {/*</Grid>*/}
        {/*<Grid item xs={6}>*/}
        {/*    <Typography*/}
        {/*        style={styles.fee}*/}
        {/*        align={"right"}*/}

        {/*    >*/}
        {/*        {props.feeDescription}*/}
        {/*    </Typography>*/}
        {/*</Grid>*/}
    </>;
}

export default PaymentOptionHeader;
