import React from "react";
import {Route, Switch} from "react-router-dom";
import {ProtectedRoute} from "./components/auth";
import "./app.css";
import "./styles/mui-over-rides.css"
import HoaPayment from "./views/HoaPayment";
import {Grid, ThemeProvider} from "@material-ui/core";
import {theme} from "./components/theme";
import {Layout} from "./components/layout";
import {HoaPaymentRouter} from "./components/navigation/HoaPaymentRouter";
import {SaveAmountToSendToCurrentTransaction} from "./components/navigation/SaveAmountToSendToCurrentTransaction";
import {HoaLegacyDemoRedirect} from "./components/navigation/HoaLegacyDemoRedirect";
import HoaMarketingLanding from "./views/HoaMarketingLanding"
import {HoaSelect} from "./views/HoaSelect";
import HoaContact from "./views/HoaContact";
import TransactionDetails from "./views/TransactionDetails";
import TransactionHistory from "./views/TransactionHistory";
import PaymentSettings from "./views/PaymentSettings";
import CustomerImportSignUpCreateAccount from "./views/CustomerImportSignUpCreateAccount";
import CustomerImportCreateAccountLanding from "./views/CustomerImportCreateAccountLanding";
import HoaLanding from "./views/HoaLanding";
import ShiftRedirectPage from "./views/ShiftRedirectPage";
import PublicInvoice from "./views/PublicInvoice";
import UserProfileSettings from "./views/UserProfileSettings";
import TransactionDetailsWithAutoPayPrompt from "./views/TransactionDetailsWithAutoPayPrompt";
import BalanceHistory from "./views/BalanceHistory";
import HoaPartialBalancePayment from "./views/HoaPartialBalancePayment";

const App = () => {

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column">
                <Layout>
                    <Grid item>
                        <Switch>
                            <Route
                                path="/welcome/:hoaCode">
                                <ShiftRedirectPage/>
                            </Route>
                            <Route
                                path="/contact">
                                <HoaContact/>
                            </Route>
                            <Route
                                strict path="/createAccount/:hoaCode/:customerId">
                                <CustomerImportCreateAccountLanding/>
                            </Route>
                            <ProtectedRoute
                                path="/signuptocreateaccount/:institutionId"
                                component={CustomerImportSignUpCreateAccount}
                                loadingMessage={"Loading PayFromAway sign up"}

                            />
                            <ProtectedRoute
                                path="/history"
                                component={TransactionHistory}/>
                            <ProtectedRoute
                                path="/balanceHistory"
                                component={BalanceHistory}/>
                            <ProtectedRoute
                                path={"/login"}
                                component={HoaPaymentRouter}/>
                            <ProtectedRoute
                                path={"/paymentSettings"}
                                component={PaymentSettings}/>
                            <ProtectedRoute
                                path={"/userProfile"}
                                component={UserProfileSettings}/>
                            <ProtectedRoute
                                path={"/hoaselect"}
                                component={HoaSelect}/>
                            <ProtectedRoute
                                strict path="/transactiondetails/:transactionId"
                                component={TransactionDetails}/>
                            <ProtectedRoute
                                strict path="/transactiondetailswithautopay/:transactionId/:routingType/:routingId"
                                component={TransactionDetailsWithAutoPayPrompt}/>
                            <Route path="/hoa/:hoaCode/:amountToSend">
                                <HoaLegacyDemoRedirect/>
                            </Route>
                            <ProtectedRoute
                                path="/paypartialbalance"
                                component={HoaPartialBalancePayment}
                            />
                            {/*<ProtectedRoute*/}
                            {/*    path="/invoice/payment/:invoiceUuid"*/}
                            {/*    component={HoaInvoicePayment}*/}
                            {/*/>*/}
                            <Route path="/invoice/:invoiceUuid">
                                <PublicInvoice/>
                            </Route>
                            <Route path="/hoa/:hoaCode">
                                <HoaLegacyDemoRedirect/>
                            </Route>
                            <Route path="/payment/:hoaCode/:amountToSend">
                                <SaveAmountToSendToCurrentTransaction/>
                            </Route>
                            <Route strict path="/payment/:hoaCode">
                                <HoaPayment/>
                            </Route>
                            <ProtectedRoute path={"/payment"} component={HoaPaymentRouter}/>
                            <ProtectedRoute path={"/:hoaCode"} component={HoaLanding}/>
                            <Route path="/">
                                <HoaMarketingLanding/>
                            </Route>
                        </Switch>
                    </Grid>
                </Layout>
            </Grid>
        </ThemeProvider>
    )
}

export default App;
