import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {HomeLocationContext} from "../context/HomeLocationContext";
import {CustomerProfileInput} from "../data/graphqlTypes";
import {UserProfileData, UserProfileForm, userProfileSchemaValidation} from "./UserProfileForm";
import {CurrentTransactionContext} from "../context/CurrentTransactionContext";
import PhoneNumberManager from "../../utilities/PhoneNumberManager";
import phoneNumberHasChangedIgnorePlusSign from "../../utilities/phoneNumberHasChangedIgnorePlusSign";
import _ from "lodash";
import {Formik, FormikProps} from "formik";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import {useEditableEntity} from "../context/EntityContext/EditableEntity";
import useGetCustomer from "../context/EntityContext/useGetCustomer";


type PropsType = {
    hoaId: string;
    setEditMode: (editingNow: boolean) => void;
    profileCreatedAction?: () => void;
    profileUpdatedAction?: () => void;
}

export interface SaveUserProfileRef {
    saveSubmitUserProfile: () => Promise<boolean>;
}

const phoneNumberManager = new PhoneNumberManager();


const UserProfile = ((props: PropsType, ref: React.Ref<SaveUserProfileRef>) => {

        const {
            customer,
            loading: entityLoading,
            error: entityError
        } = useGetCustomer();
        const homeLocationContext = useContext(HomeLocationContext);
        const {
            createEntity,
            updateEntity,
            loading: saveLoading,
            error: saveError
        } = useEditableEntity();
        const {
            updateCountryInCurrentTransaction,
            updateRegionInCurrentTransaction
        } = useContext(CurrentTransactionContext);
        const homeLocationContextCountryCode = homeLocationContext.countryCode;
        const homeLocationContextRegion = homeLocationContext.region;
        const formRef = useRef<FormikProps<UserProfileData>>(null);
        const [updatingEntity, setUpdatingEntity] = useState<boolean>(false);

        const saveUserProfile = async (input: CustomerProfileInput) => {
            try {
                if (customer?.firstName) {
                    await updateEntity(input);
                    if (props.profileUpdatedAction) {
                        props.profileUpdatedAction();
                    }
                } else {
                    await createEntity(input);
                    if (props.profileCreatedAction) {
                        props.profileCreatedAction();
                    }
                }
                updateCountryInCurrentTransaction(input.countryCode);
                updateRegionInCurrentTransaction(input.regionCode || "");
            } catch (err) {
                console.error(err);
            }
        }

        useEffect(() => {
            if (!entityLoading && !updatingEntity && !!customer) {
                if (!customer.mobile) {
                    return;
                }
                const internationalNumber = phoneNumberManager.fixLegacyPhoneNumberFormat(customer.mobile);
                if (!phoneNumberManager.isPhoneValid(internationalNumber)) {
                    return; // user will have to update their phone anyway. no point in pushing an invalid phone number to server.
                }

                if (phoneNumberHasChangedIgnorePlusSign(customer.mobile, internationalNumber)) {
                    setUpdatingEntity(true);
                    updateEntity({
                            ...customer,
                            mobile: internationalNumber,
                            institutionId: props.hoaId
                        }
                    )
                        .then(() => {
                            setUpdatingEntity(false);

                        })
                        .catch(() => {
                            setUpdatingEntity(false);
                            // do nothing. This is just a legacy data cleanup thing. It should not affect the user in any way.
                        });
                }
            }
        }, [entityLoading, updatingEntity, customer, props.hoaId]);

        useImperativeHandle(ref, () => ({saveSubmitUserProfile}));
        const currentForm = formRef && formRef.current;

        async function saveSubmitUserProfile(): Promise<boolean> {

            if (currentForm) {
                const validationErrors = await currentForm.validateForm();
                const valid = _.isEmpty(validationErrors);
                await currentForm.submitForm();
                if (saveError) {
                    return false;
                }
                return valid;
            }
            return Promise.reject("currentForm on UserProfile has not been initialized.");
        }

        if (!homeLocationContext.isReady()) {
            return <ApolloGqlQueryDisplay
                loading={true}
                loadingMessage={"Loading user profile"}
            />
        }
        const internationalNumber = phoneNumberManager.fixPhoneNumberOrBlankItOut(customer?.mobile);
        const userProfileData: UserProfileData = {
            firstName: customer?.firstName || "",
            lastName: customer?.lastName || "",
            countryCode: homeLocationContextCountryCode,
            region: homeLocationContextRegion,
            mobile: internationalNumber || "",
            address: customer?.address || "",
            postalCode: customer?.postalCode || "",
            city: customer?.city || ""
        }

        if (entityLoading) {
            return <ApolloGqlQueryDisplay
                loading={entityLoading}
                loadingMessage={"User profile loading"}
                error={entityError}
            />
        }

        return (<Formik<UserProfileData>
                enableReinitialize={true}
                initialValues={userProfileData}
                validationSchema={userProfileSchemaValidation}
                innerRef={formRef}

                onSubmit={async (values) => {
                    if (!_.isEqual(userProfileData, values)) {
                        const profileValues: CustomerProfileInput = {
                            countryCode: values.countryCode,
                            regionCode: values.region?.regionCode,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            mobile: values.mobile,
                            address: values.address,
                            postalCode: values.postalCode,
                            city: values.city,
                            institutionId: props.hoaId
                        }
                        await saveUserProfile(profileValues);
                    }
                }}>
                <UserProfileForm
                    setEditMode={props.setEditMode}
                    loading={entityLoading || saveLoading}
                    error={entityError || saveError}
                />
            </Formik>
        );
    }
);

export default forwardRef<SaveUserProfileRef, PropsType>(UserProfile);
