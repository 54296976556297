import {upsertTransactionDetailsToLocalStorage} from "../../utilities/localstorage/CurrentTransactionStore";
import {Redirect, useParams} from "react-router-dom";
import React from "react";
import {useGetInstitution} from "../data/graphql/useGetInstitution";
import {Loading} from "../layout";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";

export function SaveAmountToSendToCurrentTransaction() {

    const {hoaCode, amountToSend} = useParams<{ hoaCode: string, amountToSend: string }>();

    const {institution, loading, error} = useGetInstitution(hoaCode);

    upsertTransactionDetailsToLocalStorage(hoaCode, amountToSend);

    if (loading && !institution) {
        return <Loading message={'Redirecting...'}/>
    }
    return (
        <React.Fragment>
            <ApolloGqlQueryDisplay loading={loading} loadingMessage={'Redirecting...'} error={error}/>
            {institution && <Redirect to={`/payment/${hoaCode}`}/>}
        </React.Fragment>
    );
}
