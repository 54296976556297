import {Grid, Typography} from "@material-ui/core";
import React from "react";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";

type PropsType = {
  currency: string;
  totalCost: number;

};

const CreditCardPaymentInstructions = ({
  currency,
  totalCost,
}: PropsType) => {
  const {currencyFormatter} = useGetCurrencyFormatter();
  let myAccountCurrencyNumberFormat: Intl.NumberFormat = currencyFormatter(currency);
  return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2">Credit Card Receipt</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Thank you for you payment in the amount of {myAccountCurrencyNumberFormat.format(totalCost)}.<br/>
              A transaction receipt has been emailed to you.
            </Typography>
          </Grid>
        </Grid>
      </>
  );
};

export default CreditCardPaymentInstructions;
