import {USD} from "../components/data/graphql/getCountriesAndCurrencies";


function useGetCurrencyFormatter() {
    const preferredLanguage = navigator.language || "en-us";
    const formatter = (currency: string) => new Intl.NumberFormat(preferredLanguage, {
        style: "currency",
        currency: currency ? currency : USD.currencyCode
    });

    return {
        currencyFormatter: formatter
    }

}

export default useGetCurrencyFormatter;