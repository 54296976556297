import gql from "graphql-tag";
import React, {useEffect} from "react";
import TransactionHistoryViewer from "../components/TransactionHistory/TransactionHistoryViewer";
import {TransactionResult, useGetTransactionsLazyQuery} from "../components/data/graphqlTypes";
import ApolloGqlQueryDisplay from "../components/data/ApolloGqlQueryDisplay";
import {Button, Container, Fade, Grid, Hidden, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import useAuthorization from "../components/auth/useAuthorization";


const useStyles = makeStyles(theme => ({
    heading: {
        marginLeft: "5vw",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "4vw",
            marginRight: "2vw",
        },
    },
    button: {
        marginTop: "2vh",
        '&:hover': {
            backgroundColor: '#3E0E56',
            color: '#fff',
        }
    }
}))

export const getTransactions = gql`
    query getTransactions {
        transactionResults: getTransactions {
            successful
            transactionId
            transaction {
                customerId
                id
                createdDate
                createdBy
                creditCardProcessorApprovalCode
                payorPays {
                    currencyCode
                    amount
                }
                fees {
                    amount
                    currencyCode
                    type
                }
                payorPaysTotal {
                    currencyCode
                    amount
                }
                payeeReceives {
                    currencyCode
                    amount
                }
                payeeName
                status {
                    createdDate
                    type
                    customerComment
                }
                paymentMethod
                institutionReferenceValue
                rejectedPaymentReference
                receiveEmail
                invoices {
                    invoiceId
                    customerId
                    institutionId
                    amount
                    description
                    currencyCode
                    institutionInvoiceId
                    invoiceDate
                    dueDate
                }
            }
        }
    }
`;


const TransactionHistory = () => {
    const [getTransactions, {
        data,
        loading,
        error
    }] = useGetTransactionsLazyQuery();
    const {isAuthenticated} = useAuthorization();
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated && !loading && !data && !error) {
            getTransactions();
        }
    }, [isAuthenticated, loading, data, error]);
    if (!data || error) {
        return <ApolloGqlQueryDisplay
            loading={loading}
            loadingMessage={"Loading Transactions"}
            error={error}/>;
    }

    return (
        <Fade in={true} timeout={1000}>
            {/*<Paper style={getBackgroundImage().paperContainer}>*/}
                <Container maxWidth={"lg"}>
                    <Grid container direction={"row"} alignContent={"flex-start"} justifyContent={"flex-start"}>
                        <Grid item sm={10}>
                            <Typography className={classes.heading} variant={"h1"}>
                                Transaction History
                            </Typography>
                        </Grid>
                        <Hidden smDown>
                            <Grid item sm={2}>
                                <Button className={classes.button} size={"small"}
                                        onClick={() => history.push("/payment")}
                                >
                                    Make Payment
                                </Button>
                            </Grid>
                        </Hidden>
                    </Grid>
                    {data &&
                        <TransactionHistoryViewer
                            transactionResults={data?.transactionResults as Array<TransactionResult>}
                        />
                    }
                </Container>
            {/*</Paper>*/}
        </Fade>
    );
}

export default TransactionHistory;