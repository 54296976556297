import {PaymentProps} from "./PaymentProps";
import {Avatar, Card, CardContent, Divider, Fade, Typography} from "@material-ui/core";
import React from "react";
import RecurringPayments from "./RecurringPayments";
import {PAYMENT_OPTION_STYLES} from "./ETranferInstructions";
import {useFormatPayment} from "./GetFormattedTotalFees";
import CreditCards from "./CreditCard/CreditCards";
import {CreditCard} from "../data/graphqlTypes";
import {Currency} from "../data/graphql/getCountriesAndCurrencies";

type CreditCardParams = PaymentProps &
    {
        recurringPaymentEnabledOnInstitution: boolean;
        setSelectedCreditCard: (card: CreditCard | undefined) => void;
        institutionId: string;
        selectedCreditCard?: CreditCard;
        autoSelectCurrency?: Currency;
    };


const customStyles = {
    card: {
        boxShadow: "0 8px 30px -12px rgba(0,0,0,0.5)",
        minWidth: '100%',
    },
    fadeContainer: {
        leftPadding: '0',
        leftMargin: '0',
    }

}
const CreditCardPaymentOption = (props: CreditCardParams) => {
    const classes = PAYMENT_OPTION_STYLES();

    const {formattedTotalFees, formattedTotalPaymentAmount} = useFormatPayment(props.formatter, props.payment);

    return (
        <Fade in={true} timeout={5000}>
            <Card className={classes.card} style={customStyles.card}>
                <div className={classes.header}>
                    <Avatar
                        className={classes.avatar}
                        src="/Images/credit-card.png"
                    />
                    {props.selectedCreditCard ?
                        <Typography className={classes.head}>
                            Fees: {formattedTotalFees}
                        </Typography> :
                        <Typography className={classes.head}>
                            Fees will be added
                        </Typography>
                    }
                </div>
                <Divider className={classes.divider}/>
                <CardContent className={classes.cardContent}>
                    <Typography className={classes.head2}>
                        Credit Card
                    </Typography>
                    <Typography className={classes.paragraph}>
                        Credit Card transactions are subject to fees via our provider and external to PayFromAway. We
                        cannot control, reduce, or refund these fees. By selecting the credit card option, you agree to
                        pay these fees as part of the transaction and acknowledge that any refunds will only include the
                        principal amount and fees will not be reimbursed.
                    </Typography>
                    {props.selectedCreditCard &&
                        <Typography className={classes.etransfer}>
                            <b>{` ${formattedTotalPaymentAmount}`} </b>
                            will be charged to your credit card.
                            <br/>
                        </Typography>
                    }
                    <CreditCards
                        setSelectedCreditCard={props.setSelectedCreditCard}
                        selectedCard={props.selectedCreditCard}
                        autoSelectCurrency={props.autoSelectCurrency}
                        institutionId={props.institutionId}
                    />
                    {props.recurringPaymentEnabledOnInstitution &&
                        <RecurringPayments
                            setAcceptRecurringPaymentTerms={props.setAcceptRecurringPaymentTerms}
                            acceptRecurringPaymentTerms={props.acceptRecurringPaymentTerms}
                        />
                    }
                </CardContent>
            </Card>
        </Fade>
    );
}

export default CreditCardPaymentOption;