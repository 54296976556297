import React, {useCallback} from "react";
import {Box, Button, createStyles, Grid, makeStyles} from "@material-ui/core";
import {GetTransactionAmountsQuery, SavedExchangeRate,} from "../data/graphqlTypes";
import {ShiftModal} from "../input/ShiftModal";
import {ApolloError} from "@apollo/client";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";


const useStyles = makeStyles(() =>
    createStyles({
        svg: {
            width: "50px",
            height: "50px",
            display: "block",
            marginTop: "20px",
            marginBottom: "20px",
            overflow: "visible",

        },
        path: {
            animation: `$dash 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
        },

        "@keyframes dash": {
            "0%": {
                transform: "scale(0)",
                opacity: 1

            },
            "100%": {
                transform: "scale(1)",
                opacity: 1
            }
        }
    }))

type PropsType = {
    data: GetTransactionAmountsQuery | undefined
    loading: boolean
    error: ApolloError | undefined
    open: boolean;
    accepted: () => void;
    close: () => void;
    oldRate?: SavedExchangeRate;
    oldTotal?: string | undefined;
    nextButtonAction: () => void;


}


export const RefreshRateModal = (props: PropsType) => {
    const onClick = () => {
        props.close()
        props.nextButtonAction();
    }
    const classes = useStyles();
    const RefreshRateModalContent = useCallback(() => {

        if (props.loading) {
            return <ApolloGqlQueryDisplay
                error={props.error}
                loading={props.loading}
                loadingMessage={"Updating your exchange rate."}/>;
        }

        return <>
            <Grid container
                  direction="row"
                  alignItems="center"
                  justifyContent="center">
                <Grid item xs={12}>
                    <h6>Old rate: <Box component="span" fontWeight='bold'>{props.oldRate?.payorPaysRate.amount}</Box></h6>
                    <h6>Old Payment Amount: <Box component="span" fontWeight='bold'>${props.oldTotal}</Box>
                        </h6>

                    <Grid container
                          direction="row"
                          alignItems="center"
                          justifyContent="center">
                        <svg xmlns="https://www.w3.org/2000/svg"
                             className={classes.svg} viewBox="0 0 16 16">
                            <path className={classes.path} fill="#73AF55"
                                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                        </svg>
                    </Grid>
                    <Grid item xs={12}>
                        <h6>New rate: <Box component="span"
                                           fontWeight='bold'>{props.data?.getTransactionAmounts.savedExchangeRate.payorPaysRate.amount}</Box>
                        </h6>
                        <h6>New Payment Amount:
                            <Box component="span"
                                 fontWeight='bold'> ${props.data?.getTransactionAmounts.payorPaysAmount.amount}</Box>
                        </h6>
                    </Grid>
                    <Grid item xs={12}>
                        <Button size={"small"}
                                onClick={() => {
                                    onClick();
                                }}
                        > Got it </Button>
                    </Grid>

                </Grid>
            </Grid>
        </>
    }, [props.data?.getTransactionAmounts.savedExchangeRate.payorPaysRate.amount]);
    return <ShiftModal
        maxWidth={"xs"}
        open={props.open}
        content={<RefreshRateModalContent
        />}
        accepted={
            props.accepted
        }
        close={
            props.close
        }
        buttonGridXs={12}
        buttonGridSm={12}
        variant={undefined}
        title={"The rate you previously viewed expired"}/>

}



