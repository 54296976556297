import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid, Typography, Box } from "@material-ui/core";
import Image from "../Image";
import SectionHeader from "../SectionHeader";
import HubspotContactForm from "./HubspotContactForm";

const useStyles = makeStyles((theme) => ({
  image: {
    [theme.breakpoints.down("md")]: {
      maxWidth: 500,
      maxHeight:600
    },
  },
  sectionHead: {
    [theme.breakpoints.up("md")]: {
      marginTop: "5vh",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2vh",
    },
  },
  contactForm: {
    [theme.breakpoints.up("md")]: {
      marginTop: "3vh",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2vh",
    },
  },
}));

const Contact = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={isMd ? 4 : 2}
      >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={"fade-up"}
        >
          <Image
            src={"Images/ContactUs.png"}
            alt="Shift - HOA Payments"
            className={classes.image}
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos={"fade-up"}>
          <SectionHeader
            title={
              <span>
                Got Questions?{" "}
                <Typography component="span" variant="inherit" color="primary">
                  We're here to help.{" "}
                </Typography>
                <br />
                <span />
              </span>
            }
            subtitle="Feel free to open the chat in the bottom right corner to get access to one of our technical support members. If you're in a hurry fill out the form below and we will get back to you as soon as possible. "
            align={isMd ? "left" : "center"}
            disableGutter
            titleVariant="h3"
            className={classes.sectionHead}
          />
          <Box className={classes.sectionHead}> 
            <HubspotContactForm/>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
