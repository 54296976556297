import React from "react";
import {Button, Grid, Link, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const styles = {
    container: {
        borderRadius: 5,
        padding: 15,
        marginTop: "3vh",
        borderColor: "#000000",
        backgroundColor: "#fce070",
    },
    button: {
        margin: 15
    },
}

type PropsType = {

    url: string;
    title: string;
    description: string;
    ctaLabel: string;
};
const MessageBox = ({
                        url,
                        title,
                        description,
                        ctaLabel
                    }: PropsType) => {

    const history = useHistory();
    return (
        <Grid container direction="row" alignItems="center" justifyContent="space-around" style={styles.container}>
            <Grid item xs={7}>
                <Typography variant="h2">{title}</Typography>
                <Typography>{description}</Typography>
            </Grid>
            <Grid item xs={5} container justifyContent={"center"}>
                <Link
                    onClick={() => history.push(url)}
                >
                    <Button style={styles.button} size="small">
                        {ctaLabel}
                    </Button>
                </Link>

            </Grid>
        </Grid>

    );
};

export default MessageBox;
