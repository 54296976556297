import AchTermsContent from "./AchTermsContent";
import PadTermsContent from "./PadTermsContent";
import TermsContentInterface from "./TermsContentInterface";
import {AccountRoutingType} from "../data/graphqlTypes";
import {Typography} from "@material-ui/core";


class DirectDebitTermsBuilder {
    private terms: TermsContentInterface;

    public constructor(routingType: AccountRoutingType) {
        if (routingType === AccountRoutingType.AchDebit) {
            this.terms = new AchTermsContent();
        } else {
            this.terms = new PadTermsContent();
        }
    }

    public getTitle() {
        return this.terms.getTitle();
    }


    public buildTermsContent() {
        return <>
            <Typography>By accepting this Agreement, you authorize <b>PayFromAway</b> to debit the specified bank account for
                any amount owed for charges arising from the use of services and/or purchase of products. Payments will
                be debited from the specified account when you initiate a transaction or a transaction is triggered by a
                schedule you set. You will be notified of upcoming debits up to one (1) day before payments are collected.
            </Typography>
            <br/>
            <Typography> You have certain recourse rights if any debit does not comply with this agreement. For example,
                you have the right to receive reimbursement for any debit that is not authorized or is not consistent with
                this {this.terms.getDebitType()} Agreement. To obtain more information on your recourse rights, contact your
                financial institution or visit <a href={`https://${this.terms.getLink()}`} target='_blank'>{this.terms.getLink()}</a>.
            </Typography>
            <br/>
            <Typography> You may amend or cancel this authorization at any time by contacting <b>PayFromAway</b> at 1-877-577-4438 or
                <a href = "mailto: support@payfromaway.io"> support@payfromaway.io</a>.
            </Typography>
        </>
    }
}


export default DirectDebitTermsBuilder