import {Institution, useGetInstitutionsByIdLazyQuery} from "../components/data/graphqlTypes";
import {useEffect} from "react";
import {ApolloError} from "@apollo/client";
import {useGetUserInstitutions} from "../components/context/UserInstitutionContext/useGetUserInstitutions";


export const useGetInstitutionsByUser = (): { error: ApolloError | undefined, loading: boolean, data: Array<Institution> } => {

    const [getInstitutionsById, {data, loading, error}] = useGetInstitutionsByIdLazyQuery();
    const {userInstitutions, loading: userInstitutionsLoading} = useGetUserInstitutions({});

    useEffect(() => {
        if (!userInstitutionsLoading && userInstitutions && userInstitutions.length > 0) {

            const institutionIds: Array<string> = userInstitutions
                .map(userInstitution => userInstitution.id);
            if (institutionIds && institutionIds.length > 0) {
                getInstitutionsById({variables: {institutionIds: institutionIds}});
            }
        }
    }, [userInstitutions, userInstitutionsLoading]);

    return {
        loading: loading || userInstitutionsLoading,
        data: data?.institutions as Array<Institution> || [],
        error

    }
}