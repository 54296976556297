import gql from "graphql-tag";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {PfaTransactionDetail, useGetTransactionDetailQuery} from "../components/data/graphqlTypes";
import TransactionDetailsDisplay from "../components/Transaction/TransactionDetailsDisplay";
import {getInstructionsComponent} from "./getInstructionsComponent";
import {Typography} from "@material-ui/core";


const getTransactionDetail = gql`
    query getTransactionDetail ($transactionId: String!) {
        transactionDetails:getTransactionDetail(transactionId: $transactionId) {
            successful
            transactionId
            transaction {
                customerId
                id
                createdDate
                createdBy
                creditCardProcessorApprovalCode
                institutionId
                payorPays {
                    amount
                    currencyCode
                }
                payorPaysTotal {
                    amount
                    currencyCode
                }
                payeeReceives {
                    amount
                    currencyCode
                }
                payeeName
                status {
                    createdDate
                    type
                    customerComment
                }
                paymentMethod
                institutionReferenceValue
                rejectedPaymentReference
                receiveEmail
                fees {
                    amount
                    currencyCode
                    type
                }
            }
        }
    }
`;

const TransactionDetails = () => {
    let {transactionId}: { transactionId: string } = useParams();
    const {data, loading, error} = useGetTransactionDetailQuery({variables: {transactionId}})
    const [paymentInstructions, setPaymentInstructions] = useState<React.ReactNode>();

    const transactionResult = data?.transactionDetails;
    useEffect(() => {
        if (!loading && transactionResult && transactionResult.transaction && transactionResult.successful) {
            setPaymentInstructions(getInstructionsComponent(transactionResult.transaction as PfaTransactionDetail));
        }
    }, [data, loading]);

    if (transactionResult && !transactionResult.successful) {
        return <Typography>Could not load transaction with ID: {transactionId}. Please contact support.</Typography>;
    }

    return (
        <>
            {transactionResult &&
                <TransactionDetailsDisplay
                    value={transactionResult.transaction as PfaTransactionDetail}
                    creditCardApprovalCode={transactionResult?.transaction?.creditCardProcessorApprovalCode as string}
                >
                    {paymentInstructions}
                </TransactionDetailsDisplay>
            }
        </>
    );
}

export default TransactionDetails;