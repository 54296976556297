import {gql} from "@apollo/client";

export const getCurrentBalances = gql`
    query GetCurrentBalances {
        getCurrentBalances {
            institutionCustomerBalanceId
            institutionId
            institutionName
            customerId
            externalId
            description
            amount {
                currencyCode
                amount
            }
            pullDate
            createdDate
            updatedDate
            transactions {
                id
                payeeReceives {
                    currencyCode
                    amount
                }
                createdDate
                status {
                    createdDate
                    type
                    customerComment
                }
            }
        }
    }
`;
