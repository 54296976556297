const LAST_INSTITUTION_ID_KEY = 'LAST_INSTITUTION_ID';

export function getLastInstitutionId(): string | undefined {
    return localStorage.getItem(LAST_INSTITUTION_ID_KEY) as string;
}

export function getPathForLastVisitedInstitutionId(): string | undefined {
    const localInstitutionId = getLastInstitutionId();
    return localInstitutionId ? `/payment/${localInstitutionId}` : undefined;
}

export function storeInstitutionIdInLocalStorage(institutionId: string) {
    localStorage.setItem(LAST_INSTITUTION_ID_KEY, institutionId);
}
