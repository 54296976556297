import {Field, Form, useFormikContext} from "formik";
import {Region} from "../data/graphqlTypes";
import {createStyles, FormGroup, Grid, makeStyles} from "@material-ui/core";
import {TextField} from "formik-material-ui";
import {FormattedPhoneInput} from "./PhoneInput/FormattedPhoneInput";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import React, {useCallback, useEffect, useState} from "react";
import * as yup from "yup";
import SelectCountry from "./SelectCountry";
import SelectState from "./SelectState";
import PhoneNumberManager from "../../utilities/PhoneNumberManager";
import {ApolloError} from "@apollo/client";
import {ALLOWED_RESIDENCE_COUNTRIES, Country, US} from "../data/graphql/getCountriesAndCurrencies";

export interface UserProfileData {
    countryCode: string;
    region: Region | undefined;
    firstName: string;
    lastName: string;
    mobile: string;
    address: string;
    postalCode: string;
    city: string;
}

const phoneNumberManager = new PhoneNumberManager();
export const mobileFieldName = 'mobile';
const restrictedCharacters = '$ % ^ ! * ? | < > { } = “';
const invalidCharacterMessage = `[${restrictedCharacters}] are restricted`;
const invalidCharactersRegex: RegExp = /^[^<>$%^!{}=*|"?]+$/;

export const userProfileSchemaValidation = yup.object().shape({
    countryCode: yup.string().required('Please provide a country code.'),
    region: yup.object().required("Please provide a region code."),
    firstName: yup.string().required('First name is required')
        .matches(invalidCharactersRegex, invalidCharacterMessage)
        .max(50, 'First name has a 50 character limit'),
    lastName: yup.string().required('Last name is required')
        .matches(invalidCharactersRegex, invalidCharacterMessage)
        .max(50, 'Last name has a 50 character limit'),
    address: yup.string().required('Address is required')
        .matches(invalidCharactersRegex, invalidCharacterMessage)
        .max(50, 'Address has a 50 character limit'),
    postalCode: yup.string().required('This field is required')
        .matches(invalidCharactersRegex, invalidCharacterMessage)
        .max(10, 'This field has a 10 character limit'),
    mobile: yup.string().required('Mobile phone is required')
        .test('react-phone-number-input-isPossible', 'Invalid phone number',
            function (value) {
                return phoneNumberManager.isPhoneValid(value);
            }),
    city: yup.string().required('This field is required')
        .matches(invalidCharactersRegex, invalidCharacterMessage)
        .max(35, 'This field has a 35 character limit')

});

export interface UserProfileFormProps {
    setEditMode: (editingNow: boolean) => void;
    loading: boolean;
    error?: ApolloError;
}

export function countryIsUsa(countryCode: string) {
    return countryCode === US;
}

export const UserProfileForm = (props: UserProfileFormProps) => {
    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const {
        setFieldValue,
        setFieldTouched,
        values,
        touched,
        errors,
        isSubmitting
    } = useFormikContext<UserProfileData>();

    const useStyles = makeStyles(() =>
        createStyles({

            gridContain: {
                paddingLeft: '3px',
            },
            gridItem: {
                paddingTop: '0 !important',
                paddingBottom: '0 !important',
            },
            nextBtn: {
                margin: 0,
                padding: 0,
                paddingTop: 18,
            }
        }))
    const classes = useStyles()

    const setEditMode = props.setEditMode;
    useEffect(() => {
        setEditMode(inEditMode);
    }, [setEditMode, inEditMode]);

    useEffect(() => {
        if (!values ||
            !values.mobile ||
            !values.countryCode ||
            !values.region ||
            !values.firstName ||
            !values.lastName ||
            !values.address ||
            !values.postalCode ||
            !values.city
        ) {
            setInEditMode(true);
        }
    }, [values])

    const savedFirstName = values.firstName;
    const processingMessage = savedFirstName ? "Updating profile" : "Saving profile";
    const setUsState = useCallback((region: Region) => {
        setFieldValue('region', region);
        setFieldTouched('region', true, true);
        setInEditMode(true);
    }, [setFieldValue, setFieldTouched, setInEditMode]);

    const selectRegionLabel = countryIsUsa(values.countryCode) ? "Primary Residence - State" : "Primary Residence - Province";
    const selectPostalCodeOrZipLabel = countryIsUsa(values.countryCode) ? "Zip Code" : "Postal Code";

    return <Form
        onChange={() => setInEditMode(true)} className={classes.gridContain}
    >
        <FormGroup aria-label="position">
            <Grid container direction="row" spacing={3} justifyContent="flex-start" style={{paddingTop: 5}}>
                <Grid item xs={12} sm={6} md={4}>
                    <Field
                        fullWidth
                        variant={"outlined"}
                        component={TextField}
                        name="firstName"
                        label="First name"
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Field
                        fullWidth
                        variant={"outlined"}
                        component={TextField}
                        name="lastName"
                        label="Last name"
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    {values.countryCode &&
                        <Field
                            fullWidth
                            name={mobileFieldName}
                            component={FormattedPhoneInput}
                            value={values.mobile}
                            userSelectedCountryCode={values.countryCode}
                            onChange={(value: string) => {
                                setInEditMode(true);
                                setFieldValue(mobileFieldName, value);
                            }}
                            onBlur={() => {
                                setFieldTouched(mobileFieldName);
                            }}
                            touched={touched.mobile}
                            error={errors.mobile}
                            label={"Mobile phone"}
                        />}
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    <Field
                        fullWidth
                        variant={"outlined"}
                        component={TextField}
                        name="address"
                        label="Primary Residence - Address"
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Field
                        fullWidth
                        variant={"outlined"}
                        component={TextField}
                        name="postalCode"
                        label={selectPostalCodeOrZipLabel}
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Field
                        fullWidth
                        variant={"outlined"}
                        component={TextField}
                        name="city"
                        label={"Primary Residence - City"}
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SelectState
                        setStateCallBack={setUsState}
                        focused={false}
                        region={values.region}
                        countryCode={values.countryCode}
                        selectStateLabel={selectRegionLabel}

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    {values.countryCode && (
                        <SelectCountry
                            setCountryCallBack={(country: Country) => {
                                setFieldValue('countryCode', country.countryCode);
                                setFieldTouched('countryCode', true, true);
                                setInEditMode(true);
                            }}
                            focused={false}
                            countryCode={values.countryCode}
                            selectCountryLabel={"Primary Residence - Country"}
                            countries={ALLOWED_RESIDENCE_COUNTRIES}
                        />
                    )
                    }
                </Grid>
                    <Grid item xs={12} sm={6}>
                        <ApolloGqlQueryDisplay
                            loading={isSubmitting || props.loading}
                            loadingMessage={processingMessage}
                            error={props.error}
                        />
                    </Grid>
            </Grid>
        </FormGroup>
    </Form>;
}
