import React, {useEffect, useState} from "react";
import {useGetInstitution} from "../data/graphql/useGetInstitution";
import {useHistory} from "react-router-dom";
import {debounceTime, distinctUntilChanged, filter, map, tap} from "rxjs/operators";
import {Box, Grid, IconButton, InputAdornment, TextField, Typography} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import SearchIcon from '@material-ui/icons/Search';
import {BehaviorSubject} from "rxjs";
import {Loading} from "../layout";

const styles = {
    box: {
        border: '2px solid',
        borderColor: 'red',
        borderRadius: '5px',
        margin: 15,
    },
};

const searchObservable = new BehaviorSubject<string>('');
export const InstitutionSearch = ({hoaCode}: { hoaCode: string }) => {

    const [institutionSearch, setInstitutionSearch] = useState<string>(hoaCode);
    const [searching, setSearching] = useState<boolean>(false);
    const [searchSelected, setSearchSelected] = useState<boolean>(false);
    const {institution, loading, reload} = useGetInstitution(hoaCode);


    const history = useHistory();

    function searchInstitution(value: string) {
        setInstitutionSearch(value);
        searchObservable.next(value);
    }

    useEffect(() => {
        const subscription = searchObservable
            .pipe(
                debounceTime(1000),
                map((value: string) => value.trim().toLowerCase()),
                filter(value => value !== ''),
                distinctUntilChanged(),
                tap((value: string) => {
                    reload(value);
                    setSearching(true);
                    setSearchSelected(false);
                }),
            ).subscribe();

        return () => {
            subscription.unsubscribe();
        }
    }, [reload]);


    useEffect(() => {
        if (institution && searching) {
            setSearching(prevState => !prevState);
            if (institution){
                const institutionId = institution ? institution.institutionId : hoaCode;
                history.push(`/${institutionId}`);
                history.go(0);
            }
        }
    }, [institution, history, searching, hoaCode]);


    function handleSearch() {
        setSearchSelected(true);
        searchInstitution(institutionSearch);
    }

    return <Box style={styles.box} padding={4} paddingLeft={4} paddingRight={4} paddingBottom={4} border={1} mx={"auto"}
                m={3}>
        <Grid container spacing={3} alignItems={"center"} direction={"column"}>
            <Grid container direction={"row"} alignItems={"center"} justifyContent={"center"}>
                <Grid item>
                    <WarningIcon color={"error"}/>
                </Grid>
                <Grid item>
                    <Typography variant={"subtitle2"}>
                        Oops! We can't find the organization: <b>{hoaCode}</b>
                    </Typography>
                </Grid>
            </Grid>
            <p/>
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} sm={6}>
                    <Typography variant={"subtitle2"}>
                        Please check the link or use the search to find the correct organization.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        autoFocus={true}
                        variant={"outlined"}
                        label='Organization Search'
                        value={institutionSearch}
                        onChange={(e) => searchInstitution(e.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleSearch}
                                        // onMouseDown={handleSearch}
                                    >
                                        {loading || searchSelected ? <Loading/> : <SearchIcon/>}
                                    </IconButton>
                                </InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
            <p/>
            <Grid container spacing={3} alignItems={"center"}>
                <Grid item xs={12}>
                    <Typography variant={"subtitle2"} color={"primary"}>
                        Still having trouble?
                    </Typography>
                    <Typography variant={"subtitle2"}>
                        Contact the organization to which you want to transfer payment.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Box>
}
