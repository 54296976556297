import {isNaN} from "formik";
import {getCadOrDefaultToUsd} from "../../components/data/graphql/getCountriesAndCurrencies";

const CURRENT_TRANSACTION_PARAMS_KEY = 'currentTransactionParams';

interface PossibleHoaUrlPathLocations {
    deepestPossiblePath: string;
    found: boolean;
}

const defaultPath = '/login';

const isCountry = (other: string): boolean => {
    return getCurrentTransaction().homeCountry === other;
}

export const EMPTY_TRANSACTION: TransactionDetails = {
    amount: "1000",
    hoaCode: "",
    homeCountry: "",
    selectedAccountCurrency: "",
    targetCurrencyCode: "",
    paymentReference: ""
}

const fixBadSelectedAccountCurrency = (transactionDetails: TransactionDetails) => {
    const selectedAccountCurrency = transactionDetails.selectedAccountCurrency;
    const fixedAccountCurrency = getCadOrDefaultToUsd(selectedAccountCurrency);
    if (fixedAccountCurrency !== selectedAccountCurrency) {
        const updatedTransactionDetails = {
            ...transactionDetails,
            selectedAccountCurrency: fixedAccountCurrency
        };
        saveTransactionDetailsToLocalStorage(updatedTransactionDetails);
        return updatedTransactionDetails;
    }
    return transactionDetails;
}
const getCurrentTransaction = (): TransactionDetails => {
    const item = localStorage.getItem(CURRENT_TRANSACTION_PARAMS_KEY);
    if (item) {
        return fixBadSelectedAccountCurrency(JSON.parse(item));
    } else {
        saveTransactionDetailsToLocalStorage(EMPTY_TRANSACTION);
        return EMPTY_TRANSACTION;
    }
}

function getLoginPathsFromLocalStorageValue(): PossibleHoaUrlPathLocations {
    const item = localStorage.getItem(CURRENT_TRANSACTION_PARAMS_KEY);
    let found = false;
    if (!item) {
        return {
            deepestPossiblePath: defaultPath,
            found: found
        }
    }
    const {hoaCode, amount} = JSON.parse(item);
    let deepestPossiblePath = "/";
    if (hoaCode) {
        found = true;
        deepestPossiblePath = deepestPossiblePath + `/payment/${hoaCode}`;
        if (amount) {
            deepestPossiblePath = deepestPossiblePath + `/${amount}`;
        }
    }
    return {deepestPossiblePath, found: found};
}

interface TransactionDetails {
    hoaCode: string;
    amount: string;
    homeCountry: string;
    homeState?: string;
    selectedAccountCurrency: string;
    targetCurrencyCode: string;
    paymentReference: string;
}

const upsertTransactionDetailsToLocalStorage = (institution: string, amount?: string): TransactionDetails => {

    let updatedTransactionDetails: TransactionDetails = {
        hoaCode: institution,
        amount: amount || '',
        homeCountry: '',
        selectedAccountCurrency: '',
        targetCurrencyCode: '',
        paymentReference: '',
    };
    const currentTransaction = getCurrentTransaction();
    if (currentTransaction) {
        updatedTransactionDetails = {
            ...currentTransaction,
            hoaCode: institution,
            amount: amount || '',
        }

    }
    saveTransactionDetailsToLocalStorage(updatedTransactionDetails);
    return updatedTransactionDetails;

}


const saveTransactionDetailsToLocalStorage = (transactionDetails: TransactionDetails): boolean => {
    try {
        const trimmedAmountString = transactionDetails.amount.trim();
        if (isNaN(Number(trimmedAmountString))) {
            transactionDetails.amount = '';
        }
        localStorage.setItem(CURRENT_TRANSACTION_PARAMS_KEY, JSON.stringify(transactionDetails));
    } catch (e) {
        console.error('Error saving transaction details to local storage.');
        return false;
    }
    return true;
}
export {
    saveTransactionDetailsToLocalStorage,
    upsertTransactionDetailsToLocalStorage,
    getLoginPathsFromLocalStorageValue,
    getCurrentTransaction,
    isCountry
};
export type {TransactionDetails};

