import {
    AccountRoutingType,
    CalculateFeeForCreditCardInput,
    CalculateFeeInput,
    CalculateFeeResult,
    ShiftMoneyInput
} from "../data/graphqlTypes";
import useCalculateFeesForCreditCard from "../Fees/useCalculateFeesForCreditCard";
import useCalculateFeesForNonCreditCard from "../Fees/useCalculateFeesForNonCreditCard";
import {ApolloError} from "@apollo/client";
import {useCallback, useEffect, useState} from "react";

function getCreditCardInput(creditCardId: string,
                            hoaCode: string,
                            payorPays: ShiftMoneyInput,
                            targetCurrency: string
): CalculateFeeForCreditCardInput {
    return {
        creditCardId,
        hoaCode,
        payorPays,
        targetCurrency
    };
}

export type CalculateFeesType = {
    calculateFeeInput: CalculateFeeInput;
    creditCardId: string | undefined;
}

export type CalculateFeesForAll = {
    calculateFees: (params: CalculateFeesType) => void;
    data: CalculateFeeResult | undefined,
    error: ApolloError | undefined,
    loading: boolean,
}

function CalculateFeesResolver(): CalculateFeesForAll {

    const [fees, setFees] = useState<CalculateFeeResult>();

    const {
        calculateFees: calculateFeesForCreditCard,
        data: feesForCreditCard,
        error: errorGettingFeesForCreditCard,
        loading: loadingFeesForCreditCard
    } = useCalculateFeesForCreditCard();

    const {
        calculateFees: calculateFeesForNonCreditCard,
        data: feesForNonCreditCard,
        error: errorGettingFeesForNonCreditCard,
        loading: loadingFeesForNonCreditCard
    } = useCalculateFeesForNonCreditCard();

    const calculateFees = useCallback((params: CalculateFeesType) => {

        if (params.calculateFeeInput.paymentType === AccountRoutingType.CreditCard) {
            if (!params.creditCardId || params.creditCardId === "") {
                return;
            }
            calculateFeesForCreditCard(getCreditCardInput(
                params.creditCardId,
                params.calculateFeeInput.hoaCode,
                params.calculateFeeInput.payorPays,
                params.calculateFeeInput.targetCurrency
            ));
        } else if (!!params.calculateFeeInput.paymentType) {
            calculateFeesForNonCreditCard(params.calculateFeeInput);
        } else {
            console.log("not calculating fees because routing type not defined.");
        }
    }, []);

    useEffect(() => {
        if (feesForCreditCard) {
            setFees(feesForCreditCard);
        }
    }, [feesForCreditCard]);

    useEffect(() => {
        if (feesForNonCreditCard) {
            setFees(feesForNonCreditCard);
        }
    }, [feesForNonCreditCard]);

    return {
        calculateFees,
        data: fees,
        error: errorGettingFeesForCreditCard || errorGettingFeesForNonCreditCard,
        loading: loadingFeesForCreditCard || loadingFeesForNonCreditCard
    }
}

export default CalculateFeesResolver;
