import {CreditCard} from "../../data/graphqlTypes";
import {Avatar, Card, CardContent, CardHeader, Divider, IconButton, Tooltip, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import BackgroundImage from "../../../Images/CreditCardBackground.png";


const useStyles = makeStyles((theme) => ({
    cardRoot: {
        minWidth: 275,
        maxHeight: 150,
        borderColor: "#f4f4f4",
        backgroundImage: `url(${BackgroundImage})`,
        [theme.breakpoints.down("xs")]: {
            minWidth: 200,
            maxHeight: 125,
        }
    },
    emptyCard: {
        minWidth: 275,
        maxHeight: 150,
        border: "0px",
        backgroundImage: `url(${BackgroundImage})`,
        [theme.breakpoints.down("xs")]: {
            minWidth: 200,
            maxHeight: 125,
        }
    },
    selectedCardRoot: {
        minWidth: 275,
        maxHeight: 150,
        borderColor: "#7BFC00",
        backgroundImage: `url(${BackgroundImage})`,
        [theme.breakpoints.down("xs")]: {
            minWidth: 200,
            maxHeight: 125,
        }
    },
    cardBrand: {
        textAlign: "left",
        maxWidth: 150
    },
    cardHeader: {
        [theme.breakpoints.down("xs")]: {
            paddingBottom: 0,
        }
    }
}))

function getPaymentLogoSrc(cardBrand: string): string {

    switch (cardBrand) {
        case "AMEX":
            return "/Images/paymentlogos/AmericanExpressLogo.svg";
        case "VISA":
            return "/Images/paymentlogos/VisaLogo.svg";
        case "MC":
        case "MASTERCARD":
            return "/Images/paymentlogos/MastercardLogo.svg";
        default:
            return "/Images/credit-card.png";
    }
}


type PropsType = {
    card: CreditCard,
    selected: boolean,
    selectCard: () => void;
    deleteCard: () => void;
};
const CreditCardDisplay = (props: PropsType) => {
    let card = props.card;
    const style = useStyles();

    return <Card
        className={props.selected ? style.selectedCardRoot : style.cardRoot}
        variant="outlined"
        key={card.cardNumber}
        onClick={() => {
            props.selectCard();
        }}
    >
        <CardHeader
            className={style.cardHeader}
            title={card.cardCurrency}
            avatar={
                <Avatar
                    className={style.cardBrand}
                    src={getPaymentLogoSrc(card.cardBrand)}
                    variant={"square"}
                />
            }
            action={
                <Tooltip title="Delete">
                    <IconButton
                        aria-label="delete"
                        onClick={event => {
                            event.stopPropagation();
                            props.deleteCard();
                        }}
                    >
                        <DeleteIcon color={"primary"}/>
                    </IconButton>
                </Tooltip>
            }
        />
        <CardContent>
            <Typography>
                {card.cardNumber}
            </Typography>
            <Divider/>
            <Typography>
                {`exp: ${card.expiryMonth}/${card.expiryYear}`}
            </Typography>
        </CardContent>
    </Card>;
}

export default CreditCardDisplay;