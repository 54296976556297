import React, {useCallback, useEffect, useState} from "react";
import {
    AccountRoutingType,
    BankAccountDetail,
    CreditCard,
    InstitutionCustomerBalance,
    PaymentInput,
    useGetInstitutionByIdLazyQuery,
    useGetTransactionAmountsLazyQuery
} from "../components/data/graphqlTypes";
import PaymentStepper from "../components/PaymentStepper";
import {TRANSACTION_DETAILS_STEP, USER_PROFILE_STEP} from "./HoaPayment";
import TransactionSummary from "../components/input/TransactionSummary";
import ApolloGqlQueryDisplay from "../components/data/ApolloGqlQueryDisplay";
import {getCorrectRoutingType} from "../components/CurrentTransactionContextPaymentStepper";
import {Box, Button, Grid} from "@material-ui/core";
import {CurrencyAmountInput} from "../components/common";
import {getSelectedBalance} from "../utilities/localstorage/BalanceLocalStore";
import PaidBalance from "../components/Invoice/PaidBalance";
import BalanceView from "../components/Invoice/BalanceView";

type Params = {
    invoiceUuid: string;
}


export function calculateRemainingBalance(balance: InstitutionCustomerBalance) {
    const totalPaidSoFar = balance.transactions.map(value => +value.payeeReceives.amount)
        .reduce((aAmt, bAmt) => {
            return aAmt + bAmt;
        }, 0);
    const number = +balance.amount.amount - totalPaidSoFar;
    return number;
}

const paymentAmountCannotBeHigherThanAmountDue = "Payment amount cannot be higher than amount due.";
const amountPayableIsRequired = "Amount payable is required.";
const HoaInvoicePayment = () => {


    const [activeStep, setActiveStep] = useState<number>(TRANSACTION_DETAILS_STEP);
    const [balance, setBalance] = useState(getSelectedBalance());
    const [account, setAccount] = useState<BankAccountDetail>();
    const [routingType, setRoutingType] = useState<AccountRoutingType>()
    const [acceptRecurringPaymentTerms, setAcceptRecurringPaymentTerms] = useState<boolean>(false);
    const [creditCard, setCreditCard] = useState<CreditCard>();
    const [costToUser, setCostToUser] = useState(0);
    const [rateExpiryMinutes, setRateExpiryMinutes] = useState<string>("");
    const [payorCurrencyCode, setPayorCurrencyCode] = useState<string>();
    const [paymentAmountError, setPaymentAmountError] = useState<string>();
    const amount1 = balance?.amount;
    const [amountRemaining, setAmountRemaining] = useState<number>(0);
    const [paymentInput, setPaymentInput] = useState<PaymentInput>({
        institutionId: "",
        payeeReceives: "",
        paymentReference: "",
        rateId: ""
    });
    const [transactionDetails, setTransactionDetails] = useState<JSX.Element>(<></>);

    const updatePaymentAmount = useCallback((amountAsString: string) => {
        const selectedAmount = +amountAsString;

        if (selectedAmount < 0.01) {
            setPaymentAmountError(amountPayableIsRequired);
        }
        if (balance) {
            const originalAmountLessPreviousTransactionAmount = calculateRemainingBalance(balance);

            if (selectedAmount <= originalAmountLessPreviousTransactionAmount) {
                setAmountRemaining(selectedAmount);
                setPaymentInput(prevState => {
                    return {
                        ...prevState,
                        payeeReceives: amountAsString,
                    }
                })
            } else {
                setPaymentAmountError(paymentAmountCannotBeHigherThanAmountDue);
            }
        }
    }, []);


    const [
        getInstitution,
        {
            data: institutionData,
            loading: institutionLoading,
            error: institutionError
        }
    ] = useGetInstitutionByIdLazyQuery();


    const [getTransactionAmounts, {
        error: transactionAmountError,
        data: transactionAmountData
    }] = useGetTransactionAmountsLazyQuery({fetchPolicy: "no-cache"});

    const error = institutionError;
    const loading = institutionLoading;
    const institution = institutionData?.institution;

    useEffect(() => {
            if (!!balance) {
                setAmountRemaining(+balance.amount.amount);
                if (balance.transactions && balance.transactions.length > 0) {
                    const number = calculateRemainingBalance(balance);
                    setAmountRemaining(number);
                }
            }
        }
        , []);
    useEffect(() => {
        setPaymentInput(prevState => {
            return {
                ...prevState,
                payeeReceives: `${amountRemaining}`
            } as PaymentInput;
        });
    }, [amountRemaining]);
    useEffect(() => {
        if (balance?.institutionId && !institution) {
            getInstitution({variables: {institutionId: balance.institutionId}})
            setPaymentInput(prevState => {
                return {
                    ...prevState,
                    institutionId: balance.institutionId,
                    paymentReference: balance?.externalId || balance?.description,
                    payeeReceives: `${amountRemaining}`
                } as PaymentInput;
            });
        }
    }, [balance, institution]);

    const submitRoutingType = getCorrectRoutingType(account, routingType);

    useEffect(() => {
        if (transactionAmountData?.getTransactionAmounts.payorPaysAmount) {
            setCostToUser(+transactionAmountData.getTransactionAmounts.payorPaysAmount.amount);
            setRateExpiryMinutes(transactionAmountData.getTransactionAmounts.savedExchangeRate.validForInMinutes);
            setPaymentInput(prevState => {
                return {
                    ...prevState,
                    rateId: transactionAmountData?.getTransactionAmounts.savedExchangeRate.id,
                }
            })
        }
    }, [transactionAmountData]);

    useEffect(() => {
        if (!balance) {
            return;
        }
        if (!paymentInput.payeeReceives) {
            setPaymentAmountError(amountPayableIsRequired);
        } else if (Number(amountRemaining) > Number(balance.amount)) {
            setPaymentAmountError(paymentAmountCannotBeHigherThanAmountDue);
        } else {
            setPaymentAmountError("");
        }
    }, [amountRemaining, paymentInput, balance, setPaymentAmountError]);


    // let invoiceFailedMessage = "";
    // if (!invoiceLoading && !error && !invoiceData?.invoiceWithBalance.invoice) {
    //     invoiceFailedMessage = "Error loading invoice. Please try again or contacts support";
    // }


//Shouldn't be necessary but typescript isn't smart enough
//     if (!invoiceData?.invoiceWithBalance.invoice || !institution) {
//         return <ErrorDisplay
//             error={"Invoice or Institution not found"}
//         />
//     }


    function calculateTransaction(currencyCode: string) {
        if (!balance || !institution) {
            return;
        }
        getTransactionAmounts({
            variables: {
                input: {
                    payeeReceives: {amount: `${amountRemaining}`, currencyCode: balance.amount.currencyCode},
                    payorPaysCurrencyCode: currencyCode,
                    institutionId: institution.institutionId
                }
            }
        })
    }

    function setAccountAndCalculateTransaction(bankAccountDetails: BankAccountDetail | undefined) {
        setAccount(bankAccountDetails);
        setCreditCard(undefined);
        setPayorCurrencyCode(bankAccountDetails?.currency);
        if (bankAccountDetails) {
            calculateTransaction(bankAccountDetails.currency);
        }
    }

    function setCreditCardAndCalculateTransaction(creditCardDetails: CreditCard | undefined) {
        if (routingType !== AccountRoutingType.CreditCard) {
            return;
        }
        setCreditCard(creditCardDetails);
        setAccount(undefined);
        setPayorCurrencyCode(creditCardDetails?.cardCurrency);
        if (creditCardDetails) {
            calculateTransaction(creditCardDetails.cardCurrency);
        }
    }

    function setRoutingTypeAndCalculateTransaction(routingType: AccountRoutingType | undefined) {
        setRoutingType(routingType);
        if (routingType?.toUpperCase() === "ETRANSFER") {
            setAcceptRecurringPaymentTerms(false);
            setAccount(undefined);
            setCreditCard(undefined);
            setPayorCurrencyCode("CAD");
            calculateTransaction("CAD");

        }
    }


    let payeeCurrency = amount1?.currencyCode;
    useEffect(() => {
        if (paymentInput.payeeReceives) {
            setTransactionDetails(<Grid container direction={"row"} alignItems={"flex-start"}
                                        justifyContent={"flex-start"}>
                <BalanceView
                    balance={balance}
                />
                <Grid item xs={12} sm={8} md={4}>
                    <Box sx={{
                        pt: 3,
                        pl: 3
                    }}>
                        {payeeCurrency &&
                            <CurrencyAmountInput
                                currencyAmount={`${amountRemaining}`}
                                currencyAmountChangedCallback={updatePaymentAmount}
                                displayLabel={`Payment amount (${payeeCurrency})`}
                                currencyCode={payeeCurrency}
                                errorText={paymentAmountError || ""}
                            />
                        }
                    </Box>
                </Grid>
                <Grid container item xs={12} direction={"row"} justifyContent={'flex-end'}>
                    <Button
                        variant={"text"}
                        onClick={() => {
                            setActiveStep(USER_PROFILE_STEP);
                        }}
                        disabled={!!paymentAmountError || amountRemaining < 0.01}
                    >Next
                    </Button>
                </Grid>
            </Grid>);
        }
    }, [paymentInput, payeeCurrency, amountRemaining, updatePaymentAmount, payeeCurrency, paymentAmountError]);

    let transactionSummary: JSX.Element | undefined;
    if (!!balance && amountRemaining && (amountRemaining < 0.01)) {
        return <>
            {balance && <PaidBalance
                balance={balance}
            />}
        </>
    }
    if (!!submitRoutingType &&
        !!transactionAmountData?.getTransactionAmounts.payorPaysAmount &&
        !!payorCurrencyCode &&
        institution &&
        payeeCurrency &&
        amountRemaining
    ) {
        transactionSummary =
            <TransactionSummary
                institution={institution}
                paymentMethod={submitRoutingType}
                editStep={() => setActiveStep(TRANSACTION_DETAILS_STEP)}
                creditCard={creditCard}
                payorPaysAmount={transactionAmountData.getTransactionAmounts.payorPaysAmount.amount}
                targetCurrency={payeeCurrency}
                selectedAccountCurrency={payorCurrencyCode}
                payeeReceivesAmount={paymentInput.payeeReceives}
                paymentReference={`${balance?.externalId}`}
                rate={+transactionAmountData.getTransactionAmounts.savedExchangeRate.payeeReceivesRate.amount}
                error={transactionAmountError}
            />;
    }

    if (loading || !!error) {
        return <ApolloGqlQueryDisplay
            error={error}
            loadingMessage={"Loading invoice"}
            loading={loading}
        />;
    }
    return <>

        {institution && payeeCurrency && <PaymentStepper
            institution={institution}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            account={account}
            setAccount={setAccountAndCalculateTransaction}
            routingType={routingType}
            setRoutingType={setRoutingTypeAndCalculateTransaction}
            creditCard={creditCard}
            setCreditCard={setCreditCardAndCalculateTransaction}
            acceptRecurringPaymentTerms={acceptRecurringPaymentTerms}
            setAcceptRecurringPaymentTerms={setAcceptRecurringPaymentTerms}
            submitRoutingType={submitRoutingType}
            transactionSummary={transactionSummary}
            transactionDetails={transactionDetails}
            rateExpiresInMinutes={rateExpiryMinutes}
            costToUser={costToUser}
            enforceCurrency={undefined}
            paymentCurrency={payorCurrencyCode}
            targetCurrency={payeeCurrency}
            paymentInput={paymentInput}
            invoiceId={undefined}
            institutionCustomerBalanceId={balance?.institutionCustomerBalanceId}
        />
        }
    </>;
}

export default HoaInvoicePayment;
