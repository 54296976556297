import {Card, Grid, Step, StepButton, StepContent, Stepper} from "@material-ui/core";
import {HomeLocationContextProvider} from "./context/HomeLocationContext";
import InstitutionPageHeader from "./institution/InstitutionPageHeader";
import UserProfileWithNavigation from "./input/UserProfileWithNavigation";
import PaymentOptions from "./PaymentOptions/PaymentOptions";
import {AccountRoutingType, BankAccountDetail, CreditCard, Institution, PaymentInput} from "./data/graphqlTypes";
import TransactionConfirmation from "./Transaction/TransactionConfirmation";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    PAYMENT_DETAILS_STEP,
    TRANSACTION_DETAILS_STEP,
    TRANSACTION_SUMMARY_STEP,
    USER_PROFILE_STEP
} from "../views/HoaPayment";
import useAuthorization from "./auth/useAuthorization";


const PAYMENT_DETAILS_STEP_KEY = 'Payment Details';
const TRANSACTION_SUMMARY_STEP_KEY = 'Final Confirmation';
const USER_PROFILE_STEP_KEY = 'Account Details';
const TRANSACTION_DETAILS_STEP_KEY = 'Transfer Details';


export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        minWidth: 200,
        maxWidth: 800,
        minHeight: '600px !important',
        paddingLeft: 5,
        marginRight: '5px',
        marginLeft: '5px',
        marginBottom: '2vh',
        marginTop: '2vh',
        border: '0px solid #4D3B71',
        borderRadius: 12,
        boxShadow: "0 6px 20px #4d3b71",
        [theme.breakpoints.only("sm")]: {
            minHeight: 650,
            minWidth: 550,
            maxWidth: 550,
            marginBottom: '5vh',
            marginTop: '5vh',

        },
        [theme.breakpoints.up("md")]: {
            minHeight: 750,
            minWidth: 900,
            maxWidth: 900,
            marginBottom: '5vh',
            marginTop: '5vh',
        },
    },
    stepperButton: {
        outlineColor: 'transparent'
    },
    stepper: {
        paddingTop: '10px',
        [theme.breakpoints.up("xs")]: {
            paddingLeft: '10px'
        },
    },
    stepContent: {
        maxWidth: '100%',
    }
}));


type PropsType = {
    institution: Institution;
    activeStep: number;
    setActiveStep: (step: number) => void;
    account: BankAccountDetail | undefined;
    setAccount: (account: BankAccountDetail | undefined) => void;
    routingType: AccountRoutingType | undefined;
    setRoutingType: (routingType: AccountRoutingType | undefined) => void;
    creditCard: CreditCard | undefined;
    setCreditCard: (creditCard: CreditCard | undefined) => void;
    acceptRecurringPaymentTerms: boolean;
    setAcceptRecurringPaymentTerms: (acceptRecurring: boolean) => void;
    submitRoutingType: AccountRoutingType | undefined;
    paymentInput: PaymentInput;
    transactionSummary: JSX.Element | undefined;
    invoiceId: string | undefined;
    transactionDetails: JSX.Element;
    rateExpiresInMinutes: string;
    costToUser: number;
    enforceCurrency: string | undefined;
    paymentCurrency: string | undefined;
    institutionCustomerBalanceId: string | undefined
    targetCurrency: string;
}
const PaymentStepper = (props: PropsType) => {
    const classes = useStyles();
    const [editMode, setEditMode] = useState<boolean>(false);
    const {isAuthenticated, isLoading} = useAuthorization();


    return (
        <HomeLocationContextProvider
            institution={props.institution}
        >
            <Grid container direction='row' justifyContent="center" alignItems="center">
                <Card className={`mui-override ${classes.cardContainer}`} raised={true}
                >
                    <InstitutionPageHeader institution={props.institution}/>

                    <Stepper activeStep={props.activeStep} orientation="vertical"
                             className={classes.stepper}>
                        <Step key={TRANSACTION_DETAILS_STEP_KEY}>

                            <StepButton className={`${classes.stepperButton}`}
                                        onClick={() => props.setActiveStep(TRANSACTION_DETAILS_STEP)} >
                                {TRANSACTION_DETAILS_STEP_KEY}
                            </StepButton>

                            <StepContent className={classes.stepContent}>
                                {props.institution &&
                                    props.transactionDetails
                                }
                            </StepContent>
                        </Step>

                        <Step key={USER_PROFILE_STEP_KEY}>
                            <StepButton className={classes.stepperButton}
                                        onClick={() => {
                                            props.setActiveStep(USER_PROFILE_STEP)
                                        }}
                            >{USER_PROFILE_STEP_KEY}</StepButton>
                            <StepContent className={classes.stepContent}>
                                {!isLoading && isAuthenticated && (
                                    <UserProfileWithNavigation
                                        hoaCodeToUse={props.institution.institutionId}
                                        setEditMode={setEditMode}
                                        editMode={editMode}
                                        profileCreatedAction={() => {
                                            props.setActiveStep(PAYMENT_DETAILS_STEP);
                                        }}
                                        profileUpdatedAction={() => {
                                            props.setActiveStep(PAYMENT_DETAILS_STEP);
                                        }}
                                        backButtonAction={() => props.setActiveStep(TRANSACTION_DETAILS_STEP)}
                                        nextButtonAction={() => props.setActiveStep(PAYMENT_DETAILS_STEP)}
                                    />
                                )}
                            </StepContent>
                        </Step>
                        <Step key={PAYMENT_DETAILS_STEP_KEY}>
                            <StepButton className={classes.stepperButton}
                                        onClick={() => props.setActiveStep(PAYMENT_DETAILS_STEP)}
                            >{PAYMENT_DETAILS_STEP_KEY}</StepButton>
                            <StepContent className={classes.stepContent}>
                                {props.institution &&
                                    <PaymentOptions
                                        creditCardEnabled={props.institution.creditCardEnabled}
                                        recurringPaymentsEnabled={props.institution.recurringPaymentsEnabled}
                                        setAccount={props.setAccount}
                                        setRoutingType={props.setRoutingType}
                                        amount={props.costToUser}
                                        account={props.account}
                                        routingType={props.routingType}
                                        changeCurrencyAction={() => props.setActiveStep(TRANSACTION_DETAILS_STEP)}
                                        backButtonAction={() => props.setActiveStep(USER_PROFILE_STEP)}
                                        nextButtonAction={() => {
                                            props.setActiveStep(TRANSACTION_SUMMARY_STEP);
                                        }}
                                        setEditMode={setEditMode}
                                        editMode={editMode}
                                        allowNavigation={true}
                                        setAcceptRecurringPaymentTerms={props.setAcceptRecurringPaymentTerms}
                                        acceptRecurringPaymentTerms={props.acceptRecurringPaymentTerms}
                                        setSelectedCreditCard={props.setCreditCard}
                                        selectedCreditCard={props.creditCard}
                                        enforceAccountCurrency={props.enforceCurrency}
                                        paymentCurrency={props.paymentCurrency}
                                        hoaCode={props.institution.institutionId}
                                        targetCurrency={props.targetCurrency}
                                    />
                                }
                            </StepContent>
                        </Step>
                        <Step key={TRANSACTION_SUMMARY_STEP_KEY}>

                            <StepButton className={classes.stepperButton}
                                        onClick={() => props.setActiveStep(TRANSACTION_SUMMARY_STEP)}
                            >{TRANSACTION_SUMMARY_STEP_KEY}</StepButton>
                            <StepContent className={classes.stepContent}>
                                {
                                    props.institution && props.submitRoutingType && (
                                        <>
                                            {props.transactionSummary}
                                            <TransactionConfirmation
                                                acceptRecurringPaymentTerms={props.acceptRecurringPaymentTerms}
                                                account={props.account}
                                                routingType={props.submitRoutingType}
                                                backButtonAction={() => props.setActiveStep(PAYMENT_DETAILS_STEP)}
                                                creditCard={props.creditCard}
                                                rateExpiredButtonAction={() => props.setActiveStep(TRANSACTION_DETAILS_STEP)}
                                                rateExpiryInMinutes={props.rateExpiresInMinutes}
                                                paymentInput={props.paymentInput}
                                                invoiceId={props.invoiceId}
                                                institutionCustomerBalanceId={props.institutionCustomerBalanceId}
                                            />
                                        </>
                                    )
                                }
                            </StepContent>
                        </Step>
                    </Stepper>
                </Card>
            </Grid>
        </HomeLocationContextProvider>
    );
}

export default PaymentStepper;