import React from "react";
import {Avatar, Card, CardContent, Divider, Fade, Typography,} from "@material-ui/core";
import {PaymentProps} from "./PaymentProps";
import {useFormatPayment} from "./GetFormattedTotalFees";
import {makeStyles} from "@material-ui/core/styles";

export const EMAIL_TRANSFER_EMAIL = 'payments@payfromaway.io';
export const PAYMENT_OPTION_STYLES = makeStyles((theme) => ({
    etransfer: {
        color: '#4D3B71',
        fontSize: '14px',
        fontWeight: 500,
        margin: 6,
        padding: 10,
    },
    card: {
        minWidth: 220,
        maxWidth: 600,
        margin: 'auto',
        padding: 12,
        border: '0px solid',
        borderColor: '#000',
        borderRadius: 16,
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
            boxShadow: " 0 16px 70px -12.125px rgba( 0,0,0, 0.3)"
        },
        [theme.breakpoints.up('md')]: {
            minWidth: 600,
        },
    },
    cardContent: {
        padding: 0,
        paddingTop: 30,
    },
    avatar: {
        width: 60,
        height: 60,
        marginBottom: 10,
        backgroundColor: "#ffffff",
        transform: "translateY(10%),"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    divider: {
        marginLeft: 3,
        marginRight: 3,
    },
    head: {
        color: '#4d3b71',
        fontSize: '14px',
        fontWeight: 700,
        marginRight: 10,
    },
    head2: {
        color: '#4d3b71',
        fontSize: '16px',
        fontWeight: 700,
        marginLeft: 15,
    },
    paragraph:{
        marginLeft:15,
        fontSize:"14px"
    }

}));

const ETransferInstructions = (props: PaymentProps) => {
    const classes = PAYMENT_OPTION_STYLES();
    const {formattedTotalFees, formattedTotalPaymentAmount} = useFormatPayment(props.formatter, props.payment);
    return (

        <Fade in={true} timeout={5000}>
            <Card className={classes.card}>
                <div className={classes.header}>
                    <Avatar
                        className={classes.avatar}
                        src="/Images/InteractLogo.png"
                    />
                    <Typography className={classes.head}>
                        Fees: {formattedTotalFees}
                    </Typography>
                </div>
                <Divider className={classes.divider}/>
                <CardContent className={classes.cardContent}>
                    <Typography
                        className={classes.head2}
                    >
                        E-Transfer
                    </Typography>
                    <Typography className={classes.etransfer}>
                        To finalize your transaction, please send an Interac E-Transfer to
                        <b>{` ${EMAIL_TRANSFER_EMAIL} `}</b>
                        in the amount of
                        <b>{` ${formattedTotalPaymentAmount}`}.</b>
                        To ensure faster processing, please send your transfer from the same
                        email address you use to sign into PayFromAway and/or include
                        the transaction number provided in your Interac transfer message.
                        <br/>
                    </Typography>
                    <Typography className={classes.head2}>
                        <b>Payment must be completed within 24 hours of booking. </b>
                    </Typography>

                </CardContent>
            </Card>
        </Fade>
    );
}

export default ETransferInstructions;
