import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProps} from "../../Types/IconProps";



const useStyles = makeStyles(() => ({
  extraSmall: {
    fontSize: 10,
  },
  small: {
    fontSize: 20,
  },
  medium: {
    fontSize: 30,
  },
  large: {
    fontSize: 40,
  },
}));

/**
 * Component to display the icon
 *
 * @param {Object} props
 */
const Icon = ({fontIcon,
                size = 'medium',
                fontIconColor,
                className,
                ...rest
              }: IconProps): JSX.Element => {
  const classes = useStyles();

  return (

      <div className={clsx(
          'icon',
          className,
          classes[size],
      )}
           style={{ color: fontIconColor }}
           {...rest}>
        <FontAwesomeIcon icon={fontIcon}/>
      </div>

  );
};

export default Icon;