import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React from "react";

type PropsType = {
    open: boolean,
    close: () => void
}
export const RoutingFaq = (props: PropsType) => {


    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={props.close}
        >
            <DialogTitle id="alert-dialog-title">{"Bank Routing Number Locator"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                How do I find my Bank Routing, and Institution numbers?
                <img
                            alt={"ChequeImage"}
                            src="/app-icons/usa-cheque.png"
                            width={"500px"}
                        />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size ="small" onClick={() => props.close()} color="primary" autoFocus>
                    Thanks!
                </Button>
            </DialogActions>
        </Dialog>

    )
}