export const CA = "CA";
export const US = "US";

export type Currency = {
    currencyName: string;
    currencyCode: string;
}
export type Country = {
    countryName: string;
    countryCode: string;
    defaultCurrency: Currency;
}

export const CAD: Currency = {
    currencyName: "Canadian dollar",
    currencyCode: "CAD"
}

export const USD: Currency = {
    currencyName: "United States dollar",
    currencyCode: "USD"
}

export const MXN: Currency = {
    currencyName: "Mexican peso",
    currencyCode: "MXN"
}

const ca: Country = {
    countryName: "Canada",
    countryCode: "CA",
    defaultCurrency: CAD
}

const us: Country = {
    countryName: "United States of America",
    countryCode: "US",
    defaultCurrency: USD
}
const mx: Country = {
    countryName: "Mexico",
    countryCode: "MX",
    defaultCurrency: MXN
}

const countries: Array<Country> = [ca, us, mx];
const currencies: Array<Currency> = [CAD, USD, MXN];

export function getPaymentCurrency(countryCode: string) {
    return countryCode === CA ? CAD.currencyCode : USD.currencyCode;
}

export function getCadOrDefaultToUsd(currencyCode: string) {
    return currencyCode === CAD.currencyCode ? CAD.currencyCode : USD.currencyCode;
}

export const ALLOWED_PAYMENT_CURRENCIES = [CAD, USD];
export const ALLOWED_RESIDENCE_COUNTRIES = [ca, us];


const getCurrencyObjects = (currencyCodes: Array<string>): Array<Currency> | undefined => {
    return currencies?.filter(currency => currencyCodes.includes(currency.currencyCode));
};

const getCurrencyObject = (curerncyCode: string): Currency | undefined => {
    return currencies?.find(currency => currency.currencyCode === curerncyCode);
};

const getCountryObject = (countryCode: string): Country | undefined => {
    return countries.find(value => value.countryCode === countryCode);
}

export default function useGetCountriesAndCurrencies() {

    return {
        countries,
        currencies,
        getCurrencyObjects,
        getCurrencyObject,
        getCountryObject
    };
}