import React from "react";
import "./FormattedPhoneInput.css";
import {TextField,} from "@material-ui/core";
import FancyButFragileReactPhoneNumberInputBeWaryWaryCarefulInHere
    from "./FancyButFragileReactPhoneNumberInputBeWaryWaryCarefulInHere";

type FormattedPhoneInputPropsType = {
    value: string;
    userSelectedCountryCode: string;
    onChange: (value: string | undefined) => void;
    onBlur: () => void;
    label: string;
    error: string;
    touched: boolean;
}

export const FormattedPhoneInput = (props: FormattedPhoneInputPropsType) => {

    return <TextField
        variant={"outlined"}
        fullWidth={true}
        id="mobile-phone"
        value={props.value}
        label={props.label}
        error={props.touched && !!props.error}
        InputLabelProps={{shrink: true}}
        helperText={props.touched && props.error}
        InputProps={{
            inputComponent: FancyButFragileReactPhoneNumberInputBeWaryWaryCarefulInHere as any,
            inputProps: {
                userSelectedCountryCode: props.userSelectedCountryCode,
                valueChanged: props.onChange,
                fieldBlurred: props.onBlur
            }
        }}
    />
};
