import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@material-ui/core";
import React from "react";
import {GridSize} from "@material-ui/core/Grid/Grid";


type PropsType = {
    open: boolean;
    accepted: () => void;
    close: () => void;
    closeOnButtonKeyOnly?: boolean;
    content: string | JSX.Element;
    title: string | JSX.Element;
    acceptButtonText?: string;
    closeButtonText?: string;
    buttonGridXs?: boolean | GridSize;
    buttonGridSm?: boolean | GridSize;
    variant?: "text" | "outlined" | "contained" | undefined;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
    fullWidth?: boolean;
    modalContentStyle?: React.CSSProperties | undefined;
    titleStyle?: React.CSSProperties | undefined;
    buttonSize?: "medium" | "large";
}


export const ShiftModal = (props: PropsType) => {

    const buttonGridXs = props.buttonGridXs || 2;
    const buttonGridSm = props.buttonGridSm || 3;
    const buttonSize = props.buttonSize || "small";

    const handleClose = (event: Event, reason: string) => {
        const isBackgroundClickOrEscapeKey = !!reason &&
            (reason === "backdropClick" || reason === "escapeKeyDown");
        if (props.closeOnButtonKeyOnly && isBackgroundClickOrEscapeKey) {
            return;
        }
        props.close();
    }
    return (
        <Dialog
            open={props.open}
            maxWidth={props.maxWidth}
            fullWidth={props.fullWidth}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={handleClose}
            PaperProps={{
                style: {
                    minHeight: props.modalContentStyle?.minHeight,
                    maxHeight: props.modalContentStyle?.maxHeight,
                    minWidth: props.modalContentStyle?.minWidth,
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    component={"span"}
                >
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container direction={'row'}>
                    <Grid item xs={buttonGridXs} sm={buttonGridSm}>
                        {props.closeButtonText && (
                            <Button size={buttonSize} variant={props.variant} onClick={() => props.close()}
                                    color="primary"
                                    autoFocus>
                                {props.closeButtonText}
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={buttonGridXs} sm={buttonGridSm}>
                        {props.acceptButtonText && (
                            <Button size={buttonSize} variant={props.variant} onClick={() => props.accepted()}
                                    color="primary"
                                    autoFocus>
                                {props.acceptButtonText}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>

    )
}
