import TermsContentInterface from "./TermsContentInterface";

class PadTermsContent implements TermsContentInterface {

    private title = 'Pre-Authorized Debit Terms & Conditions';
    private debitType = 'PAD';
    private thirdParty = 'visit www.payments.ca';
    private link = 'www.payments.ca';

    public getTitle():string {
        return this.title;
    }
    public getDebitType():string {
        return this.debitType;
    }
    public getThirdParty():string {
        return this.thirdParty;
    }
    public getLink():string {
        return this.link;
    }

}

export default PadTermsContent