import {useCallback} from "react";

export function useGetRateDisplay() {

    const getRateDisplay = useCallback((sendCurrencyCode: string | undefined, displayedRate: number | undefined, receiveCurrencyCode: string | undefined) => {
        let displayValue = "";
        if (displayedRate) {
            displayValue = `1 ${receiveCurrencyCode} = ${displayedRate} ${sendCurrencyCode}`;
        }
        return displayValue;
    }, []);

    return {getRateDisplay}
}
