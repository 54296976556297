import React, {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import "./index.css"
import {loadThirdPartyScript} from "./CloverCreditCardForm";
import BlueSnapErrorTranslator, {CCN, CVV, EXP} from "./BlueSnapErrorTranslator";
import SelectCurrency from "../input/SelectCurrency";
import {ALLOWED_PAYMENT_CURRENCIES, getPaymentCurrency} from "../data/graphql/getCountriesAndCurrencies";


type PropsType = {
    saveCreditCard: (currency: string) => Promise<void>
    setError: (error: string | Array<string>) => void;
    token: string;
    institutionId: string;
    blueSnapRequestComplete: () => void;
    residenceCountryCode: string;
    currency?: string;
}

type BlueSnapErrorType = {
    errorCode: string;
}

export interface GetBlueSnapToken {
    getToken: () => Promise<void>;
}

const translator = new BlueSnapErrorTranslator();

const BlueSnapCreditCardForm = ((props: PropsType, ref: React.Ref<GetBlueSnapToken>) => {
    const [ccnHelperText, setCcnHelperText] = useState("");
    const [ccnHelperTextClass, setCcnHelperTextClass] = useState("");
    const [expHelperText, setExpHelperText] = useState("");
    const [expHelperTextClass, setExpHelperTextClass] = useState("");
    const [cvvHelperText, setCvvHelperText] = useState("");
    const [cvvHelperTextClass, setCvvHelperTextClass] = useState("");
    const [formControl, setFormControl] = useState("form-control")
    const [formControlExp, setFormControlExp] = useState("form-control")
    const [formControlCvv, setFormControlCvv] = useState("form-control")
    const [initialized, setInitialized] = useState(false);
    const [currency, setCurrency] = useState(props.currency || getPaymentCurrency(props.residenceCountryCode));


    const getToken = async (): Promise<void> => {
        // @ts-ignore
        bluesnap.hostedPaymentFieldsSubmitData(function (result) {
            if (!!result.cardData) {
                props.saveCreditCard(currency);
            } else if (!!result.error) {
                const errorArray: Array<BlueSnapErrorType> = result.error;
                const errorMessages = errorArray.map(error => translator.getGeneralErrorDescription(error.errorCode));
                props.setError(errorMessages);
            }
            props.blueSnapRequestComplete();
        });
    }

    useImperativeHandle(ref, () => ({getToken}));

    const initializeBlueSnapElements = useCallback(() => {

        // @ts-ignore
        var bsObj = {
            token: props.token,
            onFieldEventHandler: {
                /*OPTIONAL*/ setupComplete: function () {
                    // console.warn("setupComplete");
                },
                /*OPTIONAL*/ threeDsChallengeExecuted: function () {
                    // console.warn("threeDsChallengeExecuted");
                },
                // tagId returns: "ccn", "cvv", "exp"

                onFocus: function (tagId: string) {
                    switch (tagId) {
                        case CCN:
                            setFormControl("form-control-focus");
                            break;
                        case CVV:
                            setFormControlCvv("form-control-focus");
                            break;
                        case EXP:
                            setFormControlExp("form-control-focus");
                    }
                }, // Handle focus
                onBlur: function (tagId: string) {
                    props.setError("");
                }, // Handle blur
                onError: function (tagId: string, errorCode: string, errorDescription: string) {
                    props.blueSnapRequestComplete();
                    const translator = new BlueSnapErrorTranslator();
                    const isFieldError = translator.isFieldError(errorCode);
                    if (isFieldError) {
                        const translatedError = translator.getFieldErrorDescription(errorCode, tagId);
                        switch (tagId) {
                            case CCN:
                                setCcnHelperTextClass("helper-text");
                                setCcnHelperText(translatedError || "");
                                setFormControl("form-control-invalid");
                                break;
                            case CVV:
                                setCvvHelperTextClass("helper-text-cvv");
                                setCvvHelperText(translatedError || "");
                                setFormControlCvv("form-control-invalid");
                                break;
                            case EXP:
                                setExpHelperTextClass("helper-text-exp");
                                setExpHelperText(translatedError || "");
                                setFormControlExp("form-control-invalid");
                        }
                    } else if (!!errorCode) {
                        props.setError(translator.getGeneralErrorDescription(errorCode));
                    }
                },
                onType: function (tagId: string, cardType: string, cardData: any) {
                    /* cardType will give card type, and only applies to ccn: AMEX, VISA, MASTERCARD, AMEX, DISCOVER, DINERS, JCB */
                    if (null != cardData) {
                        /* cardData is an optional parameter which will provide ccType, last4Digits, issuingCountry, isRegulatedCard, cardSubType, binCategory and ccBin details (only applies to ccn) in a JsonObject */
                        // console.log(cardData);
                    }
                },
                onEnter: function (tagId: string) {
                }, // Will trigger when shopper presses enter while inside one of the inputs

                onValid: function (tagId: string) {
                    switch (tagId) {
                        case "ccn":
                            setCcnHelperTextClass("");
                            setCcnHelperText("");
                            setFormControl("form-control")
                            break;
                        case "cvv":
                            setCvvHelperTextClass('');
                            setCvvHelperText("");
                            setFormControlCvv("form-control")
                            break;
                        case "exp":
                            setExpHelperTextClass("");
                            setExpHelperText("")
                            setFormControlExp("form-control")
                    }
                } // Handle a change in validation
            },
            style: {
                ":focus": {
                    //style for all input elements on focus event
                    "color": "#4d3b71",
                },
                input: {
                    //style for all input elements
                    "color": "#9139ff",
                    "font-size": '18px',
                },
                ".invalid": {
                    //style for all input elements when invalid
                    "color": "red",
                },
            },
            ccnPlaceHolder: "Card Number", //for example
            cvvPlaceHolder: "123", //for example
            expPlaceHolder: "MM/YY", //for example
        };

        //Run the following command after Document Object Model (DOM) is fully loaded

        // @ts-ignore
        bluesnap.hostedPaymentFieldsCreate(bsObj);
    }, [props.setError, props.token]);

    if (!initialized) {
        setInitialized(true);
        loadThirdPartyScript(`https://${process.env.REACT_APP_BLUESNAP_DOMAIN}/web-sdk/4/bluesnap.js`, "blueSnapJs")
            .then(script => {
                script.addEventListener('load', initializeBlueSnapElements)
            });
    }


    return <>
        <form id="payment-form" className="PaymentForm">
            <fieldset className="FormGroup">
                <div className="FormRow">
                    <div className="column">
                        <div className={formControl} id="card-number" data-bluesnap="ccn"></div>
                        <div className="row">
                            <span className={ccnHelperTextClass} id="ccn-help">{ccnHelperText}</span>
                        </div>
                    </div>
                </div>
                <div className="FormRow2">
                    <div className="column">
                        <div className={formControlExp} id="exp-date" data-bluesnap="exp"></div>
                        <div className="row">
                            <span className={expHelperTextClass} id="exp-help">{expHelperText}</span>
                        </div>
                    </div>
                    <div className="column spacing">
                        <div className={formControlCvv} id="cvv" data-bluesnap="cvv"/>
                        <div className="row">
                            <span className={cvvHelperTextClass} id="cvv-help">{cvvHelperText}</span>
                        </div>
                    </div>
                </div>
                <div className="FormRow3">
                        <SelectCurrency
                            setCurrencyCallBack={currency => setCurrency(currency.currencyCode)}
                            displayLabel={"Credit Card Currency"}
                            currencyCode={currency}
                            allowedCurrencies={ALLOWED_PAYMENT_CURRENCIES}/>
                </div>
            </fieldset>
        </form>
    </>

})

export default forwardRef<GetBlueSnapToken, PropsType>(BlueSnapCreditCardForm);