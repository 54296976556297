import React, {FunctionComponent} from "react";
import {Route, RouteProps, useHistory} from "react-router-dom";
import useAuthorization from "./useAuthorization";
import {Button, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

type PropsType = {
    component: FunctionComponent,
    loadingMessage?: string
} & RouteProps;

const useStyles = makeStyles({
    container: {
        display: 'grid',
        placeItems: 'center',
        height: '20vh',
    }
});
const ProtectedRoute = ({
                            component,
                            loadingMessage,
                            ...args
                        }: PropsType) => {

    const {isLoading, isAuthenticated, login} = useAuthorization();
    const history = useHistory();
    const classes = useStyles();
    if (isAuthenticated) {
        return <Route
            component={component}
            {...args}
        />
    }

    if (isLoading) {
        return <CircularProgress color="primary"/>
    }

    return (<div className={classes.container}>
            <Button variant={"contained"} fullWidth={true} color={"primary"}
                    onClick={() => {

                        const pathname = history.location.pathname;
                        // console.log("history.location.pathname:", pathname);
                        login({
                            app_state: {
                                redirectTo: pathname
                            }
                        });
                    }}
            > Log in</Button>
        </div>

    )
}

export default ProtectedRoute;