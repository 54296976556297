const loadScript = (src: string) =>
    new Promise<void>((resolve, reject) => {
        const scriptElem = Object.assign(document.createElement("script"), {
            type: "text/javascript",
            defer: true,
            src,
            onerror: (e: Error) => {
                reject(e);
            }
        });
        scriptElem.onload = () => {
            resolve();
        };
        document.body.appendChild(scriptElem);
    });

export default loadScript;