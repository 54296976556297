import {Checkbox, FormControlLabel, Grid, Hidden, Tooltip, Typography} from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {ShiftModal} from "../input/ShiftModal";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";


const termsBuilder =
    <>
        <Typography variant={"h6"}>
            Overview:
        </Typography>
        <Typography display={"block"}>
            By selecting the auto pay payment method, you authorize PayFromAway to charge the payment method monthly
            in the amount specified by your HOA. This allows a completely hands-off approach to your HOA payments and
            removes the obligation to manually pay via the platform each month. If you wish to cancel your automatic
            payments or adjust your billing information, you can do so by contacting support@payfromaway.io
        </Typography>
        <Typography display={"block"}>
            Auto payments can be canceled a minimum of 15 days prior to the payment date by phone or email
            communications with PayFromAway.
        </Typography>


        <Typography variant={"h6"}> Terms & Conditions:</Typography>
        <Typography display={"inline"}>
            By selecting “agree” below I, herby authorize PayFromAway to charge my payment method on a
            recurring basis in the amount supplied by my HOA. I confirm that that my information
            is complete and accurate.

            I confirm that I am aware of the 15-day cancellation policy and I agree to ensure my payment information is
            always up to date and completely accurate.

            Upon agreement, I understand that my auto payments will be initialized immediately.
        </Typography>
    </>


const useStyles = makeStyles(() => ({
    checkBox: {
        marginLeft: '5px',
        marginRight: '2px'
    },
    toolTip: {
        marginLeft: '0px',
        marginTop: '10px',
        fontSize: '1.25rem'
    }
}));

const RecurringPayments = (props: {
    setAcceptRecurringPaymentTerms: (accepted: boolean) => void;
    acceptRecurringPaymentTerms: boolean;
}) => {
    const classes = useStyles();
    const [recurringTermsOpen, setRecurringTermsOpen] = useState(false);
    return (
        <>
            <Grid container direction={"row"}>
                <>
                    <Hidden xsDown>

                        <FormControlLabel className={classes.checkBox}
                                          control={
                                              <Checkbox
                                                  onClick={() => setRecurringTermsOpen(true)}
                                                  title={"Recurring Payments"}
                                                  checked={props.acceptRecurringPaymentTerms}
                                              />
                                          }
                                          label="Authorize Future Payments"

                        />


                        <Tooltip className={classes.toolTip}
                                 title='Future charges will be triggered by your HOA according
                             to their schedule. Amounts will be determined by your HOA in
                             their accepted currency and charged in your preferred currency
                             at the exchange rate (if applicable) on the payment date'
                                 enterTouchDelay={0}
                                 leaveDelay={100}
                        >
                            <HelpOutlineIcon/>

                        </Tooltip>
                    </Hidden>
                </>
                <>
                    <Hidden smUp>
                        <Grid item xs={10}>

                            <FormControlLabel className={classes.checkBox}
                                              control={
                                                  <Checkbox
                                                      onClick={() => setRecurringTermsOpen(true)}
                                                      title={"Recurring Payments"}
                                                      checked={props.acceptRecurringPaymentTerms}
                                                  />
                                              }
                                              label="Authorize Future Payments"

                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Tooltip className={classes.toolTip}
                                     title='Future charges will be triggered by your HOA according
                         to their schedule. Amounts will be determined by your HOA in
                         their accepted currency and charged in your preferred currency
                         at the exchange rate (if applicable) on the payment date'
                                     enterTouchDelay={0}
                                     leaveDelay={100}
                            >
                                <HelpOutlineIcon/>

                            </Tooltip>
                        </Grid>
                    </Hidden>
                </>
                <ShiftModal
                    open={recurringTermsOpen}
                    content={termsBuilder}
                    title="Recurring Terms and Conditions"
                    accepted={() => {
                        setRecurringTermsOpen(false);
                        props.setAcceptRecurringPaymentTerms(true);
                    }}
                    close={() => {
                        setRecurringTermsOpen(false);
                        props.setAcceptRecurringPaymentTerms(false);
                    }}
                    acceptButtonText={"Agree"}
                    closeButtonText={"Disagree"}
                    buttonGridXs={3}
                    buttonGridSm={2}
                    variant={undefined}
                />

            </Grid>
        </>
    )
}

export default RecurringPayments;
