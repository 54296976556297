import {Nav} from "react-bootstrap";
import React, {useMemo} from "react";
import "./ShiftAppNavBar.css";
import AuthenticatedMenu from "./AuthenticatedMenu";
import {
    AppBar,
    Box,
    Button,
    Container,
    Fade,
    Hidden,
    Link,
    makeStyles,
    MenuItem,
    Toolbar,
    Typography
} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import {Loading} from "../layout";
import WarningIcon from "@material-ui/icons/Warning";
import {useGetUserInstitutions} from "../context/UserInstitutionContext/useGetUserInstitutions";
import useGetCustomer from "../context/EntityContext/useGetCustomer";
import {useGetCurrentBalancesLazyQuery} from "../data/graphqlTypes";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import useAuthorization from "../auth/useAuthorization";


const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: '30px',
        marginLeft: '20px',
        marginTop: '0px',
    },
    menuMobile: {
        [theme.breakpoints.down("xs")]: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 'auto',
        }
    },
    flexbox: {
        flexGrow: 1,
    },
    toolbarButtons: {
        marginLeft: 'auto',
        padding: 0,
    },
    paymentButton: {
        paddingRight: "8px"
    }
}));

function DirectLink(props: { currentBalancesData: any; userInstitutions: any; locationPathname: any; }) {
    const {
        currentBalancesData,
        userInstitutions,
        locationPathname,
    } = props;
    const styles = useStyles();
    const history = useHistory();
    const {currencyFormatter} = useGetCurrencyFormatter();

    const numberOfBalances = currentBalancesData?.getCurrentBalances?.length || 0;
    // console.log('KENNY!, number of balances - ', numberOfBalances)
    // console.log('currentBalancesData.getCurrentBalances[0]', currentBalancesData)

    const balance = numberOfBalances === 1 ? currentBalancesData.getCurrentBalances[0] : null;
    let amountRemaining = balance?.amount ? +balance.amount.amount : 0;

    if (balance && balance.transactions && balance.transactions.length > 0) {
        const totalPaidSoFar = balance.transactions
            .map((transaction: { payeeReceives: { amount: string | number; }; }) => +transaction.payeeReceives.amount)
            .reduce((sum: any, amount: any) => sum + amount, 0);
        amountRemaining -= totalPaidSoFar;
    }

    function renderEntityNameAndAmountOwing() {
        if (numberOfBalances > 1) {
            return (
                <Typography align="right" variant="h6" className={styles.paymentButton}>
                    View Outstanding Balances
                </Typography>
            );
        } else if (numberOfBalances === 1 && userInstitutions.length === 1) {
            return (
                <>
                    <Typography align="right" variant="h6" className={styles.paymentButton}>
                        {userInstitutions[0].name}
                    </Typography>
                    <Typography align="right" variant="h6" className={styles.paymentButton}>
                        Balance Owing: {currencyFormatter(balance?.amount.currencyCode).format(amountRemaining)}
                    </Typography>
                </>
            );
        } else if (userInstitutions.length === 1) {
            return (
                <Typography align="right" variant="h6" className={styles.paymentButton}>
                    Pay {userInstitutions[0].name}
                </Typography>
            );
        } else {
            return (
                <Typography align="right" variant="h6" className={styles.paymentButton}>
                    Make A Payment
                </Typography>
            );
        }
    }

    function determineNavigationPath() {
        if (numberOfBalances > 0) {
            return '/balanceHistory';
        } else if (userInstitutions.length === 1) {
            return `/payment/${userInstitutions[0].id}`;
        } else {
            return '/hoaSelect';
        }
    }

    function isLinkDisabled() {
        const path = determineNavigationPath();
        return locationPathname.includes(path);
    }

    function handleNavigation() {
        const path = determineNavigationPath();
        if (!isLinkDisabled()) {
            history.push(path);
        }
    }

    return (
        <Link component="button" onClick={handleNavigation} disabled={isLinkDisabled()}>
            {renderEntityNameAndAmountOwing()}
        </Link>
    );
}


function ShiftAppNavBar() {
    const styles = useStyles();
    let {isLoading: authLoading, isAuthenticated, logout, login} = useAuthorization();
    const location = useLocation();
    const history = useHistory();
    const {
        customer,
        error: getCustomerError
    } = useGetCustomer();

    const showSignOut = isAuthenticated && !customer?.firstName;

    const {userInstitutions, loading} = useGetUserInstitutions({});

    const [getCurrentBalances, {
        data: currentBalancesData
    }] = useGetCurrentBalancesLazyQuery();
    const balances = currentBalancesData?.getCurrentBalances;

    useMemo(() => {
        if (isAuthenticated && !authLoading) {
            getCurrentBalances();
        }
    }, [authLoading, isAuthenticated]);

    return (
        <AppBar elevation={0} position="static" color={"transparent"}>
            <Container>
                <div>
                    <Toolbar>
                        <Box>
                            <MenuItem disableGutters={true}>
                                <Link
                                    onClick={() => history.push("/")}
                                >
                                    <img
                                        alt={"Pay From Away"}
                                        src="/app-icons/payfromawayLogo.svg"
                                        width={"140px"}
                                    />
                                </Link>
                            </MenuItem>
                        </Box>
                        <Hidden xsDown>
                            <Fade in={true} timeout={1000}>
                                <div className={styles.toolbarButtons}>
                                    {
                                        (isAuthenticated && !loading && userInstitutions) &&
                                        <DirectLink
                                            currentBalancesData={currentBalancesData}
                                            userInstitutions={userInstitutions}
                                            locationPathname={location.pathname}
                                        />
                                    }
                                </div>
                            </Fade>
                        </Hidden>
                        {getCustomerError && (
                            <Nav className={styles.root}>
                                <WarningIcon
                                    color={"error"}
                                />
                                <Typography
                                    variant={"subtitle2"}
                                >
                                    Could not load user profile
                                </Typography>
                            </Nav>
                        )}
                        <div className={styles.menuMobile}>
                            <Nav>
                                {
                                    authLoading ?
                                        <Loading/> :
                                        (!isAuthenticated) && (

                                            <Button variant={"text"} color={"secondary"}
                                                    onClick={ () => {
                                                        login({
                                                            app_state: {
                                                                redirectTo: "/login"
                                                            }
                                                        });
                                                    }}
                                            > Log in</Button>
                                        )}
                            </Nav>
                            {isAuthenticated && !authLoading &&
                                <AuthenticatedMenu
                                    entity={customer}
                                />
                            }
                        </div>
                    </Toolbar>
                </div>
            </Container>
        </AppBar>

    );
}

export default ShiftAppNavBar;
