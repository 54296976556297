import React from "react";
import {Card, CardActions, CardContent, Grid, IconButton, Typography} from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SelectedRoutingCodeDetail from "./SelectedRoutingCodeDetail";


type BankPropsType = {
    key: string;
    bank: SelectedRoutingCodeDetail;
    selectBank: (bank: SelectedRoutingCodeDetail) => void;
}

const Bank = (props: BankPropsType) => {

    const handleChange = () => {
        props.selectBank(props.bank);
    };

    const styles = {
        card: {
            minHeight: 160,
            paddingTop: 10,
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 0,
            "&:lastChild": {
                paddingBottom: 0
            }
        }
    };

    const countryAndPostCode = `${props.bank?.country}  ${props.bank.postCode}`.trim();


    return (

        <Grid>
            <Card onClick={handleChange}>
                <CardContent style={styles.card}>
                    <Typography variant="h5"> {props.bank.name} </Typography>
                    <Typography variant="body2">{props.bank.address1.substring(0, 22)} </Typography>
                    <Typography variant="body2">{props.bank.city} , {props.bank.province}  </Typography>
                    {countryAndPostCode && (<Typography variant="body2"> {countryAndPostCode} </Typography>)}
                    {props.bank.phone && (<Typography variant="body2"> {props.bank.phone} </Typography>)}
                    {props.bank.bankCode && (
                        <Typography variant="body2"> Bank Code: {props.bank.bankCode} </Typography>)}

                </CardContent>
                <CardActions>
                    <IconButton aria-label="select">
                        {props.bank.selected ? <CheckBoxIcon color={"primary"}/> :
                            <CheckBoxOutlineBlankIcon color={"primary"}/>}
                    </IconButton>
                </CardActions>
            </Card>
        </Grid>


    );
}
export default Bank;
