import {gql} from "@apollo/client";


const AutoPayConfigDetailsFragment = gql`
    fragment AutoPayConfigDetailsFragment on AutoPayConfiguration {
        id
        institution{
            institutionId
            name
            logoUrl
            acceptedCurrencies {
                currencyCode
                priority
                acceptedCurrencyId
            }
            defaultHomeCountry
            paymentPageTitle
            referenceFieldLabel
            recurringPaymentsEnabled
            creditCardEnabled
        }
        creditCard{
            creditCardId
            cardBrand
            expiryMonth
            expiryYear
            cardNumber
            cardCurrency
        }
        bankAccount{
            id
            currency
            status
            accountNumber
            routingType
            routingCode
            bankCode
            bankName
        }
        createdDate
    }
`;


gql`
    ${AutoPayConfigDetailsFragment}
    mutation saveAutoPayConfiguration($autoPayConfigInput: AutoPayConfigurationInput!) {
        saveAutoPayConfiguration(autoPayConfigInput: $autoPayConfigInput) {
            ...AutoPayConfigDetailsFragment
        }
    }
`;


export const getAutoPayConfigurations = gql`
    ${AutoPayConfigDetailsFragment}
    query getAutoPayConfigurations {
        getAutoPayConfigurations {
            ...AutoPayConfigDetailsFragment
        }
    }
`;

gql`
    ${AutoPayConfigDetailsFragment}
    mutation ArchiveAutoPayConfiguration($institutionId: String!) {
        archiveAutoPayConfiguration(institutionId: $institutionId) {
            ...AutoPayConfigDetailsFragment
        }
    }
`;
