import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {FeeDescriptionsByType} from "./FeeDescriptionsByType";
import React from "react";
import {Payment} from "./useCalculateFees";
import {confirmationTableStyles} from "../input/TransactionSummary";

type PropsType = {
    payment: Payment;
    currencyNumberFormat: Intl.NumberFormat
}

const FeeSummary = (props: PropsType) => {
    const tableStyles = confirmationTableStyles();
    if (!props.payment.fees || props.payment.fees.length === 0) {
        return <TableRow>
            <TableCell colSpan={1} align="right" className={tableStyles.cellBold}>Fee:</TableCell>
            <TableCell align="left"
                       className={tableStyles.generalTableCell}> {props.currencyNumberFormat.format(0)} </TableCell>
        </TableRow>;
    }

    return <>
        {
            props.payment.fees.map(fee => (
                <TableRow key={fee.type}>
                    <TableCell colSpan={1} align="right"
                               className={tableStyles.cellBold}>{FeeDescriptionsByType.get(fee.type)}:</TableCell>
                    <TableCell align="left"
                               className={tableStyles.generalTableCell}> {props.currencyNumberFormat.format(fee.amount)} </TableCell>
                </TableRow>
            ))
        }
    </>;
}

export default FeeSummary;