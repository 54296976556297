import {useEffect, useMemo} from "react";
import {
    CustomerInstitution,
    useGetUserInstitutionsAndUnitsLazyQuery,
    useSaveNewInstitutionForCustomerMutation
} from "../../data/graphqlTypes";
import {ApolloError} from "@apollo/client";
import useAuthorization from "../../auth/useAuthorization";

export function useGetUserInstitutions(props: { institutionId?: string }):
    {
        userInstitutions: Array<CustomerInstitution> | undefined,
        loading: boolean,
        found: boolean,
        error: ApolloError | undefined
    } {

    const {isAuthenticated, isLoading: authenticationLoading} = useAuthorization();

    const [getUserInstitutions,
        {
            loading: userInstitutionLoading,
            data: userInstitutionData,
            error: getUserInstitutionError
        }] = useGetUserInstitutionsAndUnitsLazyQuery();

    const [saveNewInstitutionForCustomer,
        {
            data: saveNewInstitutionForCustomerData,
            error: saveNewInstitutionForCustomerError,
            loading: saveNewInstitutionForCustomerLoading
        }
    ] = useSaveNewInstitutionForCustomerMutation();
    useEffect(() => {
        if (isAuthenticated && !userInstitutionData && !userInstitutionLoading && !getUserInstitutionError) {
            getUserInstitutions();
        }
    }, [isAuthenticated, userInstitutionData, userInstitutionLoading, getUserInstitutionError, getUserInstitutions]);

    useEffect(() => {
        if (getUserInstitutionError) {
            console.error(getUserInstitutionError);
        }
    }, [getUserInstitutionError])

    const found = useMemo(() => {

            const found = !!userInstitutionData &&
                !!userInstitutionData.userInstitutions &&
                userInstitutionData.userInstitutions.length > 0;
            return found;
        }
        , [userInstitutionData]);


    const loading = authenticationLoading || userInstitutionLoading || !userInstitutionData || saveNewInstitutionForCustomerLoading;
    const userInstitutions = userInstitutionData ? userInstitutionData.userInstitutions as Array<CustomerInstitution> : undefined;
    const hasCustomerInstitution = userInstitutions && !!userInstitutions.find(value => value.id === props.institutionId);

    useEffect(() => {


        function saveNewCustomerInstitution() {

            if (
                !!props.institutionId
                && isAuthenticated
                && userInstitutions
                && !hasCustomerInstitution
                && !userInstitutionLoading
            ) {
                saveNewInstitutionForCustomer({variables: {institutionId: props.institutionId}})
                    .then((value: any) => {
                        // getUserInstitutions();
                        console.debug("institution for customer saved: ", value.data?.saved);
                    })
                    .catch((reason: ApolloError) => console.error("Error saving new institution : ", reason))
            }
        }

        saveNewCustomerInstitution();

    }, [userInstitutionLoading, hasCustomerInstitution]);
    return {
        userInstitutions: userInstitutions,
        loading: loading,
        found,
        error: getUserInstitutionError || saveNewInstitutionForCustomerError
    };
}
