import React from "react";
import {Button, Card, Divider, Grid, Paper} from "@material-ui/core";
import {InstitutionCustomerBalance} from "../data/graphqlTypes";
import {hoaInvoiceStyles} from "../../views/HoaInvoice";
import {makeStyles} from "@material-ui/core/styles";
import paidLogo from "../../Images/paidLogo.png";
import BalanceView from "./BalanceView";


type PropsType = {
    balance: InstitutionCustomerBalance | undefined;
}


const paidInvoiceStyles = makeStyles((theme) => ({
    paidLogo: {
        height: "100%",
        width: "100%",
        maxWidth: '300px',
        maxHeight: "125px",
        marginBottom: '10px',
        marginTop: '10px',
        alignItems: 'center',
        borderColor: 'transparent',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '250px',
            maxHeight: "100px"
        }
    },
    cardContainer: {
        minWidth: 550,
        maxWidth: 800,
        height: 'auto',
        marginTop: '5vh',
        borderColor: 'transparent',
        backgroundColor: "#fff",
        padding: "10px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "2vh",
            minWidth: 250
        },
    },
}));


const PaidBalance = (props: PropsType) => {
    const styles = paidInvoiceStyles();
    const classes = hoaInvoiceStyles()

    return <Paper>
        <Grid container direction='column' justifyContent="center" alignItems="center">
            <Card className={styles.cardContainer} raised={true}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <img className={styles.paidLogo} src={paidLogo} alt={"Paid Logo"}/>
                    </Grid>
                </Grid>
                <Divider className={classes.divider}/>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <BalanceView
                            balance={props.balance}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button href={"/invoiceHistory"}>
                            View All Invoices
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    </Paper>
}
export default PaidBalance;