import {isPossiblePhoneNumber, parsePhoneNumber, formatPhoneNumberIntl} from "react-phone-number-input";

class PhoneNumberManager {

    public formatPhoneNumber(number: string){
        return formatPhoneNumberIntl(number);
    }

    public fixLegacyPhoneNumberFormat(number: string) {
        let returnVal = number.replaceAll(/-|\(|\)|\.|\s/g, "");
        if (returnVal && returnVal.length === 10 && !returnVal.startsWith("1")) { // number probably created in the legacy p2p app
            returnVal = "1" + returnVal;
        }
        if (returnVal && returnVal.indexOf("+") < 0) { // need to have a + so it is recognized as an international number
            returnVal = "+" + returnVal;
        }
        return returnVal;
    }

    public fixPhoneNumberOrBlankItOut(number: string | undefined){
        if(!number){
            return undefined;
        }
        const fixed = this.fixLegacyPhoneNumberFormat(number);
        if (!this.isPhoneValid(fixed)) {
            return undefined;
        }
        return fixed;
    }

    public isPhoneValid(number: string | undefined) {
        if(!number){
            return false;
        }
        if (!isPossiblePhoneNumber(number)) {
            return false;
        }
        const parsed = parsePhoneNumber(number);
        if (!parsed) {
            return false;
        }
        return !!parsed.country;
    }

}


export default PhoneNumberManager;