import {useCallback, useState} from "react";


export type LastTransactionRetrievedDetail = {
    sendCurrencyCode: string;
    sendAmount: number;
    targetCurrencyCode: string;
    costToUser: number;
    rate: number;
    paymentReference: string;
    currentTransactionRateValidFor:string;

}

const emptyTransaction: LastTransactionRetrievedDetail = {
    sendCurrencyCode: "",
    sendAmount: 0,
    targetCurrencyCode: "",
    costToUser: 0,
    rate: 0,
    paymentReference: "",
    currentTransactionRateValidFor:""}


const transactionHasChanged = (lastTransactionRetrievedCurrencyCode: string,
                               lastTransactionRetrievedTargetCurrencyCode: string,
                               lastTransactionRetrievedAmount: number,
                               receiveAmount: number,
                               sendCurrencyCode: string,
                               targetCurrencyCode: string) => {
    if (lastTransactionRetrievedCurrencyCode !== sendCurrencyCode) {
        return true;
    }
    if (lastTransactionRetrievedTargetCurrencyCode !== targetCurrencyCode) {
        return true;
    }
    return lastTransactionRetrievedAmount !== receiveAmount;
}


export function useLastTransactionRetrieved() {
    const [lastTransactionRetrieved, setLastTransactionRetrieved] = useState<LastTransactionRetrievedDetail>(emptyTransaction);
    const lastTransactionRetrievedAmount = lastTransactionRetrieved.sendAmount;
    const lastTransactionRetrievedCurrencyCode = lastTransactionRetrieved.sendCurrencyCode;
    const lastTransactionRetrievedTargetCurrencyCode = lastTransactionRetrieved.targetCurrencyCode;
    const lastTransactionRetrievedRateValidForInMinutes = lastTransactionRetrieved.currentTransactionRateValidFor


    const setLastTransaction = useCallback((lastTransaction: LastTransactionRetrievedDetail) => {
        setLastTransactionRetrieved(lastTransaction);
    }, [setLastTransactionRetrieved]);

    const resetLastTransactionRetrieved = useCallback(() => {
        setLastTransactionRetrieved(emptyTransaction);
    }, []);

    const forceRateRefresh = useCallback(() => {
        setLastTransactionRetrieved(prevState => {
            return {
                ...prevState}
        })
    }, [setLastTransactionRetrieved]);

    const shouldRefreshRates = useCallback((amount: number, currencyCode: string, targetCurrencyCode: string): boolean => {
        return transactionHasChanged(lastTransactionRetrievedCurrencyCode,
            lastTransactionRetrievedTargetCurrencyCode,
            lastTransactionRetrievedAmount,
            amount,
            currencyCode,
            targetCurrencyCode
        );
    }, [lastTransactionRetrievedCurrencyCode, lastTransactionRetrievedTargetCurrencyCode, lastTransactionRetrievedAmount]);

    return {
        forceRateRefresh,
        shouldRefreshRates,
        lastTransactionRetrieved,
        setLastTransaction,
        resetLastTransactionRetrieved
    };
}
