import {
    BankAccountDetail,
    BankAccountInput,
    useCreateBankAccountMutation,
    useUpdateBankAccountMutation,
} from "../../data/graphqlTypes";

export function getBankAccountInput(bankAccountId: string | undefined,
                                    currency: string,
                                    accountNumber: string,
                                    bankAddress: string,
                                    bankCity: string,
                                    bankName: string,
                                    bankProvince: string,
                                    countryCode: string,
                                    bankPostCode: string | undefined,
                                    bankCode: string | undefined | null,
                                    bankRoutingType: string,
                                    bankRoutingCode: string
): BankAccountInput {
    return {
        id: bankAccountId,
        currency: currency,
        accountNumber: accountNumber,
        bankAddress: bankAddress,
        bankCity: bankCity,
        bankCode: bankCode,
        bankName: bankName,
        bankPostCode: bankPostCode,
        bankProvince: bankProvince,
        bankRoutingCode: bankRoutingCode,
        routingType: bankRoutingType,
        accountCountry: countryCode
    };
}

export const useUpsertAccount = () => {

    const [createAccount, {
        loading: creatingAccount,
        error: createAccountError,
    }] = useCreateBankAccountMutation();

    const [updateAccount, {
        loading: updatingAccount,
        error: updateAccountError
    }] = useUpdateBankAccountMutation();

    function updateExistingAccount(bankAccountInput: BankAccountInput): Promise<BankAccountDetail> {
        const input = {updateBankAccountInput: bankAccountInput};
        return updateAccount({variables: input})
            .then((result) => {
                const account = result && result.data && result.data.updateBankAccount;
                if (!account) {
                    return Promise.reject('Account changes may not have benn saved!');
                }
                return account;
            });
    }

    function createNewAccount(bankAccountInput: BankAccountInput): Promise<BankAccountDetail> {
        const input = {createBankAccountInput: bankAccountInput};
        return createAccount({variables: input})
            .then((result) => {
                const account = result && result.data && result.data.createBankAccount;
                if (!account) {
                    return Promise.reject('Account may not have benn saved!');
                }
                return account;
            });
    }

    return {
        createNewAccount, updateExistingAccount,
        loading: updatingAccount || creatingAccount,
        error: updateAccountError || createAccountError
    };
}
