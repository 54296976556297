import {Button, createStyles, FormGroup, Grid, makeStyles, TextField} from "@material-ui/core";
import CurrencyAmountInput from "../common/CurrencyAmountInput";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {GetRateInput, Institution, SavedExchangeRate, useGetTransactionAmountsLazyQuery,} from "../data/graphqlTypes";
import {Loading} from "../layout";
import {LastTransactionRetrievedDetail} from "./useLastTransactionRetrieved";
import {CurrentTransactionContext} from "../context/CurrentTransactionContext";
import convertMaskedStringToNumber from "../../utilities/convertMaskedStringToNumber";
import {HomeLocationContext} from "../context/HomeLocationContext";
import PaymentReference from "../PaymentOptions/PaymentReference";
import SelectCurrency from "./SelectCurrency";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import {useGetRateDisplay} from "../common/useRateDisplay";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import useGetCountriesAndCurrencies, {ALLOWED_PAYMENT_CURRENCIES} from "../data/graphql/getCountriesAndCurrencies";
import {RefreshRateModal} from "../common/RefreshRateModal";
import useAuthorization from "../auth/useAuthorization";


const useStyles = makeStyles(() =>
    createStyles({
        backdropParent: {
            position: "relative",
            width: '100%',
            height: '100%',
            zIndex: 0,
        },
        backdrop: {
            position: 'absolute'
        },
        inputRoot: {
            color: 'black',
            fontWeight: 'bolder',
            paddingLeft: "14px"
        },
        labelRoot: {
            paddingLeft: "14px"
        },
        gridContain: {
            paddingTop: '10px',
            paddingLeft: '3px',
        },
        gridItem: {
            // paddingTop: '5px !important',
            // paddingBottom: '0 !important',
        },
        nextBtn: {
            margin: 0,
            padding: 0,
            paddingTop: 18,
        }
    }));

// using this kind of style because of an iOs bug that doesn't render material UI theme properly for enabled button.
export const buttonStyles = {
    buttonAsLink: {
        backgroundColor: 'transparent',
        color: '#913AFF',
        boxShadow: '0px 0px 0px 0px',
    },
    disabledButton: {
        backgroundColor: 'transparent',
        color: '#C5C5C5',
        boxShadow: '0px 0px 0px 0px',
    },

}


let lastTransactionRetrievedRate: SavedExchangeRate | undefined;
let lastTransactionRetrievedAmount: string | undefined;


type PropsType = {
    hoaCode: string;
    nextButtonAction: () => void;
    costToUserChangedCallback: (paymentAmount: string) => void;
    shouldRefreshRates: (amount: number, currencyCode: string, targetCurrencyCode: string) => boolean;
    resetLastTransactionRetrieved: () => void;
    setLastTransactionRetrieved: (lastTransactionRetrievedDetail: LastTransactionRetrievedDetail) => void;
    lastTransactionRetrieved: LastTransactionRetrievedDetail;
    institution: Institution;
}

const TransactionCountryCurrencyAmounts = (
    {
        nextButtonAction,
        costToUserChangedCallback,
        shouldRefreshRates,
        resetLastTransactionRetrieved,
        setLastTransactionRetrieved,
        lastTransactionRetrieved,
        institution,
    }: PropsType) => {

    const [getTransactionAmounts, {
        loading: transactionAmountLoading,
        error: transactionAmountError,
        data: transactionAmountData
    }] = useGetTransactionAmountsLazyQuery({fetchPolicy: "no-cache"});

    const classes = useStyles();
    const {isLoading: authLoading, isAuthenticated, login} = useAuthorization();
    const [costToUser, setCostToUser] = useState<number>(0);
    const [rateDisplay, setRateDisplay] = useState<string>("");
    const [validatePaymentReference, setValidatePaymentReference] = useState<boolean>(false);
    const [refreshRateMessage, setRefreshRateMessage] = useState<string>();
    const [currencyAmountInputErrorText, setCurrencyAmountInputErrorText] = useState<string>("");
    const [refreshRateModal, setRefreshRateModal] = useState(false);
    const {currencyFormatter} = useGetCurrencyFormatter();
    let numberFormat = currencyFormatter('USD');

    const acceptedCurrencies = institution.acceptedCurrencies;
    const {
        getCurrencyObjects,
        currencies: allCurrencies
    } = useGetCountriesAndCurrencies();

    const {getRateDisplay} = useGetRateDisplay();
    const {
        currentTransaction,
        currentTransactionRateExpiry,
        updateAmountInCurrentTransaction,
        updateIdInCurrentTransaction,
        setUserSelectedCurrencyCode,
        updateHoaCodeInCurrentTransaction,
        updateRateInCurrentTransaction,
        updateCostInCurrentTransaction,
        updateTargetCurrencyInCurrentTransaction,
        updatePaymentReferenceInCurrentTransaction,
        updateRateExpiryInCurrentTransaction
    } = useContext(CurrentTransactionContext);
    const currentTransactionTargetCurrencyCode = currentTransaction.targetCurrencyCode;

    const lockInCurrencyThatTheUserViewed = useCallback(() => {
        setUserSelectedCurrencyCode(currentTransaction.selectedAccountCurrency);
    }, [setUserSelectedCurrencyCode, currentTransaction.selectedAccountCurrency]);

    const validateAmountPayable = useCallback(() => {
        setCurrencyAmountInputErrorText('');
        if (!currentTransaction.amount) {
            setCurrencyAmountInputErrorText('Amount Payable is required');
        }
    }, [currentTransaction.amount]);
    const homeLocationContext = useContext(HomeLocationContext);

    useEffect(() => {
        if (transactionAmountData && transactionAmountData.getTransactionAmounts.savedExchangeRate.id) {
            const saveLastTransactionDetails = (savedExchangeRate: SavedExchangeRate) => {
                if (!savedExchangeRate) {
                    return;
                }
                let payeeReceivesAmount = Number(savedExchangeRate.payeeReceivesRate.amount);
                let payorPaysRate = Number(savedExchangeRate.payorPaysRate.amount);
                let payorPaysTotal = Number(transactionAmountData?.getTransactionAmounts.payorPaysAmount.amount);
                const lastTransactionDetail: LastTransactionRetrievedDetail = {
                    sendCurrencyCode: savedExchangeRate.payorPaysRate.currencyCode,
                    sendAmount: payeeReceivesAmount,
                    costToUser: payorPaysTotal,
                    targetCurrencyCode: savedExchangeRate.payeeReceivesRate.currencyCode,
                    rate: payorPaysRate,
                    paymentReference: currentTransactionPaymentReference,
                    currentTransactionRateValidFor: savedExchangeRate.validForInMinutes
                };
                setLastTransactionRetrieved(lastTransactionDetail);
                updateCostInCurrentTransaction(payorPaysTotal);
                updateRateInCurrentTransaction(payorPaysRate);
                updateHoaCodeInCurrentTransaction(institution.institutionId);
                updateIdInCurrentTransaction(savedExchangeRate.id);
                updateRateExpiryInCurrentTransaction(Date.parse(savedExchangeRate.expires));
                setRefreshRateMessage("");
            }
            saveLastTransactionDetails(transactionAmountData.getTransactionAmounts.savedExchangeRate);
        }
    }, [transactionAmountData, setLastTransactionRetrieved]);

    useEffect(() => {
        if (costToUser) {
            costToUserChangedCallback(costToUser + '');
        }
    }, [costToUser, costToUserChangedCallback]);

    const selectedAccountCurrency = currentTransaction.selectedAccountCurrency;
    const targetCurrencyCode = currentTransaction.targetCurrencyCode;


    const getRateFromServer = useCallback((accountCurrency: string, currentTransactionAmount: string) => {
        setCostToUser(0);
        if (!accountCurrency || !currentTransactionAmount || !currentTransactionTargetCurrencyCode) {
            return;
        }
        const getRateInput: GetRateInput = {
            institutionId: institution.institutionId,
            payorPaysCurrencyCode: accountCurrency,
            payeeReceives: {
                amount: currentTransactionAmount,
                currencyCode: currentTransactionTargetCurrencyCode
            }
        }
        updateIdInCurrentTransaction("");
        getTransactionAmounts({
                variables: {
                    input: getRateInput
                }
            }
        )
    }, [institution.institutionId,
        currentTransactionTargetCurrencyCode,
    ]);

    const currentTransactionAmount = currentTransaction.amount;
    const currentTransactionPaymentReference = currentTransaction.paymentReference;

    useEffect(() => {
        const amountAsNumber = convertMaskedStringToNumber(currentTransactionAmount);
        if (!amountAsNumber) {
            setCostToUser(0);
            resetLastTransactionRetrieved();
            setRateDisplay("")
            return;
        }
        const shouldRefresh = shouldRefreshRates(amountAsNumber, selectedAccountCurrency, targetCurrencyCode);
        if (!authLoading && shouldRefresh) {
            getRateFromServer(selectedAccountCurrency, currentTransactionAmount);
        }

    }, [
        currentTransactionAmount,
        authLoading,
        getRateFromServer,
        selectedAccountCurrency,
        targetCurrencyCode,
        shouldRefreshRates,
        resetLastTransactionRetrieved
    ]);


    useEffect(() => {
        const displayLastTransactionRetrieved = () => {
            setCostToUser(lastTransactionRetrieved.costToUser);
            setRateDisplay(getRateDisplay(
                lastTransactionRetrieved.sendCurrencyCode,
                lastTransactionRetrieved.rate,
                lastTransactionRetrieved.targetCurrencyCode
            ));
        }
        displayLastTransactionRetrieved();
    }, [lastTransactionRetrieved, getRateDisplay]);

    const setTargetAmount = useCallback((targetAmount: string) => {
        updateAmountInCurrentTransaction(targetAmount);

        setCurrencyAmountInputErrorText(() => {
            if (!targetAmount) {
                return 'Amount Payable is required';
            }
            return '';
        });
    }, [updateAmountInCurrentTransaction]);

    if (selectedAccountCurrency) {
        numberFormat = currencyFormatter(selectedAccountCurrency);
    }

    useEffect(() => {
        if (!acceptedCurrencies || acceptedCurrencies.length < 1) {
            return;
        }
        const defaultCurrency = acceptedCurrencies
            .reduce((prev, curr) => prev.priority < curr.priority ? prev : curr);
        if (!currentTransactionTargetCurrencyCode) {
            updateTargetCurrencyInCurrentTransaction(defaultCurrency.currencyCode);
        }
    }, [acceptedCurrencies, currentTransactionTargetCurrencyCode, updateTargetCurrencyInCurrentTransaction]);

    const allowedCurrencyCodes = acceptedCurrencies.map(currency => currency.currencyCode);
    const allowNextStep = costToUser && currentTransactionPaymentReference &&
        !transactionAmountLoading && homeLocationContext.isReady() &&
        !!transactionAmountData?.getTransactionAmounts.savedExchangeRate


    const allowedTargetCurrencies = getCurrencyObjects(allowedCurrencyCodes);

    const onClick = () => {
        const rateHasExpired: boolean = (currentTransactionRateExpiry <= Date.now()) &&
            (currentTransactionTargetCurrencyCode !== selectedAccountCurrency);
        if (rateHasExpired) {
            setRefreshRateMessage("Rate has expired, please refresh your rate");
            setRefreshRateModal(true);
            lastTransactionRetrievedRate = transactionAmountData?.getTransactionAmounts.savedExchangeRate;
            lastTransactionRetrievedAmount = transactionAmountData?.getTransactionAmounts.payorPaysAmount.amount
            getRateFromServer(selectedAccountCurrency, currentTransactionAmount);
        } else {
            lockInCurrencyThatTheUserViewed();
            validateAmountPayable();
            setValidatePaymentReference(true);
            if (allowNextStep) {
                nextButtonAction();
            }
        }

    }

    return <React.Fragment>
        <FormGroup aria-label="Institution Payment">
            <Grid container item spacing={3} direction="row" alignItems={"flex-start"} className={classes.gridContain}>
                <Grid item xs={12} sm={6}>
                    {homeLocationContext.countryCode && (
                        <SelectCurrency
                            displayLabel={"My Account Currency"}
                            currencyCode={selectedAccountCurrency}
                            setCurrencyCallBack={currency => setUserSelectedCurrencyCode(currency.currencyCode)}
                            focused={false}
                            allowedCurrencies={ALLOWED_PAYMENT_CURRENCIES}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {allowedTargetCurrencies && allCurrencies && (
                        <SelectCurrency
                            displayLabel={"Payment Currency"}
                            currencyCode={currentTransactionTargetCurrencyCode}
                            setCurrencyCallBack={currency => updateTargetCurrencyInCurrentTransaction(currency.currencyCode)}
                            focused={false}
                            allowedCurrencies={allowedTargetCurrencies}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyAmountInput
                        currencyAmount={currentTransactionAmount}
                        currencyAmountChangedCallback={setTargetAmount}
                        displayLabel={`Amount Payable (${currentTransactionTargetCurrencyCode})`}
                        currencyCode={currentTransactionTargetCurrencyCode}
                        errorText={currencyAmountInputErrorText}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PaymentReference
                        initialValue={currentTransactionPaymentReference}
                        fieldLabel={institution.referenceFieldLabel}
                        institutionId={institution.institutionId}
                        validateNow={validatePaymentReference}
                        setReferenceCallback={updatePaymentReferenceInCurrentTransaction}/>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridItem}>
                    <Grid container item direction={"column"} justifyContent={"center"}

                    >
                        <TextField
                            id="rate"
                            label="Rate"
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot
                                }
                            }}
                            value={rateDisplay}
                            InputProps={{
                                readOnly: true,
                                classes: {
                                    root: classes.inputRoot
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.gridItem}>
                    {transactionAmountError || transactionAmountLoading || !!refreshRateMessage ?
                        <ApolloGqlQueryDisplay
                            error={transactionAmountError || refreshRateMessage}
                            loading={transactionAmountLoading || !!refreshRateMessage}
                            loadingMessage={""}/> :
                        <TextField
                            id="cost"
                            label="Your Cost"
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot
                                }
                            }}
                            value={costToUser ? numberFormat.format(costToUser) : ""}
                            InputProps={{
                                readOnly: true,
                                classes: {
                                    root: classes.inputRoot
                                }
                            }}
                        />
                    }
                </Grid>
            </Grid>
        </FormGroup>

        <Grid container item xs={12} >
            {
                authLoading ? <Loading/> :
                    isAuthenticated ?
                        refreshRateMessage ?
                            <Button
                                style={buttonStyles.buttonAsLink}
                                onClick={() => {
                                    setRefreshRateMessage("");
                                    getRateFromServer(selectedAccountCurrency, currentTransactionAmount);
                                }}
                            >Refresh Rate</Button>
                            :
                            <Button
                                className={classes.nextBtn}
                                style={{...buttonStyles.buttonAsLink}}
                                onClick={() => {
                                    onClick();
                                }}
                            >Next</Button> :
                        <Button
                            style={buttonStyles.buttonAsLink}
                            onClick={ () => {
                                login({
                                    app_state: {
                                        redirectTo: window.location
                                    }
                                });
                            }}
                        >Log in / Sign up</Button>
            }
        </Grid>

        <RefreshRateModal
            oldRate={lastTransactionRetrievedRate}
            oldTotal={lastTransactionRetrievedAmount}
            data={transactionAmountData}
            loading={transactionAmountLoading}
            error={transactionAmountError}
            open={refreshRateModal}
            nextButtonAction={nextButtonAction}
            accepted={() => {
                setRefreshRateModal(false);

            }}
            close={() => {
                setRefreshRateModal(false);
            }}/>

    </React.Fragment>;
}

export default TransactionCountryCurrencyAmounts;
