import {Grid, IconButton, Typography} from "@material-ui/core";
import React, {useEffect, useMemo} from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import {useGetRateDisplay} from "../common/useRateDisplay";
import {AccountRoutingType, CreditCard, Institution} from "../data/graphqlTypes";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import useCalculateFees from "../Fees/useCalculateFees";
import {getPaymentOptionLabelForRoutingType} from "../DirectDebit/PaymentTypes";
import FeeSummary from "../Fees/FeeSummary";
import {makeStyles} from "@material-ui/core/styles";
import {ApolloError} from "@apollo/client";


export const confirmationTableStyles = makeStyles((theme) => ({
    tableContainer: {
        marginLeft: "2vh",
        marginRight: "4.5vh",
        marginTop: "2vh",
        marginBottom: "2.5vh",
        border: "2px solid",
        borderColor: "#4D3B71",
    },
    generalTableCell: {
        [theme.breakpoints.down("xs")]: {
            fontSize: "11px",
            padding: "6px 2px 6px 2px",
        },
    },
    tableHeader: {
        backgroundColor: "#4D3B71",
        color: "fff",
    },
    cellBold: {
        fontSize: "14px",
        fontWeight: 700,
        [theme.breakpoints.down("xs")]: {
            fontSize: "11px",
            padding: "3px 12px 3px 2px",
        },
    },
    cellSecondBold: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#913AFF",
        [theme.breakpoints.down("xs")]: {
            fontSize: "11px",
            padding: "3px 12px 3px 2px",
        },
    },
    cellHead: {
        fontSize: "16px",
        fontWeight: 600,
        color: "white",
        padding: "6px 18px 6px 0px",
        borderBottom: "none",
        border: "2px solid",
        borderColor: "#4D3B71",
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            padding: "3px 2px 3px 2px",
        },
    },
    cellIcon: {
        padding: '6px 0px 6px 8px',
    },
    cellHeadIcon: {
        padding: '6px 0px 6px 8px',
        borderBottom: "none",
        border: "2px solid",
        borderColor: "#4D3B71",
    },
    cellLeft: {
        padding: "6px 18px 6px 0px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "11px",
            padding: "3px 2px 3px 2px",
        },
    },
    iconButton: {
        color: "#913AFF",
        padding: '0px',
        marginRight: '1px',
    },
    rowCustom: {
        paddingBottom: '23px !important',
    },
}));

type PropsType = {
    institution: Institution,
    paymentMethod: AccountRoutingType,
    editStep: () => void;
    creditCard: CreditCard | undefined;
    targetCurrency: string;
    selectedAccountCurrency: string;
    payorPaysAmount: string;
    payeeReceivesAmount: string;
    paymentReference: string;
    rate: number;
    error?: ApolloError | undefined;
    hideEditIcon?: boolean;

}

const TransactionSummary = (props: PropsType) => {
    const tableStyles = confirmationTableStyles();
    const {currencyFormatter} = useGetCurrencyFormatter();
    const calculateFeeInput = useMemo(() => {
        return {
            hoaCode: props.institution.institutionId,
            payorPays: {
                amount: props.payorPaysAmount,
                currencyCode: props.selectedAccountCurrency
            },
            targetCurrency: props.targetCurrency,
            paymentType: props.paymentMethod
        }
    }, [props.institution.institutionId, props.payorPaysAmount, props.selectedAccountCurrency, props.targetCurrency, props.paymentMethod]);

    const {
        calculate: calculateFees,
        payment,
        loading,
        error
    } = useCalculateFees();


    let targetCurrencyNumberFormat: Intl.NumberFormat = currencyFormatter(props.targetCurrency);
    let myAccountCurrencyNumberFormat: Intl.NumberFormat = currencyFormatter(props.selectedAccountCurrency);

    const {getRateDisplay} = useGetRateDisplay();

    useEffect(() => {
        if (!!calculateFeeInput && !payment) {
            calculateFees(calculateFeeInput, props.creditCard?.creditCardId);
        }
    }, [calculateFeeInput]);

    if (loading || error || !payment || !props.paymentMethod || props.error) {
        return <ApolloGqlQueryDisplay
            loading={loading || !props.paymentMethod}
            loadingMessage={"Loading Summary"}
            error={error || props.error}
        />
    }

    return (
        <React.Fragment>
            <Grid container spacing={8} direction="row" alignItems={"flex-start"}>
                <Grid item xs={12}>
                    <Typography>
                        {" "}
                        Please carefully review your transaction details and hit confirm
                        when you are ready to finalize the transaction.{" "}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <TableContainer className={tableStyles.tableContainer} component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead className={tableStyles.tableHeader}>
                            <TableRow>
                                <TableCell className={tableStyles.cellHeadIcon}>
                                </TableCell>
                                <TableCell className={tableStyles.cellHead}>
                                    Reference{" "}
                                </TableCell>
                                <TableCell className={tableStyles.cellHead} align="center">
                                    Amount Payable
                                </TableCell>
                                <TableCell className={tableStyles.cellHead} align="right">
                                    Cost
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow >
                                {props.hideEditIcon ? (
                                    <TableCell className={tableStyles.cellIcon}>
                                    </TableCell>
                                ) : (
                                    <TableCell className={tableStyles.cellIcon}>
                                        <IconButton className={tableStyles.iconButton} onClick={() => props.editStep()}>
                                            <EditIcon fontSize='small'/>
                                        </IconButton>
                                    </TableCell>
                                )}
                                <TableCell className={tableStyles.cellLeft}>
                                    {props.paymentReference}
                                </TableCell>
                                <TableCell className={tableStyles.generalTableCell}
                                           align="center"> {targetCurrencyNumberFormat.format(Number(props.payeeReceivesAmount))}</TableCell>
                                <TableCell className={tableStyles.generalTableCell}
                                           align="right">  {myAccountCurrencyNumberFormat.format(Number(props.payorPaysAmount))}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell rowSpan={6}/>
                                <TableCell rowSpan={6}/>
                                <TableCell
                                    colSpan={1}
                                    align="right"
                                    className={tableStyles.cellBold}
                                >
                                    {" "}
                                    Rate:
                                </TableCell>
                                <TableCell className={tableStyles.generalTableCell}
                                           align="left"> {getRateDisplay(props.selectedAccountCurrency, props.rate, props.targetCurrency)} </TableCell>
                            </TableRow>
                            <FeeSummary
                                payment={payment}
                                currencyNumberFormat={myAccountCurrencyNumberFormat}
                            />
                            <TableRow>
                                <TableCell colSpan={1} align="right" className={tableStyles.cellBold}>
                                    Payment Method:
                                </TableCell>
                                <TableCell className={tableStyles.generalTableCell}
                                           align="left"> {getPaymentOptionLabelForRoutingType(props.paymentMethod)} </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={1} align="right" className={tableStyles.cellBold}>Paid
                                    to:</TableCell>
                                <TableCell align="left"
                                           className={tableStyles.generalTableCell}> {props.institution.name}  </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={1} align="right" className={tableStyles.cellSecondBold}>
                                    Total Cost:
                                </TableCell>
                                <TableCell align="left" className={tableStyles.cellBold}>
                                    {" "}
                                    {myAccountCurrencyNumberFormat.format(payment.totalPaymentAmount)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </React.Fragment>
    );
};

export default TransactionSummary;
