import React, {useEffect, useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid,} from "@material-ui/core";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    AccountRoutingType,
    BankAccountDetail,
    CalculateFeeInput,
    CreditCard,
    useGetBankAccountsLazyQuery
} from "../data/graphqlTypes";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {DirectDebitPaymentOption} from "../DirectDebit";
import {CREDIT_CARD, DIRECT_DEBIT, ETRANSFER, SelectedPaymentOptionType} from "../DirectDebit/PaymentTypes";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import ETransferInstructions from "./ETranferInstructions";
import {ShiftModal} from "../input/ShiftModal";
import PaymentOptionHeader from "./PaymentOptionHeader";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import useCalculateFees from "../Fees/useCalculateFees";
import FurtherActionModelContent from "./FurtherActionModelContent";
import WirePaymentOptionWithAccordion from "./WirePaymentOptionWithAccordion";
import RecurringPayments from "./RecurringPayments";
import {accountAndRoutingTypeIsValid, isDirectDebit} from "./accountAndRoutingTypeIsValid";
import CreditCardPaymentOption from "./CreditCardPaymentOption";
import useGetCountriesAndCurrencies, {ALLOWED_RESIDENCE_COUNTRIES} from "../data/graphql/getCountriesAndCurrencies";
import useGetCustomer from "../context/EntityContext/useGetCustomer";
import {getCountryFromList} from "../../utilities/useGetCountry";


type PropsType = {
    amount: number;
    setAccount: (accountId: BankAccountDetail | undefined) => void;
    setRoutingType: (routingType: AccountRoutingType | undefined) => void;
    account: BankAccountDetail | undefined;
    routingType: AccountRoutingType | undefined;
    setEditMode: ((editingNow: boolean) => void);
    allowNavigation: boolean;
    backButtonAction: () => void;
    changeCurrencyAction: () => void;
    nextButtonAction: () => void;
    setAcceptRecurringPaymentTerms: (accepted: boolean) => void;
    acceptRecurringPaymentTerms: boolean;
    creditCardEnabled: boolean;
    recurringPaymentsEnabled: boolean;
    editMode: boolean;
    setSelectedCreditCard: (card: CreditCard | undefined) => void;
    selectedCreditCard?: CreditCard;
    enforceAccountCurrency: string | undefined;
    paymentCurrency: string | undefined;
    hoaCode: string;
    targetCurrency: string;
}


function isEtransferOrWire(selectedAccountRoutingType: AccountRoutingType | undefined): boolean {
    return selectedAccountRoutingType === AccountRoutingType.Etransfer ||
        selectedAccountRoutingType === AccountRoutingType.Swift;
}

type CurrencyMismatchParams = {
    content: string;
    accountCurrencyCode: string;
    closeButtonText: string;
}

function showEtransferOption(customerCountry: string | undefined, currency: string | undefined) {
    return customerCountry === 'CA' && currency !== 'USD';
}

const PaymentOptions = (props: PropsType) => {

    const [openFurtherActionModel, setOpenFurtherActionModal] = useState<boolean>(false);
    const [mismatchedCurrencyPaymentDetails, setMismatchedCurrencyPaymentDetails] = useState<CurrencyMismatchParams>();
    const {currencyFormatter} = useGetCurrencyFormatter();

    const {
        payment,
        loading: calculateFeesLoading,
        error: calculateFeesError,
        calculate: calculateFees
    } = useCalculateFees();

    const {
        customer,
    } = useGetCustomer()

    const {
        getCurrencyObject
    } = useGetCountriesAndCurrencies();


    const [getAccounts, {
        loading: accountsLoading,
        error: accountsError,
        data: accountsData
    }] = useGetBankAccountsLazyQuery();

    useEffect(() => {
        if (!!props.routingType && !!props.paymentCurrency) {
            const calculateFeeInput: CalculateFeeInput = {
                payorPays: {
                    amount: String(props.amount),
                    currencyCode: props.paymentCurrency
                },
                paymentType: props.routingType,
                hoaCode: props.hoaCode,
                targetCurrency: props.targetCurrency
            };
            calculateFees(calculateFeeInput, props.selectedCreditCard?.creditCardId);

        }
    }, [
        props.routingType,
        props.paymentCurrency,
        props.amount,
        props.hoaCode,
        props.targetCurrency,
        calculateFees,
        props.selectedCreditCard?.creditCardId
    ]);

    useEffect(() => {
        if (!accountsData && !accountsLoading) {
            getAccounts();
        }
    });

    function openMismatchedCreditCardCurrencyWarning() {
        if (props.selectedCreditCard) {
            const card = props.selectedCreditCard;
            setMismatchedCurrencyPaymentDetails({
                closeButtonText: "Use a different credit card",
                content: `Your transaction uses ${props.enforceAccountCurrency} but the credit card
         you chose uses ${card.cardCurrency}. Please change the payment currency or use a different ${props.enforceAccountCurrency} credit card`,
                accountCurrencyCode: card.cardCurrency
            });
        }
    }

    function isCreditCardAndCurrencyDoesNotMatchTransaction() {
        return !!props.selectedCreditCard &&
            selectedAccountRoutingType === AccountRoutingType.CreditCard &&
            !!props.enforceAccountCurrency &&
            props.selectedCreditCard.cardCurrency !== props.enforceAccountCurrency;
    }


    function setAccount(account: BankAccountDetail | undefined) {
        if (!account) {
            props.setAccount(undefined);
            return;
        }
        if (props.routingType !== AccountRoutingType.Pad && props.routingType !== AccountRoutingType.AchDebit) {
            return;
        }
        if (account?.currency && !props.enforceAccountCurrency || (props.enforceAccountCurrency === account?.currency)) {
            props.setAccount(account);
            return;
        }
        if (isDirectDebit(selectedAccountRoutingType)) {
            setMismatchedCurrencyPaymentDetails({
                closeButtonText: "Use another bank account",
                content: `Your transaction uses ${props.enforceAccountCurrency} but the bank account you chose uses
                    ${account?.currency}. Please add or change your bank account or go
                back and change the transaction currency.`,
                accountCurrencyCode: account?.currency,
            });
        }
    }

    const handleChange = (selectedType: SelectedPaymentOptionType) => {
        props.setEditMode(false);
        props.setRoutingType(selectedType.routingType);
    };

    const selectedAccountRoutingType = props.routingType;
    const handleFurtherActionNotAccepted = () => {
        setOpenFurtherActionModal(false);
        props.setEditMode(true);
        props.setAccount(undefined);
        props.setRoutingType(undefined);
    };
    const currency = props.paymentCurrency ? getCurrencyObject(props.paymentCurrency) : undefined;
    const customerCountryCode = customer?.countryCode ? customer?.countryCode : "US";
    let customerCountry = getCountryFromList(ALLOWED_RESIDENCE_COUNTRIES, customerCountryCode);
    const transactionCurrency =
        props.paymentCurrency ||
        props.enforceAccountCurrency ||
        customerCountry?.defaultCurrency.currencyCode ||
        "USD";

    return (
        <React.Fragment>
            <FormControl>
                {props.creditCardEnabled && (
                    <Accordion expanded={selectedAccountRoutingType === AccountRoutingType.CreditCard}
                               onChange={() => handleChange(CREDIT_CARD)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            {selectedAccountRoutingType === AccountRoutingType.CreditCard ?
                                <CheckBoxIcon color={"primary"}/> :
                                <CheckBoxOutlineBlankIcon color={"primary"}/>}
                            <PaymentOptionHeader
                                label={CREDIT_CARD.label}
                            />

                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="column">
                                <CreditCardPaymentOption
                                    recurringPaymentEnabledOnInstitution={props.recurringPaymentsEnabled}
                                    formatter={currencyFormatter(transactionCurrency)}
                                    payment={payment}
                                    setAcceptRecurringPaymentTerms={props.setAcceptRecurringPaymentTerms}
                                    acceptRecurringPaymentTerms={props.acceptRecurringPaymentTerms}
                                    setSelectedCreditCard={props.setSelectedCreditCard}
                                    selectedCreditCard={props.selectedCreditCard}
                                    autoSelectCurrency={currency}
                                    institutionId={props.hoaCode}
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
                {showEtransferOption(customerCountryCode, props.enforceAccountCurrency) &&
                    <Accordion expanded={selectedAccountRoutingType === AccountRoutingType.Etransfer}
                               onChange={() => handleChange(ETRANSFER)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            {selectedAccountRoutingType === AccountRoutingType.Etransfer ?
                                <CheckBoxIcon color={"primary"}/> :
                                <CheckBoxOutlineBlankIcon color={"primary"}/>}
                            <PaymentOptionHeader
                                label={ETRANSFER.label}
                                // feeDescription={"No Fee"}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="column">
                                <ETransferInstructions
                                    formatter={currencyFormatter(transactionCurrency)}
                                    payment={payment}
                                    setAcceptRecurringPaymentTerms={props.setAcceptRecurringPaymentTerms}
                                    acceptRecurringPaymentTerms={props.acceptRecurringPaymentTerms}
                                />

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                }
                <Accordion
                    expanded={isDirectDebit(selectedAccountRoutingType)}
                    onChange={() => handleChange(DIRECT_DEBIT)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-label="Expand"
                        aria-controls="additional-actions2-content"
                        id="additional-actions2-header"
                    >
                        {(selectedAccountRoutingType === AccountRoutingType.Pad) || (selectedAccountRoutingType === AccountRoutingType.AchDebit) ?
                            <CheckBoxIcon color={"primary"}/> : <CheckBoxOutlineBlankIcon color={"primary"}/>}
                        <PaymentOptionHeader
                            label={"Direct Debit"}
                            // feeDescription={"No Fee"}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} direction={"column"}>
                            <Grid>

                                <DirectDebitPaymentOption
                                    setAccount={setAccount}
                                    currencyOfNewAccounts={transactionCurrency}
                                    accountId={props.account?.id}
                                    setEditMode={props.setEditMode}
                                    editMode={props.editMode}
                                    autoSelectCurrency={transactionCurrency}
                                    formatter={currencyFormatter(transactionCurrency)}
                                    payment={payment}
                                    selectedAccountCurrency={props.enforceAccountCurrency}

                                />
                                <ApolloGqlQueryDisplay loading={accountsLoading} loadingMessage={''}
                                                       error={accountsError}/>
                            </Grid>
                            {props.recurringPaymentsEnabled &&
                                <Grid>
                                    <RecurringPayments
                                        setAcceptRecurringPaymentTerms={props.setAcceptRecurringPaymentTerms}
                                        acceptRecurringPaymentTerms={props.acceptRecurringPaymentTerms}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <WirePaymentOptionWithAccordion
                    selectedAccountRoutingType={selectedAccountRoutingType}
                    setAcceptRecurringPaymentTerms={props.setAcceptRecurringPaymentTerms}
                    acceptRecurringPaymentTerms={props.acceptRecurringPaymentTerms}
                    payment={payment}
                    handleChange={handleChange}
                    transactionCurrency={transactionCurrency}
                />
                <ApolloGqlQueryDisplay
                    loading={!!props.routingType && calculateFeesLoading}
                    loadingMessage="Calculating fees"
                    error={calculateFeesError}
                />
            </FormControl>
            <Grid container direction={"row"}>
                <Grid item xs={12}>
                    <Button
                        variant={"text"}
                        onClick={props.backButtonAction}>
                        Back</Button>
                    <Button
                        variant={"text"}
                        onClick={() => {
                            if (isEtransferOrWire(selectedAccountRoutingType)) {
                                setOpenFurtherActionModal(true);
                            } else if (isCreditCardAndCurrencyDoesNotMatchTransaction()) {
                                openMismatchedCreditCardCurrencyWarning();
                            } else {
                                return props.nextButtonAction();
                            }
                        }}
                        disabled={!accountAndRoutingTypeIsValid(props.account, props.selectedCreditCard, props.routingType)}>
                        Next
                    </Button>
                </Grid>
            </Grid>
            {(payment && (selectedAccountRoutingType === AccountRoutingType.Etransfer ||
                    selectedAccountRoutingType === AccountRoutingType.Swift)) &&
                <ShiftModal
                    content={
                        <FurtherActionModelContent
                            selectedAccountRoutingType={selectedAccountRoutingType}
                            payment={payment}
                            formatter={currencyFormatter(transactionCurrency)}/>
                    }
                    title={'Further Action Required'}
                    open={openFurtherActionModel}
                    accepted={props.nextButtonAction}
                    close={handleFurtherActionNotAccepted}
                    acceptButtonText={"Got it!"}
                    closeButtonText={"Cancel"}
                    buttonGridXs={3}
                    buttonGridSm={2}
                    variant={undefined}
                />
            }
            {!!mismatchedCurrencyPaymentDetails &&

                <ShiftModal
                    open={!!mismatchedCurrencyPaymentDetails}
                    content={mismatchedCurrencyPaymentDetails.content}
                    title="Currency Mismatch"
                    accepted={() => {
                        props.changeCurrencyAction();
                    }}
                    close={() => setMismatchedCurrencyPaymentDetails(undefined)}
                    acceptButtonText={"Change payment currency"}
                    closeButtonText={mismatchedCurrencyPaymentDetails.closeButtonText}
                    buttonGridXs={6}
                    buttonGridSm={5}
                    variant={"text"}
                />
            }
        </React.Fragment>
    );
}

export default PaymentOptions;

