import {Grid, Typography} from "@material-ui/core";
import React from "react";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";

const styles = {
  paymentInstructions:{
      fontWeight:400,
      letterSpacing: "-.12px",
  }
} 

type PropsType = {
  currency: string;
  totalCost: number;
  transactionNumber: string;
  emailTransferReceiveEmail: string;
};

const EmailTransferPaymentInstructions = ({
  currency,
  totalCost,
  transactionNumber,
  emailTransferReceiveEmail,
}: PropsType) => {
  const {currencyFormatter} = useGetCurrencyFormatter();
  let myAccountCurrencyNumberFormat: Intl.NumberFormat = currencyFormatter(currency);
  return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2">
              Email Transfer Payment Instructions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={styles.paymentInstructions}>
              To finalize your transaction, please send <b> {myAccountCurrencyNumberFormat.format(totalCost)}</b> via
              Interac E-Transfer to{" "}
              <b> {emailTransferReceiveEmail}</b>.
              <br/>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={styles.paymentInstructions}>
              To ensure faster processing, please send your transfer from the same
              email address you use to sign into Pay From Away and / or include
            the following transaction number in your Interac transfer message:{" "}#
            {transactionNumber}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default EmailTransferPaymentInstructions;
