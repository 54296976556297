import {useState} from "react";
import {ApolloError} from "@apollo/client";
import {
    AccountRoutingType,
    BankAccountDetail,
    BookPaymentResult,
    CreditCardPaymentInput,
    DirectDebitPaymentInput,
    PaymentInput,
    useBookCreditCardAccountBalancePaymentMutation,
    useBookCreditCardInvoicePaymentMutation,
    useBookCreditCardPaymentMutation,
    useBookDirectDebitAccountBalancePaymentMutation,
    useBookDirectDebitInvoicePaymentMutation,
    useBookDirectDebitPaymentMutation,
    useBookEmailTransferInvoicePaymentMutation,
    useBookEmailTransferPaymentMutation
} from "../components/data/graphqlTypes";
import {getTransactions} from "../views/TransactionHistory";
import {getCurrentBalances} from "../components/data/graphql/customerBalance.graphql";

type PropsType = {
    account: BankAccountDetail | undefined;
    routingType: AccountRoutingType;
    acceptRecurringPaymentTerms: boolean;
    creditCardId?: string;
    paymentInput: PaymentInput;
};

type SubmitTransactionReturnType = {
    submitTransaction: (invoiceId: string | undefined, institutionCustomerBalanceId: string | undefined) => Promise<BookPaymentResult | void | undefined>;
    loading: boolean;
    error: ApolloError | undefined;
    validationError: string | undefined;
}

function getDirectDebitPaymentVariables(directDebitPaymentInput: DirectDebitPaymentInput) {
    return {
        variables: {directDebitPaymentInput: directDebitPaymentInput},
        refetchQueries: [{query: getTransactions}, {query: getCurrentBalances}]
    };
}

function getCreditCardPaymentInput(institutionId: string, props: PropsType): CreditCardPaymentInput {
    if (!props.creditCardId) {
        throw new Error("Cannot book credit card payment without a credit card id");
    }
    return {
        paymentInput: {
            institutionId: institutionId,
            paymentReference: props.paymentInput.paymentReference,
            rateId: props.paymentInput.rateId,
            payeeReceives: props.paymentInput.payeeReceives
        },
        creditCardId: props.creditCardId,
    };
}

function getCreditCardPaymentVariables(creditCardPaymentInput: CreditCardPaymentInput) {
    return {
        variables: {creditCardPaymentInput: creditCardPaymentInput},
        refetchQueries: [{query: getTransactions}, {query: getCurrentBalances}]
    };
}

function getDirectDebitPaymentInput(institutionId: string, props: PropsType): DirectDebitPaymentInput {
    if (!props.account?.id) {
        throw new Error("Cannot book direct debit payment without an account id.");
    }
    return {
        paymentInput: {
            institutionId: institutionId,
            payeeReceives: props.paymentInput.payeeReceives,
            paymentReference: props.paymentInput.paymentReference,
            rateId: props.paymentInput.rateId
        },
        paymentAccountId: props.account.id,
        paymentMethod: props.routingType,
    };
}

function getEmailTransferPaymentInput(props: PropsType, institutionId: string) {
    return {
        rateId: props.paymentInput.rateId,
        payeeReceives: props.paymentInput.payeeReceives,
        institutionId: institutionId,
        paymentReference: props.paymentInput.paymentReference
    };
}

function getEmailTransferPaymentVariables(emailTransferPaymentInput: PaymentInput) {
    return {
        variables: {emailTransferPaymentInput: emailTransferPaymentInput},
        refetchQueries: [{query: getTransactions}]
    };
}

export function useTransactionSubmit(props: PropsType): SubmitTransactionReturnType {

    const [loading, setLoading] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<string>();

    const [bookEmailTransferPayment, {
        loading: bookEmailTransferPaymentLoading,
        error: bookEmailTransferPaymentError
    }] = useBookEmailTransferPaymentMutation();

    const [bookCreditCardPayment, {
        loading: bookCreditCardPaymentLoading,
        error: bookCreditCardPaymentError
    }] = useBookCreditCardPaymentMutation();

    const [bookDirectDebitPayment, {
        loading: bookDirectDebitPaymentLoading,
        error: bookDirectDebitPaymentError
    }] = useBookDirectDebitPaymentMutation();

    const [bookEmailTransferInvoicePayment, {
        loading: bookEmailTransferInvoicePaymentLoading,
        error: bookEmailTransferInvoicePaymentError
    }] = useBookEmailTransferInvoicePaymentMutation();

    const [bookCreditCardInvoicePayment, {
        loading: bookCreditCardInvoicePaymentLoading,
        error: bookCreditCardInvoicePaymentError
    }] = useBookCreditCardInvoicePaymentMutation();

    const [bookDirectDebitInvoicePayment, {
        loading: bookDirectDebitInvoicePaymentLoading,
        error: bookDirectDebitInvoicePaymentError
    }] = useBookDirectDebitInvoicePaymentMutation();

    const [bookDirectDebitAccountBalancePayment, {
        loading: bookDirectDebitAccountBalancePaymentLoading,
        error: bookDirectDebitAccountBalancePaymentError
    }] = useBookDirectDebitAccountBalancePaymentMutation();

    const [bookCreditCardAccountBalancePayment, {
        loading: bookCreditCardAccountBalancePaymentLoading,
        error: bookCreditCardAccountBalancePaymentError
    }] = useBookCreditCardAccountBalancePaymentMutation()

    const institutionId = props.paymentInput.institutionId;

    const bookDirectDebitTransaction = async (): Promise<BookPaymentResult | void | null | undefined> => {
        if (!props.account) {
            setValidationError("No direct debit account provided for transaction");
            return;
        }
        let directDebitPaymentInput: DirectDebitPaymentInput = getDirectDebitPaymentInput(institutionId, props);
        const result = await bookDirectDebitPayment(getDirectDebitPaymentVariables(directDebitPaymentInput));
        return result.data?.bookDirectDebitPayment;
    }

    const bookCreditCardTransaction = async (): Promise<BookPaymentResult | void | null | undefined> => {
        if (!props.creditCardId) {
            setValidationError("No Credit Card Id provided for transaction");
            return;
        }
        let creditCardPaymentInput: CreditCardPaymentInput = getCreditCardPaymentInput(institutionId, props);
        const result = await bookCreditCardPayment(getCreditCardPaymentVariables(creditCardPaymentInput));
        return result.data?.bookCreditCardPayment;
    }


    const bookEmailTransferTransaction = async (): Promise<BookPaymentResult | void | undefined> => {
        const emailTransferPaymentInput: PaymentInput = getEmailTransferPaymentInput(props, institutionId)
        const result = await bookEmailTransferPayment(getEmailTransferPaymentVariables(emailTransferPaymentInput));
        return result.data?.bookEmailTransferPayment;
    }

    const bookDirectDebitInvoiceTransaction = async (invoiceId: string): Promise<BookPaymentResult | void | null | undefined> => {
        if (!props.account) {
            setValidationError("No direct debit account provided for transaction");
            return;
        }
        let directDebitPaymentInput: DirectDebitPaymentInput = getDirectDebitPaymentInput(institutionId, props);
        const result = await bookDirectDebitInvoicePayment({
            variables: {
                directDebitPaymentInput: directDebitPaymentInput,
                invoiceId: invoiceId
            },
            refetchQueries: [{query: getTransactions}, {query: getCurrentBalances}]
        });
        return result.data?.bookDirectDebitInvoicePayment;
    }

    const bookDirectDebitAccountBalanceTransaction = async (institutionCustomerBalanceId: string): Promise<BookPaymentResult | void | null | undefined> => {
        if (!props.account) {
            setValidationError("No direct debit account provided for transaction");
            return;
        }
        let directDebitPaymentInput: DirectDebitPaymentInput = getDirectDebitPaymentInput(institutionId, props);
        const result = await bookDirectDebitAccountBalancePayment({
            variables: {
                directDebitPaymentInput: directDebitPaymentInput,
                institutionCustomerBalanceId: institutionCustomerBalanceId
            },
            refetchQueries: [{query: getTransactions}, {query: getCurrentBalances}]
        });
        return result.data?.bookDirectDebitAccountBalancePayment;
    }

    const bookCreditCardCustomerAccountBalancePayment = async (institutionCustomerBalanceId: string): Promise<BookPaymentResult | void | null | undefined> => {
        if (!props.creditCardId) {
            setValidationError("No Credit Card Id provided for transaction");
            return;
        }
        let creditCardPaymentInput: CreditCardPaymentInput = getCreditCardPaymentInput(institutionId, props);
        const result = await bookCreditCardAccountBalancePayment({
            variables: {
                creditCardPaymentInput: creditCardPaymentInput,
                institutionCustomerBalanceId: institutionCustomerBalanceId
            },
            refetchQueries: [{query: getTransactions}, {query: getCurrentBalances}]
        });
        return result.data?.bookCreditCardAccountBalancePayment;
    }

    const bookCreditCardInvoiceTransaction = async (invoiceId: string): Promise<BookPaymentResult | void | null | undefined> => {
        if (!props.creditCardId) {
            setValidationError("No Credit Card Id provided for transaction");
            return;
        }
        let creditCardPaymentInput: CreditCardPaymentInput = getCreditCardPaymentInput(institutionId, props);
        const result = await bookCreditCardInvoicePayment({
            variables: {
                creditCardPaymentInput: creditCardPaymentInput,
                invoiceId: invoiceId
            },
            refetchQueries: [{query: getTransactions}, {query: getCurrentBalances}]
        });
        return result.data?.bookCreditCardInvoicePayment;
    }


    const bookItEmailTransferInvoiceTransaction = async (invoiceId: string): Promise<BookPaymentResult | void | undefined> => {
        const emailTransferPaymentInput: PaymentInput = getEmailTransferPaymentInput(props, institutionId)
        const result = await bookEmailTransferInvoicePayment({
            variables: {
                emailTransferPaymentInput: emailTransferPaymentInput,
                invoiceId: invoiceId
            },
            refetchQueries: [{query: getTransactions}, {query: getCurrentBalances}]
        });
        return result.data?.bookEmailTransferInvoicePayment;
    }

    const bookIt = async (invoiceId: string | undefined, institutionCustomerBalanceId: string | undefined): Promise<BookPaymentResult | void | undefined> => {
        if (loading || bookEmailTransferPaymentLoading || bookCreditCardPaymentLoading) {
            return;
        }
        setLoading(true);

        let result;
        if (!!institutionCustomerBalanceId) {
            switch (props.routingType) {
                case AccountRoutingType.AchDebit:
                case AccountRoutingType.Pad:
                    result = await bookDirectDebitAccountBalanceTransaction(institutionCustomerBalanceId) as BookPaymentResult;
                    break;
                case AccountRoutingType.CreditCard:
                    result = await bookCreditCardCustomerAccountBalancePayment(institutionCustomerBalanceId) as BookPaymentResult;
                    break;
                default:
                    setValidationError("Payment type not supported.");
            }
        } else if (!!invoiceId) {
            switch (props.routingType) {
                case AccountRoutingType.AchDebit:
                case AccountRoutingType.Pad:
                    result = await bookDirectDebitInvoiceTransaction(invoiceId) as BookPaymentResult;
                    break;
                case AccountRoutingType.CreditCard:
                    result = await bookCreditCardInvoiceTransaction(invoiceId) as BookPaymentResult;
                    break;
                case AccountRoutingType.Etransfer:
                    result = await bookItEmailTransferInvoiceTransaction(invoiceId) as BookPaymentResult;
                    break;
                default:
                    setValidationError("Payment type not supported.");
            }
        } else {
            switch (props.routingType) {
                case AccountRoutingType.AchDebit:
                case AccountRoutingType.Pad:
                    result = await bookDirectDebitTransaction() as BookPaymentResult;
                    break;
                case AccountRoutingType.CreditCard:
                    result = await bookCreditCardTransaction() as BookPaymentResult;
                    break;
                case AccountRoutingType.Etransfer:
                    result = await bookEmailTransferTransaction() as BookPaymentResult;
                    break;
                default:
                    setValidationError("Payment type not supported.");
            }
        }
        setLoading(false);
        return result;

    }

    return {
        loading: bookEmailTransferPaymentLoading || bookCreditCardPaymentLoading || bookDirectDebitPaymentLoading ||
            bookEmailTransferInvoicePaymentLoading || bookCreditCardInvoicePaymentLoading || bookDirectDebitInvoicePaymentLoading ||
            bookDirectDebitAccountBalancePaymentLoading || bookCreditCardAccountBalancePaymentLoading,
        error: bookEmailTransferPaymentError || bookCreditCardPaymentError || bookDirectDebitPaymentError ||
            bookEmailTransferInvoicePaymentError || bookCreditCardInvoicePaymentError || bookDirectDebitInvoicePaymentError ||
            bookDirectDebitAccountBalancePaymentError ||bookCreditCardAccountBalancePaymentError,
        validationError: validationError,
        submitTransaction: bookIt
    }

}

