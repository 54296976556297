import {CalculateFeeInput, FeeType} from "../data/graphqlTypes";
import {useCallback, useEffect, useState} from "react";
import CalculateFeesResolver from "../PaymentOptions/calculateFeesResolver";
import useAuthorization from "../auth/useAuthorization";


type Fee = {
    type: FeeType;
    amount: number;
    currency: string;
}

export type Payment = {
    fees: Array<Fee>;
    totalPaymentAmount: number;
}

const useCalculateFees = () => {

    const [payment, setPayment] = useState<Payment>();
    const {isAuthenticated} = useAuthorization();

    const {
        calculateFees,
        error,
        data: calculatedFeeData,
        loading
    } = CalculateFeesResolver();


    useEffect(() => {
        if (calculatedFeeData) {
            const fees: Array<Fee> = calculatedFeeData.fees.map(feeFromServer => {
                if (!feeFromServer) {
                    throw new Error("Fee is empty but the graphql says it can't be. Typescript is dumb.");
                }
                const fee: Fee = {
                    amount: Number(feeFromServer.amount),
                    currency: feeFromServer.currencyCode,
                    type: feeFromServer.type
                }
                return fee;
            });
            setPayment({
                fees,
                totalPaymentAmount: Number(calculatedFeeData.totalAmount)
            });
        }
    }, [calculatedFeeData, setPayment]);

    const calculate = useCallback((calculateFeeInput: CalculateFeeInput, creditCardId: string | undefined) => {
        calculateFees(
            {
                calculateFeeInput: calculateFeeInput
                ,
                creditCardId
            });
    }, [calculateFees]);

    return {
        loading,
        error,
        payment,
        calculate
    }

}

export default useCalculateFees;
