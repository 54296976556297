import React from "react";
import {useParams} from "react-router-dom";
import {CurrentTransactionContextProvider} from "../components/context/CurrentTransactionContext";
import CurrentTransactionContextPaymentStepper from "../components/CurrentTransactionContextPaymentStepper";

export const TRANSACTION_DETAILS_STEP = 0;
export const USER_PROFILE_STEP = 1;
export const PAYMENT_DETAILS_STEP = 2;
export const TRANSACTION_SUMMARY_STEP = 3;


export const NO_CODE = 'no-code';


interface Params {
    hoaCode: string;
}


const HoaPayment = () => {
    let {hoaCode}: Params = useParams();


    return <CurrentTransactionContextProvider
        hoaCode={hoaCode}
    >
        <CurrentTransactionContextPaymentStepper
            hoaCode={hoaCode}
        />

    </CurrentTransactionContextProvider>

};

export default HoaPayment;
