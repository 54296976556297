import {Grid} from "@material-ui/core";
import React from "react";
import {Image} from "react-bootstrap";
import {Institution} from "../data/graphqlTypes";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    imageLogo: {
        height: "100%",
        width: "100%",
        maxWidth: '200px',
        maxHeight: "100px",
        marginBottom: '10px',
        marginTop: '10px',
        alignItems: 'right',
        borderColor: 'transparent',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '150px',
            maxHeight: "75px"
        }
    },
}));

type PropsType = {
    institution: Institution
}

const InstitutionPageHeader = (props: PropsType) => {
    const styles = useStyles();

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center"
        >
            <Grid item xs={12} style={{
                marginBottom: 0,
                paddingBottom: 0,
            }}>
                <Image className={styles.imageLogo} src={props.institution.logoUrl} thumbnail
                       style={{
                           marginBottom: 0,
                           paddingBottom: 0,
                       }}
                />
            </Grid>
        </Grid>
    );
}

export default InstitutionPageHeader;
