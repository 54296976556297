import {Button, Grid, IconButton, Tooltip} from "@material-ui/core";
import React, {useCallback, useRef, useState} from "react";
import {buttonStyles} from "../input/TransactionCountryCurrencyAmounts";
import HttpsIcon from "@material-ui/icons/Https";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import CloverCreditCardForm, {GetCloverToken} from "./CloverCreditCardForm";
import {CreditCard, SaveCreditCardInput, useSaveCloverPaymentProcessorCardProfileMutation} from "../data/graphqlTypes";

type PropsType = {
    addCreditCardCallBack: () => void;
    cancelCallback: () => void;
    apiKey: string;
    setSelectedCreditCard?: (selectedCard: CreditCard) => void;
    institutionId: string;
    setIsPaymentOptionSaved?: any;
}

const CloverCreditCardProcessorDisplay = (props: PropsType) => {
    const [cloverCardError, setCloverCardError] = useState("")
    const saveCardRef = useRef<GetCloverToken>(null);
    const [addButtonClicked, setAddButtonClicked] = useState(false);

    const [saveCloverPaymentProcessorCardProfile,
        {
            loading: saveProfileLoading,
            error: saveProfileError
        }
    ] = useSaveCloverPaymentProcessorCardProfileMutation();

    const setSelectedCard = useCallback((card: CreditCard) => {
        if (!!props.setSelectedCreditCard) {
            props.setSelectedCreditCard(card);
        }
    }, [props.setSelectedCreditCard]);

    const saveCard = useCallback(async (input: SaveCreditCardInput) => {
        return saveCloverPaymentProcessorCardProfile(
            {
                variables: {
                    input: input
                }
            }).then((creditCard) => {
            props.addCreditCardCallBack();
            props.setIsPaymentOptionSaved(true)
            if (!!creditCard.data) {
                setSelectedCard(creditCard.data.saveCloverPaymentProcessorCard as CreditCard);
            }
        }).catch(reason => {
            console.log("Failed to save credit card.", reason);
        });
    }, [saveCloverPaymentProcessorCardProfile, props.addCreditCardCallBack, props.setIsPaymentOptionSaved]);

    return <Grid container direction="row" justifyContent="center"
                 alignItems="center">
        <ApolloGqlQueryDisplay
            loading={saveProfileLoading}
            loadingMessage={"Saving Credit Card"}
            error={saveProfileError}/>
        <CloverCreditCardForm
            saveCardProfile={saveCard}
            setError={(value) => setCloverCardError(value)}
            ref={saveCardRef}
            apiKey={props.apiKey}
            institutionId={props.institutionId}
        />
        <Grid item xs={4} sm={6}>
            <Button
                variant={"text"}
                style={buttonStyles.buttonAsLink}
                onClick={() => props.cancelCallback()}
            >Cancel</Button>
        </Grid>
        <Grid item xs={8} sm={6}>
            <Button
                variant={"text"}
                disabled={!!saveProfileError || saveProfileLoading || !!cloverCardError || addButtonClicked}
                style={buttonStyles.buttonAsLink}
                onClick={() => {
                    saveCardRef.current?.getCloverToken();
                    setAddButtonClicked(true);
                }}
            >Add Card</Button>
            <Tooltip title="Your credit card details go directly to Clover for processing and storage.
                            PayFromAway never sees or stores your credit card details. Clover is a PCI
                             compliant global payment processor."
            >
                <IconButton aria-label="Secure" style={{padding: 0}}>
                    <HttpsIcon/>
                </IconButton>
            </Tooltip>
        </Grid>
    </Grid>;

}
export default CloverCreditCardProcessorDisplay;