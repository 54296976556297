import {Payment} from "../Fees/useCalculateFees";
import {useEffect, useState} from "react";

function getFormattedTotalFees(payment: Payment) {
    return payment.fees
        .map(value => value.amount)
        .reduce((feeA, feeB) => feeA + feeB, 0);
}

export function useFormatPayment(formatter: Intl.NumberFormat, payment: Payment | undefined) {

    const [formattedTotalFees, setFormattedTotalFees] = useState(() => {
        if (payment) {
            return formatter.format(getFormattedTotalFees(payment));
        }
        return formatter.format(0);
    });
    const [formattedTotalPaymentAmount, setFormattedTotalPaymentAmount] = useState(() => {
        if (payment) {
            return formatter.format(payment.totalPaymentAmount);
        }
        return formatter.format(0)
    });

    useEffect(() => {
        if (!payment) {
            return;
        }
        setFormattedTotalPaymentAmount(formatter.format(payment.totalPaymentAmount));
        setFormattedTotalFees(formatter.format(getFormattedTotalFees(payment)));
    }, [formatter, payment])

    return {
        formattedTotalFees,
        formattedTotalPaymentAmount
    };
}
