import * as Apollo from '@apollo/client';
import {gql} from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};


export type AcceptedCurrency = {
  __typename?: 'AcceptedCurrency';
  currencyCode: Scalars['String'];
  priority: Scalars['Int'];
  acceptedCurrencyId: Scalars['String'];
  pullFundsDate?: Maybe<Scalars['String']>;
  pushFundsDate?: Maybe<Scalars['String']>;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  currency: Scalars['String'];
  status?: Maybe<AccountStatus>;
  accountNumber: Scalars['String'];
  routingType: AccountRoutingType;
  routingCode: Scalars['String'];
  accountCountry: Scalars['String'];
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankCity?: Maybe<Scalars['String']>;
  bankRegion?: Maybe<Scalars['String']>;
  bankPostCode?: Maybe<Scalars['String']>;
};

export enum AccountRoutingType {
  Swift = 'SWIFT',
  Ach = 'ACH',
  Eft = 'EFT',
  Aba = 'ABA',
  Iban = 'IBAN',
  Pad = 'PAD',
  AchDebit = 'ACH_DEBIT',
  Etransfer = 'ETRANSFER',
  Ift = 'IFT',
  InternalTransfer = 'INTERNAL_TRANSFER',
  Matching = 'MATCHING',
  Memo = 'MEMO',
  CreditCard = 'CREDIT_CARD',
  InvalidDoNotUseWillBeFiltered = 'INVALID_DO_NOT_USE_WILL_BE_FILTERED'
}

export enum AccountStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Address = {
  __typename?: 'Address';
  streetAddress: Scalars['String'];
  postalCode: Scalars['String'];
  province?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  city: Scalars['String'];
};

export type AutoPayConfiguration = {
  __typename?: 'AutoPayConfiguration';
  id?: Maybe<Scalars['String']>;
  institution: Institution;
  creditCard?: Maybe<CreditCard>;
  bankAccount?: Maybe<BankAccountDetail>;
  createdDate?: Maybe<Scalars['String']>;
};

export type AutoPayConfigurationInput = {
  institutionId: Scalars['String'];
  creditCardId?: Maybe<Scalars['String']>;
  bankAccountId?: Maybe<Scalars['String']>;
};

export enum BalanceType {
  Invoice = 'INVOICE',
  AccountBalance = 'ACCOUNT_BALANCE'
}

export type BankAccountBalanceWithStatement = {
  __typename?: 'BankAccountBalanceWithStatement';
  acceptedCurrencyId: Scalars['String'];
  wireFxAccount: WireFxLedgerResponse;
  statement: InstitutionStatement;
};

export enum BankAccountClass {
  Corporate = 'CORPORATE',
  International = 'INTERNATIONAL'
}

export type BankAccountDetail = {
  __typename?: 'BankAccountDetail';
  id: Scalars['ID'];
  currency: Scalars['String'];
  status: BankAccountStatus;
  accountNumber: Scalars['String'];
  routingType: AccountRoutingType;
  routingCode: Scalars['String'];
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type BankAccountInput = {
  id?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  accountNumber: Scalars['String'];
  accountCountry: Scalars['String'];
  bankRoutingCode: Scalars['String'];
  bankCode?: Maybe<Scalars['String']>;
  bankName: Scalars['String'];
  bankAddress: Scalars['String'];
  bankCity: Scalars['String'];
  bankProvince: Scalars['String'];
  bankPostCode?: Maybe<Scalars['String']>;
  routingType: Scalars['String'];
};

export enum BankAccountStatus {
  New = 'NEW',
  Verified = 'VERIFIED',
  Replaced = 'REPLACED',
  Rejected = 'REJECTED',
  Disabled = 'DISABLED',
  Error = 'ERROR'
}

export type BankAccountsForEntity = {
  __typename?: 'BankAccountsForEntity';
  bankAccounts: Array<Maybe<FxoBankAccount>>;
};

export type BeneficiaryDetail = {
  __typename?: 'BeneficiaryDetail';
  beneficiaryDetailId: Scalars['ID'];
  institutionId: Scalars['String'];
  contactId: Scalars['String'];
  accountNumber: Scalars['String'];
  beneficiaryName: Scalars['String'];
  beneficiaryAddress: Scalars['String'];
  beneficiaryBank: Scalars['String'];
  byOrderOf: Scalars['String'];
  createdDate: Scalars['String'];
  createdBy: Scalars['String'];
  updatedDate: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type BlueSnapToken = {
  __typename?: 'BlueSnapToken';
  token: Scalars['String'];
};

export type BluesnapVendor = {
  __typename?: 'BluesnapVendor';
  paymentProcessorId: Scalars['String'];
  status: Scalars['String'];
  vendorName: Scalars['String'];
  payoutFrequency: Scalars['String'];
  payoutDelay: Scalars['String'];
  commission: Scalars['String'];
  bluesnapCountry: Scalars['String'];
  merchantAccountCountry: Scalars['String'];
};

export type BookDealResult = {
  __typename?: 'BookDealResult';
  dealId: Scalars['String'];
  dealNumber: Scalars['String'];
};

export type BookPaymentResult = {
  __typename?: 'BookPaymentResult';
  status?: Maybe<BookPaymentStatus>;
  transaction?: Maybe<Transaction>;
};

export enum BookPaymentStatus {
  RateExpired = 'RATE_EXPIRED',
  Success = 'SUCCESS',
  Error = 'ERROR'
}

export type BookTransactionResult = {
  __typename?: 'BookTransactionResult';
  dealNumber: Scalars['String'];
  dealId: Scalars['String'];
  confirmationEmailSent: Scalars['Boolean'];
};

export type CalculateFeeForCreditCardInput = {
  payorPays: ShiftMoneyInput;
  hoaCode: Scalars['String'];
  targetCurrency: Scalars['String'];
  creditCardId: Scalars['String'];
};

export type CalculateFeeInput = {
  payorPays: ShiftMoneyInput;
  hoaCode: Scalars['String'];
  targetCurrency: Scalars['String'];
  paymentType: AccountRoutingType;
};

export type CalculateFeeResult = {
  __typename?: 'CalculateFeeResult';
  fees: Array<Maybe<FeeToCharge>>;
  totalAmount: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  entityId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  dateOfBirth?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
};

export type CreateBluesnapVendorInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  country: Scalars['String'];
  institutionId: Scalars['String'];
  phone: Scalars['String'];
  address: Scalars['String'];
  city: Scalars['String'];
  zip: Scalars['String'];
  frequency: Scalars['String'];
  payoutDelay: Scalars['String'];
  vendorPrincipal: VendorPrincipalInput;
  payoutInfo: PayoutInfoInput;
  vendorAgreement: VendorAgreementInput;
  bluesnapAccountUserId: Scalars['String'];
  bluesnapAccountPin: Scalars['String'];
  defaultStatementDescriptor: Scalars['String'];
};

export type CreateCustomerAsAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  countryCode: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  mobile: Scalars['String'];
  address: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  institutionId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
};

export type CreateDealExchangeRate = {
  payorCurrencyCode: Scalars['String'];
  payeeCurrencyCode: Scalars['String'];
  payorExchangeRate: Scalars['String'];
  payorMarketRate: Scalars['String'];
};

export type CreateEntityInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  countryCode: Scalars['String'];
  provinceName?: Maybe<Scalars['String']>;
  mobile: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type CreateFxoBankAccountInput = {
  currency: Scalars['String'];
  accountNumber: Scalars['String'];
  accountCountry: Scalars['String'];
  bankRoutingCode: Scalars['String'];
  bankName: Scalars['String'];
  bankAddress: Scalars['String'];
  bankCity: Scalars['String'];
  bankProvince: Scalars['String'];
  bankPostCode: Scalars['String'];
  routingType: Scalars['String'];
  bankCode?: Maybe<Scalars['String']>;
};

export type CreateFxoDealInput = {
  payorEntityId: Scalars['String'];
  payorPaymentMethod: PaymentMethod;
  payorAccountId?: Maybe<Scalars['String']>;
  payorPays: ShiftMoneyInput;
  payeeName: Scalars['String'];
  payeeReceives: ShiftMoneyInput;
  exchangeRate: CreateDealExchangeRate;
  payeeEntityId: Scalars['String'];
  payeeAccountId: Scalars['String'];
  paymentReference: Scalars['String'];
  fees?: Maybe<Array<FeeInput>>;
};

export type CreateInvoiceForCustomerInput = {
  customerId: Scalars['String'];
  invoiceInput: CreateInvoiceInput;
};

export type CreateInvoiceInput = {
  institutionId: Scalars['String'];
  amount: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  institutionInvoiceId?: Maybe<Scalars['String']>;
  invoiceDate: Scalars['String'];
  dueDate: Scalars['String'];
};

export type CreateInvoiceNonCustomerInput = {
  email: Scalars['String'];
  invoiceInput: CreateInvoiceInput;
};

export type CreateInvoiceResult = {
  __typename?: 'CreateInvoiceResult';
  successful: Scalars['Boolean'];
  invoice?: Maybe<Invoice>;
};

export type CreateRelatedEntityInput = {
  payorEntityId: Scalars['String'];
  payeeEntityId: Scalars['String'];
};

export type CreditCard = {
  __typename?: 'CreditCard';
  creditCardId: Scalars['String'];
  cardBrand: Scalars['String'];
  expiryMonth: Scalars['String'];
  expiryYear: Scalars['String'];
  cardNumber: Scalars['String'];
  cardCurrency: Scalars['String'];
};

export type CreditCardPaymentInput = {
  creditCardId: Scalars['String'];
  paymentInput: PaymentInput;
};

export type CreditCardTransactionInput = {
  transactionId: Scalars['String'];
  institutionId: Scalars['String'];
  paymentReference: Scalars['String'];
  creditCardId: Scalars['String'];
  acceptRecurringPaymentTerms?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  customerId: Scalars['String'];
};

export type CustomerInstitution = {
  __typename?: 'CustomerInstitution';
  id: Scalars['String'];
  units?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  acceptRecurringPaymentTerms?: Maybe<Scalars['String']>;
};

export type CustomerInstitutionInput = {
  id: Scalars['String'];
  units: Array<Scalars['String']>;
};

export type CustomerInstitutionInvoice = {
  __typename?: 'CustomerInstitutionInvoice';
  balance: ShiftMoney;
  invoices: Array<InvoiceWithBalance>;
};

export type CustomerProfile = {
  __typename?: 'CustomerProfile';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type CustomerProfileInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  countryCode: Scalars['String'];
  regionCode?: Maybe<Scalars['String']>;
  mobile: Scalars['String'];
  address: Scalars['String'];
  postalCode: Scalars['String'];
  city: Scalars['String'];
  institutionId: Scalars['String'];
};

export type CustomerStatement = {
  __typename?: 'CustomerStatement';
  balance: ShiftMoney;
  customerStatementItems: Array<CustomerStatementItem>;
};

export type CustomerStatementItem = {
  __typename?: 'CustomerStatementItem';
  id: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  invoiceUuid?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  institutionalInvoiceId?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  invoice?: Maybe<ShiftMoney>;
  payment?: Maybe<ShiftMoney>;
  balance: ShiftMoney;
  transactionStatus?: Maybe<TransactionStatus>;
  invoiceStatus?: Maybe<InvoiceStatus>;
};

export type CustomerView = {
  __typename?: 'CustomerView';
  id: Scalars['ID'];
  profileLink: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  accountStatus: AccountStatus;
  total?: Maybe<ShiftMoney>;
  institutionCustomerBalance?: Maybe<InstitutionCustomerBalance>;
  autoPayConfiguration?: Maybe<AutoPayConfiguration>;
  sendEmailResult?: Maybe<SendEmailResult>;
};

export type Deal = {
  __typename?: 'Deal';
  dealId: Scalars['String'];
  webDealId: Scalars['String'];
  dealNumberValue: Scalars['String'];
  sendCurrency: Scalars['String'];
  sendAmount: Scalars['Float'];
  totalCost: Scalars['Float'];
  receiveCurrency: Scalars['String'];
  receiveAmount: Scalars['Float'];
  actualMarketRate: Scalars['Float'];
  marketRate: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  valueDate: Scalars['String'];
  realValueDate: Scalars['String'];
  entryDate: Scalars['String'];
  sender?: Maybe<Sender>;
  recipient?: Maybe<Recipient>;
  entityId?: Maybe<Scalars['String']>;
  sendAccountId?: Maybe<Scalars['String']>;
  receiveAccountId?: Maybe<Scalars['String']>;
  sendAccountRoutingType?: Maybe<Scalars['String']>;
  receiveAccountRoutingType?: Maybe<Scalars['String']>;
  sendAccountName?: Maybe<Scalars['String']>;
  receiveAccountName?: Maybe<Scalars['String']>;
  receiveAccountInstitutionId?: Maybe<Scalars['String']>;
  receiveAccountCountry?: Maybe<Scalars['String']>;
  receiveAccountEmail?: Maybe<Scalars['String']>;
  isPosted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<TransactionStatus>;
  paymentMethod?: Maybe<Scalars['String']>;
  rejectedPaymentReference?: Maybe<Scalars['String']>;
  purposeOfPayment?: Maybe<Scalars['String']>;
  purposeOfPaymentDetails?: Maybe<Scalars['String']>;
  recipientCountry?: Maybe<Scalars['String']>;
  receiveAccountRoutingCode?: Maybe<Scalars['String']>;
  sendAccountRoutingCode?: Maybe<Scalars['String']>;
  estimatedCompletionDays?: Maybe<Scalars['Float']>;
  transactionFinalizedDate?: Maybe<Scalars['String']>;
  institutionReferenceValue?: Maybe<Scalars['String']>;
  emailTransferReceiveEmail?: Maybe<Scalars['String']>;
  fees: Array<Maybe<FeeDescription>>;
};

export enum DealStatus {
  Cancelled = 'CANCELLED',
  PaymentDeclined = 'PAYMENT_DECLINED',
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Unknown = 'UNKNOWN'
}

export type DealsForEntityResult = {
  __typename?: 'DealsForEntityResult';
  deals: Array<Maybe<FxoDeal>>;
};

export type DeleteCreditCardResult = {
  __typename?: 'DeleteCreditCardResult';
  cardDeleted: Scalars['Boolean'];
};

export type DirectDebitPaymentInput = {
  paymentAccountId: Scalars['String'];
  paymentMethod: Scalars['String'];
  paymentInput: PaymentInput;
};

export type EmailEvent = {
  __typename?: 'EmailEvent';
  id: Scalars['String'];
  userVariables: Scalars['String'];
  subject: Scalars['String'];
  event: Scalars['String'];
  template: Scalars['String'];
  timestamp: Scalars['String'];
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  name: Scalars['String'];
  code: Scalars['String'];
  body: Scalars['String'];
};

export enum EmailType {
  Invoice = 'INVOICE',
  AutoPayInvoice = 'AUTO_PAY_INVOICE',
  Customer = 'CUSTOMER',
  Transaction = 'TRANSACTION',
  BalancePending = 'BALANCE_PENDING'
}

export type Entity = {
  __typename?: 'Entity';
  id: Scalars['ID'];
  name: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  entityContacts?: Maybe<Array<Contact>>;
  countryCode?: Maybe<Scalars['String']>;
};

export type EntityDetail = {
  __typename?: 'EntityDetail';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type ExchangeRateInput = {
  timestamp: Scalars['String'];
  payorPays: ShiftMoneyInput;
  payeeReceives: ShiftMoneyInput;
};

export type Fee = {
  __typename?: 'Fee';
  fee: ShiftMoney;
  type: FeeType;
};

export type FeeDescription = {
  __typename?: 'FeeDescription';
  amount: Scalars['String'];
  currencyCode: Scalars['String'];
  type: Scalars['String'];
};

export type FeeDescriptionInput = {
  amount: Scalars['String'];
  currencyCode: Scalars['String'];
  type: Scalars['String'];
};

export type FeeInput = {
  fee: ShiftMoneyInput;
  type: FeeType;
};

export type FeeToCharge = {
  __typename?: 'FeeToCharge';
  amount: Scalars['String'];
  currencyCode: Scalars['String'];
  type: FeeType;
};

export enum FeeType {
  CreditCard = 'CREDIT_CARD',
  Amex = 'AMEX',
  DirectDebit = 'DIRECT_DEBIT',
  EmailTransfer = 'EMAIL_TRANSFER',
  SameCurrency = 'SAME_CURRENCY'
}

export type FinalizeInvoicesResult = {
  __typename?: 'FinalizeInvoicesResult';
  invoiceIds: Array<Maybe<Scalars['String']>>;
};

export type FxoBankAccount = {
  __typename?: 'FxoBankAccount';
  id: Scalars['ID'];
  accountNumber: Scalars['String'];
  currencyCode: Scalars['String'];
  routingCode: Scalars['String'];
  routingBankCode: Scalars['String'];
  bankCountryCode: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
};

export type FxoDeal = {
  __typename?: 'FxoDeal';
  dealId: Scalars['String'];
  dealNumberValue: Scalars['String'];
  paymentReference: Scalars['String'];
  payorPaysTotal?: Maybe<ShiftMoney>;
  payorPaysSubTotal?: Maybe<ShiftMoney>;
  payorEntityId: Scalars['String'];
  payorPaymentMethod: PaymentMethod;
  payeeReceives?: Maybe<ShiftMoney>;
  createdOn: Scalars['String'];
  payeeAccountId?: Maybe<Scalars['String']>;
  fees: Array<Maybe<Fee>>;
  status: DealStatus;
};

export type FxoGetRateInput = {
  payorPaysCurrency: Scalars['String'];
  payeeReceivesCurrency: Scalars['String'];
};

export type FxoMarketRate = {
  __typename?: 'FxoMarketRate';
  timestamp: Scalars['String'];
  payorCurrencyCode: Scalars['String'];
  payeeCurrencyCode: Scalars['String'];
  payorMarketRate: Scalars['String'];
};

export type GetRateInput = {
  institutionId: Scalars['String'];
  payorPaysCurrencyCode: Scalars['String'];
  payeeReceives: ShiftMoneyInput;
};

export type GetTransactionsForInstitutionInput = {
  institutionId: Scalars['String'];
};

export type ImportedCustomer = {
  __typename?: 'ImportedCustomer';
  id: Scalars['ID'];
  customerImportId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  ownerReference: Scalars['String'];
  ownerId: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  updatedBy: Scalars['String'];
  updatedDate: Scalars['String'];
};

export type ImportedCustomerWithHistory = {
  __typename?: 'ImportedCustomerWithHistory';
  id: Scalars['ID'];
  customerImportId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  ownerReference: Scalars['String'];
  ownerId: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  updatedBy: Scalars['String'];
  updatedDate: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['String']>;
  acceptRecurringPaymentTerms?: Maybe<Scalars['String']>;
  institutionId: Scalars['String'];
  creditCardCurrency?: Maybe<Scalars['String']>;
  creditCardUpdatedDate?: Maybe<Scalars['String']>;
  creditCardUpdatedBy?: Maybe<Scalars['String']>;
};

export type ImportedInvoice = {
  __typename?: 'ImportedInvoice';
  id: Scalars['ID'];
  email: Scalars['String'];
  institutionInvoiceId: Scalars['String'];
  invoiceDate: Scalars['String'];
  dueDate: Scalars['String'];
  amount: ShiftMoney;
  status: Scalars['String'];
  description: Scalars['String'];
};

export type Institution = {
  __typename?: 'Institution';
  institutionId: Scalars['String'];
  name: Scalars['String'];
  logoUrl: Scalars['String'];
  acceptedCurrencies: Array<AcceptedCurrency>;
  defaultHomeCountry: Scalars['String'];
  paymentPageTitle: Scalars['String'];
  referenceFieldLabel: Scalars['String'];
  recurringPaymentsEnabled: Scalars['Boolean'];
  creditCardEnabled: Scalars['Boolean'];
};

export type InstitutionAccountBalance = {
  __typename?: 'InstitutionAccountBalance';
  accountBalanceId: Scalars['ID'];
  balance: ShiftMoney;
  available: ShiftMoney;
  lastPayment: ShiftMoney;
  lastPaymentDate: Scalars['String'];
  institution: Institution;
};

export type InstitutionAdmin = {
  __typename?: 'InstitutionAdmin';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  email: Scalars['String'];
  institutionId: Scalars['String'];
  roles: Array<Scalars['String']>;
};

export type InstitutionAdminInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  institutionId: Scalars['String'];
  oauth2Id: Scalars['String'];
};

export type InstitutionCustomerBalance = {
  __typename?: 'InstitutionCustomerBalance';
  institutionCustomerBalanceId: Scalars['ID'];
  institutionId: Scalars['String'];
  institutionName: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: ShiftMoney;
  transactions: Array<PfaTransactionDetail>;
  pullDate?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  createdBy: Scalars['String'];
  updatedDate: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type InstitutionCustomerBalanceEmailInput = {
  email: Scalars['String'];
  institutionLogoUrl: Scalars['String'];
  institutionName: Scalars['String'];
  institutionId: Scalars['String'];
  amount: ShiftMoneyInput;
  description: Scalars['String'];
  institutionCustomerBalanceId: Scalars['String'];
  paymentUrl: Scalars['String'];
};

export type InstitutionCustomerBalanceWithCustomer = {
  __typename?: 'InstitutionCustomerBalanceWithCustomer';
  balance: InstitutionCustomerBalance;
  customerView: CustomerView;
};

export type InstitutionPayment = {
  __typename?: 'InstitutionPayment';
  id: Scalars['ID'];
  amount: ShiftMoney;
  description: Scalars['String'];
  timestamp: Scalars['String'];
};

export type InstitutionStatement = {
  __typename?: 'InstitutionStatement';
  balance: ShiftMoney;
  lineItems: Array<InstitutionStatementLine>;
};

export type InstitutionStatementLine = {
  __typename?: 'InstitutionStatementLine';
  transactionId: Scalars['ID'];
  statusEventId: Scalars['ID'];
  status: Scalars['String'];
  statusComment?: Maybe<Scalars['String']>;
  finalStatus: Scalars['String'];
  finalStatusComment?: Maybe<Scalars['String']>;
  finalStatusDate: Scalars['String'];
  date: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  deposit: ShiftMoney;
  withdraw: ShiftMoney;
  payorTotalAmount?: Maybe<ShiftMoney>;
  balance: ShiftMoney;
  invoices: Array<Maybe<Invoice>>;
  paymentMethod?: Maybe<Scalars['String']>;
  exchangeSystem?: Maybe<Scalars['String']>;
  creditCardProcessorApprovalCode?: Maybe<Scalars['String']>;
  fees?: Maybe<Array<Maybe<FeeToCharge>>>;
};

export type InstitutionWireConfirmationEmailInput = {
  email: Scalars['String'];
  institutionLogoUrl: Scalars['String'];
  institutionName: Scalars['String'];
  institutionId: Scalars['String'];
  amount: ShiftMoneyInput;
  valueDate: Scalars['String'];
  date: Scalars['String'];
  referenceNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  beneficiaryName: Scalars['String'];
  beneficiaryAddress: Scalars['String'];
  beneficiaryBank: Scalars['String'];
  byOrderOf: Scalars['String'];
};

export type InstitutionWireEmailConfirmationInput = {
  institutionPaymentId?: Maybe<Scalars['ID']>;
  amount: ShiftMoneyInput;
  valueDate: Scalars['String'];
  date: Scalars['String'];
};

export type InstitutionalCustomer = {
  __typename?: 'InstitutionalCustomer';
  id: Scalars['ID'];
  institutionId: Scalars['String'];
  institutionName: Scalars['String'];
  paymentReference: Scalars['String'];
};

export type InstitutionalCustomerDetail = {
  __typename?: 'InstitutionalCustomerDetail';
  id: Scalars['ID'];
  entityId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  province?: Maybe<Scalars['String']>;
};

export type InterpolateProperty = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  invoiceId: Scalars['String'];
  customerId: Scalars['String'];
  institutionId: Scalars['String'];
  amount: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  currencyCode: Scalars['String'];
  institutionInvoiceId?: Maybe<Scalars['String']>;
  invoiceDate: Scalars['String'];
  dueDate: Scalars['String'];
  customerEmail?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<InvoiceStatus>;
  invoiceUuid: Scalars['String'];
};

export enum InvoiceStatus {
  Saved = 'SAVED',
  Sent = 'SENT',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Overdue = 'OVERDUE',
  Cancelled = 'CANCELLED',
  Viewed = 'VIEWED'
}

export type InvoiceWithBalance = {
  __typename?: 'InvoiceWithBalance';
  balance: ShiftMoney;
  transactionIds: Array<Maybe<Scalars['String']>>;
  invoice: Invoice;
};

export type InvoiceWithBalanceAndCustomerView = {
  __typename?: 'InvoiceWithBalanceAndCustomerView';
  balanceType: BalanceType;
  invoiceWithBalance: InvoiceWithBalance;
  customerView: CustomerView;
};

export type Mutation = {
  __typename?: 'Mutation';
  createWireFxEntity: WireFxEntityResponse;
  createWireFxDebit: WireFxDebitResponse;
  processInstitutionCustomerBalancePayment: PfaTransactionDetail;
  processInvoicePayment: PfaTransactionDetail;
  saveNewInstitutionForCustomer: Scalars['Boolean'];
  createBankAccount: BankAccountDetail;
  updateBankAccount: BankAccountDetail;
  bookCreditCardPayment: BookPaymentResult;
  bookCreditCardInvoicePayment: BookPaymentResult;
  bookDirectDebitPayment: BookPaymentResult;
  bookDirectDebitAccountBalancePayment: BookPaymentResult;
  bookCreditCardAccountBalancePayment: BookPaymentResult;
  bookDirectDebitInvoicePayment: BookPaymentResult;
  bookEmailTransferPayment: BookPaymentResult;
  bookEmailTransferInvoicePayment: BookPaymentResult;
  updateCustomerInstitution: Array<Maybe<CustomerInstitution>>;
  saveCloverPaymentProcessorCard: CreditCard;
  saveBlueSnapCreditCard: CreditCard;
  deleteCreditCard: DeleteCreditCardResult;
  createNewCustomerFromImport: Customer;
  createCustomerProfile: CustomerProfile;
  updateCustomerProfile: CustomerProfile;
  saveRateToDatabase: SavedExchangeRate;
  populateBankAccountsInCustomerBankAccountTable: UpdateBankAccountsResult;
  createBluesnapVendor: UpsertVendorResult;
  updateBluesnapVendor: UpsertVendorResult;
  createInvoiceAndSendEmailToPayor: CreateInvoiceResult;
  createInvoiceAndSendEmailToNonCustomer: CreateInvoiceResult;
  finalizeImportedInvoices: FinalizeInvoicesResult;
  saveAutoPayConfiguration: AutoPayConfiguration;
  archiveAutoPayConfiguration: AutoPayConfiguration;
  createInstitutionPayment: InstitutionPayment;
  institutionWireEmailConfirmation: SendEmailResult;
  createInstitutionAdmin: InstitutionAdmin;
  saveNewCustomerForInstitutionAsAdmin: CustomerView;
  createFxoDeal?: Maybe<FxoDeal>;
  createFxoDealAsApi?: Maybe<FxoDeal>;
  createFxoEntityForSignedInUser: EntityDetail;
  updateFxoEntityForSignedInUser: EntityDetail;
  addFxoEntityNumberToAuth0Profile?: Maybe<EntityDetail>;
  createRelatedEntity: RelatedEntityResult;
  createFxoBankAccountForSignedInUser: Account;
  updateFxoBankAccountForSignedInUser: Account;
  sendEmailToCustomers: SendEmailToCustomersResult;
  sendTransactionConfirmation: SendEmailResult;
  sendInvoiceEmail: SendEmailResult;
  sendInstitutionWireConfirmationEmail: SendEmailResult;
  sendInstitutionCustomerBalanceEmail: SendEmailResult;
};


export type MutationCreateWireFxEntityArgs = {
  createEntityInput: WireFxCreateEntityInput;
};


export type MutationCreateWireFxDebitArgs = {
  createDebitInput: WireFxCreateDebitInput;
};


export type MutationProcessInstitutionCustomerBalancePaymentArgs = {
  balanceId: Scalars['String'];
};


export type MutationProcessInvoicePaymentArgs = {
  invoiceId: Scalars['String'];
};


export type MutationSaveNewInstitutionForCustomerArgs = {
  institutionId?: Maybe<Scalars['String']>;
};


export type MutationCreateBankAccountArgs = {
  createBankAccountInput: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  updateBankAccountInput: BankAccountInput;
};


export type MutationBookCreditCardPaymentArgs = {
  creditCardPaymentInput?: Maybe<CreditCardPaymentInput>;
};


export type MutationBookCreditCardInvoicePaymentArgs = {
  creditCardPaymentInput?: Maybe<CreditCardPaymentInput>;
  invoiceId?: Maybe<Scalars['String']>;
};


export type MutationBookDirectDebitPaymentArgs = {
  directDebitPaymentInput?: Maybe<DirectDebitPaymentInput>;
};


export type MutationBookDirectDebitAccountBalancePaymentArgs = {
  directDebitPaymentInput?: Maybe<DirectDebitPaymentInput>;
  institutionCustomerBalanceId?: Maybe<Scalars['String']>;
};


export type MutationBookCreditCardAccountBalancePaymentArgs = {
  creditCardPaymentInput?: Maybe<CreditCardPaymentInput>;
  institutionCustomerBalanceId?: Maybe<Scalars['String']>;
};


export type MutationBookDirectDebitInvoicePaymentArgs = {
  directDebitPaymentInput?: Maybe<DirectDebitPaymentInput>;
  invoiceId?: Maybe<Scalars['String']>;
};


export type MutationBookEmailTransferPaymentArgs = {
  emailTransferPaymentInput?: Maybe<PaymentInput>;
};


export type MutationBookEmailTransferInvoicePaymentArgs = {
  emailTransferPaymentInput?: Maybe<PaymentInput>;
  invoiceId?: Maybe<Scalars['String']>;
};


export type MutationUpdateCustomerInstitutionArgs = {
  customerInstitutionInput: CustomerInstitutionInput;
};


export type MutationSaveCloverPaymentProcessorCardArgs = {
  input: SaveCreditCardInput;
};


export type MutationSaveBlueSnapCreditCardArgs = {
  input: SaveCreditCardInput;
};


export type MutationDeleteCreditCardArgs = {
  creditCardId: Scalars['String'];
};


export type MutationCreateNewCustomerFromImportArgs = {
  importedCustomerId: Scalars['String'];
  institutionId: Scalars['String'];
};


export type MutationCreateCustomerProfileArgs = {
  customerProfileInput: CustomerProfileInput;
};


export type MutationUpdateCustomerProfileArgs = {
  customerProfileInput: CustomerProfileInput;
};


export type MutationSaveRateToDatabaseArgs = {
  input: ExchangeRateInput;
};


export type MutationCreateBluesnapVendorArgs = {
  createVendorInput: CreateBluesnapVendorInput;
};


export type MutationUpdateBluesnapVendorArgs = {
  updateVendorInput: UpdateBluesnapVendorInput;
};


export type MutationCreateInvoiceAndSendEmailToPayorArgs = {
  createInvoiceForCustomerInput: CreateInvoiceForCustomerInput;
};


export type MutationCreateInvoiceAndSendEmailToNonCustomerArgs = {
  createInvoiceNonCustomerInput: CreateInvoiceNonCustomerInput;
};


export type MutationFinalizeImportedInvoicesArgs = {
  importedInvoiceIds: Array<Scalars['String']>;
};


export type MutationSaveAutoPayConfigurationArgs = {
  autoPayConfigInput?: Maybe<AutoPayConfigurationInput>;
};


export type MutationArchiveAutoPayConfigurationArgs = {
  institutionId: Scalars['String'];
};


export type MutationCreateInstitutionPaymentArgs = {
  wirePaymentInput?: Maybe<WirePaymentInput>;
};


export type MutationInstitutionWireEmailConfirmationArgs = {
  institutionWireEmailConfirmationInput?: Maybe<InstitutionWireEmailConfirmationInput>;
};


export type MutationCreateInstitutionAdminArgs = {
  institutionAdminInput: InstitutionAdminInput;
};


export type MutationSaveNewCustomerForInstitutionAsAdminArgs = {
  createCustomerAsAdminInput: CreateCustomerAsAdminInput;
};


export type MutationCreateFxoDealArgs = {
  input: CreateFxoDealInput;
};


export type MutationCreateFxoDealAsApiArgs = {
  input: CreateFxoDealInput;
};


export type MutationCreateFxoEntityForSignedInUserArgs = {
  createEntityInput: CreateEntityInput;
};


export type MutationUpdateFxoEntityForSignedInUserArgs = {
  updateEntityInput: CreateEntityInput;
};


export type MutationAddFxoEntityNumberToAuth0ProfileArgs = {
  auth0Id: Scalars['String'];
};


export type MutationCreateRelatedEntityArgs = {
  createRelatedEntityInput: CreateRelatedEntityInput;
};


export type MutationCreateFxoBankAccountForSignedInUserArgs = {
  createAccountInput: CreateFxoBankAccountInput;
};


export type MutationUpdateFxoBankAccountForSignedInUserArgs = {
  updateAccountInput: UpdateFxoBankAccountInput;
};


export type MutationSendEmailToCustomersArgs = {
  input: SendEmailToCustomersInput;
};


export type MutationSendTransactionConfirmationArgs = {
  confirmationEmailInput: SendConfirmationEmailInput;
};


export type MutationSendInvoiceEmailArgs = {
  invoiceEmailInput: SendInvoiceEmailInput;
};


export type MutationSendInstitutionWireConfirmationEmailArgs = {
  institutionWireConfirmationEmailInput: InstitutionWireConfirmationEmailInput;
};


export type MutationSendInstitutionCustomerBalanceEmailArgs = {
  institutionCustomerBalanceEmailInput: InstitutionCustomerBalanceEmailInput;
};

export type PaymentInput = {
  rateId: Scalars['String'];
  payeeReceives: Scalars['String'];
  institutionId: Scalars['String'];
  paymentReference: Scalars['String'];
};

export enum PaymentMethod {
  Pad = 'PAD',
  AchDebit = 'ACH_DEBIT',
  EmailTransfer = 'EMAIL_TRANSFER',
  CreditCard = 'CREDIT_CARD',
  Swift = 'SWIFT',
  Amex = 'AMEX',
  Unknown = 'UNKNOWN'
}

export type PaymentProcessorDetails = {
  __typename?: 'PaymentProcessorDetails';
  paymentProcessorCode: Scalars['String'];
  processorApiKey?: Maybe<Scalars['String']>;
};

export type PaymentProcessorResolverCriteria = {
  residenceCountryCode: Scalars['String'];
  institutionId: Scalars['String'];
};

export type PayoutInfoInput = {
  payoutType: PayoutType;
  baseCurrency: Scalars['String'];
  nameOnAccount: Scalars['String'];
  bankAccountClass: BankAccountClass;
  bankName: Scalars['String'];
  bankId: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  bankAccountId?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  swiftBic?: Maybe<Scalars['String']>;
};

export enum PayoutType {
  Ach = 'ACH',
  Wire = 'WIRE'
}

export type PfaTransactionDetail = {
  __typename?: 'PfaTransactionDetail';
  id: Scalars['String'];
  customerId: Scalars['Int'];
  createdDate: Scalars['String'];
  createdBy: Scalars['String'];
  creditCardProcessorApprovalCode?: Maybe<Scalars['String']>;
  institutionId: Scalars['String'];
  payorPays: ShiftMoney;
  fees?: Maybe<Array<Maybe<FeeToCharge>>>;
  feesTotal?: Maybe<ShiftMoney>;
  payorPaysTotal: ShiftMoney;
  payeeReceives: ShiftMoney;
  payeeName: Scalars['String'];
  payorName?: Maybe<Scalars['String']>;
  payorEmailAddress?: Maybe<Scalars['String']>;
  status: TransactionStatusEvent;
  paymentMethod: Scalars['String'];
  institutionReferenceValue: Scalars['String'];
  rejectedPaymentReference?: Maybe<Scalars['String']>;
  receiveEmail?: Maybe<Scalars['String']>;
  invoices: Array<Maybe<Invoice>>;
};

export type PopulateBankAccountsResult = {
  __typename?: 'PopulateBankAccountsResult';
  customerEmail?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type PopulateTransactionDetailResult = {
  __typename?: 'PopulateTransactionDetailResult';
  transactionId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getWireFxEntity?: Maybe<WireFxEntityResponse>;
  getWireFxDebit?: Maybe<WireFxDebitResponse>;
  getWireFxLedger?: Maybe<WireFxLedgerResponse>;
  getBankBalance: Array<BankAccountBalanceWithStatement>;
  getInstitutionsById: Array<Maybe<Institution>>;
  getInstitutionById: Institution;
  getCustomerInstitutions?: Maybe<Array<Maybe<CustomerInstitution>>>;
  getUserLocation: UserLocation;
  getUsStates: Array<State>;
  getRegions: Array<Region>;
  calculateFees: CalculateFeeResult;
  calculateFeesForCreditCard: CalculateFeeResult;
  getCloverCard?: Maybe<CreditCard>;
  getBlueSnapCard?: Maybe<CreditCard>;
  hasCustomerBeenCreated: Scalars['Boolean'];
  getTransactionDetail: TransactionResult;
  getTransactions: Array<Maybe<TransactionResult>>;
  getPaymentProcessorConnectionDetails: PaymentProcessorDetails;
  getBlueSnapCreditCardToken: BlueSnapToken;
  getTransactionAmounts: TransactionAmounts;
  getCustomerProfile?: Maybe<CustomerProfile>;
  getCustomersForInstitutionAsAdmin: Array<CustomerView>;
  getBankAccounts: Array<Maybe<BankAccountDetail>>;
  getBluesnapVendor: BluesnapVendor;
  getBanksForRoutingCode?: Maybe<Array<RoutingCodeDetail>>;
  getTransactionsForInstitution?: Maybe<Array<PfaTransactionDetail>>;
  getInstitutionAccountBalances: Array<Maybe<InstitutionAccountBalance>>;
  getInstitutionPayments: Array<Maybe<InstitutionPayment>>;
  getInstitutionStatement?: Maybe<InstitutionStatement>;
  getCustomerStatement: Array<CustomerStatement>;
  getInvoice: Invoice;
  getImportedInvoices: Array<Maybe<ImportedInvoice>>;
  getUploadedData: Array<Upload>;
  getInvoiceWithBalance: InvoiceWithBalance;
  getInvoicesForInstitutionAcceptedCurrency: Array<Maybe<InvoiceWithBalance>>;
  getUnpaidInvoices: Array<InvoiceWithBalanceAndCustomerView>;
  getUnpaidBalances: Array<InstitutionCustomerBalanceWithCustomer>;
  getCustomerInvoices: Array<CustomerInstitutionInvoice>;
  getCustomerTransaction?: Maybe<PfaTransactionDetail>;
  getAutoPayConfigurations: Array<AutoPayConfiguration>;
  getAutoPayConfigurationsAsAdmin: Array<AutoPayConfiguration>;
  settlements: Array<Settlement>;
  getInstitutionAdmins: Array<InstitutionAdmin>;
  institutionBeneficiaryDetail?: Maybe<BeneficiaryDetail>;
  getCurrentBalances: Array<InstitutionCustomerBalance>;
  getInstitutionCustomerBalanceHistory: Array<InstitutionCustomerBalance>;
  getInstitutionCustomerBalances: Array<CustomerView>;
  getDealFromRestApiById: FxoDeal;
  getHoaDealsForEntityAsAdmin?: Maybe<DealsForEntityResult>;
  getDealsForEntityAsAdmin?: Maybe<DealsForEntityResult>;
  getEntityAsAdmin: EntityDetail;
  getEntityForSignedInUser?: Maybe<EntityDetail>;
  getFxoLiveMarketRate: FxoMarketRate;
  getFxoBankAccountInformation: BankAccountsForEntity;
  getFxoBankAccount?: Maybe<FxoBankAccount>;
  getFxoBankAccountAsApi?: Maybe<Account>;
  getBankAccountsForSignedInUser?: Maybe<Array<Account>>;
  getBanksFromFxo?: Maybe<Array<Maybe<RoutingCodeDetail>>>;
  getEmailTemplates: Array<EmailTemplate>;
  getEmailEvents: Array<Maybe<EmailEvent>>;
  getEmailTemplate: EmailTemplate;
  getImportedCustomers: Array<Maybe<ImportedCustomerWithHistory>>;
  getInstitutions: Array<Institution>;
};


export type QueryGetWireFxEntityArgs = {
  entityId: Scalars['String'];
};


export type QueryGetWireFxDebitArgs = {
  debitId: Scalars['String'];
};


export type QueryGetWireFxLedgerArgs = {
  ledgerId: Scalars['String'];
};


export type QueryGetInstitutionsByIdArgs = {
  institutionIds: Array<Maybe<Scalars['String']>>;
};


export type QueryGetInstitutionByIdArgs = {
  institutionId: Scalars['String'];
};


export type QueryCalculateFeesArgs = {
  feeInput: CalculateFeeInput;
};


export type QueryCalculateFeesForCreditCardArgs = {
  creditCardFeeInput: CalculateFeeForCreditCardInput;
};


export type QueryGetCloverCardArgs = {
  institutionId: Scalars['String'];
};


export type QueryGetBlueSnapCardArgs = {
  institutionId: Scalars['String'];
};


export type QueryHasCustomerBeenCreatedArgs = {
  importedCustomerId: Scalars['String'];
};


export type QueryGetTransactionDetailArgs = {
  transactionId: Scalars['String'];
};


export type QueryGetPaymentProcessorConnectionDetailsArgs = {
  input: PaymentProcessorResolverCriteria;
};


export type QueryGetBlueSnapCreditCardTokenArgs = {
  input: PaymentProcessorResolverCriteria;
};


export type QueryGetTransactionAmountsArgs = {
  getRateInput: GetRateInput;
};


export type QueryGetCustomersForInstitutionAsAdminArgs = {
  institutionId: Scalars['String'];
};


export type QueryGetBluesnapVendorArgs = {
  paymentProcessorId: Scalars['String'];
};


export type QueryGetBanksForRoutingCodeArgs = {
  routingCode: Scalars['String'];
};


export type QueryGetTransactionsForInstitutionArgs = {
  getTransactionsInput: GetTransactionsForInstitutionInput;
};


export type QueryGetInstitutionPaymentsArgs = {
  institutionId: Scalars['String'];
};


export type QueryGetInstitutionStatementArgs = {
  institutionId: Scalars['String'];
  currencyCode: Scalars['String'];
};


export type QueryGetCustomerStatementArgs = {
  customerId: Scalars['Int'];
  institutionId: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  invoiceUuid: Scalars['String'];
};


export type QueryGetImportedInvoicesArgs = {
  importId: Scalars['String'];
};


export type QueryGetUploadedDataArgs = {
  institutionId: Scalars['String'];
};


export type QueryGetInvoiceWithBalanceArgs = {
  invoiceUuid: Scalars['String'];
};


export type QueryGetInvoicesForInstitutionAcceptedCurrencyArgs = {
  acceptedCurrencyId: Scalars['String'];
};


export type QueryGetUnpaidInvoicesArgs = {
  day: Scalars['String'];
};


export type QueryGetUnpaidBalancesArgs = {
  day: Scalars['String'];
};


export type QueryGetCustomerTransactionArgs = {
  transactionId: Scalars['String'];
};


export type QueryGetAutoPayConfigurationsAsAdminArgs = {
  customerId: Scalars['String'];
};


export type QuerySettlementsArgs = {
  settlementFilter?: Maybe<SettlementFilterInput>;
};


export type QueryGetInstitutionCustomerBalanceHistoryArgs = {
  institutionId: Scalars['String'];
  customerId: Scalars['String'];
};


export type QueryGetInstitutionCustomerBalancesArgs = {
  acceptedCurrencyId: Scalars['String'];
};


export type QueryGetDealFromRestApiByIdArgs = {
  dealId: Scalars['String'];
};


export type QueryGetHoaDealsForEntityAsAdminArgs = {
  entityId: Scalars['String'];
};


export type QueryGetDealsForEntityAsAdminArgs = {
  entityId: Scalars['String'];
};


export type QueryGetEntityAsAdminArgs = {
  entityId: Scalars['String'];
};


export type QueryGetFxoLiveMarketRateArgs = {
  input: FxoGetRateInput;
};


export type QueryGetFxoBankAccountInformationArgs = {
  entityId: Scalars['String'];
};


export type QueryGetFxoBankAccountArgs = {
  fxoAccountId: Scalars['String'];
};


export type QueryGetFxoBankAccountAsApiArgs = {
  fxoAccountId: Scalars['String'];
};


export type QueryGetBanksFromFxoArgs = {
  routingCode: Scalars['String'];
};


export type QueryGetEmailEventsArgs = {
  customerId: Scalars['String'];
};


export type QueryGetEmailTemplateArgs = {
  name: Scalars['String'];
  interpolateProperties: Array<InterpolateProperty>;
};

export type Recipient = {
  __typename?: 'Recipient';
  action: Scalars['String'];
  currency: Scalars['String'];
  amount: Scalars['String'];
  valueDate: Scalars['String'];
  payeeId: Scalars['String'];
  isEquivalent: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  regionCode: Scalars['String'];
  regionName: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
};

export type RelatedEntityResult = {
  __typename?: 'RelatedEntityResult';
  result: Scalars['Boolean'];
};

export type RoutingCodeDetail = {
  __typename?: 'RoutingCodeDetail';
  name: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  province: Scalars['String'];
  country: Scalars['String'];
  postCode: Scalars['String'];
  bankCode?: Maybe<Scalars['String']>;
  routingCode: Scalars['String'];
  routingType: AccountRoutingType;
  phone?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type SaveCreditCardInput = {
  cardCurrency: Scalars['String'];
  token: Scalars['String'];
  acceptRecurringPaymentTerms?: Maybe<Scalars['String']>;
  institutionId: Scalars['String'];
};

export type SavePaymentProcessorCardProfileInput = {
  transactionId: Scalars['String'];
  cardCurrency: Scalars['String'];
  token: Scalars['String'];
  acceptRecurringPaymentTerms?: Maybe<Scalars['String']>;
};

export type SavedExchangeRate = {
  __typename?: 'SavedExchangeRate';
  id: Scalars['ID'];
  timestamp: Scalars['String'];
  expires: Scalars['String'];
  payorPaysRate: ShiftMoney;
  payeeReceivesRate: ShiftMoney;
  validForInMinutes: Scalars['String'];
};

export type SendConfirmationEmailInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  institutionLogoUrl: Scalars['String'];
  transactionId: Scalars['String'];
  institutionId: Scalars['String'];
  institutionName: Scalars['String'];
  paymentMethod: PaymentMethod;
  totalAmount: ShiftMoneyInput;
  transactionDate: Scalars['String'];
  creditCardApprovalCode?: Maybe<Scalars['String']>;
  receiveAmount: ShiftMoneyInput;
  fees: Array<Maybe<FeeDescriptionInput>>;
  ownerReference: Scalars['String'];
};

export type SendEmailResult = {
  __typename?: 'SendEmailResult';
  successful: Scalars['Boolean'];
  emailProviderId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type SendEmailToCustomersInput = {
  customerIds: Array<Scalars['String']>;
  templateCode: Scalars['String'];
};

export type SendEmailToCustomersResult = {
  __typename?: 'SendEmailToCustomersResult';
  customerIds: Array<Scalars['String']>;
  templateCode: Scalars['String'];
};

export type SendInvoiceEmailInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  institutionLogoUrl: Scalars['String'];
  institutionId: Scalars['String'];
  institutionName: Scalars['String'];
  invoiceId: Scalars['String'];
  amount: ShiftMoneyInput;
  invoiceDate: Scalars['String'];
  invoiceDueDate: Scalars['String'];
  description: Scalars['String'];
  paymentUrl: Scalars['String'];
  emailType: EmailType;
};

export type Sender = {
  __typename?: 'Sender';
  action: Scalars['String'];
  currency: Scalars['String'];
  amount: Scalars['String'];
  valueDate: Scalars['String'];
  payeeId: Scalars['String'];
};

export type Settlement = {
  __typename?: 'Settlement';
  id: Scalars['String'];
  name: Scalars['String'];
  amount: Scalars['String'];
  currencyCode: Scalars['String'];
  date: Scalars['String'];
  status: TransactionStatusEvent;
  paymentMethod: PaymentMethod;
  institutionId: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  transitNumber?: Maybe<Scalars['String']>;
  routingCode?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  creditCardProcessorTransactionId?: Maybe<Scalars['String']>;
  emailTransferReceivedDate?: Maybe<Scalars['String']>;
  transactionExportStatus?: Maybe<TransactionExportStatus>;
};

export type SettlementFilterInput = {
  paymentMethod: PaymentMethod;
  currencyCode?: Maybe<Scalars['String']>;
};

export type ShiftMoney = {
  __typename?: 'ShiftMoney';
  currencyCode: Scalars['String'];
  amount: Scalars['String'];
};

export type ShiftMoneyInput = {
  currencyCode: Scalars['String'];
  amount: Scalars['String'];
};

export type State = {
  __typename?: 'State';
  stateCode: Scalars['String'];
  stateName: Scalars['String'];
};

export type Transaction = {
  __typename?: 'Transaction';
  transactionId: Scalars['String'];
};

export type TransactionAmounts = {
  __typename?: 'TransactionAmounts';
  savedExchangeRate: SavedExchangeRate;
  payorPaysAmount: ShiftMoney;
};

export type TransactionDetail = {
  __typename?: 'TransactionDetail';
  customerId: Scalars['Int'];
  dealId: Scalars['String'];
  createdDate: Scalars['String'];
  createdBy: Scalars['String'];
  updatedDate: Scalars['String'];
  updatedBy: Scalars['String'];
  creditCardProcessor?: Maybe<Scalars['String']>;
  creditCardProcessorApprovalCode?: Maybe<Scalars['String']>;
  creditCardProcessorTransactionId?: Maybe<Scalars['String']>;
  creditCardProcessorCreatedDate?: Maybe<Scalars['String']>;
  creditCardProcessorCreatedBy?: Maybe<Scalars['String']>;
};

export type TransactionDetailInternal = {
  __typename?: 'TransactionDetailInternal';
  transactionDetail: PfaTransactionDetail;
  statusInternal?: Maybe<TransactionStatusEventInternal>;
};

export type TransactionExport = {
  __typename?: 'TransactionExport';
  exportId: Scalars['ID'];
  exportDate: Scalars['String'];
};

export type TransactionExportStatus = {
  __typename?: 'TransactionExportStatus';
  transactionId: Scalars['String'];
  exported: Scalars['Boolean'];
  transactionExports: Array<TransactionExport>;
};

export type TransactionResult = {
  __typename?: 'TransactionResult';
  successful: Scalars['Boolean'];
  transactionId: Scalars['String'];
  transaction?: Maybe<PfaTransactionDetail>;
};

export enum TransactionStatus {
  Cancelled = 'CANCELLED',
  PaymentDeclined = 'PAYMENT_DECLINED',
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Unknown = 'UNKNOWN',
  Nsf = 'NSF',
  InvalidAccount = 'INVALID_ACCOUNT',
  Chargeback = 'CHARGEBACK'
}

export type TransactionStatusEvent = {
  __typename?: 'TransactionStatusEvent';
  createdDate: Scalars['String'];
  type: TransactionStatusEventType;
  customerComment?: Maybe<Scalars['String']>;
};

export type TransactionStatusEventInternal = {
  __typename?: 'TransactionStatusEventInternal';
  createdDate: Scalars['String'];
  type: TransactionStatusEventType;
  customerComment: Scalars['String'];
  internalComment: Scalars['String'];
};

export enum TransactionStatusEventType {
  Cancelled = 'CANCELLED',
  Nsf = 'NSF',
  InvalidAccount = 'INVALID_ACCOUNT',
  Chargeback = 'CHARGEBACK',
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type UpdateBankAccountsResult = {
  __typename?: 'UpdateBankAccountsResult';
  message: Scalars['String'];
};

export type UpdateBluesnapVendorInput = {
  createBluesnapVendorInput: CreateBluesnapVendorInput;
  paymentProcessorId: Scalars['String'];
};

export type UpdateEntityResult = {
  __typename?: 'UpdateEntityResult';
  customerId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type UpdateFxoBankAccountInput = {
  accountId: Scalars['String'];
  createAccountInput: CreateFxoBankAccountInput;
};

export type Upload = {
  __typename?: 'Upload';
  id: Scalars['ID'];
  institutionId: Scalars['String'];
  fileName: Scalars['String'];
  uploadType: Scalars['String'];
  status: Scalars['String'];
  createdBy: Scalars['String'];
  created: Scalars['String'];
};

export type UpsertVendorResult = {
  __typename?: 'UpsertVendorResult';
  createResult: Scalars['Boolean'];
  paymentProcessorId?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['String']>;
  errors: Array<Maybe<Scalars['String']>>;
};

export type UserLocation = {
  __typename?: 'UserLocation';
  countryCode: Scalars['String'];
  stateCode?: Maybe<Scalars['String']>;
};

export type VendorAgreementInput = {
  maxCommissionPercent: Scalars['String'];
};

export type VendorPrincipalInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  zip: Scalars['String'];
  dob: Scalars['String'];
  driverLicenseNumber?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type VerifySubmitInstitutionTransactionInput = {
  routingType: AccountRoutingType;
  sendAccountId?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  institutionId: Scalars['String'];
  paymentReference: Scalars['String'];
  acceptRecurringPaymentTerms?: Maybe<Scalars['String']>;
};

export type WebDealByIdTransaction = {
  __typename?: 'WebDealByIdTransaction';
  webDealId: Scalars['String'];
  sendCurrency: Scalars['String'];
  sendAmount: Scalars['Float'];
  receiveCurrency: Scalars['String'];
};

export type WireFxAch = {
  aba: Scalars['String'];
  account: Scalars['String'];
  accountType: WireFxAchType;
};

export enum WireFxAchType {
  Dda = 'dda',
  Savings = 'savings'
}

export type WireFxCreateDebitInput = {
  id: Scalars['String'];
  ledgerId: Scalars['String'];
  amount: Scalars['String'];
  currency: Scalars['String'];
  entityId: Scalars['String'];
  description: Scalars['String'];
  identifier: Scalars['String'];
};

export type WireFxCreateEntityInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: WireFxEntityType;
  cardUrl?: Maybe<Scalars['String']>;
  achInfo: WireFxAch;
};

export type WireFxCreditResponse = {
  __typename?: 'WireFxCreditResponse';
  id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<WireFxTransactionState>;
  estimatedAt?: Maybe<Scalars['String']>;
};

export type WireFxDebitResponse = {
  __typename?: 'WireFxDebitResponse';
  id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<WireFxTransactionState>;
  estimatedAt?: Maybe<Scalars['String']>;
};

export type WireFxEntityResponse = {
  __typename?: 'WireFxEntityResponse';
  entityId: Scalars['ID'];
  state: WireFxEntityState;
  url?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum WireFxEntityState {
  Ready = 'ready',
  Pending = 'pending',
  Rejected = 'rejected',
  Disabled = 'disabled'
}

export enum WireFxEntityType {
  Person = 'person',
  Company = 'company'
}

export type WireFxLedgerResponse = {
  __typename?: 'WireFxLedgerResponse';
  ledgerId: Scalars['String'];
  isDefault: Scalars['Boolean'];
  currency: Scalars['String'];
  balance: Scalars['String'];
  available: Scalars['String'];
  fbo?: Maybe<Scalars['String']>;
};

export type WireFxNotification = {
  __typename?: 'WireFxNotification';
  timestamp: Scalars['String'];
  entity?: Maybe<WireFxEntityResponse>;
  debit?: Maybe<WireFxDebitResponse>;
  credit?: Maybe<WireFxCreditResponse>;
  ledger?: Maybe<WireFxLedgerResponse>;
};

export enum WireFxTransactionState {
  Pending = 'pending',
  Rejected = 'rejected',
  Canceled = 'canceled',
  Sent = 'sent',
  Complete = 'complete',
  Reversed = 'reversed',
  Returned = 'returned'
}

export enum WireFxWebHookUrlErrorType {
  InvalidUrl = 'InvalidUrl',
  InvalidHeaders = 'InvalidHeaders'
}

export type WireFxWebHookUrlInput = {
  url: Scalars['String'];
  basicAuthorizationKey: Scalars['String'];
};

export type WireFxWebHookUrlResponse = {
  __typename?: 'WireFxWebHookUrlResponse';
  wireFxErrorCode?: Maybe<WireFxWebHookUrlErrorType>;
  message: Scalars['String'];
};

export type WirePaymentInput = {
  acceptedCurrencyId: Scalars['String'];
  description: Scalars['String'];
  reference: Scalars['String'];
  amount: Scalars['String'];
  paymentDate: Scalars['String'];
};

export type RoutingCodeDetailsFragmentFragment = (
  { __typename?: 'RoutingCodeDetail' }
  & Pick<RoutingCodeDetail, 'id' | 'name' | 'address1' | 'address2' | 'city' | 'province' | 'country' | 'postCode' | 'bankCode' | 'routingCode' | 'routingType' | 'phone'>
);

export type GetBanksForRoutingCodeQueryVariables = Exact<{
  routingCode: Scalars['String'];
}>;


export type GetBanksForRoutingCodeQuery = (
  { __typename?: 'Query' }
  & { banks?: Maybe<Array<(
    { __typename?: 'RoutingCodeDetail' }
    & RoutingCodeDetailsFragmentFragment
  )>> }
);

export type GetCustomerProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerProfileQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'CustomerProfile' }
    & Pick<CustomerProfile, 'countryCode' | 'mobile' | 'firstName' | 'lastName' | 'regionCode' | 'address' | 'postalCode' | 'city'>
  )> }
);

export type CreateCustomerProfileMutationVariables = Exact<{
  customerProfileInput: CustomerProfileInput;
}>;


export type CreateCustomerProfileMutation = (
  { __typename?: 'Mutation' }
  & { profile: (
    { __typename?: 'CustomerProfile' }
    & Pick<CustomerProfile, 'firstName' | 'lastName' | 'countryCode' | 'regionCode' | 'mobile' | 'address' | 'postalCode' | 'city'>
  ) }
);

export type UpdateCustomerProfileMutationVariables = Exact<{
  customerProfileInput: CustomerProfileInput;
}>;


export type UpdateCustomerProfileMutation = (
  { __typename?: 'Mutation' }
  & { profile: (
    { __typename?: 'CustomerProfile' }
    & Pick<CustomerProfile, 'firstName' | 'lastName' | 'countryCode' | 'regionCode' | 'mobile' | 'address' | 'postalCode' | 'city'>
  ) }
);

export type UpdateUserInstitutionMutationVariables = Exact<{
  userInstitutionInput: CustomerInstitutionInput;
}>;


export type UpdateUserInstitutionMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerInstitution: Array<Maybe<(
    { __typename?: 'CustomerInstitution' }
    & Pick<CustomerInstitution, 'id' | 'units'>
  )>> }
);

export type GetUserInstitutionsAndUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInstitutionsAndUnitsQuery = (
  { __typename?: 'Query' }
  & { userInstitutions?: Maybe<Array<Maybe<(
    { __typename?: 'CustomerInstitution' }
    & Pick<CustomerInstitution, 'units' | 'id' | 'name' | 'acceptRecurringPaymentTerms'>
  )>>> }
);

export type SaveNewInstitutionForCustomerMutationVariables = Exact<{
  institutionId: Scalars['String'];
}>;


export type SaveNewInstitutionForCustomerMutation = (
  { __typename?: 'Mutation' }
  & { saved: Mutation['saveNewInstitutionForCustomer'] }
);

export type CreateBankAccountMutationVariables = Exact<{
  createBankAccountInput: BankAccountInput;
}>;


export type CreateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { createBankAccount: (
    { __typename?: 'BankAccountDetail' }
    & Pick<BankAccountDetail, 'id' | 'currency' | 'status' | 'accountNumber' | 'routingType' | 'routingCode'>
  ) }
);

export type UpdateBankAccountMutationVariables = Exact<{
  updateBankAccountInput: BankAccountInput;
}>;


export type UpdateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateBankAccount: (
    { __typename?: 'BankAccountDetail' }
    & Pick<BankAccountDetail, 'id' | 'currency' | 'status' | 'accountNumber' | 'routingType' | 'routingCode'>
  ) }
);

export type GetBankAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBankAccountsQuery = (
  { __typename?: 'Query' }
  & { accounts: Array<Maybe<(
    { __typename?: 'BankAccountDetail' }
    & Pick<BankAccountDetail, 'id' | 'currency' | 'status' | 'accountNumber' | 'routingType' | 'routingCode' | 'bankCode' | 'bankName'>
  )>> }
);

export type AutoPayConfigDetailsFragmentFragment = (
  { __typename?: 'AutoPayConfiguration' }
  & Pick<AutoPayConfiguration, 'id' | 'createdDate'>
  & { institution: (
    { __typename?: 'Institution' }
    & Pick<Institution, 'institutionId' | 'name' | 'logoUrl' | 'defaultHomeCountry' | 'paymentPageTitle' | 'referenceFieldLabel' | 'recurringPaymentsEnabled' | 'creditCardEnabled'>
    & { acceptedCurrencies: Array<(
      { __typename?: 'AcceptedCurrency' }
      & Pick<AcceptedCurrency, 'currencyCode' | 'priority' | 'acceptedCurrencyId'>
    )> }
  ), creditCard?: Maybe<(
    { __typename?: 'CreditCard' }
    & Pick<CreditCard, 'creditCardId' | 'cardBrand' | 'expiryMonth' | 'expiryYear' | 'cardNumber' | 'cardCurrency'>
  )>, bankAccount?: Maybe<(
    { __typename?: 'BankAccountDetail' }
    & Pick<BankAccountDetail, 'id' | 'currency' | 'status' | 'accountNumber' | 'routingType' | 'routingCode' | 'bankCode' | 'bankName'>
  )> }
);

export type SaveAutoPayConfigurationMutationVariables = Exact<{
  autoPayConfigInput: AutoPayConfigurationInput;
}>;


export type SaveAutoPayConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { saveAutoPayConfiguration: (
    { __typename?: 'AutoPayConfiguration' }
    & AutoPayConfigDetailsFragmentFragment
  ) }
);

export type GetAutoPayConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAutoPayConfigurationsQuery = (
  { __typename?: 'Query' }
  & { getAutoPayConfigurations: Array<(
    { __typename?: 'AutoPayConfiguration' }
    & AutoPayConfigDetailsFragmentFragment
  )> }
);

export type ArchiveAutoPayConfigurationMutationVariables = Exact<{
  institutionId: Scalars['String'];
}>;


export type ArchiveAutoPayConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { archiveAutoPayConfiguration: (
    { __typename?: 'AutoPayConfiguration' }
    & AutoPayConfigDetailsFragmentFragment
  ) }
);

export type BookEmailTransferPaymentMutationVariables = Exact<{
  emailTransferPaymentInput: PaymentInput;
}>;


export type BookEmailTransferPaymentMutation = (
  { __typename?: 'Mutation' }
  & { bookEmailTransferPayment: (
    { __typename?: 'BookPaymentResult' }
    & Pick<BookPaymentResult, 'status'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'transactionId'>
    )> }
  ) }
);

export type BookDirectDebitPaymentMutationVariables = Exact<{
  directDebitPaymentInput: DirectDebitPaymentInput;
}>;


export type BookDirectDebitPaymentMutation = (
  { __typename?: 'Mutation' }
  & { bookDirectDebitPayment: (
    { __typename?: 'BookPaymentResult' }
    & Pick<BookPaymentResult, 'status'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'transactionId'>
    )> }
  ) }
);

export type BookCreditCardPaymentMutationVariables = Exact<{
  creditCardPaymentInput: CreditCardPaymentInput;
}>;


export type BookCreditCardPaymentMutation = (
  { __typename?: 'Mutation' }
  & { bookCreditCardPayment: (
    { __typename?: 'BookPaymentResult' }
    & Pick<BookPaymentResult, 'status'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'transactionId'>
    )> }
  ) }
);

export type BookEmailTransferInvoicePaymentMutationVariables = Exact<{
  emailTransferPaymentInput: PaymentInput;
  invoiceId: Scalars['String'];
}>;


export type BookEmailTransferInvoicePaymentMutation = (
  { __typename?: 'Mutation' }
  & { bookEmailTransferInvoicePayment: (
    { __typename?: 'BookPaymentResult' }
    & Pick<BookPaymentResult, 'status'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'transactionId'>
    )> }
  ) }
);

export type BookDirectDebitInvoicePaymentMutationVariables = Exact<{
  directDebitPaymentInput: DirectDebitPaymentInput;
  invoiceId: Scalars['String'];
}>;


export type BookDirectDebitInvoicePaymentMutation = (
  { __typename?: 'Mutation' }
  & { bookDirectDebitInvoicePayment: (
    { __typename?: 'BookPaymentResult' }
    & Pick<BookPaymentResult, 'status'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'transactionId'>
    )> }
  ) }
);

export type BookCreditCardInvoicePaymentMutationVariables = Exact<{
  creditCardPaymentInput: CreditCardPaymentInput;
  invoiceId: Scalars['String'];
}>;


export type BookCreditCardInvoicePaymentMutation = (
  { __typename?: 'Mutation' }
  & { bookCreditCardInvoicePayment: (
    { __typename?: 'BookPaymentResult' }
    & Pick<BookPaymentResult, 'status'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'transactionId'>
    )> }
  ) }
);

export type BookDirectDebitAccountBalancePaymentMutationVariables = Exact<{
  directDebitPaymentInput: DirectDebitPaymentInput;
  institutionCustomerBalanceId: Scalars['String'];
}>;


export type BookDirectDebitAccountBalancePaymentMutation = (
  { __typename?: 'Mutation' }
  & { bookDirectDebitAccountBalancePayment: (
    { __typename?: 'BookPaymentResult' }
    & Pick<BookPaymentResult, 'status'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'transactionId'>
    )> }
  ) }
);

export type BookCreditCardAccountBalancePaymentMutationVariables = Exact<{
  creditCardPaymentInput: CreditCardPaymentInput;
  institutionCustomerBalanceId: Scalars['String'];
}>;


export type BookCreditCardAccountBalancePaymentMutation = (
  { __typename?: 'Mutation' }
  & { bookCreditCardAccountBalancePayment: (
    { __typename?: 'BookPaymentResult' }
    & Pick<BookPaymentResult, 'status'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'transactionId'>
    )> }
  ) }
);

export type CalculateFeesQueryVariables = Exact<{
  input: CalculateFeeInput;
}>;


export type CalculateFeesQuery = (
  { __typename?: 'Query' }
  & { calculateFees: (
    { __typename?: 'CalculateFeeResult' }
    & Pick<CalculateFeeResult, 'totalAmount'>
    & { fees: Array<Maybe<(
      { __typename?: 'FeeToCharge' }
      & Pick<FeeToCharge, 'amount' | 'currencyCode' | 'type'>
    )>> }
  ) }
);

export type CalculateFeesForCreditCardQueryVariables = Exact<{
  input: CalculateFeeForCreditCardInput;
}>;


export type CalculateFeesForCreditCardQuery = (
  { __typename?: 'Query' }
  & { calculateFeesForCreditCard: (
    { __typename?: 'CalculateFeeResult' }
    & Pick<CalculateFeeResult, 'totalAmount'>
    & { fees: Array<Maybe<(
      { __typename?: 'FeeToCharge' }
      & Pick<FeeToCharge, 'amount' | 'currencyCode' | 'type'>
    )>> }
  ) }
);

export type DeleteCreditCardMutationVariables = Exact<{
  creditCardId: Scalars['String'];
}>;


export type DeleteCreditCardMutation = (
  { __typename?: 'Mutation' }
  & { deleteCreditCard: (
    { __typename?: 'DeleteCreditCardResult' }
    & Pick<DeleteCreditCardResult, 'cardDeleted'>
  ) }
);

export type SaveCloverPaymentProcessorCardProfileMutationVariables = Exact<{
  input: SaveCreditCardInput;
}>;


export type SaveCloverPaymentProcessorCardProfileMutation = (
  { __typename?: 'Mutation' }
  & { saveCloverPaymentProcessorCard: (
    { __typename?: 'CreditCard' }
    & Pick<CreditCard, 'cardBrand' | 'expiryMonth' | 'expiryYear' | 'cardNumber' | 'cardCurrency' | 'creditCardId'>
  ) }
);

export type SaveBlueSnapCreditCardMutationVariables = Exact<{
  input: SaveCreditCardInput;
}>;


export type SaveBlueSnapCreditCardMutation = (
  { __typename?: 'Mutation' }
  & { saveBlueSnapCreditCard: (
    { __typename?: 'CreditCard' }
    & Pick<CreditCard, 'cardBrand' | 'expiryMonth' | 'expiryYear' | 'cardNumber' | 'cardCurrency' | 'creditCardId'>
  ) }
);

export type GetBlueSnapCreditCardTokenQueryVariables = Exact<{
  input: PaymentProcessorResolverCriteria;
}>;


export type GetBlueSnapCreditCardTokenQuery = (
  { __typename?: 'Query' }
  & { getBlueSnapCreditCardToken: (
    { __typename?: 'BlueSnapToken' }
    & Pick<BlueSnapToken, 'token'>
  ) }
);

export type GetBlueSnapCardQueryVariables = Exact<{
  institutionId: Scalars['String'];
}>;


export type GetBlueSnapCardQuery = (
  { __typename?: 'Query' }
  & { blueSnapCard?: Maybe<(
    { __typename?: 'CreditCard' }
    & Pick<CreditCard, 'cardBrand' | 'expiryMonth' | 'expiryYear' | 'cardNumber' | 'cardCurrency' | 'creditCardId'>
  )> }
);

export type GetPaymentProcessorConnectionDetailsQueryVariables = Exact<{
  input: PaymentProcessorResolverCriteria;
}>;


export type GetPaymentProcessorConnectionDetailsQuery = (
  { __typename?: 'Query' }
  & { getPaymentProcessorConnectionDetails: (
    { __typename?: 'PaymentProcessorDetails' }
    & Pick<PaymentProcessorDetails, 'paymentProcessorCode' | 'processorApiKey'>
  ) }
);

export type CreateNewCustomerFromImportMutationVariables = Exact<{
  importedCustomerId: Scalars['String'];
  institutionId: Scalars['String'];
}>;


export type CreateNewCustomerFromImportMutation = (
  { __typename?: 'Mutation' }
  & { createNewCustomerFromImport: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'customerId'>
  ) }
);

export type HasCustomerBeenCreatedQueryVariables = Exact<{
  importedCustomerId: Scalars['String'];
}>;


export type HasCustomerBeenCreatedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'hasCustomerBeenCreated'>
);

export type GetCurrentBalancesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentBalancesQuery = (
  { __typename?: 'Query' }
  & { getCurrentBalances: Array<(
    { __typename?: 'InstitutionCustomerBalance' }
    & Pick<InstitutionCustomerBalance, 'institutionCustomerBalanceId' | 'institutionId' | 'institutionName' | 'customerId' | 'externalId' | 'description' | 'pullDate' | 'createdDate' | 'createdBy' | 'updatedDate' | 'updatedBy'>
    & { amount: (
      { __typename?: 'ShiftMoney' }
      & Pick<ShiftMoney, 'currencyCode' | 'amount'>
    ), transactions: Array<(
      { __typename?: 'PfaTransactionDetail' }
      & Pick<PfaTransactionDetail, 'id' | 'createdDate'>
      & { payeeReceives: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'currencyCode' | 'amount'>
      ), status: (
        { __typename?: 'TransactionStatusEvent' }
        & Pick<TransactionStatusEvent, 'createdDate' | 'type' | 'customerComment'>
      ) }
    )> }
  )> }
);

export type GetInstitutionsByIdQueryVariables = Exact<{
  institutionIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type GetInstitutionsByIdQuery = (
  { __typename?: 'Query' }
  & { institutions: Array<Maybe<(
    { __typename?: 'Institution' }
    & Pick<Institution, 'institutionId' | 'name' | 'logoUrl' | 'defaultHomeCountry' | 'paymentPageTitle' | 'referenceFieldLabel' | 'recurringPaymentsEnabled' | 'creditCardEnabled'>
    & { acceptedCurrencies: Array<(
      { __typename?: 'AcceptedCurrency' }
      & Pick<AcceptedCurrency, 'currencyCode' | 'priority' | 'acceptedCurrencyId'>
    )> }
  )>> }
);

export type GetInstitutionByIdQueryVariables = Exact<{
  institutionId: Scalars['String'];
}>;


export type GetInstitutionByIdQuery = (
  { __typename?: 'Query' }
  & { institution: (
    { __typename?: 'Institution' }
    & Pick<Institution, 'institutionId' | 'name' | 'logoUrl' | 'defaultHomeCountry' | 'paymentPageTitle' | 'referenceFieldLabel' | 'recurringPaymentsEnabled' | 'creditCardEnabled'>
    & { acceptedCurrencies: Array<(
      { __typename?: 'AcceptedCurrency' }
      & Pick<AcceptedCurrency, 'currencyCode' | 'priority' | 'acceptedCurrencyId'>
    )> }
  ) }
);

export type GetRegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegionsQuery = (
  { __typename?: 'Query' }
  & { regions: Array<(
    { __typename?: 'Region' }
    & Pick<Region, 'regionName' | 'regionCode' | 'countryCode'>
  )> }
);

export type GetTransactionAmountsQueryVariables = Exact<{
  input: GetRateInput;
}>;


export type GetTransactionAmountsQuery = (
  { __typename?: 'Query' }
  & { getTransactionAmounts: (
    { __typename?: 'TransactionAmounts' }
    & { savedExchangeRate: (
      { __typename?: 'SavedExchangeRate' }
      & Pick<SavedExchangeRate, 'id' | 'timestamp' | 'expires' | 'validForInMinutes'>
      & { payorPaysRate: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'currencyCode' | 'amount'>
      ), payeeReceivesRate: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'currencyCode' | 'amount'>
      ) }
    ), payorPaysAmount: (
      { __typename?: 'ShiftMoney' }
      & Pick<ShiftMoney, 'currencyCode' | 'amount'>
    ) }
  ) }
);

export type GetUsStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsStatesQuery = (
  { __typename?: 'Query' }
  & { usStates: Array<(
    { __typename?: 'State' }
    & Pick<State, 'stateName' | 'stateCode'>
  )> }
);

export type GetUserLocationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserLocationQuery = (
  { __typename?: 'Query' }
  & { userLocation: (
    { __typename?: 'UserLocation' }
    & Pick<UserLocation, 'countryCode' | 'stateCode'>
  ) }
);

export type GetInvoiceQueryVariables = Exact<{
  invoiceUuid: Scalars['String'];
}>;


export type GetInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'invoiceId' | 'customerId' | 'institutionId' | 'amount' | 'description' | 'currencyCode' | 'institutionInvoiceId' | 'invoiceDate' | 'dueDate'>
  ) }
);

export type GetInvoiceWithBalanceQueryVariables = Exact<{
  invoiceUuid: Scalars['String'];
}>;


export type GetInvoiceWithBalanceQuery = (
  { __typename?: 'Query' }
  & { invoiceWithBalance: (
    { __typename?: 'InvoiceWithBalance' }
    & { balance: (
      { __typename?: 'ShiftMoney' }
      & Pick<ShiftMoney, 'amount' | 'currencyCode'>
    ), invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'invoiceId' | 'customerId' | 'institutionId' | 'amount' | 'description' | 'currencyCode' | 'institutionInvoiceId' | 'invoiceDate' | 'dueDate'>
    ) }
  ) }
);

export type GetCustomerInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerInvoicesQuery = (
  { __typename?: 'Query' }
  & { invoicegroups: Array<(
    { __typename?: 'CustomerInstitutionInvoice' }
    & { balance: (
      { __typename?: 'ShiftMoney' }
      & Pick<ShiftMoney, 'amount' | 'currencyCode'>
    ), invoices: Array<(
      { __typename?: 'InvoiceWithBalance' }
      & Pick<InvoiceWithBalance, 'transactionIds'>
      & { balance: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'amount' | 'currencyCode'>
      ), invoice: (
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'invoiceId' | 'customerId' | 'institutionId' | 'amount' | 'description' | 'currencyCode' | 'institutionInvoiceId' | 'invoiceDate' | 'dueDate' | 'invoiceUuid'>
      ) }
    )> }
  )> }
);

export type GetTransactionDetailQueryVariables = Exact<{
  transactionId: Scalars['String'];
}>;


export type GetTransactionDetailQuery = (
  { __typename?: 'Query' }
  & { transactionDetails: (
    { __typename?: 'TransactionResult' }
    & Pick<TransactionResult, 'successful' | 'transactionId'>
    & { transaction?: Maybe<(
      { __typename?: 'PfaTransactionDetail' }
      & Pick<PfaTransactionDetail, 'customerId' | 'id' | 'createdDate' | 'createdBy' | 'creditCardProcessorApprovalCode' | 'institutionId' | 'payeeName' | 'paymentMethod' | 'institutionReferenceValue' | 'rejectedPaymentReference' | 'receiveEmail'>
      & { payorPays: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'amount' | 'currencyCode'>
      ), payorPaysTotal: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'amount' | 'currencyCode'>
      ), payeeReceives: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'amount' | 'currencyCode'>
      ), status: (
        { __typename?: 'TransactionStatusEvent' }
        & Pick<TransactionStatusEvent, 'createdDate' | 'type' | 'customerComment'>
      ), fees?: Maybe<Array<Maybe<(
        { __typename?: 'FeeToCharge' }
        & Pick<FeeToCharge, 'amount' | 'currencyCode' | 'type'>
      )>>> }
    )> }
  ) }
);

export type GetTransactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTransactionsQuery = (
  { __typename?: 'Query' }
  & { transactionResults: Array<Maybe<(
    { __typename?: 'TransactionResult' }
    & Pick<TransactionResult, 'successful' | 'transactionId'>
    & { transaction?: Maybe<(
      { __typename?: 'PfaTransactionDetail' }
      & Pick<PfaTransactionDetail, 'customerId' | 'id' | 'createdDate' | 'createdBy' | 'creditCardProcessorApprovalCode' | 'payeeName' | 'paymentMethod' | 'institutionReferenceValue' | 'rejectedPaymentReference' | 'receiveEmail'>
      & { payorPays: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'currencyCode' | 'amount'>
      ), fees?: Maybe<Array<Maybe<(
        { __typename?: 'FeeToCharge' }
        & Pick<FeeToCharge, 'amount' | 'currencyCode' | 'type'>
      )>>>, payorPaysTotal: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'currencyCode' | 'amount'>
      ), payeeReceives: (
        { __typename?: 'ShiftMoney' }
        & Pick<ShiftMoney, 'currencyCode' | 'amount'>
      ), status: (
        { __typename?: 'TransactionStatusEvent' }
        & Pick<TransactionStatusEvent, 'createdDate' | 'type' | 'customerComment'>
      ), invoices: Array<Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'invoiceId' | 'customerId' | 'institutionId' | 'amount' | 'description' | 'currencyCode' | 'institutionInvoiceId' | 'invoiceDate' | 'dueDate'>
      )>> }
    )> }
  )>> }
);

export const RoutingCodeDetailsFragmentFragmentDoc = gql`
    fragment RoutingCodeDetailsFragment on RoutingCodeDetail {
  id
  name
  address1
  address2
  city
  province
  country
  postCode
  bankCode
  routingCode
  routingType
  phone
}
    `;
export const AutoPayConfigDetailsFragmentFragmentDoc = gql`
    fragment AutoPayConfigDetailsFragment on AutoPayConfiguration {
  id
  institution {
    institutionId
    name
    logoUrl
    acceptedCurrencies {
      currencyCode
      priority
      acceptedCurrencyId
    }
    defaultHomeCountry
    paymentPageTitle
    referenceFieldLabel
    recurringPaymentsEnabled
    creditCardEnabled
  }
  creditCard {
    creditCardId
    cardBrand
    expiryMonth
    expiryYear
    cardNumber
    cardCurrency
  }
  bankAccount {
    id
    currency
    status
    accountNumber
    routingType
    routingCode
    bankCode
    bankName
  }
  createdDate
}
    `;
export const GetBanksForRoutingCodeDocument = gql`
    query getBanksForRoutingCode($routingCode: String!) {
  banks: getBanksForRoutingCode(routingCode: $routingCode) {
    ...RoutingCodeDetailsFragment
  }
}
    ${RoutingCodeDetailsFragmentFragmentDoc}`;

/**
 * __useGetBanksForRoutingCodeQuery__
 *
 * To run a query within a React component, call `useGetBanksForRoutingCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBanksForRoutingCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBanksForRoutingCodeQuery({
 *   variables: {
 *      routingCode: // value for 'routingCode'
 *   },
 * });
 */
export function useGetBanksForRoutingCodeQuery(baseOptions: Apollo.QueryHookOptions<GetBanksForRoutingCodeQuery, GetBanksForRoutingCodeQueryVariables>) {
        return Apollo.useQuery<GetBanksForRoutingCodeQuery, GetBanksForRoutingCodeQueryVariables>(GetBanksForRoutingCodeDocument, baseOptions);
      }
export function useGetBanksForRoutingCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBanksForRoutingCodeQuery, GetBanksForRoutingCodeQueryVariables>) {
          return Apollo.useLazyQuery<GetBanksForRoutingCodeQuery, GetBanksForRoutingCodeQueryVariables>(GetBanksForRoutingCodeDocument, baseOptions);
        }
export type GetBanksForRoutingCodeQueryHookResult = ReturnType<typeof useGetBanksForRoutingCodeQuery>;
export type GetBanksForRoutingCodeLazyQueryHookResult = ReturnType<typeof useGetBanksForRoutingCodeLazyQuery>;
export type GetBanksForRoutingCodeQueryResult = Apollo.QueryResult<GetBanksForRoutingCodeQuery, GetBanksForRoutingCodeQueryVariables>;
export const GetCustomerProfileDocument = gql`
    query getCustomerProfile {
  customer: getCustomerProfile {
    countryCode
    mobile
    firstName
    lastName
    regionCode
    address
    postalCode
    city
  }
}
    `;

/**
 * __useGetCustomerProfileQuery__
 *
 * To run a query within a React component, call `useGetCustomerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>) {
        return Apollo.useQuery<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>(GetCustomerProfileDocument, baseOptions);
      }
export function useGetCustomerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>(GetCustomerProfileDocument, baseOptions);
        }
export type GetCustomerProfileQueryHookResult = ReturnType<typeof useGetCustomerProfileQuery>;
export type GetCustomerProfileLazyQueryHookResult = ReturnType<typeof useGetCustomerProfileLazyQuery>;
export type GetCustomerProfileQueryResult = Apollo.QueryResult<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>;
export const CreateCustomerProfileDocument = gql`
    mutation createCustomerProfile($customerProfileInput: CustomerProfileInput!) {
  profile: createCustomerProfile(customerProfileInput: $customerProfileInput) {
    firstName
    lastName
    countryCode
    regionCode
    mobile
    address
    postalCode
    city
  }
}
    `;
export type CreateCustomerProfileMutationFn = Apollo.MutationFunction<CreateCustomerProfileMutation, CreateCustomerProfileMutationVariables>;

/**
 * __useCreateCustomerProfileMutation__
 *
 * To run a mutation, you first call `useCreateCustomerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerProfileMutation, { data, loading, error }] = useCreateCustomerProfileMutation({
 *   variables: {
 *      customerProfileInput: // value for 'customerProfileInput'
 *   },
 * });
 */
export function useCreateCustomerProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerProfileMutation, CreateCustomerProfileMutationVariables>) {
        return Apollo.useMutation<CreateCustomerProfileMutation, CreateCustomerProfileMutationVariables>(CreateCustomerProfileDocument, baseOptions);
      }
export type CreateCustomerProfileMutationHookResult = ReturnType<typeof useCreateCustomerProfileMutation>;
export type CreateCustomerProfileMutationResult = Apollo.MutationResult<CreateCustomerProfileMutation>;
export type CreateCustomerProfileMutationOptions = Apollo.BaseMutationOptions<CreateCustomerProfileMutation, CreateCustomerProfileMutationVariables>;
export const UpdateCustomerProfileDocument = gql`
    mutation updateCustomerProfile($customerProfileInput: CustomerProfileInput!) {
  profile: updateCustomerProfile(customerProfileInput: $customerProfileInput) {
    firstName
    lastName
    countryCode
    regionCode
    mobile
    address
    postalCode
    city
  }
}
    `;
export type UpdateCustomerProfileMutationFn = Apollo.MutationFunction<UpdateCustomerProfileMutation, UpdateCustomerProfileMutationVariables>;

/**
 * __useUpdateCustomerProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerProfileMutation, { data, loading, error }] = useUpdateCustomerProfileMutation({
 *   variables: {
 *      customerProfileInput: // value for 'customerProfileInput'
 *   },
 * });
 */
export function useUpdateCustomerProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerProfileMutation, UpdateCustomerProfileMutationVariables>) {
        return Apollo.useMutation<UpdateCustomerProfileMutation, UpdateCustomerProfileMutationVariables>(UpdateCustomerProfileDocument, baseOptions);
      }
export type UpdateCustomerProfileMutationHookResult = ReturnType<typeof useUpdateCustomerProfileMutation>;
export type UpdateCustomerProfileMutationResult = Apollo.MutationResult<UpdateCustomerProfileMutation>;
export type UpdateCustomerProfileMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerProfileMutation, UpdateCustomerProfileMutationVariables>;
export const UpdateUserInstitutionDocument = gql`
    mutation updateUserInstitution($userInstitutionInput: CustomerInstitutionInput!) {
  updateCustomerInstitution(customerInstitutionInput: $userInstitutionInput) {
    id
    units
  }
}
    `;
export type UpdateUserInstitutionMutationFn = Apollo.MutationFunction<UpdateUserInstitutionMutation, UpdateUserInstitutionMutationVariables>;

/**
 * __useUpdateUserInstitutionMutation__
 *
 * To run a mutation, you first call `useUpdateUserInstitutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInstitutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInstitutionMutation, { data, loading, error }] = useUpdateUserInstitutionMutation({
 *   variables: {
 *      userInstitutionInput: // value for 'userInstitutionInput'
 *   },
 * });
 */
export function useUpdateUserInstitutionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInstitutionMutation, UpdateUserInstitutionMutationVariables>) {
        return Apollo.useMutation<UpdateUserInstitutionMutation, UpdateUserInstitutionMutationVariables>(UpdateUserInstitutionDocument, baseOptions);
      }
export type UpdateUserInstitutionMutationHookResult = ReturnType<typeof useUpdateUserInstitutionMutation>;
export type UpdateUserInstitutionMutationResult = Apollo.MutationResult<UpdateUserInstitutionMutation>;
export type UpdateUserInstitutionMutationOptions = Apollo.BaseMutationOptions<UpdateUserInstitutionMutation, UpdateUserInstitutionMutationVariables>;
export const GetUserInstitutionsAndUnitsDocument = gql`
    query getUserInstitutionsAndUnits {
  userInstitutions: getCustomerInstitutions {
    units
    id
    name
    acceptRecurringPaymentTerms
  }
}
    `;

/**
 * __useGetUserInstitutionsAndUnitsQuery__
 *
 * To run a query within a React component, call `useGetUserInstitutionsAndUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInstitutionsAndUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInstitutionsAndUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInstitutionsAndUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInstitutionsAndUnitsQuery, GetUserInstitutionsAndUnitsQueryVariables>) {
        return Apollo.useQuery<GetUserInstitutionsAndUnitsQuery, GetUserInstitutionsAndUnitsQueryVariables>(GetUserInstitutionsAndUnitsDocument, baseOptions);
      }
export function useGetUserInstitutionsAndUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInstitutionsAndUnitsQuery, GetUserInstitutionsAndUnitsQueryVariables>) {
          return Apollo.useLazyQuery<GetUserInstitutionsAndUnitsQuery, GetUserInstitutionsAndUnitsQueryVariables>(GetUserInstitutionsAndUnitsDocument, baseOptions);
        }
export type GetUserInstitutionsAndUnitsQueryHookResult = ReturnType<typeof useGetUserInstitutionsAndUnitsQuery>;
export type GetUserInstitutionsAndUnitsLazyQueryHookResult = ReturnType<typeof useGetUserInstitutionsAndUnitsLazyQuery>;
export type GetUserInstitutionsAndUnitsQueryResult = Apollo.QueryResult<GetUserInstitutionsAndUnitsQuery, GetUserInstitutionsAndUnitsQueryVariables>;
export const SaveNewInstitutionForCustomerDocument = gql`
    mutation saveNewInstitutionForCustomer($institutionId: String!) {
  saved: saveNewInstitutionForCustomer(institutionId: $institutionId)
}
    `;
export type SaveNewInstitutionForCustomerMutationFn = Apollo.MutationFunction<SaveNewInstitutionForCustomerMutation, SaveNewInstitutionForCustomerMutationVariables>;

/**
 * __useSaveNewInstitutionForCustomerMutation__
 *
 * To run a mutation, you first call `useSaveNewInstitutionForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNewInstitutionForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNewInstitutionForCustomerMutation, { data, loading, error }] = useSaveNewInstitutionForCustomerMutation({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useSaveNewInstitutionForCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SaveNewInstitutionForCustomerMutation, SaveNewInstitutionForCustomerMutationVariables>) {
        return Apollo.useMutation<SaveNewInstitutionForCustomerMutation, SaveNewInstitutionForCustomerMutationVariables>(SaveNewInstitutionForCustomerDocument, baseOptions);
      }
export type SaveNewInstitutionForCustomerMutationHookResult = ReturnType<typeof useSaveNewInstitutionForCustomerMutation>;
export type SaveNewInstitutionForCustomerMutationResult = Apollo.MutationResult<SaveNewInstitutionForCustomerMutation>;
export type SaveNewInstitutionForCustomerMutationOptions = Apollo.BaseMutationOptions<SaveNewInstitutionForCustomerMutation, SaveNewInstitutionForCustomerMutationVariables>;
export const CreateBankAccountDocument = gql`
    mutation createBankAccount($createBankAccountInput: BankAccountInput!) {
  createBankAccount(createBankAccountInput: $createBankAccountInput) {
    id
    currency
    status
    accountNumber
    routingType
    routingCode
  }
}
    `;
export type CreateBankAccountMutationFn = Apollo.MutationFunction<CreateBankAccountMutation, CreateBankAccountMutationVariables>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      createBankAccountInput: // value for 'createBankAccountInput'
 *   },
 * });
 */
export function useCreateBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>) {
        return Apollo.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument, baseOptions);
      }
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = Apollo.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = Apollo.BaseMutationOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>;
export const UpdateBankAccountDocument = gql`
    mutation updateBankAccount($updateBankAccountInput: BankAccountInput!) {
  updateBankAccount(updateBankAccountInput: $updateBankAccountInput) {
    id
    currency
    status
    accountNumber
    routingType
    routingCode
  }
}
    `;
export type UpdateBankAccountMutationFn = Apollo.MutationFunction<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;

/**
 * __useUpdateBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankAccountMutation, { data, loading, error }] = useUpdateBankAccountMutation({
 *   variables: {
 *      updateBankAccountInput: // value for 'updateBankAccountInput'
 *   },
 * });
 */
export function useUpdateBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>) {
        return Apollo.useMutation<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>(UpdateBankAccountDocument, baseOptions);
      }
export type UpdateBankAccountMutationHookResult = ReturnType<typeof useUpdateBankAccountMutation>;
export type UpdateBankAccountMutationResult = Apollo.MutationResult<UpdateBankAccountMutation>;
export type UpdateBankAccountMutationOptions = Apollo.BaseMutationOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;
export const GetBankAccountsDocument = gql`
    query getBankAccounts {
  accounts: getBankAccounts {
    id
    currency
    status
    accountNumber
    routingType
    routingCode
    bankCode
    bankName
  }
}
    `;

/**
 * __useGetBankAccountsQuery__
 *
 * To run a query within a React component, call `useGetBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBankAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetBankAccountsQuery, GetBankAccountsQueryVariables>) {
        return Apollo.useQuery<GetBankAccountsQuery, GetBankAccountsQueryVariables>(GetBankAccountsDocument, baseOptions);
      }
export function useGetBankAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankAccountsQuery, GetBankAccountsQueryVariables>) {
          return Apollo.useLazyQuery<GetBankAccountsQuery, GetBankAccountsQueryVariables>(GetBankAccountsDocument, baseOptions);
        }
export type GetBankAccountsQueryHookResult = ReturnType<typeof useGetBankAccountsQuery>;
export type GetBankAccountsLazyQueryHookResult = ReturnType<typeof useGetBankAccountsLazyQuery>;
export type GetBankAccountsQueryResult = Apollo.QueryResult<GetBankAccountsQuery, GetBankAccountsQueryVariables>;
export const SaveAutoPayConfigurationDocument = gql`
    mutation saveAutoPayConfiguration($autoPayConfigInput: AutoPayConfigurationInput!) {
  saveAutoPayConfiguration(autoPayConfigInput: $autoPayConfigInput) {
    ...AutoPayConfigDetailsFragment
  }
}
    ${AutoPayConfigDetailsFragmentFragmentDoc}`;
export type SaveAutoPayConfigurationMutationFn = Apollo.MutationFunction<SaveAutoPayConfigurationMutation, SaveAutoPayConfigurationMutationVariables>;

/**
 * __useSaveAutoPayConfigurationMutation__
 *
 * To run a mutation, you first call `useSaveAutoPayConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAutoPayConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAutoPayConfigurationMutation, { data, loading, error }] = useSaveAutoPayConfigurationMutation({
 *   variables: {
 *      autoPayConfigInput: // value for 'autoPayConfigInput'
 *   },
 * });
 */
export function useSaveAutoPayConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<SaveAutoPayConfigurationMutation, SaveAutoPayConfigurationMutationVariables>) {
        return Apollo.useMutation<SaveAutoPayConfigurationMutation, SaveAutoPayConfigurationMutationVariables>(SaveAutoPayConfigurationDocument, baseOptions);
      }
export type SaveAutoPayConfigurationMutationHookResult = ReturnType<typeof useSaveAutoPayConfigurationMutation>;
export type SaveAutoPayConfigurationMutationResult = Apollo.MutationResult<SaveAutoPayConfigurationMutation>;
export type SaveAutoPayConfigurationMutationOptions = Apollo.BaseMutationOptions<SaveAutoPayConfigurationMutation, SaveAutoPayConfigurationMutationVariables>;
export const GetAutoPayConfigurationsDocument = gql`
    query getAutoPayConfigurations {
  getAutoPayConfigurations {
    ...AutoPayConfigDetailsFragment
  }
}
    ${AutoPayConfigDetailsFragmentFragmentDoc}`;

/**
 * __useGetAutoPayConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetAutoPayConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoPayConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoPayConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAutoPayConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAutoPayConfigurationsQuery, GetAutoPayConfigurationsQueryVariables>) {
        return Apollo.useQuery<GetAutoPayConfigurationsQuery, GetAutoPayConfigurationsQueryVariables>(GetAutoPayConfigurationsDocument, baseOptions);
      }
export function useGetAutoPayConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoPayConfigurationsQuery, GetAutoPayConfigurationsQueryVariables>) {
          return Apollo.useLazyQuery<GetAutoPayConfigurationsQuery, GetAutoPayConfigurationsQueryVariables>(GetAutoPayConfigurationsDocument, baseOptions);
        }
export type GetAutoPayConfigurationsQueryHookResult = ReturnType<typeof useGetAutoPayConfigurationsQuery>;
export type GetAutoPayConfigurationsLazyQueryHookResult = ReturnType<typeof useGetAutoPayConfigurationsLazyQuery>;
export type GetAutoPayConfigurationsQueryResult = Apollo.QueryResult<GetAutoPayConfigurationsQuery, GetAutoPayConfigurationsQueryVariables>;
export const ArchiveAutoPayConfigurationDocument = gql`
    mutation ArchiveAutoPayConfiguration($institutionId: String!) {
  archiveAutoPayConfiguration(institutionId: $institutionId) {
    ...AutoPayConfigDetailsFragment
  }
}
    ${AutoPayConfigDetailsFragmentFragmentDoc}`;
export type ArchiveAutoPayConfigurationMutationFn = Apollo.MutationFunction<ArchiveAutoPayConfigurationMutation, ArchiveAutoPayConfigurationMutationVariables>;

/**
 * __useArchiveAutoPayConfigurationMutation__
 *
 * To run a mutation, you first call `useArchiveAutoPayConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAutoPayConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAutoPayConfigurationMutation, { data, loading, error }] = useArchiveAutoPayConfigurationMutation({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useArchiveAutoPayConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveAutoPayConfigurationMutation, ArchiveAutoPayConfigurationMutationVariables>) {
        return Apollo.useMutation<ArchiveAutoPayConfigurationMutation, ArchiveAutoPayConfigurationMutationVariables>(ArchiveAutoPayConfigurationDocument, baseOptions);
      }
export type ArchiveAutoPayConfigurationMutationHookResult = ReturnType<typeof useArchiveAutoPayConfigurationMutation>;
export type ArchiveAutoPayConfigurationMutationResult = Apollo.MutationResult<ArchiveAutoPayConfigurationMutation>;
export type ArchiveAutoPayConfigurationMutationOptions = Apollo.BaseMutationOptions<ArchiveAutoPayConfigurationMutation, ArchiveAutoPayConfigurationMutationVariables>;
export const BookEmailTransferPaymentDocument = gql`
    mutation bookEmailTransferPayment($emailTransferPaymentInput: PaymentInput!) {
  bookEmailTransferPayment(emailTransferPaymentInput: $emailTransferPaymentInput) {
    transaction {
      transactionId
    }
    status
  }
}
    `;
export type BookEmailTransferPaymentMutationFn = Apollo.MutationFunction<BookEmailTransferPaymentMutation, BookEmailTransferPaymentMutationVariables>;

/**
 * __useBookEmailTransferPaymentMutation__
 *
 * To run a mutation, you first call `useBookEmailTransferPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookEmailTransferPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookEmailTransferPaymentMutation, { data, loading, error }] = useBookEmailTransferPaymentMutation({
 *   variables: {
 *      emailTransferPaymentInput: // value for 'emailTransferPaymentInput'
 *   },
 * });
 */
export function useBookEmailTransferPaymentMutation(baseOptions?: Apollo.MutationHookOptions<BookEmailTransferPaymentMutation, BookEmailTransferPaymentMutationVariables>) {
        return Apollo.useMutation<BookEmailTransferPaymentMutation, BookEmailTransferPaymentMutationVariables>(BookEmailTransferPaymentDocument, baseOptions);
      }
export type BookEmailTransferPaymentMutationHookResult = ReturnType<typeof useBookEmailTransferPaymentMutation>;
export type BookEmailTransferPaymentMutationResult = Apollo.MutationResult<BookEmailTransferPaymentMutation>;
export type BookEmailTransferPaymentMutationOptions = Apollo.BaseMutationOptions<BookEmailTransferPaymentMutation, BookEmailTransferPaymentMutationVariables>;
export const BookDirectDebitPaymentDocument = gql`
    mutation bookDirectDebitPayment($directDebitPaymentInput: DirectDebitPaymentInput!) {
  bookDirectDebitPayment(directDebitPaymentInput: $directDebitPaymentInput) {
    transaction {
      transactionId
    }
    status
  }
}
    `;
export type BookDirectDebitPaymentMutationFn = Apollo.MutationFunction<BookDirectDebitPaymentMutation, BookDirectDebitPaymentMutationVariables>;

/**
 * __useBookDirectDebitPaymentMutation__
 *
 * To run a mutation, you first call `useBookDirectDebitPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookDirectDebitPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookDirectDebitPaymentMutation, { data, loading, error }] = useBookDirectDebitPaymentMutation({
 *   variables: {
 *      directDebitPaymentInput: // value for 'directDebitPaymentInput'
 *   },
 * });
 */
export function useBookDirectDebitPaymentMutation(baseOptions?: Apollo.MutationHookOptions<BookDirectDebitPaymentMutation, BookDirectDebitPaymentMutationVariables>) {
        return Apollo.useMutation<BookDirectDebitPaymentMutation, BookDirectDebitPaymentMutationVariables>(BookDirectDebitPaymentDocument, baseOptions);
      }
export type BookDirectDebitPaymentMutationHookResult = ReturnType<typeof useBookDirectDebitPaymentMutation>;
export type BookDirectDebitPaymentMutationResult = Apollo.MutationResult<BookDirectDebitPaymentMutation>;
export type BookDirectDebitPaymentMutationOptions = Apollo.BaseMutationOptions<BookDirectDebitPaymentMutation, BookDirectDebitPaymentMutationVariables>;
export const BookCreditCardPaymentDocument = gql`
    mutation bookCreditCardPayment($creditCardPaymentInput: CreditCardPaymentInput!) {
  bookCreditCardPayment(creditCardPaymentInput: $creditCardPaymentInput) {
    transaction {
      transactionId
    }
    status
  }
}
    `;
export type BookCreditCardPaymentMutationFn = Apollo.MutationFunction<BookCreditCardPaymentMutation, BookCreditCardPaymentMutationVariables>;

/**
 * __useBookCreditCardPaymentMutation__
 *
 * To run a mutation, you first call `useBookCreditCardPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookCreditCardPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookCreditCardPaymentMutation, { data, loading, error }] = useBookCreditCardPaymentMutation({
 *   variables: {
 *      creditCardPaymentInput: // value for 'creditCardPaymentInput'
 *   },
 * });
 */
export function useBookCreditCardPaymentMutation(baseOptions?: Apollo.MutationHookOptions<BookCreditCardPaymentMutation, BookCreditCardPaymentMutationVariables>) {
        return Apollo.useMutation<BookCreditCardPaymentMutation, BookCreditCardPaymentMutationVariables>(BookCreditCardPaymentDocument, baseOptions);
      }
export type BookCreditCardPaymentMutationHookResult = ReturnType<typeof useBookCreditCardPaymentMutation>;
export type BookCreditCardPaymentMutationResult = Apollo.MutationResult<BookCreditCardPaymentMutation>;
export type BookCreditCardPaymentMutationOptions = Apollo.BaseMutationOptions<BookCreditCardPaymentMutation, BookCreditCardPaymentMutationVariables>;
export const BookEmailTransferInvoicePaymentDocument = gql`
    mutation bookEmailTransferInvoicePayment($emailTransferPaymentInput: PaymentInput!, $invoiceId: String!) {
  bookEmailTransferInvoicePayment(
    emailTransferPaymentInput: $emailTransferPaymentInput
    invoiceId: $invoiceId
  ) {
    transaction {
      transactionId
    }
    status
  }
}
    `;
export type BookEmailTransferInvoicePaymentMutationFn = Apollo.MutationFunction<BookEmailTransferInvoicePaymentMutation, BookEmailTransferInvoicePaymentMutationVariables>;

/**
 * __useBookEmailTransferInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useBookEmailTransferInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookEmailTransferInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookEmailTransferInvoicePaymentMutation, { data, loading, error }] = useBookEmailTransferInvoicePaymentMutation({
 *   variables: {
 *      emailTransferPaymentInput: // value for 'emailTransferPaymentInput'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useBookEmailTransferInvoicePaymentMutation(baseOptions?: Apollo.MutationHookOptions<BookEmailTransferInvoicePaymentMutation, BookEmailTransferInvoicePaymentMutationVariables>) {
        return Apollo.useMutation<BookEmailTransferInvoicePaymentMutation, BookEmailTransferInvoicePaymentMutationVariables>(BookEmailTransferInvoicePaymentDocument, baseOptions);
      }
export type BookEmailTransferInvoicePaymentMutationHookResult = ReturnType<typeof useBookEmailTransferInvoicePaymentMutation>;
export type BookEmailTransferInvoicePaymentMutationResult = Apollo.MutationResult<BookEmailTransferInvoicePaymentMutation>;
export type BookEmailTransferInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<BookEmailTransferInvoicePaymentMutation, BookEmailTransferInvoicePaymentMutationVariables>;
export const BookDirectDebitInvoicePaymentDocument = gql`
    mutation bookDirectDebitInvoicePayment($directDebitPaymentInput: DirectDebitPaymentInput!, $invoiceId: String!) {
  bookDirectDebitInvoicePayment(
    directDebitPaymentInput: $directDebitPaymentInput
    invoiceId: $invoiceId
  ) {
    transaction {
      transactionId
    }
    status
  }
}
    `;
export type BookDirectDebitInvoicePaymentMutationFn = Apollo.MutationFunction<BookDirectDebitInvoicePaymentMutation, BookDirectDebitInvoicePaymentMutationVariables>;

/**
 * __useBookDirectDebitInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useBookDirectDebitInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookDirectDebitInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookDirectDebitInvoicePaymentMutation, { data, loading, error }] = useBookDirectDebitInvoicePaymentMutation({
 *   variables: {
 *      directDebitPaymentInput: // value for 'directDebitPaymentInput'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useBookDirectDebitInvoicePaymentMutation(baseOptions?: Apollo.MutationHookOptions<BookDirectDebitInvoicePaymentMutation, BookDirectDebitInvoicePaymentMutationVariables>) {
        return Apollo.useMutation<BookDirectDebitInvoicePaymentMutation, BookDirectDebitInvoicePaymentMutationVariables>(BookDirectDebitInvoicePaymentDocument, baseOptions);
      }
export type BookDirectDebitInvoicePaymentMutationHookResult = ReturnType<typeof useBookDirectDebitInvoicePaymentMutation>;
export type BookDirectDebitInvoicePaymentMutationResult = Apollo.MutationResult<BookDirectDebitInvoicePaymentMutation>;
export type BookDirectDebitInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<BookDirectDebitInvoicePaymentMutation, BookDirectDebitInvoicePaymentMutationVariables>;
export const BookCreditCardInvoicePaymentDocument = gql`
    mutation bookCreditCardInvoicePayment($creditCardPaymentInput: CreditCardPaymentInput!, $invoiceId: String!) {
  bookCreditCardInvoicePayment(
    creditCardPaymentInput: $creditCardPaymentInput
    invoiceId: $invoiceId
  ) {
    transaction {
      transactionId
    }
    status
  }
}
    `;
export type BookCreditCardInvoicePaymentMutationFn = Apollo.MutationFunction<BookCreditCardInvoicePaymentMutation, BookCreditCardInvoicePaymentMutationVariables>;

/**
 * __useBookCreditCardInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useBookCreditCardInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookCreditCardInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookCreditCardInvoicePaymentMutation, { data, loading, error }] = useBookCreditCardInvoicePaymentMutation({
 *   variables: {
 *      creditCardPaymentInput: // value for 'creditCardPaymentInput'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useBookCreditCardInvoicePaymentMutation(baseOptions?: Apollo.MutationHookOptions<BookCreditCardInvoicePaymentMutation, BookCreditCardInvoicePaymentMutationVariables>) {
        return Apollo.useMutation<BookCreditCardInvoicePaymentMutation, BookCreditCardInvoicePaymentMutationVariables>(BookCreditCardInvoicePaymentDocument, baseOptions);
      }
export type BookCreditCardInvoicePaymentMutationHookResult = ReturnType<typeof useBookCreditCardInvoicePaymentMutation>;
export type BookCreditCardInvoicePaymentMutationResult = Apollo.MutationResult<BookCreditCardInvoicePaymentMutation>;
export type BookCreditCardInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<BookCreditCardInvoicePaymentMutation, BookCreditCardInvoicePaymentMutationVariables>;
export const BookDirectDebitAccountBalancePaymentDocument = gql`
    mutation bookDirectDebitAccountBalancePayment($directDebitPaymentInput: DirectDebitPaymentInput!, $institutionCustomerBalanceId: String!) {
  bookDirectDebitAccountBalancePayment(
    directDebitPaymentInput: $directDebitPaymentInput
    institutionCustomerBalanceId: $institutionCustomerBalanceId
  ) {
    transaction {
      transactionId
    }
    status
  }
}
    `;
export type BookDirectDebitAccountBalancePaymentMutationFn = Apollo.MutationFunction<BookDirectDebitAccountBalancePaymentMutation, BookDirectDebitAccountBalancePaymentMutationVariables>;

/**
 * __useBookDirectDebitAccountBalancePaymentMutation__
 *
 * To run a mutation, you first call `useBookDirectDebitAccountBalancePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookDirectDebitAccountBalancePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookDirectDebitAccountBalancePaymentMutation, { data, loading, error }] = useBookDirectDebitAccountBalancePaymentMutation({
 *   variables: {
 *      directDebitPaymentInput: // value for 'directDebitPaymentInput'
 *      institutionCustomerBalanceId: // value for 'institutionCustomerBalanceId'
 *   },
 * });
 */
export function useBookDirectDebitAccountBalancePaymentMutation(baseOptions?: Apollo.MutationHookOptions<BookDirectDebitAccountBalancePaymentMutation, BookDirectDebitAccountBalancePaymentMutationVariables>) {
        return Apollo.useMutation<BookDirectDebitAccountBalancePaymentMutation, BookDirectDebitAccountBalancePaymentMutationVariables>(BookDirectDebitAccountBalancePaymentDocument, baseOptions);
      }
export type BookDirectDebitAccountBalancePaymentMutationHookResult = ReturnType<typeof useBookDirectDebitAccountBalancePaymentMutation>;
export type BookDirectDebitAccountBalancePaymentMutationResult = Apollo.MutationResult<BookDirectDebitAccountBalancePaymentMutation>;
export type BookDirectDebitAccountBalancePaymentMutationOptions = Apollo.BaseMutationOptions<BookDirectDebitAccountBalancePaymentMutation, BookDirectDebitAccountBalancePaymentMutationVariables>;
export const BookCreditCardAccountBalancePaymentDocument = gql`
    mutation bookCreditCardAccountBalancePayment($creditCardPaymentInput: CreditCardPaymentInput!, $institutionCustomerBalanceId: String!) {
  bookCreditCardAccountBalancePayment(
    creditCardPaymentInput: $creditCardPaymentInput
    institutionCustomerBalanceId: $institutionCustomerBalanceId
  ) {
    transaction {
      transactionId
    }
    status
  }
}
    `;
export type BookCreditCardAccountBalancePaymentMutationFn = Apollo.MutationFunction<BookCreditCardAccountBalancePaymentMutation, BookCreditCardAccountBalancePaymentMutationVariables>;

/**
 * __useBookCreditCardAccountBalancePaymentMutation__
 *
 * To run a mutation, you first call `useBookCreditCardAccountBalancePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookCreditCardAccountBalancePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookCreditCardAccountBalancePaymentMutation, { data, loading, error }] = useBookCreditCardAccountBalancePaymentMutation({
 *   variables: {
 *      creditCardPaymentInput: // value for 'creditCardPaymentInput'
 *      institutionCustomerBalanceId: // value for 'institutionCustomerBalanceId'
 *   },
 * });
 */
export function useBookCreditCardAccountBalancePaymentMutation(baseOptions?: Apollo.MutationHookOptions<BookCreditCardAccountBalancePaymentMutation, BookCreditCardAccountBalancePaymentMutationVariables>) {
        return Apollo.useMutation<BookCreditCardAccountBalancePaymentMutation, BookCreditCardAccountBalancePaymentMutationVariables>(BookCreditCardAccountBalancePaymentDocument, baseOptions);
      }
export type BookCreditCardAccountBalancePaymentMutationHookResult = ReturnType<typeof useBookCreditCardAccountBalancePaymentMutation>;
export type BookCreditCardAccountBalancePaymentMutationResult = Apollo.MutationResult<BookCreditCardAccountBalancePaymentMutation>;
export type BookCreditCardAccountBalancePaymentMutationOptions = Apollo.BaseMutationOptions<BookCreditCardAccountBalancePaymentMutation, BookCreditCardAccountBalancePaymentMutationVariables>;
export const CalculateFeesDocument = gql`
    query calculateFees($input: CalculateFeeInput!) {
  calculateFees(feeInput: $input) {
    fees {
      amount
      currencyCode
      type
    }
    totalAmount
  }
}
    `;

/**
 * __useCalculateFeesQuery__
 *
 * To run a query within a React component, call `useCalculateFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateFeesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateFeesQuery(baseOptions: Apollo.QueryHookOptions<CalculateFeesQuery, CalculateFeesQueryVariables>) {
        return Apollo.useQuery<CalculateFeesQuery, CalculateFeesQueryVariables>(CalculateFeesDocument, baseOptions);
      }
export function useCalculateFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateFeesQuery, CalculateFeesQueryVariables>) {
          return Apollo.useLazyQuery<CalculateFeesQuery, CalculateFeesQueryVariables>(CalculateFeesDocument, baseOptions);
        }
export type CalculateFeesQueryHookResult = ReturnType<typeof useCalculateFeesQuery>;
export type CalculateFeesLazyQueryHookResult = ReturnType<typeof useCalculateFeesLazyQuery>;
export type CalculateFeesQueryResult = Apollo.QueryResult<CalculateFeesQuery, CalculateFeesQueryVariables>;
export const CalculateFeesForCreditCardDocument = gql`
    query calculateFeesForCreditCard($input: CalculateFeeForCreditCardInput!) {
  calculateFeesForCreditCard(creditCardFeeInput: $input) {
    fees {
      amount
      currencyCode
      type
    }
    totalAmount
  }
}
    `;

/**
 * __useCalculateFeesForCreditCardQuery__
 *
 * To run a query within a React component, call `useCalculateFeesForCreditCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateFeesForCreditCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateFeesForCreditCardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateFeesForCreditCardQuery(baseOptions: Apollo.QueryHookOptions<CalculateFeesForCreditCardQuery, CalculateFeesForCreditCardQueryVariables>) {
        return Apollo.useQuery<CalculateFeesForCreditCardQuery, CalculateFeesForCreditCardQueryVariables>(CalculateFeesForCreditCardDocument, baseOptions);
      }
export function useCalculateFeesForCreditCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateFeesForCreditCardQuery, CalculateFeesForCreditCardQueryVariables>) {
          return Apollo.useLazyQuery<CalculateFeesForCreditCardQuery, CalculateFeesForCreditCardQueryVariables>(CalculateFeesForCreditCardDocument, baseOptions);
        }
export type CalculateFeesForCreditCardQueryHookResult = ReturnType<typeof useCalculateFeesForCreditCardQuery>;
export type CalculateFeesForCreditCardLazyQueryHookResult = ReturnType<typeof useCalculateFeesForCreditCardLazyQuery>;
export type CalculateFeesForCreditCardQueryResult = Apollo.QueryResult<CalculateFeesForCreditCardQuery, CalculateFeesForCreditCardQueryVariables>;
export const DeleteCreditCardDocument = gql`
    mutation deleteCreditCard($creditCardId: String!) {
  deleteCreditCard(creditCardId: $creditCardId) {
    cardDeleted
  }
}
    `;
export type DeleteCreditCardMutationFn = Apollo.MutationFunction<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>;

/**
 * __useDeleteCreditCardMutation__
 *
 * To run a mutation, you first call `useDeleteCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditCardMutation, { data, loading, error }] = useDeleteCreditCardMutation({
 *   variables: {
 *      creditCardId: // value for 'creditCardId'
 *   },
 * });
 */
export function useDeleteCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>) {
        return Apollo.useMutation<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>(DeleteCreditCardDocument, baseOptions);
      }
export type DeleteCreditCardMutationHookResult = ReturnType<typeof useDeleteCreditCardMutation>;
export type DeleteCreditCardMutationResult = Apollo.MutationResult<DeleteCreditCardMutation>;
export type DeleteCreditCardMutationOptions = Apollo.BaseMutationOptions<DeleteCreditCardMutation, DeleteCreditCardMutationVariables>;
export const SaveCloverPaymentProcessorCardProfileDocument = gql`
    mutation saveCloverPaymentProcessorCardProfile($input: SaveCreditCardInput!) {
  saveCloverPaymentProcessorCard(input: $input) {
    cardBrand
    expiryMonth
    expiryYear
    cardNumber
    cardCurrency
    creditCardId
  }
}
    `;
export type SaveCloverPaymentProcessorCardProfileMutationFn = Apollo.MutationFunction<SaveCloverPaymentProcessorCardProfileMutation, SaveCloverPaymentProcessorCardProfileMutationVariables>;

/**
 * __useSaveCloverPaymentProcessorCardProfileMutation__
 *
 * To run a mutation, you first call `useSaveCloverPaymentProcessorCardProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCloverPaymentProcessorCardProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCloverPaymentProcessorCardProfileMutation, { data, loading, error }] = useSaveCloverPaymentProcessorCardProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCloverPaymentProcessorCardProfileMutation(baseOptions?: Apollo.MutationHookOptions<SaveCloverPaymentProcessorCardProfileMutation, SaveCloverPaymentProcessorCardProfileMutationVariables>) {
        return Apollo.useMutation<SaveCloverPaymentProcessorCardProfileMutation, SaveCloverPaymentProcessorCardProfileMutationVariables>(SaveCloverPaymentProcessorCardProfileDocument, baseOptions);
      }
export type SaveCloverPaymentProcessorCardProfileMutationHookResult = ReturnType<typeof useSaveCloverPaymentProcessorCardProfileMutation>;
export type SaveCloverPaymentProcessorCardProfileMutationResult = Apollo.MutationResult<SaveCloverPaymentProcessorCardProfileMutation>;
export type SaveCloverPaymentProcessorCardProfileMutationOptions = Apollo.BaseMutationOptions<SaveCloverPaymentProcessorCardProfileMutation, SaveCloverPaymentProcessorCardProfileMutationVariables>;
export const SaveBlueSnapCreditCardDocument = gql`
    mutation saveBlueSnapCreditCard($input: SaveCreditCardInput!) {
  saveBlueSnapCreditCard(input: $input) {
    cardBrand
    expiryMonth
    expiryYear
    cardNumber
    cardCurrency
    creditCardId
  }
}
    `;
export type SaveBlueSnapCreditCardMutationFn = Apollo.MutationFunction<SaveBlueSnapCreditCardMutation, SaveBlueSnapCreditCardMutationVariables>;

/**
 * __useSaveBlueSnapCreditCardMutation__
 *
 * To run a mutation, you first call `useSaveBlueSnapCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBlueSnapCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBlueSnapCreditCardMutation, { data, loading, error }] = useSaveBlueSnapCreditCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBlueSnapCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<SaveBlueSnapCreditCardMutation, SaveBlueSnapCreditCardMutationVariables>) {
        return Apollo.useMutation<SaveBlueSnapCreditCardMutation, SaveBlueSnapCreditCardMutationVariables>(SaveBlueSnapCreditCardDocument, baseOptions);
      }
export type SaveBlueSnapCreditCardMutationHookResult = ReturnType<typeof useSaveBlueSnapCreditCardMutation>;
export type SaveBlueSnapCreditCardMutationResult = Apollo.MutationResult<SaveBlueSnapCreditCardMutation>;
export type SaveBlueSnapCreditCardMutationOptions = Apollo.BaseMutationOptions<SaveBlueSnapCreditCardMutation, SaveBlueSnapCreditCardMutationVariables>;
export const GetBlueSnapCreditCardTokenDocument = gql`
    query getBlueSnapCreditCardToken($input: PaymentProcessorResolverCriteria!) {
  getBlueSnapCreditCardToken(input: $input) {
    token
  }
}
    `;

/**
 * __useGetBlueSnapCreditCardTokenQuery__
 *
 * To run a query within a React component, call `useGetBlueSnapCreditCardTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlueSnapCreditCardTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlueSnapCreditCardTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBlueSnapCreditCardTokenQuery(baseOptions: Apollo.QueryHookOptions<GetBlueSnapCreditCardTokenQuery, GetBlueSnapCreditCardTokenQueryVariables>) {
        return Apollo.useQuery<GetBlueSnapCreditCardTokenQuery, GetBlueSnapCreditCardTokenQueryVariables>(GetBlueSnapCreditCardTokenDocument, baseOptions);
      }
export function useGetBlueSnapCreditCardTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlueSnapCreditCardTokenQuery, GetBlueSnapCreditCardTokenQueryVariables>) {
          return Apollo.useLazyQuery<GetBlueSnapCreditCardTokenQuery, GetBlueSnapCreditCardTokenQueryVariables>(GetBlueSnapCreditCardTokenDocument, baseOptions);
        }
export type GetBlueSnapCreditCardTokenQueryHookResult = ReturnType<typeof useGetBlueSnapCreditCardTokenQuery>;
export type GetBlueSnapCreditCardTokenLazyQueryHookResult = ReturnType<typeof useGetBlueSnapCreditCardTokenLazyQuery>;
export type GetBlueSnapCreditCardTokenQueryResult = Apollo.QueryResult<GetBlueSnapCreditCardTokenQuery, GetBlueSnapCreditCardTokenQueryVariables>;
export const GetBlueSnapCardDocument = gql`
    query getBlueSnapCard($institutionId: String!) {
  blueSnapCard: getBlueSnapCard(institutionId: $institutionId) {
    cardBrand
    expiryMonth
    expiryYear
    cardNumber
    cardCurrency
    creditCardId
  }
}
    `;

/**
 * __useGetBlueSnapCardQuery__
 *
 * To run a query within a React component, call `useGetBlueSnapCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlueSnapCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlueSnapCardQuery({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useGetBlueSnapCardQuery(baseOptions: Apollo.QueryHookOptions<GetBlueSnapCardQuery, GetBlueSnapCardQueryVariables>) {
        return Apollo.useQuery<GetBlueSnapCardQuery, GetBlueSnapCardQueryVariables>(GetBlueSnapCardDocument, baseOptions);
      }
export function useGetBlueSnapCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlueSnapCardQuery, GetBlueSnapCardQueryVariables>) {
          return Apollo.useLazyQuery<GetBlueSnapCardQuery, GetBlueSnapCardQueryVariables>(GetBlueSnapCardDocument, baseOptions);
        }
export type GetBlueSnapCardQueryHookResult = ReturnType<typeof useGetBlueSnapCardQuery>;
export type GetBlueSnapCardLazyQueryHookResult = ReturnType<typeof useGetBlueSnapCardLazyQuery>;
export type GetBlueSnapCardQueryResult = Apollo.QueryResult<GetBlueSnapCardQuery, GetBlueSnapCardQueryVariables>;
export const GetPaymentProcessorConnectionDetailsDocument = gql`
    query getPaymentProcessorConnectionDetails($input: PaymentProcessorResolverCriteria!) {
  getPaymentProcessorConnectionDetails(input: $input) {
    paymentProcessorCode
    processorApiKey
  }
}
    `;

/**
 * __useGetPaymentProcessorConnectionDetailsQuery__
 *
 * To run a query within a React component, call `useGetPaymentProcessorConnectionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentProcessorConnectionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentProcessorConnectionDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPaymentProcessorConnectionDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentProcessorConnectionDetailsQuery, GetPaymentProcessorConnectionDetailsQueryVariables>) {
        return Apollo.useQuery<GetPaymentProcessorConnectionDetailsQuery, GetPaymentProcessorConnectionDetailsQueryVariables>(GetPaymentProcessorConnectionDetailsDocument, baseOptions);
      }
export function useGetPaymentProcessorConnectionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentProcessorConnectionDetailsQuery, GetPaymentProcessorConnectionDetailsQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentProcessorConnectionDetailsQuery, GetPaymentProcessorConnectionDetailsQueryVariables>(GetPaymentProcessorConnectionDetailsDocument, baseOptions);
        }
export type GetPaymentProcessorConnectionDetailsQueryHookResult = ReturnType<typeof useGetPaymentProcessorConnectionDetailsQuery>;
export type GetPaymentProcessorConnectionDetailsLazyQueryHookResult = ReturnType<typeof useGetPaymentProcessorConnectionDetailsLazyQuery>;
export type GetPaymentProcessorConnectionDetailsQueryResult = Apollo.QueryResult<GetPaymentProcessorConnectionDetailsQuery, GetPaymentProcessorConnectionDetailsQueryVariables>;
export const CreateNewCustomerFromImportDocument = gql`
    mutation createNewCustomerFromImport($importedCustomerId: String!, $institutionId: String!) {
  createNewCustomerFromImport(
    importedCustomerId: $importedCustomerId
    institutionId: $institutionId
  ) {
    customerId
  }
}
    `;
export type CreateNewCustomerFromImportMutationFn = Apollo.MutationFunction<CreateNewCustomerFromImportMutation, CreateNewCustomerFromImportMutationVariables>;

/**
 * __useCreateNewCustomerFromImportMutation__
 *
 * To run a mutation, you first call `useCreateNewCustomerFromImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewCustomerFromImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewCustomerFromImportMutation, { data, loading, error }] = useCreateNewCustomerFromImportMutation({
 *   variables: {
 *      importedCustomerId: // value for 'importedCustomerId'
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useCreateNewCustomerFromImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewCustomerFromImportMutation, CreateNewCustomerFromImportMutationVariables>) {
        return Apollo.useMutation<CreateNewCustomerFromImportMutation, CreateNewCustomerFromImportMutationVariables>(CreateNewCustomerFromImportDocument, baseOptions);
      }
export type CreateNewCustomerFromImportMutationHookResult = ReturnType<typeof useCreateNewCustomerFromImportMutation>;
export type CreateNewCustomerFromImportMutationResult = Apollo.MutationResult<CreateNewCustomerFromImportMutation>;
export type CreateNewCustomerFromImportMutationOptions = Apollo.BaseMutationOptions<CreateNewCustomerFromImportMutation, CreateNewCustomerFromImportMutationVariables>;
export const HasCustomerBeenCreatedDocument = gql`
    query hasCustomerBeenCreated($importedCustomerId: String!) {
  hasCustomerBeenCreated(importedCustomerId: $importedCustomerId)
}
    `;

/**
 * __useHasCustomerBeenCreatedQuery__
 *
 * To run a query within a React component, call `useHasCustomerBeenCreatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasCustomerBeenCreatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasCustomerBeenCreatedQuery({
 *   variables: {
 *      importedCustomerId: // value for 'importedCustomerId'
 *   },
 * });
 */
export function useHasCustomerBeenCreatedQuery(baseOptions: Apollo.QueryHookOptions<HasCustomerBeenCreatedQuery, HasCustomerBeenCreatedQueryVariables>) {
        return Apollo.useQuery<HasCustomerBeenCreatedQuery, HasCustomerBeenCreatedQueryVariables>(HasCustomerBeenCreatedDocument, baseOptions);
      }
export function useHasCustomerBeenCreatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasCustomerBeenCreatedQuery, HasCustomerBeenCreatedQueryVariables>) {
          return Apollo.useLazyQuery<HasCustomerBeenCreatedQuery, HasCustomerBeenCreatedQueryVariables>(HasCustomerBeenCreatedDocument, baseOptions);
        }
export type HasCustomerBeenCreatedQueryHookResult = ReturnType<typeof useHasCustomerBeenCreatedQuery>;
export type HasCustomerBeenCreatedLazyQueryHookResult = ReturnType<typeof useHasCustomerBeenCreatedLazyQuery>;
export type HasCustomerBeenCreatedQueryResult = Apollo.QueryResult<HasCustomerBeenCreatedQuery, HasCustomerBeenCreatedQueryVariables>;
export const GetCurrentBalancesDocument = gql`
    query GetCurrentBalances {
  getCurrentBalances {
    institutionCustomerBalanceId
    institutionId
    institutionName
    customerId
    externalId
    description
    amount {
      currencyCode
      amount
    }
    pullDate
    createdDate
    createdBy
    updatedDate
    updatedBy
    transactions {
      id
      payeeReceives {
        currencyCode
        amount
      }
      createdDate
      status {
        createdDate
        type
        customerComment
      }
    }
  }
}
    `;

/**
 * __useGetCurrentBalancesQuery__
 *
 * To run a query within a React component, call `useGetCurrentBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentBalancesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentBalancesQuery, GetCurrentBalancesQueryVariables>) {
        return Apollo.useQuery<GetCurrentBalancesQuery, GetCurrentBalancesQueryVariables>(GetCurrentBalancesDocument, baseOptions);
      }
export function useGetCurrentBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentBalancesQuery, GetCurrentBalancesQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentBalancesQuery, GetCurrentBalancesQueryVariables>(GetCurrentBalancesDocument, baseOptions);
        }
export type GetCurrentBalancesQueryHookResult = ReturnType<typeof useGetCurrentBalancesQuery>;
export type GetCurrentBalancesLazyQueryHookResult = ReturnType<typeof useGetCurrentBalancesLazyQuery>;
export type GetCurrentBalancesQueryResult = Apollo.QueryResult<GetCurrentBalancesQuery, GetCurrentBalancesQueryVariables>;
export const GetInstitutionsByIdDocument = gql`
    query getInstitutionsById($institutionIds: [String]!) {
  institutions: getInstitutionsById(institutionIds: $institutionIds) {
    institutionId
    name
    logoUrl
    defaultHomeCountry
    paymentPageTitle
    referenceFieldLabel
    recurringPaymentsEnabled
    creditCardEnabled
    acceptedCurrencies {
      currencyCode
      priority
      acceptedCurrencyId
    }
  }
}
    `;

/**
 * __useGetInstitutionsByIdQuery__
 *
 * To run a query within a React component, call `useGetInstitutionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstitutionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstitutionsByIdQuery({
 *   variables: {
 *      institutionIds: // value for 'institutionIds'
 *   },
 * });
 */
export function useGetInstitutionsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetInstitutionsByIdQuery, GetInstitutionsByIdQueryVariables>) {
        return Apollo.useQuery<GetInstitutionsByIdQuery, GetInstitutionsByIdQueryVariables>(GetInstitutionsByIdDocument, baseOptions);
      }
export function useGetInstitutionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstitutionsByIdQuery, GetInstitutionsByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetInstitutionsByIdQuery, GetInstitutionsByIdQueryVariables>(GetInstitutionsByIdDocument, baseOptions);
        }
export type GetInstitutionsByIdQueryHookResult = ReturnType<typeof useGetInstitutionsByIdQuery>;
export type GetInstitutionsByIdLazyQueryHookResult = ReturnType<typeof useGetInstitutionsByIdLazyQuery>;
export type GetInstitutionsByIdQueryResult = Apollo.QueryResult<GetInstitutionsByIdQuery, GetInstitutionsByIdQueryVariables>;
export const GetInstitutionByIdDocument = gql`
    query getInstitutionById($institutionId: String!) {
  institution: getInstitutionById(institutionId: $institutionId) {
    institutionId
    name
    logoUrl
    defaultHomeCountry
    paymentPageTitle
    referenceFieldLabel
    recurringPaymentsEnabled
    creditCardEnabled
    acceptedCurrencies {
      currencyCode
      priority
      acceptedCurrencyId
    }
  }
}
    `;

/**
 * __useGetInstitutionByIdQuery__
 *
 * To run a query within a React component, call `useGetInstitutionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstitutionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstitutionByIdQuery({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useGetInstitutionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetInstitutionByIdQuery, GetInstitutionByIdQueryVariables>) {
        return Apollo.useQuery<GetInstitutionByIdQuery, GetInstitutionByIdQueryVariables>(GetInstitutionByIdDocument, baseOptions);
      }
export function useGetInstitutionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstitutionByIdQuery, GetInstitutionByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetInstitutionByIdQuery, GetInstitutionByIdQueryVariables>(GetInstitutionByIdDocument, baseOptions);
        }
export type GetInstitutionByIdQueryHookResult = ReturnType<typeof useGetInstitutionByIdQuery>;
export type GetInstitutionByIdLazyQueryHookResult = ReturnType<typeof useGetInstitutionByIdLazyQuery>;
export type GetInstitutionByIdQueryResult = Apollo.QueryResult<GetInstitutionByIdQuery, GetInstitutionByIdQueryVariables>;
export const GetRegionsDocument = gql`
    query getRegions {
  regions: getRegions {
    regionName
    regionCode
    countryCode
  }
}
    `;

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegionsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
        return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, baseOptions);
      }
export function useGetRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
          return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, baseOptions);
        }
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<typeof useGetRegionsLazyQuery>;
export type GetRegionsQueryResult = Apollo.QueryResult<GetRegionsQuery, GetRegionsQueryVariables>;
export const GetTransactionAmountsDocument = gql`
    query getTransactionAmounts($input: GetRateInput!) {
  getTransactionAmounts(getRateInput: $input) {
    savedExchangeRate {
      id
      timestamp
      expires
      validForInMinutes
      payorPaysRate {
        currencyCode
        amount
      }
      payeeReceivesRate {
        currencyCode
        amount
      }
    }
    payorPaysAmount {
      currencyCode
      amount
    }
  }
}
    `;

/**
 * __useGetTransactionAmountsQuery__
 *
 * To run a query within a React component, call `useGetTransactionAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionAmountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTransactionAmountsQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionAmountsQuery, GetTransactionAmountsQueryVariables>) {
        return Apollo.useQuery<GetTransactionAmountsQuery, GetTransactionAmountsQueryVariables>(GetTransactionAmountsDocument, baseOptions);
      }
export function useGetTransactionAmountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionAmountsQuery, GetTransactionAmountsQueryVariables>) {
          return Apollo.useLazyQuery<GetTransactionAmountsQuery, GetTransactionAmountsQueryVariables>(GetTransactionAmountsDocument, baseOptions);
        }
export type GetTransactionAmountsQueryHookResult = ReturnType<typeof useGetTransactionAmountsQuery>;
export type GetTransactionAmountsLazyQueryHookResult = ReturnType<typeof useGetTransactionAmountsLazyQuery>;
export type GetTransactionAmountsQueryResult = Apollo.QueryResult<GetTransactionAmountsQuery, GetTransactionAmountsQueryVariables>;
export const GetUsStatesDocument = gql`
    query getUsStates {
  usStates: getUsStates {
    stateName
    stateCode
  }
}
    `;

/**
 * __useGetUsStatesQuery__
 *
 * To run a query within a React component, call `useGetUsStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetUsStatesQuery, GetUsStatesQueryVariables>) {
        return Apollo.useQuery<GetUsStatesQuery, GetUsStatesQueryVariables>(GetUsStatesDocument, baseOptions);
      }
export function useGetUsStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsStatesQuery, GetUsStatesQueryVariables>) {
          return Apollo.useLazyQuery<GetUsStatesQuery, GetUsStatesQueryVariables>(GetUsStatesDocument, baseOptions);
        }
export type GetUsStatesQueryHookResult = ReturnType<typeof useGetUsStatesQuery>;
export type GetUsStatesLazyQueryHookResult = ReturnType<typeof useGetUsStatesLazyQuery>;
export type GetUsStatesQueryResult = Apollo.QueryResult<GetUsStatesQuery, GetUsStatesQueryVariables>;
export const GetUserLocationDocument = gql`
    query getUserLocation {
  userLocation: getUserLocation {
    countryCode
    stateCode
  }
}
    `;

/**
 * __useGetUserLocationQuery__
 *
 * To run a query within a React component, call `useGetUserLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserLocationQuery(baseOptions?: Apollo.QueryHookOptions<GetUserLocationQuery, GetUserLocationQueryVariables>) {
        return Apollo.useQuery<GetUserLocationQuery, GetUserLocationQueryVariables>(GetUserLocationDocument, baseOptions);
      }
export function useGetUserLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLocationQuery, GetUserLocationQueryVariables>) {
          return Apollo.useLazyQuery<GetUserLocationQuery, GetUserLocationQueryVariables>(GetUserLocationDocument, baseOptions);
        }
export type GetUserLocationQueryHookResult = ReturnType<typeof useGetUserLocationQuery>;
export type GetUserLocationLazyQueryHookResult = ReturnType<typeof useGetUserLocationLazyQuery>;
export type GetUserLocationQueryResult = Apollo.QueryResult<GetUserLocationQuery, GetUserLocationQueryVariables>;
export const GetInvoiceDocument = gql`
    query getInvoice($invoiceUuid: String!) {
  invoice: getInvoice(invoiceUuid: $invoiceUuid) {
    invoiceId
    customerId
    institutionId
    amount
    description
    currencyCode
    institutionInvoiceId
    invoiceDate
    dueDate
  }
}
    `;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      invoiceUuid: // value for 'invoiceUuid'
 *   },
 * });
 */
export function useGetInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        return Apollo.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
      }
export function useGetInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceQueryResult = Apollo.QueryResult<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const GetInvoiceWithBalanceDocument = gql`
    query getInvoiceWithBalance($invoiceUuid: String!) {
  invoiceWithBalance: getInvoiceWithBalance(invoiceUuid: $invoiceUuid) {
    balance {
      amount
      currencyCode
    }
    invoice {
      invoiceId
      customerId
      institutionId
      amount
      description
      currencyCode
      institutionInvoiceId
      invoiceDate
      dueDate
    }
  }
}
    `;

/**
 * __useGetInvoiceWithBalanceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceWithBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceWithBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceWithBalanceQuery({
 *   variables: {
 *      invoiceUuid: // value for 'invoiceUuid'
 *   },
 * });
 */
export function useGetInvoiceWithBalanceQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceWithBalanceQuery, GetInvoiceWithBalanceQueryVariables>) {
        return Apollo.useQuery<GetInvoiceWithBalanceQuery, GetInvoiceWithBalanceQueryVariables>(GetInvoiceWithBalanceDocument, baseOptions);
      }
export function useGetInvoiceWithBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceWithBalanceQuery, GetInvoiceWithBalanceQueryVariables>) {
          return Apollo.useLazyQuery<GetInvoiceWithBalanceQuery, GetInvoiceWithBalanceQueryVariables>(GetInvoiceWithBalanceDocument, baseOptions);
        }
export type GetInvoiceWithBalanceQueryHookResult = ReturnType<typeof useGetInvoiceWithBalanceQuery>;
export type GetInvoiceWithBalanceLazyQueryHookResult = ReturnType<typeof useGetInvoiceWithBalanceLazyQuery>;
export type GetInvoiceWithBalanceQueryResult = Apollo.QueryResult<GetInvoiceWithBalanceQuery, GetInvoiceWithBalanceQueryVariables>;
export const GetCustomerInvoicesDocument = gql`
    query getCustomerInvoices {
  invoicegroups: getCustomerInvoices {
    balance {
      amount
      currencyCode
    }
    invoices {
      balance {
        amount
        currencyCode
      }
      invoice {
        invoiceId
        customerId
        institutionId
        amount
        description
        currencyCode
        institutionInvoiceId
        invoiceDate
        dueDate
        invoiceUuid
      }
      transactionIds
    }
  }
}
    `;

/**
 * __useGetCustomerInvoicesQuery__
 *
 * To run a query within a React component, call `useGetCustomerInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>) {
        return Apollo.useQuery<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>(GetCustomerInvoicesDocument, baseOptions);
      }
export function useGetCustomerInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>(GetCustomerInvoicesDocument, baseOptions);
        }
export type GetCustomerInvoicesQueryHookResult = ReturnType<typeof useGetCustomerInvoicesQuery>;
export type GetCustomerInvoicesLazyQueryHookResult = ReturnType<typeof useGetCustomerInvoicesLazyQuery>;
export type GetCustomerInvoicesQueryResult = Apollo.QueryResult<GetCustomerInvoicesQuery, GetCustomerInvoicesQueryVariables>;
export const GetTransactionDetailDocument = gql`
    query getTransactionDetail($transactionId: String!) {
  transactionDetails: getTransactionDetail(transactionId: $transactionId) {
    successful
    transactionId
    transaction {
      customerId
      id
      createdDate
      createdBy
      creditCardProcessorApprovalCode
      institutionId
      payorPays {
        amount
        currencyCode
      }
      payorPaysTotal {
        amount
        currencyCode
      }
      payeeReceives {
        amount
        currencyCode
      }
      payeeName
      status {
        createdDate
        type
        customerComment
      }
      paymentMethod
      institutionReferenceValue
      rejectedPaymentReference
      receiveEmail
      fees {
        amount
        currencyCode
        type
      }
    }
  }
}
    `;

/**
 * __useGetTransactionDetailQuery__
 *
 * To run a query within a React component, call `useGetTransactionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionDetailQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGetTransactionDetailQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionDetailQuery, GetTransactionDetailQueryVariables>) {
        return Apollo.useQuery<GetTransactionDetailQuery, GetTransactionDetailQueryVariables>(GetTransactionDetailDocument, baseOptions);
      }
export function useGetTransactionDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionDetailQuery, GetTransactionDetailQueryVariables>) {
          return Apollo.useLazyQuery<GetTransactionDetailQuery, GetTransactionDetailQueryVariables>(GetTransactionDetailDocument, baseOptions);
        }
export type GetTransactionDetailQueryHookResult = ReturnType<typeof useGetTransactionDetailQuery>;
export type GetTransactionDetailLazyQueryHookResult = ReturnType<typeof useGetTransactionDetailLazyQuery>;
export type GetTransactionDetailQueryResult = Apollo.QueryResult<GetTransactionDetailQuery, GetTransactionDetailQueryVariables>;
export const GetTransactionsDocument = gql`
    query getTransactions {
  transactionResults: getTransactions {
    successful
    transactionId
    transaction {
      customerId
      id
      createdDate
      createdBy
      creditCardProcessorApprovalCode
      payorPays {
        currencyCode
        amount
      }
      fees {
        amount
        currencyCode
        type
      }
      payorPaysTotal {
        currencyCode
        amount
      }
      payeeReceives {
        currencyCode
        amount
      }
      payeeName
      status {
        createdDate
        type
        customerComment
      }
      paymentMethod
      institutionReferenceValue
      rejectedPaymentReference
      receiveEmail
      invoices {
        invoiceId
        customerId
        institutionId
        amount
        description
        currencyCode
        institutionInvoiceId
        invoiceDate
        dueDate
      }
    }
  }
}
    `;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
        return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, baseOptions);
      }
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, baseOptions);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;