import {CustomerInstitutionInput, useUpdateUserInstitutionMutation} from "../../data/graphqlTypes";
import {getUserInstitutionsAndUnits} from "./getUnitsFromInstitution.graphql";
import {useEffect, useState} from "react";

export type SavedCustomerInstitution = { id: string, unit: string };

export function useUpdateUserInstitution() {

    const [updateUserInstitution, {data, loading, error}] = useUpdateUserInstitutionMutation();
    const [userInstitutionInput, setUserInstitutionInput] = useState<CustomerInstitutionInput>();
    const [customerInstitution, setCustomerInstitution] = useState<SavedCustomerInstitution>(() => {
        return {id: "", unit: ""}
    });

    const doUpdate = async (input: CustomerInstitutionInput) => {
        const userInstitutionInput: CustomerInstitutionInput = {
            id: input.id,
            units: input.units as string[]
        };
        setUserInstitutionInput(userInstitutionInput);
        await updateUserInstitution({
            variables: {userInstitutionInput: userInstitutionInput},
            refetchQueries: [{query: getUserInstitutionsAndUnits}]
        });
        return {...input};
    };


    // todo: probably need to get rid of this with the line items change.
    useEffect(() => {
        if (data && userInstitutionInput) {
            const found = data.updateCustomerInstitution.find(value => value && userInstitutionInput.id === value.id);
            if (found) {
                const unit = found.units ? found.units.join() : "";
                if (unit !== customerInstitution.unit) {

                    setCustomerInstitution(prevState => {
                        return {
                            ...prevState,
                            unit
                        }
                    });
                }
            }
        }
    }, [customerInstitution, data, userInstitutionInput])

    return {
        updateUserInstitution: doUpdate,
        updateUserInstitutionData: customerInstitution,
        updateUserInstitutionLoading: loading,
        updateUserInstitutionError: error
    };
}
