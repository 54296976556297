import React, {useEffect, useState} from "react";
import {Card, CardActions, CardContent, Grid, IconButton, Typography} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import {GetBanksForRoutingCodeQuery} from "../data/graphqlTypes";
import {ApolloError} from "@apollo/client";
import {isFiveOrNineDigits} from "../DirectDebit/DirectDebitConfiguration";

type PropsType = {
    banksData: GetBanksForRoutingCodeQuery | undefined,
    loading: boolean,
    error: ApolloError | undefined,
    routingCode: string
}

const BankPlaceholder = (props: PropsType) => {

    const [error, setError] = useState<string>();

    const styles = {
        card: {
            minHeight: 160,
            paddingTop: 10,
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 0,
            "&:lastChild": {
                paddingBottom: 0
            }
        }
    };

    let fiveOrNineDigits = isFiveOrNineDigits(props.routingCode);
    useEffect(() => {
        if (
            fiveOrNineDigits &&
            !!props.banksData &&
            (
                !props.banksData.banks ||
                props.banksData.banks.length < 1
            )
        ) {
            setError("No banks found. Note that US banks have 9 digit routing codes and Canadian banks have 5 digit transit codes.");
        } else {
            setError(undefined);
        }
    }, [fiveOrNineDigits, props.banksData, setError]);

    useEffect(() => {
        if (props.routingCode.length > 9) {
            setError("The routing code / transit number cannot be more than 9 digits long.");
        }
    }, [props.routingCode, setError]);


    return (

        <Grid>
            <Card>
                <CardContent style={styles.card}>
                    <Typography variant="h5"> Bank Name </Typography>
                    <Typography variant="body2"> Bank Address </Typography>
                    {
                        props.loading || props.error || error ?
                            <ApolloGqlQueryDisplay
                                loading={props.loading}
                                error={props.error || error}
                            /> :
                            <>
                                <Typography variant="body2">City, Province/State</Typography>
                                <Typography variant="body2">Country, Post/Zip Code </Typography>
                                <Typography variant="body2"> Bank Phone </Typography>
                                <Typography variant="body2"> Bank Code:</Typography>
                            </>
                    }
                </CardContent>
                <CardActions>
                    <IconButton aria-label="select">
                        <CheckBoxOutlineBlankIcon color={"primary"}/>
                    </IconButton>
                </CardActions>
            </Card>
        </Grid>


    );
}
export default BankPlaceholder;
