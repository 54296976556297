import React, {useCallback, useEffect, useState} from "react";
import {
    AccountRoutingType,
    BankAccountDetail,
    Institution,
    InstitutionCustomerBalance,
    TransactionAmounts,
    useGetAutoPayConfigurationsQuery,
    useGetCurrentBalancesQuery,
    useGetInstitutionsByIdLazyQuery,
    useGetTransactionAmountsLazyQuery,
} from "../data/graphqlTypes";
import {Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography} from "@material-ui/core";
import {ShiftModal} from "../input/ShiftModal";
import TransactionSummary from "../input/TransactionSummary";
import TransactionConfirmation from "../Transaction/TransactionConfirmation";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import {parse, parseISO} from "date-fns";
import {PaymentMethodCombined} from "./useGetAvailablePaymentMethods";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {makeStyles} from "@material-ui/core/styles";
import BalanceCard from "./BalanceCard";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import {useHistory} from "react-router-dom";
import {calculateRemainingBalance} from "../../views/HoaPartialBalancePayment";
import useGetCustomer from "../context/EntityContext/useGetCustomer";


// ---------------------------------Styles --------------------------//
const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 3px 5px rgba(0,0,0,0.2)',
        borderRadius: '4px',
        '&:not(:last-child)': {
            marginBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    boxContainer: {
        paddingBottom: theme.spacing(4),
    },
    heading: {
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 600,
        flexGrow: 1,
    },
    balance: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 700,
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            marginLeft: "auto",
        },
    },
    summary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(0, 0, 0, 0.06)',

    },
    details: {
        flexDirection: 'column',
    },
    expanded: {
        border: '1px solid rgba(0, 0, 0, 0.1)',
    },
}));

// ---------------------------------PROPS --------------------------//
type PropsType = {
    accountPaymentCallback: (
        institutionCustomerBalanceId: string,
        autoPayAccountId: string
    ) => Promise<void>;
};
// -----------------------------------------------------------------//


const sortBalanceByPullDateThenByCreatedDesc = (a: InstitutionCustomerBalance | undefined, b: InstitutionCustomerBalance | undefined) => {
    // Handle null dates
    const aPullDate = a && a.pullDate;
    const bPullDate = b && b.pullDate;
    if (!aPullDate && !bPullDate) return 0;
    if (!aPullDate) return 1; // Place null at the end
    if (!bPullDate) return -1; // Place null at the end

    if (aPullDate && bPullDate) {
        const dateDifference = parseISO(aPullDate).getTime() - parseISO(bPullDate).getTime();
        if (dateDifference !== 0) {

            return dateDifference
        }
    }
    const aRemainingBalance = calculateRemainingBalance(a);
    const bRemainingBalance = calculateRemainingBalance(b);
    const balanceDifference = bRemainingBalance - aRemainingBalance;
    if (balanceDifference !== 0) {
        return balanceDifference
    }


    return parseISO(b.createdDate).getTime() - parseISO(a.createdDate).getTime();
};

const BalanceDisplay = (props: PropsType) => {
    const [institutionCustomerBalance, setInstitutionCustomerBalance] = useState<InstitutionCustomerBalance>();
    const [accountRoutingType, setAccountRoutingType] = useState<AccountRoutingType>();
    const [open, setOpen] = useState(false);
    const [rate, setRate] = useState<TransactionAmounts>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodCombined>();
    const [selectedAccountCurrency, setSelectedAccountCurrency] = useState<string>();
    const [institution, setInstitution] = useState<Institution>();
    const [expanded, setExpanded] = useState<string | false>(false);
    const [amountRemaining, setAmountRemaining] = useState<number>();


    const {push} = useHistory();

    const classes = useStyles();

    const {
        data: autoPayData,
        loading: autoPayLoading,
        error: autoPayError
    } = useGetAutoPayConfigurationsQuery({fetchPolicy: "no-cache"});

    const {
        customer,
        loading: customerLoading,
        error: customerError
    } = useGetCustomer()

    const [getInstitutionsById,
        {
            data: institutionData,
            loading: institutionLoading,
            error: institutionError
        }] = useGetInstitutionsByIdLazyQuery();


    // -------------------GET BALANCE QUERIES ------------------//

    const {
        data: currentBalancesData,
        error: currentBalancesError,
        loading: currentBalancesLoading,
        refetch: currentBalancesRefresh,
    } = useGetCurrentBalancesQuery({fetchPolicy: "no-cache"});


    const balances = currentBalancesData?.getCurrentBalances;


    useEffect(() => {
        if (balances && balances.length > 0) {
            const institutionIds = balances.map(value => value.institutionId);
            getInstitutionsById({variables: {institutionIds}})
        }
        if (balances && balances.length > 0) {

            const first = balances
                .map(value => value as InstitutionCustomerBalance)
                .sort(sortBalanceByPullDateThenByCreatedDesc)
                .find(value => !!value);


            if (first) {

                setExpanded(first.institutionCustomerBalanceId);
            }
        }
    }, [balances]);


    // --------------------------------------------------------//

    const [getRate, {data, error, loading: rateLoading}] = useGetTransactionAmountsLazyQuery();

    const accountPaymentInit = useCallback(async (
        institutionCustomerBalanceId: string,
        paymentMethod2: PaymentMethodCombined,
        balanceInstitution: Institution | undefined,
        amountRemaining: number
    ): Promise<void> => {

        const bankAccount = paymentMethod2?.bankAccount;
        const creditCard = paymentMethod2?.creditCard;
        if (!bankAccount && !creditCard && !balanceInstitution) {
            console.error("Setup Payment Account");

        }
        setAmountRemaining(amountRemaining);
        setPaymentMethod(paymentMethod2);
        setInstitution(balanceInstitution);
        const balance: InstitutionCustomerBalance | undefined = currentBalancesData?.getCurrentBalances.find(balance => balance.institutionCustomerBalanceId === institutionCustomerBalanceId) as InstitutionCustomerBalance;
        const payorPaysCurrencyCode = bankAccount?.currency || creditCard?.cardCurrency;
        const institutionId = balance?.institutionId;
        const selectedAccountCurrency = creditCard?.cardCurrency || bankAccount?.currency;
        setSelectedAccountCurrency(selectedAccountCurrency);
        if (!!institutionId && !!payorPaysCurrencyCode) {
            setInstitutionCustomerBalance(balance);
            setAccountRoutingType(() => {
                setOpen(true);
                if (bankAccount?.routingType) {
                    return bankAccount?.routingType;
                }
                return AccountRoutingType.CreditCard;

            })
            getRate({
                variables: {
                    input: {
                        institutionId: institutionId,
                        payeeReceives: {
                            amount: "" + amountRemaining,
                            currencyCode: balance?.amount.currencyCode
                        },
                        payorPaysCurrencyCode: payorPaysCurrencyCode
                    }
                }
            })

        }

    }, [currentBalancesData?.getCurrentBalances, getRate]);

    useEffect(() => {
        if (!!data) {
            setRate(data.getTransactionAmounts);
        }
    }, [data]);

    const paymentReference = `${institutionCustomerBalance?.externalId} (${institutionCustomerBalance?.description})`;
    const currencyFormatter = useGetCurrencyFormatter();


    if (autoPayError || autoPayLoading || institutionError || institutionLoading || rateLoading) {
        return <ApolloGqlQueryDisplay
            loading={autoPayLoading || currentBalancesLoading || institutionLoading || rateLoading}
            error={autoPayError || currentBalancesError || institutionError}
        />
    }


    return (<>
            <Container maxWidth={"lg"}>
                <Grid container>
                    <Grid item xs={12}>
                        {institutionData && balances && balances.length > 0 &&
                            <Box className={classes.boxContainer}>
                                {balances
                                    .map(value => value as InstitutionCustomerBalance)
                                    .sort(sortBalanceByPullDateThenByCreatedDesc)
                                    .map(balance => {
                                        const dueDate = new Date(parse(balance.pullDate as string, 'yyyy-MM-dd', new Date())).toLocaleDateString();
                                        const autopayConfig = autoPayData?.getAutoPayConfigurations.find(institutionAutopay => institutionAutopay.institution.institutionId === balance.institutionId)
                                        const balanceInstitution = institutionData.institutions.find(value => value && (value.institutionId === balance.institutionId));
                                        let amountRemaining = +balance.amount.amount;
                                        if (balance.transactions && balance.transactions.length > 0) {
                                            const totalPaidSoFar = balance.transactions.map(value => +value.payeeReceives.amount)
                                                .reduce((aAmt, bAmt) => {
                                                    return aAmt + bAmt;
                                                });
                                            amountRemaining = +balance.amount.amount - totalPaidSoFar;
                                        }


                                        return (
                                            <Accordion
                                                key={balance.institutionCustomerBalanceId}
                                                className={`${classes.root} ${expanded === balance.institutionCustomerBalanceId ? "" : classes.expanded}`}
                                                expanded={expanded === balance.institutionCustomerBalanceId}
                                                onChange={() => setExpanded(expanded === balance.institutionCustomerBalanceId ? false : balance.institutionCustomerBalanceId)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls={`panel${balance.institutionCustomerBalanceId}-content`}
                                                    id={`panel${balance.institutionCustomerBalanceId}-header`}
                                                    className={classes.summary}
                                                >
                                                    <Grid container direction={"row"} justifyContent={"space-between"}>
                                                        <Grid item>
                                                            <Typography
                                                                className={classes.heading}>{balance.description}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                className={classes.balance}> {currencyFormatter.currencyFormatter(balance.amount.currencyCode).format(amountRemaining)}
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>


                                                </AccordionSummary>
                                                <AccordionDetails className={classes.details}>
                                                    {balanceInstitution &&
                                                        <BalanceCard
                                                            balance={balance as InstitutionCustomerBalance}
                                                            dueDate={dueDate}
                                                            autoPayConfiguration={autopayConfig}
                                                            showAccounts={() => push("/paymentSettings")}
                                                            institution={balanceInstitution}
                                                            accountPayment={accountPaymentInit}
                                                            amountRemaining={amountRemaining}
                                                            customer={customer}
                                                        />
                                                    }
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                            </Box>
                        }
                    </Grid>
                </Grid>

                <ShiftModal maxWidth={"sm"} open={open} accepted={() => console.log("confirmed!!!")}
                            close={() => setOpen(false)} content={
                    <>
                        {accountRoutingType &&
                        rate &&
                        institution &&
                        selectedAccountCurrency &&
                        amountRemaining &&
                        institutionCustomerBalance ?
                            <>
                                <TransactionSummary
                                    institution={institution}
                                    paymentMethod={accountRoutingType}
                                    editStep={() => {
                                    }}
                                    creditCard={paymentMethod?.creditCard || undefined}
                                    payorPaysAmount={rate.payorPaysAmount.amount}
                                    targetCurrency={institutionCustomerBalance.amount.currencyCode}
                                    selectedAccountCurrency={selectedAccountCurrency}
                                    payeeReceivesAmount={amountRemaining + ""}
                                    paymentReference={paymentReference}
                                    rate={+rate.savedExchangeRate.payeeReceivesRate.amount}
                                    error={error}
                                    hideEditIcon={true}
                                />
                                <TransactionConfirmation
                                    account={accountRoutingType !== AccountRoutingType.CreditCard ? paymentMethod?.bankAccount as BankAccountDetail : undefined}
                                    creditCard={paymentMethod?.creditCard || undefined}
                                    routingType={accountRoutingType}
                                    backButtonAction={function (): void {
                                        setOpen(false)
                                    }}
                                    acceptRecurringPaymentTerms={false}
                                    rateExpiredButtonAction={function (): void {
                                        setOpen(false)
                                    }}
                                    rateExpiryInMinutes={rate.savedExchangeRate.validForInMinutes}
                                    paymentInput={{
                                        rateId: rate.savedExchangeRate.id,
                                        payeeReceives: `${amountRemaining}`,
                                        institutionId: institutionCustomerBalance?.institutionId,
                                        paymentReference: paymentReference
                                    }}
                                    invoiceId={undefined}
                                    institutionCustomerBalanceId={institutionCustomerBalance.institutionCustomerBalanceId}
                                />
                            </>

                            :
                            <ApolloGqlQueryDisplay loading={true} error={error}/>

                        }
                    </>
                } title={"Account Balance Payment"}/>
            </Container>
        </>

    );
};

export default BalanceDisplay;
