import {gql} from "@apollo/client";

export const getUserInstitutionsAndUnits = gql`
    query getUserInstitutionsAndUnits {
        userInstitutions: getCustomerInstitutions {
            units
            id
            name
            acceptRecurringPaymentTerms
        }
    }
`;

const saveNewInstitutionForCustomer = gql`
    mutation saveNewInstitutionForCustomer($institutionId: String!) {
        saved: saveNewInstitutionForCustomer(institutionId: $institutionId)
    }
`;
