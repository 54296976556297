import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {colors, Container, Divider} from '@material-ui/core';
import  Section  from '../components/landingPage/components/Section';
import   About from '../components/landingPage/components/About';
import Faq from '../components/landingPage/components/Faq';
import {Hero} from '../components/landingPage/components/Hero/';
import Services from '../components/landingPage/components/Services';

import {
  services,
  about,
  faq,
} from "../../src/components/landingPage/data/data"

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    background: '#F6F9FA',
    borderBottomRightRadius: '100%',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}));

const HoaMarketingLanding = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Container maxWidth={"lg"}>
      <Section className={classes.pagePaddingTop}>
        <Hero />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <Services data={services} />
      </Section>
      <Divider />
      <div className={classes.shape}>
        <Section className={classes.sectionNoPaddingTop}>
          <About data={about} />
        </Section>
      </div>

        <Section className={classes.sectionNoPaddingTop}>
          <Faq data={faq} />
        </Section>
    </Container>
  );
};

export default HoaMarketingLanding;