import {TransactionStatusEventType} from "../data/graphqlTypes";


const dealStatusStyles = {
    dealStatusActionRequired: {
        color: "#d22b2b"
    },
    dealStatusCancelled: {
        color: "#808080"
    },
    dealStatusPending: {
        color: "#f67418"
    },
    dealStatusComplete: {
        color: "#4cd038"
    }
};

export function getStyleForStatus(status: TransactionStatusEventType): {} {
    switch (status) {
        case TransactionStatusEventType.Cancelled:
            return dealStatusStyles.dealStatusCancelled;
        case TransactionStatusEventType.Nsf:
        case TransactionStatusEventType.InvalidAccount:
        case TransactionStatusEventType.Chargeback:
            return dealStatusStyles.dealStatusActionRequired;
        case TransactionStatusEventType.Completed:
            return dealStatusStyles.dealStatusComplete;
        default:
            return dealStatusStyles.dealStatusPending;
    }
}