import {
    CustomerProfileInput,
    useCreateCustomerProfileMutation,
    useUpdateCustomerProfileMutation,
} from "../../data/graphqlTypes";
import {getCustomerProfile} from "./EntityGraphQL";
import {useCallback} from "react";

export function useEditableEntity() {
    const [createCustomerProfile, {
        data,
        loading,
        error
    }] = useCreateCustomerProfileMutation();
    const [updateCustomerProfile, {
        data: upData,
        loading: upLoading,
        error: upError
    }] = useUpdateCustomerProfileMutation();

    const updateCustomer = useCallback(async (input: CustomerProfileInput): Promise<void> => {
        const userProfileInput: CustomerProfileInput = {
            firstName: input.firstName,
            lastName: input.lastName,
            countryCode: input.countryCode,
            regionCode: input.regionCode,
            mobile: input.mobile,
            address: input.address,
            postalCode: input.postalCode,
            city: input.city,
            institutionId: input.institutionId
        };
        try {
            const fetchResult = await updateCustomerProfile({
                variables: {customerProfileInput: userProfileInput},
                refetchQueries: [{query: getCustomerProfile}]
            });
            const customer = fetchResult.data && fetchResult.data.profile;
            if (!customer || !customer.firstName) {
                return Promise.reject("entity not updated");
            }
        } catch (e) {
            return Promise.reject(e);
        }

    }, [updateCustomerProfile]);

    const createCustomer = useCallback(async (input: CustomerProfileInput): Promise<void> => {
        try {
            const fetchResult = await createCustomerProfile({
                variables: {customerProfileInput: input},
                refetchQueries: [{query: getCustomerProfile}]
            });
            const entityId = fetchResult.data && fetchResult.data.profile;
            if (!entityId) {
                return Promise.reject("entity not created");
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }, [createCustomerProfile]);

    return {
        createEntity: createCustomer,
        updateEntity: updateCustomer,
        data: data || upData,
        loading: loading || upLoading,
        error: error || upError
    };
}
