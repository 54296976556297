import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import React from "react";

interface ControlledAutoCompleteProps<T extends object, K extends keyof T, D extends keyof T> {
    focused: boolean;
    loading: boolean;
    value: T | undefined;
    optionArray: T[];
    valueChangeCallback: (value: T) => void;
    label: string;
    displayLabel: D;
    arrayKey: K;
    disabled?: boolean;
    errorMessage?: React.ReactNode;
    autocompleteHintText?: string;

}

function ControlledAutoComplete<T extends object, K extends keyof T, D extends keyof T>(
    {
        focused,
        loading,
        value,
        optionArray,
        valueChangeCallback,
        label,
        displayLabel,
        arrayKey,
        disabled,
        errorMessage,
        autocompleteHintText
    }: ControlledAutoCompleteProps<T, K, D>
) {
    const setSelected = (value: T) => {
        valueChangeCallback(value);
    };

    function getOptionFromArray(newInputValue: string) {
        const selected = optionArray.find(value => String(value[arrayKey]) === newInputValue);
        if (selected) {
            setSelected(selected);
        }

    }

    return (
        <Autocomplete<T>
            id={label}
            loading={loading}
            value={value}
            options={optionArray}
            disabled={disabled || false}
            getOptionLabel={(option: T) => String(option[displayLabel])}
            getOptionSelected={(option, value1) => {
                // console.log(arrayKey+"<>", option, value1)
                return option[arrayKey] === value1[arrayKey];
            }}
            onChange={(event: any, newValue: T | null) => {
                if (newValue) {
                    setSelected(newValue);
                }
            }}
            onInputChange={(event, newInputValue) => {
                getOptionFromArray(newInputValue);
            }}
            renderInput={(params) =>
                <TextField autoFocus={focused}
                           {...params}
                           inputProps={{...params.inputProps, autoComplete: autocompleteHintText || 'new-password'}}
                           label={label}
                           variant="outlined"
                           helperText={errorMessage}
                           error={!!errorMessage}

                />}
        />
    );
}

export default ControlledAutoComplete;