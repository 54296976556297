import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import {BrowserRouter as Router} from "react-router-dom";

import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {AuthProvider} from "./components/auth";
import ShiftApolloProvider from "./components/data/ShiftApolloProvider/ShiftApolloProvider";

ReactDOM.render(
    <Router>
        <AuthProvider>
            <ShiftApolloProvider>
                <App/>
            </ShiftApolloProvider>
        </AuthProvider>
    </Router>,
    document.getElementById("root")
);

