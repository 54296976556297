import {AccountRoutingType, BankAccountDetail, CreditCard} from "../data/graphqlTypes";

export function accountAndRoutingTypeIsValid(account: BankAccountDetail | undefined,
                                             creditCard: CreditCard | undefined,
                                             routingType: AccountRoutingType | undefined) {
    switch (routingType) {
        case (AccountRoutingType.AchDebit):
        case (AccountRoutingType.Pad):
            return !!account?.id;
        case (AccountRoutingType.CreditCard) :
            return !!creditCard?.cardNumber;
        default:
            return !!routingType;
    }
}

export function isDirectDebit(routingType: AccountRoutingType | undefined) {
    return !!routingType && routingType === AccountRoutingType.Pad || routingType === AccountRoutingType.AchDebit;
}

