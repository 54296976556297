import {Grid} from "@material-ui/core";
import React, {useContext, useState} from "react";
import {DirectDebitPaymentOption} from "./DirectDebitPaymentOption";
import {HomeLocationContext} from "../context/HomeLocationContext";
import useGetCountriesAndCurrencies from "../data/graphql/getCountriesAndCurrencies";

type PropsType = {
    setIsPaymentOptionSaved?: any;
}
const DirectDebitEditor = (props: PropsType) => {

    const [editMode, setEditMode] = useState<boolean>(false);
    const {countryCode} = useContext(HomeLocationContext);

    const {getCountryObject} = useGetCountriesAndCurrencies();
    const country = getCountryObject(countryCode);
    return <>
        <Grid item xs={12}>
            {country && country.defaultCurrency &&
                <DirectDebitPaymentOption
                    currencyOfNewAccounts={country.defaultCurrency.currencyCode}
                    accountId={undefined}
                    setEditMode={setEditMode}
                    editMode={editMode}
                    payment={undefined}
                    formatter={new Intl.NumberFormat()}
                    selectedAccountCurrency={country.defaultCurrency.currencyCode}
                    setIsPaymentOptionSaved={props.setIsPaymentOptionSaved}
                />
            }
        </Grid>
    </>
}

export default DirectDebitEditor;
