import {Avatar, Box, Button, Card, Grid, Typography, useMediaQuery} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import ApolloGqlQueryDisplay from "../components/data/ApolloGqlQueryDisplay";
import {useGetInstitution} from "../components/data/graphql/useGetInstitution";
import SettingsIcon from '@material-ui/icons/Settings';
import PaymentIcon from '@material-ui/icons/Payment';
import ListIcon from '@material-ui/icons/List';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {storeInstitutionIdInLocalStorage} from "../utilities/localstorage/InstitutionContextStore";
import {useGetCurrentBalancesQuery} from "../components/data/graphqlTypes";
import useGetCurrencyFormatter from "../utilities/useGetCurrencyFormatter";


export const useStyles = makeStyles((theme) => ({
    root2: {
        minWidth: '325px',
        height: '300px',
        position: 'relative',
        boxShadow: `8px 8px 15px #a3a3a3, 
                    -8px -8px 15px #ffffff`,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '10px',
        overflow: 'hidden',
        backgroundColor: "#e0e0e0",
        boxSizing: 'border-box',
        margin: 0,
        "&:hover": {
            boxShadow: `inset 5px 5px 10px #a3a3a3, 
                    inset -5px -5px 10px #ffffff`,
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: '275px',
            height: '250px',
        },
        [theme.breakpoints.down('md')]: {
            minWidth: '375px',
            height: '185px',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '300px',
            height: '165px',
        },
    },
    icon: {
        margin: '10px',
        color: '#fff',
        backgroundColor: "#4D3B71",
        fontSize: '2.5rem',
        boxShadow: `inset 5px 5px 10px #a3a3a3, 
                    inset -5px -5px 10px #ffffff`,
    },
    cardContent: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
    },
    cardContainer: {
        minWidth: "95vw",
        marginTop: '1vh',
        borderColor: 'transparent',
        backgroundColor: "#fff",
        padding: "10px",
        borderRadius: '15px',
        boxShadow: `inset 4px 4px 8px #bebebe, 
                inset -4px -4px 8px #ffffff`,
        [theme.breakpoints.up('sm')]: {
            minWidth: "90vw",
            marginTop: '5vh',
            marginBottom: '5vh',
            padding: "40px",
        },
        [theme.breakpoints.up('xl')]: {
            minWidth: "80vw",
            marginTop: '5vh',
            marginBottom: '5vh',
            padding: "40px",
        },
    },
    balanceContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        width: '100%',
    },
    balanceTypography: {
        fontWeight: 'bold',
    },

    imageLogo: {
        width: "100%",
        height: "100%",
        maxWidth: '175px',
        maxHeight: '175px',
        marginBottom: '20px',
        borderColor: 'transparent',
        "&:hover $focusHighlight": {
            opacity: 0
        }

    },
}));


interface Params {
    hoaCode: string;
}


const HoaLanding = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [balance, setBalance] = useState<string>()
    let {hoaCode}: Params = useParams();
    const {currencyFormatter} = useGetCurrencyFormatter();
    const {
        institution,
        loading,
        error
    } = useGetInstitution(hoaCode);

    const {
        data,
        loading: balanceLoading,
        error: balanceError
    } =
        useGetCurrentBalancesQuery();

    const balances = data?.getCurrentBalances;
    useEffect(() => {

        let total = "";
        if (institution && balances && balances.length > 0) {
            institution.acceptedCurrencies
                .map(value => value.currencyCode)
                .flatMap(value => {
                        const sum = balances
                            .filter(value1 => !!value1.amount.amount && value1.amount.currencyCode === value && value1.institutionId === institution.institutionId)
                            .map(value => +value.amount.amount)
                            .reduce((amount1, amount2) => amount1 + amount2, 0);
                        const numberFormat = currencyFormatter(value);
                        const abalance = numberFormat.format(sum);
                        setBalance(abalance);
                    }
                )
        }
    }, [balances, institution]);

    useEffect(() => {
        if (institution) {
            storeInstitutionIdInLocalStorage(institution.institutionId);
        }
    }, [institution]);

    if (error || balanceError || loading || balanceLoading) return <ApolloGqlQueryDisplay error={error || balanceError}
                                                                                          loading={loading || balanceLoading}/>;

    return (
        <Grid container direction="column" alignItems="center">
            <Card className={classes.cardContainer} raised={true}>
                <Box className={classes.balanceContainer}>
                    <Avatar aria-label="Account Logo" variant="square" className={classes.imageLogo}
                            src={institution?.logoUrl}>
                    </Avatar>
                    {balance ? (
                        <Box>
                            <Typography variant={isSmallScreen ? "subtitle1" : "h2"}
                                        className={classes.balanceTypography}>
                                {`Balance: ${balance}`}
                            </Typography>
                            <Link to={`/balanceHistory`} style={{textDecoration: 'none', width: '100%'}}>
                                <Button size={isSmallScreen ? "small" : "large"} variant="outlined" fullWidth>
                                    View Details
                                </Button>
                            </Link>
                        </Box>
                    ) : (
                        <ApolloGqlQueryDisplay loading={balanceLoading}/>
                    )}
                </Box>
                <Grid container direction="row"
                      spacing={3}>
                    {[
                        {
                            id: "balance",
                            icon: <PaymentIcon/>,
                            title: ``,
                            subheader: "Make Payment",
                            link: `/payment/${institution?.institutionId}`
                        },
                        {
                            id: institution?.institutionId,
                            icon: <AccountBalanceIcon/>,
                            title: ``,
                            subheader: "Payment Settings",
                            link: `paymentSettings`
                        },
                        {
                            id: "settings",
                            icon: <SettingsIcon/>,
                            title: '',
                            subheader: "User Profile",
                            link: `/userProfile`
                        },
                        {
                            id: "transactions",
                            icon: <ListIcon/>,
                            title: '',
                            subheader: "Transaction History",
                            link: `/history`
                        },
                    ].map((cardInfo) => (
                        <Grid item xs={12} sm={6} lg={3} key={cardInfo.id}
                              style={{display: 'flex', justifyContent: 'center'}}>

                            <Link
                                to={cardInfo.link}
                                style={{textDecoration: 'none'}}
                            >
                                <Card className={classes.root2}>
                                    <div className={classes.cardContent}>
                                        <Avatar aria-label="Account Logo" className={classes.icon}>
                                            {cardInfo.icon}
                                        </Avatar>
                                        <Typography variant={isSmallScreen ? "subtitle1" : "h6"} align="center">
                                            {cardInfo.title}
                                        </Typography>
                                        <Typography variant={isSmallScreen ? "subtitle2" : "h2"} align="center">
                                            {cardInfo.subheader}
                                        </Typography>
                                    </div>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Card>
        </Grid>
    );
}

export default HoaLanding;
