import {Avatar, Card, Divider, Grid, Typography} from "@material-ui/core";
import React from "react";
import {BankAccountDetail} from "../data/graphqlTypes";
import {PAYMENT_OPTION_STYLES} from "../PaymentOptions/ETranferInstructions";
import {useFormatPayment} from "../PaymentOptions/GetFormattedTotalFees";
import {Payment} from "../Fees/useCalculateFees";
import DirectDebitAccounts from "./DirectDebitAccounts";

type PropsType = {
    setAccount?: (accountId: BankAccountDetail | undefined) => void;
    currencyOfNewAccounts: string;
    accountId: string | undefined;
    setEditMode: (editingNow: boolean) => void;
    editMode: boolean;
    formatter: Intl.NumberFormat;
    payment: Payment | undefined;
    autoSelectCurrency?: string;
    selectedAccountCurrency: string | undefined;
    setIsPaymentOptionSaved?: any;
}

export const DirectDebitPaymentOption = (props: PropsType) => {
    const paymentOptionStyle = PAYMENT_OPTION_STYLES();

    const {formattedTotalFees} = useFormatPayment(props.formatter, props.payment);

    return <Grid item xs={12}>
        <Card className={paymentOptionStyle.card}>
            <div className={paymentOptionStyle.header}>
                <Avatar
                    className={paymentOptionStyle.avatar}
                    src={"/Images/AvatarAccounts.png"}
                />
                {props.payment &&
                    <Typography className={paymentOptionStyle.head}>
                        Fees: {formattedTotalFees}
                    </Typography>
                }
            </div>
            <Divider className={paymentOptionStyle.divider}/>
            <DirectDebitAccounts
                setAccount={props.setAccount}
                currencyOfNewAccounts={props.currencyOfNewAccounts}
                autoSelectCurrency={props.autoSelectCurrency}
                accountId={props.accountId}
                setEditMode={props.setEditMode}
                editMode={props.editMode}
                selectedAccountCurrency={props.selectedAccountCurrency}
                setIsPaymentOptionSaved={props.setIsPaymentOptionSaved}
            />
        </Card>
    </Grid>
}
