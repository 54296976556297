import React, {useContext, useState} from "react";
import {
    AutoPayConfiguration,
    AutoPayConfigurationInput,
    BankAccountDetail,
    CreditCard,
    InstitutionCustomerBalance,
    useArchiveAutoPayConfigurationMutation,
    useSaveAutoPayConfigurationMutation,
} from "../data/graphqlTypes";
import {Button, Card, Grid, Typography} from "@material-ui/core";
import {Image} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {ShiftModal} from "../input/ShiftModal";
import CreditCards from "../PaymentOptions/CreditCard/CreditCards";
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";
import CreditCardDisplay from "../PaymentOptions/CreditCard/CreditCardDisplay";
import {getAutoPayConfigurations} from "../data/graphql/autopay.graphql";
import DirectDebitAccounts from "../DirectDebit/DirectDebitAccounts";
import {HomeLocationContext} from "../context/HomeLocationContext";
import useGetCountriesAndCurrencies from "../data/graphql/getCountriesAndCurrencies";
import BankAccountDisplay from "../DirectDebit/Account/BankAccountDisplay";
import {
    CREDIT_CARD_AUTO_PAY_TERMS,
    CREDIT_CARD_AUTO_PAY_TERMS_TITLE,
    DIRECT_DEBIT_AUTO_PAY_TERMS,
    DIRECT_DEBIT_AUTO_PAY_TERMS_TITLE,
} from "./autoPayTerms";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";

export const useStyles = makeStyles((theme) => ({
    creditCardCard: {
        maxWidth: "600px",
        margin: "2vh 60px 3vh",
        padding: "5px",
        borderRadius: 6,
        borderColor: "transparent",
        [theme.breakpoints.down("sm")]: {
            margin: "2vh 0px 3vh",
        },
        position: "relative",
    },
    creditCardCard2: {
        minWidth: "600px",
        minHeight: "100%",
        padding: "5px",
        borderRadius: 6,
        [theme.breakpoints.down("sm")]: {
            margin: "2vh 0px 3vh",
            minWidth: "200px",
        },
        position: "relative",
    },
    imageLogo2: {
        maxHeight: "125px",
        maxWidth: "125px",
        borderColor: "transparent",
    },
    topRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
    },
    topRight: {
        position: "absolute",
        top: "10px",
        right: "10px",
        textAlign: "right",
    },
    amount: {
        fontWeight: "bold",
    },
    payNowLink: {
        textDecoration: "none",
        color: theme.palette.primary.main,
        display: "block",
    },
}));

type PropsType = {
    autoPayConfig: AutoPayConfiguration;
    accountBalances: Array<InstitutionCustomerBalance>;
    accountPaymentCallback: (
        institutionCustomerBalanceId: string,
        autoPayAccountId: string
    ) => Promise<void>;
};

const AutoPayConfigurationDisplay = (props: PropsType) => {
    const classes = useStyles();
    const {countryCode} = useContext(HomeLocationContext);
    const {getCountryObject} = useGetCountriesAndCurrencies();
    const country = getCountryObject(countryCode);

    const [showEnableAutoPayModal, setShowEnableAutoPayModal] = useState<boolean>(
        false
    );
    const [selectedCreditCard, setSelectedCreditCard] = useState<CreditCard>();
    const [selectedBankAccount, setSelectedBankAccount] =
        useState<BankAccountDetail>();
    const [showCreditCardTerms, setShowCreditCardTerms] = useState<boolean>(
        false
    );
    const [showBankAccountTerms, setShowBankAccountTerms] = useState<boolean>(
        false
    );
    const [editMode, setEditMode] = useState<boolean>(false);

    const institution = props.autoPayConfig.institution;

    const [saveAutoPayConfiguration, {loading: saveAutoPayLoading, error: saveAutoPayError}] =
        useSaveAutoPayConfigurationMutation();

    const [archiveAutoPayConfiguration, {loading: deleteLoading, error: deleteError}] =
        useArchiveAutoPayConfigurationMutation();

    const {currencyFormatter} = useGetCurrencyFormatter();

    function saveNewAutoPay(autoPayConfigInput: AutoPayConfigurationInput) {
        saveAutoPayConfiguration({
            variables: {
                autoPayConfigInput: autoPayConfigInput,
            },
            refetchQueries: [{query: getAutoPayConfigurations}],
        }).catch(() => {
            /* do nothing. Errors handled elsewhere. */
        });
    }

    function deleteAutoPayConfig() {
        archiveAutoPayConfiguration({
            variables: {
                institutionId: props.autoPayConfig.institution.institutionId,
            },
            refetchQueries: [{query: getAutoPayConfigurations}],
        }).catch(() => {
            /* do nothing. error handled elsewhere. */
        });
    }

    const autoPayContent = (
        <>
            <Typography>Choose a credit card or bank account</Typography>
            <CreditCards
                selectedCard={selectedCreditCard}
                setSelectedCreditCard={(selectedCard) => {
                    setSelectedCreditCard(selectedCard);
                    setShowCreditCardTerms(true);
                }}
                institutionId={institution.institutionId}
            />
            {!!country && !!country.defaultCurrency && (
                <DirectDebitAccounts
                    currencyOfNewAccounts={country.defaultCurrency.currencyCode}
                    accountId={selectedBankAccount?.id}
                    setEditMode={setEditMode}
                    editMode={editMode}
                    selectedAccountCurrency={undefined}
                    setAccount={(selectedAccount) => {
                        setSelectedBankAccount(selectedAccount);
                        setShowBankAccountTerms(true);
                    }}
                />
            )}
        </>
    );

    const autoPayCreditCard = props.autoPayConfig.creditCard;
    const autoPayBankAccount = props.autoPayConfig.bankAccount;
    const balances = props.accountBalances.filter((autoPayBankAccount) => {
        return (
            autoPayBankAccount.institutionId === institution.institutionId
        );
    });

    return (
        <Card className={classes.creditCardCard} key={institution.institutionId}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Image
                        className={classes.imageLogo2}
                        src={institution.logoUrl}
                        alt={institution.name}
                        thumbnail
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={8}>
                    {autoPayCreditCard || autoPayBankAccount ? (
                        <>
                            {autoPayCreditCard && (
                                <CreditCardDisplay
                                    card={autoPayCreditCard}
                                    deleteCard={deleteAutoPayConfig}
                                    selectCard={() => {
                                    }}
                                    selected={true}
                                />
                            )}
                            {autoPayBankAccount && (
                                <BankAccountDisplay
                                    setAccount={() => {
                                    }}
                                    account={autoPayBankAccount}
                                    selectable={false}
                                    selected={true}
                                    currencyMatch={true}
                                    deleteAccount={deleteAutoPayConfig}
                                />
                            )}
                            <Typography>
                                This is the autopay method you currently have on file. If you'd like to change your
                                payment details or use an alternative payment method, delete your current method and
                                enter your new details.
                            </Typography>
                        </>
                    ) : (
                        <Grid container direction="column" alignItems="center" justifyContent="center">
                            {(saveAutoPayError || saveAutoPayLoading || deleteError || deleteLoading) ? (
                                <ApolloGqlQueryDisplay
                                    loading={saveAutoPayLoading || deleteLoading}
                                    error={saveAutoPayError || deleteError}
                                />
                            ) : (
                                <>
                                    <Typography>
                                        You haven't signed up for autopay yet. If you'd like to opt for a hands-off
                                        approach to your HOA payments, all you need to do is select a payment method to
                                        be automatically charged when payments are due. This way, you don't have to
                                        worry about a thing, and we'll take care of your payments for you.
                                    </Typography>
                                    <Button
                                        size="small"
                                        onClick={() => setShowEnableAutoPayModal(true)}
                                    >
                                        Enable AutoPay
                                    </Button>
                                </>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <ShiftModal
                open={showEnableAutoPayModal}
                accepted={() => setShowEnableAutoPayModal(false)}
                close={() => setShowEnableAutoPayModal(false)}
                content={autoPayContent}
                title={`${institution.name} Auto Pay`}
            />
            {selectedCreditCard && (
                <ShiftModal
                    open={showCreditCardTerms}
                    accepted={async () => {
                        await saveNewAutoPay({
                            institutionId: institution.institutionId,
                            creditCardId: selectedCreditCard.creditCardId,
                        });
                        setShowCreditCardTerms(false);
                        setShowEnableAutoPayModal(false);
                    }}
                    close={() => setShowCreditCardTerms(false)}
                    content={<Typography>{CREDIT_CARD_AUTO_PAY_TERMS}</Typography>}
                    title={<Typography>{CREDIT_CARD_AUTO_PAY_TERMS_TITLE}</Typography>}
                    acceptButtonText={"Accept"}
                    closeButtonText={"Decline"}
                />
            )}
            {selectedBankAccount && (
                <ShiftModal
                    open={showBankAccountTerms}
                    accepted={async () => {
                        await saveNewAutoPay({
                            institutionId: institution.institutionId,
                            bankAccountId: selectedBankAccount?.id,
                        });
                        setShowBankAccountTerms(false);
                        setShowEnableAutoPayModal(false);
                    }}
                    close={() => setShowBankAccountTerms(false)}
                    content={<Typography>{DIRECT_DEBIT_AUTO_PAY_TERMS}</Typography>}
                    title={<Typography>{DIRECT_DEBIT_AUTO_PAY_TERMS_TITLE}</Typography>}
                    acceptButtonText={"Accept"}
                    closeButtonText={"Decline"}
                />
            )}
        </Card>
    );
};

export default AutoPayConfigurationDisplay;
