import {ApolloClient, ApolloLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {getHttpLink} from "./apolloLink";
import {onError} from "@apollo/client/link/error";
import {is401NetworkError, isNetworkErrorWithMessageInvalidState} from "./helper/errorsUtilities";
import {RetryLink} from "@apollo/client/link/retry";
import {tokenIsActive} from "../components/data/ShiftApolloProvider/ShiftApolloProvider";


export const cache = new InMemoryCache();

export function createApolloClient(token: string | undefined, refreshTokenIfAuthenticated: () => Promise<string | void>) {

    const link = ApolloLink.from([
        getRetryLink(),
        getErrorHandlingLink(refreshTokenIfAuthenticated),
        setAuthorizationHeader(token, refreshTokenIfAuthenticated),
        getHttpLink()
    ]);

    return new ApolloClient({
        link: link,
        cache: cache
    });

}

const retryExemptions = [
    'createNonRegulatedUserProfile',
    'createAccountForUser',
    'submitTransaction',
    'submitCreditCardTransaction'
];
const getRetryLink = () => {
    return new RetryLink({
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true
        },
        attempts: {
            max: 2,
            retryIf: (error, _operation) => {
                console.error("error: ", error);
                console.error("if above error is 401: Unauthorized, use it to add a condition to the retryIf in createApolloClient.ts");
                const operationName = _operation.operationName;
                const shouldRetryOperation = !retryExemptions.find(value => value === operationName);
                return !!error && shouldRetryOperation;
            }
        }
    });
}


const getErrorHandlingLink = (refreshTokenIfAuthenticated: () => Promise<string | void>): ApolloLink => {
    return onError(({networkError, graphQLErrors}) => {
        if (networkError) {
            if (is401NetworkError(networkError) || isNetworkErrorWithMessageInvalidState(networkError)) {
                refreshTokenIfAuthenticated();
                console.error(`token deleted?  Retry!?! [Network error]: ${networkError}`);
            }
            console.log(`[Network error]: ${networkError}`);
        }
        if (graphQLErrors) {
            const find = graphQLErrors.find(x => {
                    return x.message === '401: Unauthorized' ||
                        x.message.includes('Access is denied')
                }
            );
            if (find) {
                console.error("REFRESHING TOKEN ON ERROR. SHOULD NOT HAPPEN UNDER NORMAL CIRCUMSTANCES. MIGHT WANT TO INVESTIGATE THIS.")
                refreshTokenIfAuthenticated();
            }
            console.error('[GraphQlError error]: ', graphQLErrors);
        }
    });
}


function setAuthorizationHeader(token: string | undefined, refreshTokenIfAuthenticated: () => Promise<string | void>): ApolloLink {
    return setContext(async (_, {headers}) => {
        let auth0Token = token;
        if (!!token && !tokenIsActive(auth0Token)) {
            const newToken = await refreshTokenIfAuthenticated();
            if (!newToken) {
                throw Error("Could not retrieve access token from auth0");
            }
            auth0Token = newToken;
        }
        return {
            headers: {
                ...headers,
                ...(auth0Token ? {authorization: `Bearer ${auth0Token}`} : {}),
            }
        }
    });
}