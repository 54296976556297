import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#913AFF',
        },
        secondary: {
            main: '#2E2343',
            light: "#fafafa"
        },
        background:{
            default: '#333333',
        },
    },
    typography: {
        fontFamily: "Montserrat, sans-serif",
        h1:{
            fontSize: 30,
            fontWeight: 700,
            margin:24,
        },
        h2:{
            fontSize: 24,
            fontWeight: 700,
        },
        h5:{
            fontSize: 12,
            fontWeight:700,
        },
        h6:{
            fontSize: 16,
            fontWeight: 700,

        },
        subtitle1: {
            fontSize: 12,
        },
        subtitle2:{
            fontSize: 10, 
            fontWeight:400, 
        },
        body1: {
            fontWeight: 500,
        },
        body2: {
            fontSize: 14,
            fontWeight: 500,
        },

        
        button: {
            fontSize: 18,
            fontWeight:700,
        },
    },



    overrides: {
        MuiButton: {
            root: {
                textTransform: "none",
                margin: "10px",
                padding: "15px",
            },
            text :{
                '&:focus': {
                    outline:'transparent',
                    color: '#4D3B71',
                },
            },
            fullWidth: {
                maxWidth: "350px"
            },
            sizeLarge: {
                margin: "10px",
                padding: "15px 50px"
            }
        },
        MuiLink:{
            root:{
                fontFamily: "Messina Sans, sans-serif",
                color: '#4d3b71',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 700,
                textDecoration: 'none',
                textDecorationColor: 'fff',
                          
            "&:hover": {
                color: '#B728FF',
            }
        } 
        },
        MuiCard:{
            root:{
                display: 'flex',
                flexDirection: 'column',
                margin: '5px',
                maxWidth: 170,
                border: '1px solid',
                borderColor: '#B728FF',
                borderRadius: 6,
                transition: "0.3s",
                "&:hover": {
                    boxShadow: "0 16px 70px -12.15px rgba(0,0,0,0.3)"
                },
            },
        },


        MuiCardContent:{
            root: {
                display:'flex',
                flexDirection:'column',
                minHeight:95,
                paddingTop:10,
                paddingRight:20,
                paddingLeft:20,
                paddingBottom:0,
                "&:last-child": {
                    paddingBottom: 0
                  }
                
            },
        },
        MuiCardActions:{
            root:{
                display:'flex',
                flexDirection:'column',
                marginTop: 'auto',
                alignItems: 'flex-end',
                paddingTop:0,
                paddingRight:0,
                paddingLeft:0,
                paddingBottom:0,
                "&:last-child": {
                    paddingBottom: 0
                },
                
            },
        },

        MuiStepLabel: {
            label: {
                fontSize: 16,
                fontWeight:700,
                color: '#21252A',
                '&$active': {
                    color: '#913AFF',
                    fontWeight:'700'
                },
            },
        },
        MuiCardActionArea:{
            root:{
                focusHighlight: 'false',

                "&:hover $focusHighlight": {
                    opacity: 0
                },
                            },
        
        },

        MuiInputBase:{
            root:{
                fontFamily: "Montserrat, sans-serif",
                color:'#2e2343',
                fontSize: "14px"
            }
        },

        MuiInputLabel:{
            root:{
                fontFamily: "Montserrat, sans-serif",
                color:'#2e2343',
                fontSize: 16,
                fontWeight:500,
                backgroundColor:"white",
            }
        },

        MuiOutlinedInput :{
            root:{
                fontFamily: "Montserrat, sans-serif",
                color:'#2e2343',
                fontSize: 14,
                fontWeight:500,

            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
            },
        },

        MuiFormLabel: {
            root:{
                fontFamily: "Montserrat, sans-serif",
                color:'#2e2343',
                fontSize:28,
                fontWeight:300,

            }
        },
    },

    props: {
        MuiButton: {
            disableRipple:true,
            variant:"contained",
            color: "primary",

        }
    }



});



export default theme;