import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    AccountRoutingType,
    AutoPayConfiguration,
    AutoPayConfigurationInput,
    PfaTransactionDetail,
    useGetAutoPayConfigurationsQuery,
    useGetTransactionDetailQuery
} from "../components/data/graphqlTypes";
import TransactionDetailsDisplay from "../components/Transaction/TransactionDetailsDisplay";
import {getInstructionsComponent} from "./getInstructionsComponent";
import {Typography} from "@material-ui/core";
import AutoPayModal from "../components/autopay/AutoPayModal";

type ParamsType = {
    transactionId: string;
    routingType: string;
    routingId: string;
};

function isDirectDebitOrCreditCard(routingType: AccountRoutingType) {
    switch (routingType) {
        case AccountRoutingType.CreditCard:
        case AccountRoutingType.AchDebit:
        case AccountRoutingType.Pad:
            return true;
        default:
            return false;
    }
}

const TransactionDetailsWithAutoPayPrompt = () => {
    let {transactionId, routingType, routingId}: ParamsType = useParams();
    const {
        data: transactionData,
        loading
    } = useGetTransactionDetailQuery({variables: {transactionId}})

    // const lastInstitutionId = getLastInstitutionId();

    const {
        data: autoPayData,
        loading: autoPayLoading,
        error: autoPayError
    } = useGetAutoPayConfigurationsQuery();

    const [paymentInstructions, setPaymentInstructions] = useState<React.ReactNode>();
    const [autoPayConfigInput, setAutoPayConfigInput] = useState<AutoPayConfigurationInput>();

    const accountRoutingType = routingType as AccountRoutingType;
    const transactionResult = transactionData?.transactionDetails;
    const transaction = transactionResult?.transaction as PfaTransactionDetail;
    useEffect(() => {

        if (!loading && !!transactionResult && !!transaction && transactionResult.successful && autoPayData && !autoPayLoading) {
            setPaymentInstructions(getInstructionsComponent(transaction));
            let institutionId = transaction.institutionId;
            const autopayConfig: AutoPayConfiguration | undefined = autoPayData?.getAutoPayConfigurations
                .find(valueOf => {
                    return valueOf.institution.institutionId === institutionId
                });
            if (isDirectDebitOrCreditCard(accountRoutingType) && !!institutionId && !autopayConfig?.id) {
                setTimeout(() => {
                    loadAutoPayParams(institutionId);
                }, 2000);
            }
        }
    }, [transactionResult, transaction, loading, autoPayData, autoPayLoading]);

    function loadAutoPayParams(institutionId: string) {
        if (accountRoutingType === AccountRoutingType.CreditCard) {
            setAutoPayConfigInput(getCreditCardAutoPayConfigInput(institutionId))
        } else {
            setAutoPayConfigInput(getDirectDebitAutoPayConfigInput(institutionId))
        }
    }

    function getDirectDebitAutoPayConfigInput(institutionId: string) {
        return {
            institutionId: institutionId,
            bankAccountId: routingId
        };
    }

    function getCreditCardAutoPayConfigInput(institutionId: string) {
        return {
            institutionId: institutionId,
            creditCardId: routingId
        };
    }


    if (transactionResult && !transactionResult.successful) {
        return <Typography>Could not load transaction with ID: {transactionId}. Please contact support.</Typography>;
    }

    return (
        <>
            {transactionResult &&
                <TransactionDetailsDisplay
                    value={transactionResult.transaction as PfaTransactionDetail}
                    creditCardApprovalCode={transactionResult?.transaction?.creditCardProcessorApprovalCode as string}
                >
                    {paymentInstructions}
                </TransactionDetailsDisplay>
            }
            {!!autoPayConfigInput &&
                <AutoPayModal
                    open={!!autoPayConfigInput}
                    close={() => {
                        setAutoPayConfigInput(undefined);
                    }
                    }
                    autoPayConfigInput={autoPayConfigInput}
                />
            }
        </>
    );
}

export default TransactionDetailsWithAutoPayPrompt;