import React, {forwardRef} from "react";
import {Input, InputProps} from "@material-ui/core";
import PhoneInput from "react-phone-number-input";
import {CountryCode} from "libphonenumber-js/core";

const styles = {
    inputPadding: {
        flex: 1,
        padding: "12px 0px",
    },
    phoneInputPadding: {
        paddingLeft: "10px",
    }
};

const StyledPhoneInputField = forwardRef((props: InputProps, ref) => {

    return (
        <Input
            {...props}
            disableUnderline={true}
            inputRef={ref}
            fullWidth={true}
            style={styles.inputPadding}
        />
    );
});

type Props = {
    value: string;
    userSelectedCountryCode: string;
    valueChanged: (value: string | undefined) => void;
    fieldBlurred: () => void;
};

const FancyButFragileReactPhoneNumberInputBeWaryWaryCarefulInHere = (props: Props) => {

    const countryCodeUpper = !!props.userSelectedCountryCode ? props.userSelectedCountryCode.toUpperCase() : "";
    const countryCode: CountryCode = countryCodeUpper as CountryCode;

    return <PhoneInput
        inputComponent={StyledPhoneInputField}
        defaultCountry={countryCode}
        international={true}
        countryCallingCodeEditable={false}
        placeholder="Enter phone number"
        value={props.value}
        onBlur={props.fieldBlurred}
        flagUrl={`https://flagcdn.com/{xx}.svg`}
        onChange={props.valueChanged}
        style={styles.phoneInputPadding}
    />
}

export default FancyButFragileReactPhoneNumberInputBeWaryWaryCarefulInHere;