import React from "react";
import {format, parseISO} from 'date-fns';

import {
    Button,
    Card,
    Grid,
    Hidden,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import MessageBox from "../common/MessageBox";

import {FeeToCharge, PfaTransactionDetail, TransactionStatusEventType} from "../data/graphqlTypes";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import {useHistory} from "react-router-dom";
import translateTransactionStatus from "../TransactionHistory/translateTransactionStatus";
import {getStyleForStatus} from "./dealStatusStyles";
import {FeeDescriptionsByType} from "../Fees/FeeDescriptionsByType";

type PropsType = {
    value: PfaTransactionDetail;
    creditCardApprovalCode?: string;
    children: React.ReactNode;

};

const styles = {
    container: {
        padding: 10,
    },
    cardContainer: {
        minWidth: 200,
        maxWidth: 600,
        height: "auto",
        minHeight: "60vh",
        marginTop: "3vh",
        borderColor: "#000000",
        padding: 30,
    },
    header: {
        margin: 0,
        lineHeight: "32px",
        fontWeight: 700,
        letterSpacing: "-1px",
        fontSize: "24px",
    },
    headerNumber: {
        margin: 0,
        marginBottom: 25,
        lineHeight: "44px",
        fontWeight: 700,
        letterSpacing: "-1.5px",
        fontSize: "40px",
    },
    subHeading: {
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "-0.12px",
        fontWeight: 800,

    },
    amount: {
        fontWeight: 300,
        letterSpacing: "-.75px",
    },
    paymentDeclined: {
        color: '#d22b2b',
        marginBottom: 10,
    }
};


const tableStyles = {
    tableContainer: {
        marginTop: "2vh",
        marginBottom: '2vh',
        border: "1px solid",
        borderColor: "#4D3B71",
        maxWidth: 550,
        borderRadius: 5,
    },
    tableHeader: {
        backgroundColor: "#4D3B71",
        color: "fff",
    },
    cellBold: {
        fontSize: "14px",
        fontWeight: 700,
    },
    cellSecondBold: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#913AFF",
    },
    cellHead: {
        fontSize: "16px",
        fontWeight: 600,
        color: "white",

    },
};


function paymentIsDeclined(type: TransactionStatusEventType) {
    switch (type) {
        case TransactionStatusEventType.Nsf:
        case TransactionStatusEventType.InvalidAccount:
        case TransactionStatusEventType.Chargeback:
            return true;
        default:
            return false;
    }
}

const TransactionDetailsDisplay = (props: PropsType) => {
    const transaction = props.value;
    const formattedDate = format(parseISO(transaction.createdDate), 'MMMM dd, yyyy');
    const history = useHistory();
    const {currencyFormatter} = useGetCurrencyFormatter();
    let myAccountCurrencyNumberFormat = currencyFormatter(transaction.payorPaysTotal.currencyCode);
    let receiveCurrencyNumberFormat = currencyFormatter(transaction.payeeReceives.currencyCode);
    const transactionFees: Array<FeeToCharge> = transaction?.fees as Array<FeeToCharge>;
    return (
        <>
            {" "}
            <Paper>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12}>
                        <Card style={styles.cardContainer}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={8}>
                                    <Typography variant="h1" component="legend" style={styles.header}>
                                        Transaction
                                    </Typography>
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item sm={4}>
                                        <Button variant={"text"} size={'small'}
                                                onClick={() => history.push('/history')}
                                        > View
                                            Transactions</Button>
                                    </Grid>
                                </Hidden>
                                <Grid item xs={8}>
                                    <Typography variant="h2" component="legend" style={styles.headerNumber}>
                                        {transaction.id}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant={"inherit"} component="legend" align={"center"}
                                                style={getStyleForStatus(transaction.status.type)}>
                                        {translateTransactionStatus(transaction.status.type)}
                                    </Typography>
                                </Grid>
                                {paymentIsDeclined(transaction.status.type) &&
                                    <Grid item xs={12}>
                                        <Typography style={styles.paymentDeclined}>
                                            {transaction.status.customerComment}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <Typography style={styles.subHeading}>Your Total Cost</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={styles.subHeading}>Transaction Date</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        style={styles.amount}> {myAccountCurrencyNumberFormat.format(+transaction.payorPaysTotal.amount)} </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={styles.amount}>{formattedDate}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer style={tableStyles.tableContainer}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow style={tableStyles.tableHeader}>
                                                <TableCell colSpan={2} style={tableStyles.cellHead}>Summary</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    {transaction.payeeName} Receives:
                                                </TableCell>
                                                <TableCell> {receiveCurrencyNumberFormat.format(+transaction.payeeReceives.amount)}</TableCell>
                                            </TableRow>
                                            {transactionFees &&
                                                transactionFees.map(fee =>
                                                    <TableRow key={fee.type}>
                                                        <TableCell>
                                                            {FeeDescriptionsByType.get(fee.type)}:
                                                        </TableCell>
                                                        <TableCell>{myAccountCurrencyNumberFormat.format(parseFloat(fee.amount))}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                            <TableRow>
                                                <TableCell>
                                                    Total Cost:
                                                </TableCell>
                                                <TableCell> {myAccountCurrencyNumberFormat.format(+transaction.payorPaysTotal.amount)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Payment Method:
                                                </TableCell>
                                                <TableCell> {transaction.paymentMethod} </TableCell>
                                            </TableRow>
                                            {props.creditCardApprovalCode &&
                                                <TableRow>
                                                    <TableCell> Approval Code:
                                                    </TableCell>
                                                    <TableCell> {props?.creditCardApprovalCode}
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            <TableRow>
                                                <TableCell>
                                                    Paid to:
                                                </TableCell>
                                                <TableCell> {transaction.payeeName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Your HOA Reference:
                                                </TableCell>
                                                <TableCell> {transaction.institutionReferenceValue}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid container style={styles.container}>
                                {props.children}
                            </Grid>
                            <Grid container>
                                <MessageBox
                                    title="Need Help?"
                                    description="Call, Email or Chat if you have any questions."
                                    url="https://shiftconnect.com/contact"
                                    ctaLabel="Contact Us"
                                />
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
};

export default TransactionDetailsDisplay;