import UserProfile, {SaveUserProfileRef} from "./UserProfile";
import {Button, createStyles, Grid} from "@material-ui/core";
import {buttonStyles} from "./TransactionCountryCurrencyAmounts";
import React, {useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";

type PropsType = {
    hoaCodeToUse: string;
    setEditMode: (editMode: boolean) => void;
    profileCreatedAction: () => void;
    profileUpdatedAction: () => void;
    backButtonAction: () => void;
    nextButtonAction: () => void;
    editMode: boolean;
}


const UserProfileWithNavigation = (props: PropsType) => {

    const userProfileRef = useRef<SaveUserProfileRef>(null);

    async function saveAndContinue() {
        try {
            if (props.editMode) {
                const validAndSaved = await userProfileRef.current?.saveSubmitUserProfile();
                if (validAndSaved) {
                    props.setEditMode(false);
                }
            } else {
                props.nextButtonAction();
            }
        } catch (e) {
            console.error("edit mode activated without user profile current form instantiated. ", e);
        }
    }

    const useStyles = makeStyles(() =>
        createStyles({

            gridContain: {
                paddingLeft: '3px',
            },
            gridItem: {
                paddingTop: '0 !important',
                paddingBottom: '0 !important',
            },
            nextBtn: {
                margin: 0,
                padding: 0,
                paddingTop: 18,
            }
        }))
    const classes = useStyles()
    return <>
        <UserProfile
            hoaId={props.hoaCodeToUse}
            setEditMode={props.setEditMode}
            profileCreatedAction={props.profileUpdatedAction}
            profileUpdatedAction={props.profileUpdatedAction}
            ref={userProfileRef}
        />
        <Grid container item xs={12} direction="row" alignItems="center"
        >
            <Button
                variant={"text"}
                onClick={() => {
                    props.setEditMode(false);
                    props.backButtonAction();
                }}
            >Back</Button>
            <Button
                variant={"text"}
                style={buttonStyles.buttonAsLink}
                onClick={saveAndContinue}
            >Next</Button>
        </Grid>
    </>;
}

export default UserProfileWithNavigation;
