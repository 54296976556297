import React, {useContext, useEffect, useState} from "react";
import {BankAccountDetail, BankAccountStatus, useGetBankAccountsLazyQuery,} from "../../data/graphqlTypes";
import {Collapse, Grid} from "@material-ui/core";
import ApolloGqlQueryDisplay from "../../data/ApolloGqlQueryDisplay";
import AccountAddUpdate from "./AccountAddUpdate";
import BankAccountDisplay from "./BankAccountDisplay";
import {CurrentTransactionContext} from "../../context/CurrentTransactionContext";
import useAuthorization from "../../auth/useAuthorization";


type PropsType = {
    setAccount: (account: BankAccountDetail | undefined) => void;
    selectedAccountId?: string;
    setEditMode: (editingNow: boolean) => void;
    editMode: boolean;
    autoSelectCurrency?: string;
    selectable: boolean;
};

function getKey(account: BankAccountDetail | undefined) {
    if (!!account && !!account.id && !!account.accountNumber) {
        return account.id + account.accountNumber;
    }
    return "id1";
}

export const Accounts = (props: PropsType) => {

    const [displayEditAccount, setDisplayEditAccount] = useState<boolean>(false);
    const [accountToEdit, setAccountToEdit] = useState<BankAccountDetail>();
    const {isAuthenticated} = useAuthorization();
    const {currentTransaction} = useContext(CurrentTransactionContext);

    const [getAccounts, {
        loading: accountsLoading,
        error: accountsError,
        data: accountsData
    }] = useGetBankAccountsLazyQuery({fetchPolicy: "no-cache"});

    useEffect(() => {
        if (!props.autoSelectCurrency) {
            return;
        }
        if (!props.selectedAccountId && accountsData && accountsData.accounts && accountsData.accounts.length === 1) {
            const account = accountsData.accounts[0];
            if (account?.currency === props.autoSelectCurrency) {
                props.setAccount({
                    accountNumber: account.accountNumber,
                    routingCode: account.routingCode,
                    currency: account.currency,
                    routingType: account.routingType,
                    id: account.id,
                    status: BankAccountStatus.New
                });
            }
        }
    }, [accountsData?.accounts, props.autoSelectCurrency, props.setAccount, props.selectedAccountId]);

    useEffect(() => {
        function clearSelectedAccountIdIfCurrencyMismatched() {
            if (!props.selectedAccountId) {
                return;
            }
            if (!accountsData || !accountsData.accounts || accountsData.accounts.length === 0) {
                return;
            }
            const selectedAccount = accountsData?.accounts.find(account => account?.id === props.selectedAccountId)
            if (!!selectedAccount && !!props.autoSelectCurrency && selectedAccount.currency !== props.autoSelectCurrency) {
                props.setAccount(undefined);
            }
        }

        clearSelectedAccountIdIfCurrencyMismatched();
    }, [accountsData, props.setAccount, props.autoSelectCurrency, props.selectedAccountId]);

    useEffect(() => {
        if (!accountsData && !accountsLoading && !accountsError && isAuthenticated) {
            getAccounts();
        }
    }, [accountsData, accountsLoading, accountsError, isAuthenticated]);

    useEffect(() => {
        function refreshAccountsAfterEdit() {
            if (!props.editMode) {
                getAccounts();
            }
        }

        refreshAccountsAfterEdit();
    }, [getAccounts, props.editMode]);

    return (

        <Grid container spacing={2} direction={"column"}>
            <Grid item>
                <Collapse in={!displayEditAccount}
                          unmountOnExit
                          mountOnEnter
                          style={{transformOrigin: '0 0 0'}}
                          {...(!displayEditAccount ? {timeout: 1000} : {})}>
                    <Grid container spacing={2} direction={"row"}>
                        {accountsData && accountsData.accounts && accountsData.accounts.length > 0 &&
                            accountsData.accounts
                                .map(
                                    account => {
                                        const currencyMatch = currentTransaction.selectedAccountCurrency ? currentTransaction.selectedAccountCurrency === account?.currency : true;
                                        return !!account && (
                                            <Grid item key={getKey(account as BankAccountDetail)}>
                                                <BankAccountDisplay
                                                    account={account}
                                                    currencyMatch={currencyMatch}
                                                    selectable={props.selectable}
                                                    selected={account.id === props.selectedAccountId}
                                                    setAccount={props.setAccount}
                                                    editAccount={account => {
                                                        setAccountToEdit(account);
                                                        setDisplayEditAccount(true);
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    }
                                )
                        }
                    </Grid>
                    <ApolloGqlQueryDisplay
                        loading={accountsLoading}
                        loadingMessage={`Loading account details`}
                        error={accountsError}
                    />
                </Collapse>
            </Grid>
            <Grid item>
                <Collapse in={displayEditAccount}
                          unmountOnExit
                          mountOnEnter
                          style={{transformOrigin: '0 0 0'}}
                          {...(displayEditAccount ? {timeout: 1000} : {})}>
                    {accountToEdit &&
                        <AccountAddUpdate
                            setEditMode={props.setEditMode}
                            setAccount={(account) => props.setAccount(account)}
                            defaultCurrency={accountToEdit.currency}
                            account={accountToEdit}
                            closeAccountEditor={() => setDisplayEditAccount(false)}
                        />
                    }
                </Collapse>
            </Grid>
        </Grid>
    )
}
