import {AccountRoutingType} from "../data/graphqlTypes";
import {Payment} from "../Fees/useCalculateFees";
import {useFormatPayment} from "./GetFormattedTotalFees";
import {Typography} from "@material-ui/core";
import React from "react";
import {EMAIL_TRANSFER_EMAIL} from "./ETranferInstructions";

const FurtherActionModelContent = (props: { selectedAccountRoutingType: AccountRoutingType, payment: Payment, formatter: Intl.NumberFormat }) => {
    const {formattedTotalPaymentAmount: amount} = useFormatPayment(props.formatter, props.payment);
    if (props.selectedAccountRoutingType === AccountRoutingType.Etransfer) {
        return (
            <Typography>
                After confirming this transaction, login to your online banking and send an E-Transfer in the amount
                of <b>{amount}</b> to <b> {EMAIL_TRANSFER_EMAIL} </b>.
            </Typography>
        )
    }
    return (
        <Typography>
            After confirming this transaction, have your bank send a wire transfer in the amount
            of <b>{amount}</b> to PayFromAway using the wire instructions provided in your receipt.
        </Typography>
    );
}
export default FurtherActionModelContent;
