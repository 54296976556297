import {CalculateFeeInput, CalculateFeeResult, useCalculateFeesLazyQuery} from "../data/graphqlTypes";
import {ApolloError} from "@apollo/client";
import {useCallback} from "react";


export type CalculateFeesHook = {
    data: CalculateFeeResult | undefined,
    error: ApolloError | undefined,
    loading: boolean,
    calculateFees: (input: CalculateFeeInput) => void
}

const useCalculateFeesForNonCreditCard = (): CalculateFeesHook => {
    const [calculateFees,
        {
            data,
            error,
            loading
        }] = useCalculateFeesLazyQuery({
        fetchPolicy: "no-cache"
    });


    const calculateFeesFunction = useCallback(async (input: CalculateFeeInput): Promise<any> => {
        return calculateFees({variables: {input: input as CalculateFeeInput}})
            .catch(() => {
                /*Handled elsewhere*/
            })

    }, [calculateFees]);

    return {
        data: data?.calculateFees,
        error,
        loading,
        calculateFees: calculateFeesFunction
    }
}

export default useCalculateFeesForNonCreditCard;