import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    rootBox: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    footer: {
        backgroundColor: '#4D3B71',
    },
    footerNav: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: theme.spacing(0),
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: theme.spacing(3),
        }
    },
    footerLink: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        color: "#fafafa",
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        }
    },
    footerCopy: {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: "#fafafa",
    },
    copy: {
        color: "#fafafa",
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    icons: {
        color: "#fafafa",
        [theme.breakpoints.down('md')]: {
            marginRight: 'auto',
        }
    }
}));

export default function Footer() {
    const classes = useStyles();

    const content = {
        'copy': '© 2024 PayFromAway. All rights reserved.',
        'link1': 'About',
        'link2': 'Contact Us',
        'link3': 'Support 877-577-4438',
        'link4': 'Terms & Conditions',
        'link5': 'Privacy Policy',
    };

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Box display="flex" flexWrap="wrap" alignItems="center" className={classes.rootBox} pt={3} pb={1}>
                    <Link href="#" color="inherit" underline="none">
                        <img src={"/Images/LogoWhite.png"} alt="" width="80"/>
                    </Link>
                    <Box component="nav" ml="auto" className={classes.footerNav}>
                        <Link href="https://www.payfromaway.io/about-us" variant="body1" color="textPrimary" className={classes.footerLink}>{content['link1']}</Link>
                        <Link href="https://www.payfromaway.io/contact-us" variant="body1" color="textPrimary" className={classes.footerLink}>{content['link2']}</Link>
                        <Link href="https://www.payfromaway.io/terms-and-conditions" target="_blank" variant="body1" color="textPrimary" className={classes.footerLink}>{content['link4']}</Link>
                        {/* <Link href="https://www.payfromaway.io/privacy-policy" variant="body1" color="textPrimary" className={classes.footerLink}>{content['link5']}</Link> */}
                    </Box>
                </Box>
                <Box display="flex" flexWrap="wrap" alignItems="center" className={classes.footerCopy} pb={3}>
                    <Typography color="textSecondary" component="p" variant="caption" gutterBottom={false} className={classes.copy}>{content['copy']}</Typography>
                    <Box ml="auto" className={classes.icons}>
                        <Typography variant="body1" color="textPrimary" className={classes.footerLink}>{content['link3']}</Typography>
                    </Box>
                </Box>
            </Container>
        </footer>
    );
}
