import React from "react";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@material-ui/core";

import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import {Maybe, TransactionResult} from "../data/graphqlTypes";
import {useHistory} from "react-router-dom";
import {format, parseISO} from "date-fns";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import {getStyleForStatus} from "../Transaction/dealStatusStyles";
import translateTransactionStatus from "./translateTransactionStatus";


type PropsType = {
    transactionResults: Array<Maybe<TransactionResult>>

};


const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            fontSize: "12px",
            color: "#2e2343",
            lineHeight: '20px',
            letterSpacing: "0.5px",
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: "11px",
                lineHeight: "15px",
            },

        },
        body: {
            fontSize: "16px",
            color: "#746d83",
            lineHeight: '24px',
            letterSpacing: "-0.12px",
            fontWeight: 500,
            padding: "12px",
            [theme.breakpoints.down('sm')]: {
                fontSize: "12px",
                lineHeight: "15px",
                padding: "9px",
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "12px",
                lineHeight: "15px",
                padding: "5px",
            },
        },
    }),
)(TableCell);

const useStyles = makeStyles(() => ({
    table: {
        width: "100%"
    },
    linkedCell: {
        fontWeight: 700,
        color: "#9139ff",

    },
    tableRows: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#E0ecf8",
        },
        '&:hover': {
            backgroundColor: "#FCE070",
        },
    },
    paper: {
        overflowX: "auto"
    },
    container: {
        padding: 10,
        border: "1px solid",
        borderRadius: 10,
        borderColor: "#4D3B71",
        marginTop: "1vh",
        marginBottom: "4vh",
    }

}));


const TransactionHistoryViewer = (props: PropsType) => {
    const transactionsResults = props.transactionResults;
    const classes = useStyles();
    const {currencyFormatter} = useGetCurrencyFormatter();
    const history = useHistory();


    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={12}>
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">TRANSACTION</StyledTableCell>
                                <StyledTableCell align="right">SENDING</StyledTableCell>
                                <StyledTableCell align="right">RECIPIENT</StyledTableCell>
                                <StyledTableCell align="right">RECEIVES</StyledTableCell>
                                <StyledTableCell align="right">TOTAL COST</StyledTableCell>
                                <StyledTableCell align="center">METHOD</StyledTableCell>
                                <StyledTableCell align="right">DATE</StyledTableCell>
                                <StyledTableCell align="right">STATUS</StyledTableCell>
                                <StyledTableCell align="right">STATUS COMMENT</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactionsResults && transactionsResults.length > 0 &&
                                transactionsResults.map(transactionResult => {
                                    if (!transactionResult) {
                                        return <></>;
                                    }
                                    if (!transactionResult.successful) {
                                        return <TableRow
                                            className={classes.tableRows}
                                            key={transactionResult.transactionId}
                                        >
                                            <StyledTableCell
                                            > {transactionResult.transactionId}</StyledTableCell>
                                            <StyledTableCell
                                                colSpan={7}
                                            >Unable to load transaction with ID: {transactionResult.transactionId}.
                                                Please contact support.</StyledTableCell>
                                        </TableRow>
                                    } else if (!!transactionResult.transaction) {
                                        const transaction = transactionResult.transaction;
                                        const formattedDate = format(parseISO(transaction.createdDate), 'MMM dd, yyyy');
                                        let myAccountCurrencyNumberFormat: Intl.NumberFormat = currencyFormatter(transaction.payorPays.currencyCode);
                                        let receiveCurrencyNumberFormat: Intl.NumberFormat = currencyFormatter(transaction.payeeReceives.currencyCode);
                                        const transactionStatus = translateTransactionStatus(transaction.status.type);

                                        return (
                                            <TableRow className={classes.tableRows}
                                                      onClick={() => {
                                                          if (transaction.id) {
                                                              history.push(`/transactiondetails/${transaction.id}`);
                                                          }
                                                      }}
                                                      key={transaction.id}>
                                                <StyledTableCell
                                                    className={classes.linkedCell}>{transaction.id}</StyledTableCell>
                                                <StyledTableCell
                                                    align="right"> {myAccountCurrencyNumberFormat.format(+transaction.payorPays.amount)}</StyledTableCell>
                                                <StyledTableCell
                                                    align="right">{transaction.payeeName}</StyledTableCell>
                                                <StyledTableCell
                                                    align="right">{receiveCurrencyNumberFormat.format(+transaction.payeeReceives.amount)}</StyledTableCell>
                                                <StyledTableCell
                                                    align="right">{myAccountCurrencyNumberFormat.format(+transaction.payorPaysTotal.amount)}</StyledTableCell>
                                                <StyledTableCell
                                                    align="right">{transaction.paymentMethod}</StyledTableCell>
                                                <StyledTableCell
                                                    align="right">{formattedDate}</StyledTableCell>
                                                <StyledTableCell
                                                    align="right"
                                                    style={getStyleForStatus(transaction.status.type)}
                                                >{transactionStatus} </StyledTableCell>
                                                <StyledTableCell
                                                    align="right"
                                                >{transaction.status.customerComment} </StyledTableCell>
                                            </TableRow>

                                        )
                                    }
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>

    )
}


export default TransactionHistoryViewer
