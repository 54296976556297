import {HomeLocationContextProvider} from "../components/context/HomeLocationContext";
import ApolloGqlQueryDisplay from "../components/data/ApolloGqlQueryDisplay";
import {useGetInstitution} from "../components/data/graphql/useGetInstitution";
import ErrorDisplay from "../components/ErrorDisplay";
import {
    getLastInstitutionId,
    storeInstitutionIdInLocalStorage
} from "../utilities/localstorage/InstitutionContextStore";
import {getNewCustomerId, storeNewCustomerIdInLocalStorage} from "../utilities/localstorage/NewCustomerContextStore";
import {useSettingsStyles} from "./PaymentSettings";
import {useHasCustomerBeenCreatedQuery} from "../components/data/graphqlTypes";
import {Link, useHistory, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {Button, Card, Fade, Grid, Paper, Typography} from "@material-ui/core";
import {Image} from "react-bootstrap";
import useAuthorization from "../components/auth/useAuthorization";


interface Params {
    hoaCode: string;
    customerId: string;
}

const CustomerImportCreateAccountLanding = () => {
    const classes = useSettingsStyles();

    let {hoaCode, customerId}: Params = useParams();
    const {
        isAuthenticated,
        isLoading: authLoading
    } = useAuthorization();
    const history = useHistory();

    const {
        institution,
        loading: institutionLoading,
        error: institutionError
    } = useGetInstitution(hoaCode);

    const {
        data: beenCreatedData,
        loading: beenCreatedLoading,
        error: beenCreatedError
    } = useHasCustomerBeenCreatedQuery({variables: {importedCustomerId: customerId}});


    useEffect(() => {
        const lastInstitutionId = getLastInstitutionId();
        if (hoaCode && hoaCode != lastInstitutionId) {
            storeInstitutionIdInLocalStorage(hoaCode);
        }
    }, [hoaCode, storeInstitutionIdInLocalStorage, getLastInstitutionId]);

    useEffect(() => {
        const lastCustomerId = getNewCustomerId();
        if (customerId && customerId != lastCustomerId) {
            storeNewCustomerIdInLocalStorage(customerId);
        }
    }, [customerId, storeNewCustomerIdInLocalStorage, getNewCustomerId]);


    if (institutionLoading || institutionError || beenCreatedLoading || beenCreatedError || authLoading) {
        return <ApolloGqlQueryDisplay
            loadingMessage={`Loading HOA data for ${hoaCode}`}
            error={institutionError || beenCreatedError}
            loading={institutionLoading || beenCreatedLoading || authLoading}
        />;
    }


    if (beenCreatedData && beenCreatedData.hasCustomerBeenCreated) {
        if (isAuthenticated) {
            history.push(`/${hoaCode}`);
        } else {
            return <Fade in={true} timeout={1000}>
                <Paper>
                    <Grid container direction='column' justifyContent="center" alignItems="center">
                        <Card variant={"elevation"} className={classes.cardContainer}>
                            <Grid item xs={12} className={classes.topMargin}>
                                <Typography className={classes.titleTypography}>
                                    An account has already been created from this link. Please {" "}
                                    <Link
                                        to={"/paymentSettings"}
                                    >
                                        log in
                                    </Link>.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className={classes.titleTypography}
                                        variant={"text"}
                                        onClick={() => history.push("/paymentSettings")}
                                >Log in</Button>
                            </Grid>
                        </Card>
                    </Grid>
                </Paper>
            </Fade>
        }
    }

    if (!institution) {
        return <ErrorDisplay
            error={`Unable to load data for ${hoaCode}. Please try again or contact support.`}
        />
    }


    return (
        <Fade in={true} timeout={1000}>
            <HomeLocationContextProvider
                institution={institution}
            >
                <Grid container direction='column' justifyContent="center" alignItems="center">
                    <Card variant={"elevation"} className={classes.cardContainer} style={{padding: 10}}>
                        <Grid item container direction="row" justifyContent={"space-between"}
                              alignItems={"flex-start"}>
                            <Grid item xs={8}>
                                <Typography variant={"h3"} className={classes.titleTypography} style={{marginTop: 20}}>
                                    Welcome to <b>PayFromAway</b>.
                                </Typography>
                            </Grid>
                            <Grid item xs={4}
                                  style={{display: 'flex', justifyContent: 'flex-end', alignItems: "flex-start"}}>
                                <Image src={institution.logoUrl} thumbnail style={{
                                    height: 'auto',
                                    maxWidth: '50%',
                                    display: 'block',
                                    border: "transparent"
                                }}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.titleTypography}>
                                To get started with PayFromAway and simplify your HOA payment experience, click below to
                                create your account. If you already have an account, log in below.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={classes.titleTypography} variant={"contained"} size={"large"}
                                    onClick={() => history.push(`/signuptocreateaccount/${hoaCode}`)}>Create
                                Account</Button>
                        </Grid>
                        <Grid item xs={12} className={classes.titleTypography}>
                            <Typography variant="body1" style={{marginLeft: "30px"}}>
                                Existing User <Link to={`/signuptocreateaccount/${hoaCode}`} style={{
                                textDecoration: 'none',
                                color: "#913AFF"
                            }}>Login</Link>.
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>
            </HomeLocationContextProvider>
        </Fade>

    )
        ;
};

export default CustomerImportCreateAccountLanding;
