import {AccountRoutingType} from "../data/graphqlTypes";

export const FIVE_OR_NINE_DIGITS_REGEX = /^\d{5}$|^\d{9}$/

export function isFiveOrNineDigits(test: string) {
    return FIVE_OR_NINE_DIGITS_REGEX.test(test);
}

export function getAccountAndTransactionRoutingTypeForBankRoutingType(routingType: AccountRoutingType): AccountRoutingType {
    switch (routingType) {
        case AccountRoutingType.Ach:
        case AccountRoutingType.AchDebit:
            return AccountRoutingType.AchDebit
        default:
            return AccountRoutingType.Pad
    }
}
