import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import HoaMarketingLanding from "./HoaMarketingLanding";
import logo from "../Images/SC-PFA.png";
import {makeStyles} from "@material-ui/core/styles";
import {Redirect, useParams} from "react-router-dom";
import {storeInstitutionIdInLocalStorage} from "../utilities/localstorage/InstitutionContextStore";

const useStyles = makeStyles({
    dialogTitle: {
        fontSize: "35px",
        fontWeight: 700
    },
    fontSpacing: {
        marginTop: "10px",
    }
});

type ParamsType = {
    hoaCode: string;
}

const ShiftRedirectPage = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const {hoaCode} = useParams<ParamsType>();
    const [redirect, setRedirect] = useState<string>();

    const handleClose = () => {
        setRedirect(`/${hoaCode}`);
        setOpen(false);
    };

    useEffect(() => {
        if(hoaCode) {
            storeInstitutionIdInLocalStorage(hoaCode);
        }
    }, [hoaCode]);

    if (redirect) {
        return (
            <>
                <Redirect to={redirect}/>
            </>
        );
    }

    return (
        <>
            <HoaMarketingLanding/>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <img src={logo} alt="logo"/>
                <DialogTitle id="customized-dialog-title">
                    <Typography className={classes.dialogTitle}>
                        Big News!
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        The Shift Connect team is excited to announce that we’ve rebranded our HOA payments platform
                        with a new logo and name: PayFromAway. This is still your dedicated HOA platform and apart from
                        the
                        new name, everything will remain the same on your end. Your login details and past transactions
                        will still be the same! If you have any questions please don't hesitate to reach out to us via
                        chat
                        or the number in the footer.
                    </Typography>
                    <Typography className={classes.fontSpacing}>
                        In the future you can find us directly by visiting https://hoa.payfromaway.io.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        I understand
                    </Button>
                </DialogActions>
            </Dialog>

        </>

    )
};
export default ShiftRedirectPage;