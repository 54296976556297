import React from "react";
import ControlledAutocomplete from "./ControlledAutoComplete";
import {Currency} from "../data/graphql/getCountriesAndCurrencies";
import {Grid} from "@material-ui/core";


type SelectCurrencyParams = {
    setCurrencyCallBack: (currency: Currency) => void,
    displayLabel: string,
    currencyCode: string,
    focused?: boolean,
    allowedCurrencies: Array<Currency>,
};

function SelectCurrency(props: SelectCurrencyParams) {

    const currency = props.allowedCurrencies.find(value => value.currencyCode === props.currencyCode) || '';

    return (
        <Grid item xs={12}>
            {currency && props.allowedCurrencies &&
                <ControlledAutocomplete<Currency, 'currencyCode', 'currencyName'>
                    focused={props.focused || false}
                    loading
                    disabled={props.allowedCurrencies.length < 2}
                    optionArray={props.allowedCurrencies}
                    value={currency}
                    valueChangeCallback={props.setCurrencyCallBack}
                    label={props.displayLabel}
                    displayLabel={'currencyName'}
                    arrayKey={'currencyCode'}
                />
            }
        </Grid>
    )
}

export default SelectCurrency;