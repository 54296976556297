import {Button, Grid, IconButton, InputAdornment, Typography} from "@material-ui/core";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {RoutingCodeDetail, useGetBanksForRoutingCodeLazyQuery} from "../../data/graphqlTypes";
import ApolloGqlQueryDisplay from "../../data/ApolloGqlQueryDisplay";
import {Field, Formik, FormikProps} from "formik";
import {TextField} from "formik-material-ui";
import {isCountry} from "../../../utilities/localstorage/CurrentTransactionStore";
import {TransitFaq} from "../../PaymentOptions/TransitFaq";
import {RoutingFaq} from "../../PaymentOptions/RoutingFaq";
import {FIVE_OR_NINE_DIGITS_REGEX, isFiveOrNineDigits} from "../DirectDebitConfiguration";
import * as yup from "yup";
import SearchIcon from "@material-ui/icons/Search";
import Bank from "../../Banks/Bank";
import {makeStyles} from "@material-ui/core/styles";
import BankSearchPlaceholder from "../../Banks/BankPlaceholder";

const ROUTING_CODE_OR_TRANSIT_NUMBER = `Routing Code or Transit Number`;
const validationSchema = () => yup.object().shape({
    transitNumber: yup.string()
        .required(`${ROUTING_CODE_OR_TRANSIT_NUMBER} is required`)
        .matches(FIVE_OR_NINE_DIGITS_REGEX, `${ROUTING_CODE_OR_TRANSIT_NUMBER} must be 5 or 9 digits`)
});

interface BankFormData {
    transitNumber: string;
    banks: any[];
    bank: RoutingCodeDetail;
}

const useStyles = makeStyles(() => ({
    container: {
        marginLeft: "10px",
        marginBottom: "15px"
    },
    selectBankPrompt: {
        marginTop: "5px",
        marginLeft: "5px"
    }
}));

type PropsType = {
    setSelectedBank: (bank: RoutingCodeDetail) => void;
    originalBankId?: string;
    originalRoutingCode?: string;
};

const BankSelect = (props: PropsType) => {

    const classes = useStyles();
    const [transitFaqOpen, setTransitFaqOpen] = useState(false);
    const formRef = useRef<FormikProps<BankFormData>>(null);
    const [routingCode, setRoutingCode] = useState<string>("");

    const [getBanksForRoutingCode, {
        data: banksData,
        loading: banksLoading,
        error: getBanksError
    }
    ] = useGetBanksForRoutingCodeLazyQuery();

    useEffect(() => {
        if (props.originalRoutingCode) {
            setRoutingCode(props.originalRoutingCode);
            getBanksForRoutingCode({variables: {routingCode: props.originalRoutingCode}});
        }
    }, [props.originalRoutingCode, getBanksForRoutingCode]);


    useEffect(() => {
        if (!banksLoading && !getBanksError && banksData && banksData?.banks) {
            if (banksData.banks.length === 1) {
                props.setSelectedBank(banksData.banks[0]);
            }
        }
    }, [banksLoading, getBanksError, banksData]);

    const getBanks = useCallback((routingCode: string) => {
        if (routingCode) {
            getBanksForRoutingCode({variables: {routingCode: routingCode}});
        } else {
            console.error('Validation needs work');
        }
    }, [getBanksForRoutingCode]);

    return (
        <Formik<BankFormData>
            initialValues={{
                transitNumber: routingCode,
                banks: banksData?.banks || [],
                bank: {id: props.originalBankId} as RoutingCodeDetail
            } as BankFormData}
            enableReinitialize={true}
            onSubmit={() => {/** don't submit because we don't to interrupt the user. */
            }}
            validationSchema={validationSchema()}
            innerRef={formRef}
        >

            {({values, setFieldValue, submitForm}) => {

                const isFiveOrNine = isFiveOrNineDigits(routingCode);
                const needToSelectABank = values.banks && values.banks.length > 0 && !values.bank;
                const searchFieldAdornment = <InputAdornment position="end">
                    <IconButton
                        aria-label="search for bank"
                        onClick={() => submitForm()}
                    >
                        <SearchIcon/>
                    </IconButton>
                </InputAdornment>;
                return <Grid className={classes.container} container direction={"row"}>

                    <Grid item>
                        <Field
                            fullWidth
                            name="transitNumber"
                            label={ROUTING_CODE_OR_TRANSIT_NUMBER}
                            variant={"outlined"}
                            type={"text"}
                            component={TextField}
                            disabled={false}
                            InputLabelProps={{shrink: true}}
                            InputProps={{endAdornment: searchFieldAdornment}}
                            onChange={async (e: any) => {
                                let value = e.currentTarget.value
                                setRoutingCode(value);
                                if (!isFiveOrNineDigits(value)) {
                                    return;
                                } else {
                                    getBanks(value);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Button size='small' onClick={() => {
                            setTransitFaqOpen(true)
                        }} color="secondary">Need Help?</Button>
                        {isCountry('CA') ? <TransitFaq
                            open={transitFaqOpen}
                            close={() => setTransitFaqOpen(false)}
                        /> : <RoutingFaq
                            open={transitFaqOpen}
                            close={() => setTransitFaqOpen(false)}/>}
                    </Grid>

                    <Grid container item direction={"row"}>
                        <React.Fragment>
                            {
                                isFiveOrNine && values.banks && values.banks.length > 0 ?
                                    values.banks
                                        .filter(value => value.routingCode === values.transitNumber)
                                        .map((bank) => {
                                            const selected = (values.bank && values.bank.id === bank.id);
                                            return {
                                                ...bank,
                                                selected: selected
                                            }
                                        }).map((bank) =>
                                        (
                                            <Bank key={`${bank.routingCode}${bank.bankCode}`}
                                                  bank={bank}
                                                  selectBank={(v) => {
                                                      setFieldValue('bank', v);
                                                      props.setSelectedBank(v);
                                                  }}
                                            />
                                        ))
                                    :
                                    <BankSearchPlaceholder
                                        loading={banksLoading}
                                        error={getBanksError}
                                        banksData={banksData}
                                        routingCode={routingCode}
                                    />

                            }
                        </React.Fragment>
                    </Grid>
                    <ApolloGqlQueryDisplay loading={false}
                                           loadingMessage={''}
                                           error={getBanksError}
                    />
                    {needToSelectABank &&
                        <Grid className={classes.selectBankPrompt}>
                            <Typography align={"center"}> Please select a bank</Typography>
                        </Grid>
                    }
                </Grid>
                    ;
            }}
        </Formik>
    );
}

export default BankSelect;
