import React, {useEffect, useState} from "react";
import {Country} from "../data/graphql/getCountriesAndCurrencies";
import ControlledAutocomplete from './ControlledAutoComplete';
import {getCountryFromList} from "../../utilities/useGetCountry";
import {Loading} from "../layout";

interface SelectedCountryProps {
    setCountryCallBack: (value: Country) => void;
    focused?: boolean;
    countryCode?: string;
    selectCountryLabel: string;
    countries: Array<Country>
}

function SelectCountry(props: SelectedCountryProps) {

    const [country, setCountry] = useState<Country>();
    const propsCountryCode = props.countryCode;

    const setAndPushCountry = (selectedCountry: Country) => {
        setCountry(prevState => {
            if (!prevState || (prevState.countryCode !== selectedCountry.countryCode)) {
                props.setCountryCallBack(selectedCountry);
            }
            return selectedCountry;
        });
    };

    useEffect(() => {
        if (props.countries) {
            const propsCountry = getCountryFromList(props.countries, propsCountryCode);
            if (propsCountry) {
                setCountry(propsCountry);
            }
        }
    }, [props.countries, propsCountryCode]);

    if (!country) {
        return <Loading/>;
    }

    return <ControlledAutocomplete<Country, 'countryCode', 'countryName'>
        focused={props.focused || false}
        loading
        optionArray={props.countries}
        value={country}
        valueChangeCallback={setAndPushCountry}
        label={props.selectCountryLabel}
        displayLabel={'countryName'}
        arrayKey={'countryCode'}
        autocompleteHintText="country-name"
    />;
}

export default SelectCountry;