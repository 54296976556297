import React, {useEffect, useState} from "react";
import {Region, useGetRegionsQuery} from "../data/graphqlTypes";
import ControlledAutocomplete from './ControlledAutoComplete';
import ApolloGqlQueryDisplay from "../data/ApolloGqlQueryDisplay";

interface SelectedStateProps {
    setStateCallBack: (value: Region) => void;
    focused?: boolean;
    countryCode: string;
    region: Region | undefined;
    selectStateLabel: string;
}

function regionsContainsRegion(regions1: Array<Region>, regionCode: string | undefined) {
    return regions1.find(region => region.regionCode === regionCode);
}

function SelectState(props: SelectedStateProps) {


    const {data, loading, error} = useGetRegionsQuery();
    const [regions, setRegions] = useState<Array<Region>>()


    useEffect(() => {
        if (loading) {
            return;
        }
        if (data) {
            setRegions(data.regions.filter(region => region.countryCode == "US"));
            if (props.countryCode === "CA") {
                setRegions(data.regions.filter(region => region.countryCode == "CA"));
            }
        }
    }, [data, loading, setRegions, props.countryCode]);

    useEffect(() => {
        function updateRegionsAndReplaceSelectedIfItIsNotValidAnymore() {
            if (regions) {
                const regionValidation = regionsContainsRegion(regions, props.region?.regionCode);
                if (!regionValidation) {
                    props.setStateCallBack(regions[0])
                }
            }
        }

        updateRegionsAndReplaceSelectedIfItIsNotValidAnymore();

    }, [props.region, regions])


    return (
        <React.Fragment>
            {regions && regions.length > 0 && regionsContainsRegion(regions, props.region?.regionCode) &&
                <>
                    <ControlledAutocomplete<Region, 'regionCode', 'regionName'>
                        focused={props.focused || false}
                        loading
                        optionArray={regions as Array<Region>}
                        value={props.region}
                        valueChangeCallback={props.setStateCallBack}
                        label={props.selectStateLabel}
                        displayLabel={'regionName'}
                        arrayKey={'regionCode'}
                        autocompleteHintText="address-level1"
                    />
                    <ApolloGqlQueryDisplay
                        loading={loading}
                        loadingMessage='Loading Regions'
                        error={error}
                    />
                </>
            }
        </React.Fragment>
    );
}

export default SelectState;