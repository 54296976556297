import React from "react";
import {Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@material-ui/core";
import {CustomerView, InvoiceWithBalance} from "../data/graphqlTypes";
import {format, parseISO} from "date-fns";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import {makeStyles} from "@material-ui/core/styles";
import {theme} from "../theme";


export const invoiceStyles = makeStyles(() => ({
    tableContainer: {
        marginTop: "2vh",
        marginBottom: '2vh',
        maxWidth: 750,
        borderRadius: 5,
    },
    box: {
        backgroundColor: "#f4f4f4",
        borderRadius: "4px",
        padding: "20px 20px",
        margin: "10px 20px",
        maxWidth: "400px",
        minWidth: "400px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "125px",
        },
    },
    introFont: {
        fontSize: "48px",
        lineHeight: "56px",
        fontWeight: 400,
        color: "#4d3b71",
        marginTop: "12px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "36px",
            lineHeight: "48px",
        }
    },
    paymentFont: {
        fontSize: "36px",
        lineHeight: "48px",
        fontWeight: 600,
        color: "#4d3b71",
        marginTop: "12px",
        marginBottom: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
            lineHeight: "36px",
        }
    },

    tableFont: {
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 600,
        marginTop: "12px",
        color: "#4d3b71",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
        }
    },
}));


type PropsType = {
    customer?: CustomerView;
    invoiceWithBalance: InvoiceWithBalance;
}

const InvoiceView = (props: PropsType) => {
    const classes = invoiceStyles()
    const invoiceWithBalance = props.invoiceWithBalance;
    const formattedDueDate = format(parseISO(invoiceWithBalance.invoice.dueDate), 'MMM dd, yyyy');
    const {currencyFormatter} = useGetCurrencyFormatter();
    let invoiceAmountFormatter: Intl.NumberFormat = currencyFormatter(invoiceWithBalance.invoice.currencyCode);

    return <Grid
        container
        direction="column"
    >

        <Box className={classes.box}>
            <Typography className={classes.introFont} align={"center"}>Amount Due</Typography>
            <Typography className={classes.paymentFont}
                        align={"center"}>
                {invoiceAmountFormatter.format(+props.invoiceWithBalance.balance.amount)}
            </Typography>
            <Grid item xs={12}>
                <TableContainer className={classes.tableContainer}>
                    <Table size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableFont}>
                                    Invoice ID:
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {props.invoiceWithBalance.invoice.institutionInvoiceId}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableFont}>
                                    Due Date
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {formattedDueDate}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableFont} width={"25%"}>
                                    Description:
                                </TableCell>
                                <TableCell width={"75%"}>
                                    <Typography>
                                        {props.invoiceWithBalance.invoice.description}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    </Grid>
        ;
}

export default InvoiceView;