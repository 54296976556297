import TermsContentInterface from "./TermsContentInterface";

class AchTermsContent implements TermsContentInterface {
  private title =
    "Automated Clearing House Debit (ACH Debit) Terms & Conditions";
  private debitType = "ACH Debit";
  private thirdParty = "National Automated Clearing House Association";
  private link = "www.nacha.org";

  public getTitle(): string {
    return this.title;
  }
  public getDebitType(): string {
    return this.debitType;
  }
  public getThirdParty(): string {
    return this.thirdParty;
  }
  public getLink(): string {
    return this.link;
  }
}

export default AchTermsContent;
