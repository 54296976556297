import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Button, Grid, Typography, useMediaQuery} from '@material-ui/core';
import Image from "../Image";
import SectionHeader from "../SectionHeader";
import {getLastInstitutionId} from '../../../../utilities/localstorage/InstitutionContextStore';

const useStyles = makeStyles(theme => ({
    image: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
        },
    },
}));


function getGetStartedButton() {

    const lastInstitutionId = getLastInstitutionId();
    if (lastInstitutionId) {
        return <Link to={`/${lastInstitutionId}`}>
            <Button variant="contained" color="primary" size="large">
                Get Started
            </Button>
        </Link>
    }

    return <Button variant="contained" color="primary" size="large"
                   onClick={() => {
                       window.location.href = 'https://www.payfromaway.io/contact-us';
                   }}>
        Get Started
    </Button>;
}

const Hero = ({className, ...rest}: ViewComponentProps): JSX.Element => {
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <div className={className} {...rest}>
            <Grid container justifyContent="center" alignContent="center" spacing={isMd ? 4 : 2}>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos={'fade-up'}
                >
                    <Image
                        src={"/Images/HeroLanding.svg"}
                        alt="PayFromAway - HOA Payments"
                        className={classes.image}
                    />
                </Grid>
                <Grid item xs={12} md={6} data-aos={'fade-up'}>
                    <SectionHeader
                        title={
                            <span>
                Welcome to{' '}
                                <Typography component="span" variant="inherit"
                                            color="primary">Pay From Away.</Typography>
                <br/>
                <span/>
              </span>
                        }

                        subtitle=" Our simple, safe, and secure payments platform was created with you in mind. Easily pay your home owner association or condo fees in just a few simple steps."
                        ctaGroup={[
                            getGetStartedButton(),
                        ]}

                        align={isMd ? 'left' : 'center'}
                        disableGutter
                        titleVariant="h3"
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default Hero;
