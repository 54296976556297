import {PaymentMethod, PfaTransactionDetail} from "../components/data/graphqlTypes";
import React from "react";
import EmailTransferPaymentInstructions from "../components/PaymentInstructions/EmailTransferPaymentInstructions";
import CreditCardPaymentInstructions from "../components/PaymentInstructions/CreditCardPayentInstructions";
import {Typography} from "@material-ui/core";

export function getInstructionsComponent(transaction: PfaTransactionDetail): React.ReactNode {
    switch (transaction.paymentMethod) {


        case PaymentMethod.EmailTransfer:
            if (!transaction.receiveEmail) {
                throw new Error("Receive email not defined")
            }
            return <EmailTransferPaymentInstructions
                currency={transaction.payorPays.currencyCode}
                totalCost={+transaction.payorPaysTotal.amount}
                emailTransferReceiveEmail={transaction.receiveEmail}
                transactionNumber={transaction.id}
            />
        case PaymentMethod.CreditCard:
            return <CreditCardPaymentInstructions
                currency={transaction.payorPaysTotal.currencyCode}
                totalCost={+transaction.payorPaysTotal.amount}
            />

        default:
            return <Typography> Thank you for your transaction.</Typography>


    }
}