import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {
    EMPTY_TRANSACTION,
    getCurrentTransaction,
    saveTransactionDetailsToLocalStorage,
    TransactionDetails
} from "../../utilities/localstorage/CurrentTransactionStore";
import useGetCountriesAndCurrencies, {getPaymentCurrency} from "../data/graphql/getCountriesAndCurrencies";
import {HomeLocationContext} from "./HomeLocationContext";

export type TransactionDetailsContextType = {
    updatePaymentReferenceInCurrentTransaction: (paymentReference: string) => boolean;
    updateCountryInCurrentTransaction: (countryCode: string) => boolean;
    updateRegionInCurrentTransaction: (regionCode: string) => boolean;
    updateAmountInCurrentTransaction: (amount: string) => boolean;
    updateIdInCurrentTransaction: (transactionId: string) => void;
    updateCostInCurrentTransaction: (cost: number) => void;
    updateRateInCurrentTransaction: (rate: number) => void;
    updateHoaCodeInCurrentTransaction: (hoaCode: string) => boolean;
    currentTransaction: TransactionDetails;
    currentTransactionId: string;
    currentTransactionCost: number;
    currentTransactionRate: number;
    currentTransactionRateExpiry: number;
    setUserSelectedCurrencyCode: (currency: string) => void;
    updateTargetCurrencyInCurrentTransaction: (currency: string) => void;
    updateRateExpiryInCurrentTransaction: (expiryTime: number) => void;
}

const emptyContext: TransactionDetailsContextType = {
    currentTransaction: EMPTY_TRANSACTION,
    currentTransactionId: "",
    currentTransactionCost: 0,
    currentTransactionRate: 0,
    currentTransactionRateExpiry: 0,
    updatePaymentReferenceInCurrentTransaction(): boolean {
        return false;
    },
    updateCountryInCurrentTransaction(): boolean {
        return false;
    },
    updateRegionInCurrentTransaction(): boolean {
        return false;
    },
    updateAmountInCurrentTransaction(): boolean {
        return false;
    },
    updateIdInCurrentTransaction(): void {
    },
    updateCostInCurrentTransaction(): void {
    },
    updateRateInCurrentTransaction(): void {
    },
    updateHoaCodeInCurrentTransaction(): boolean {
        return false;
    },
    setUserSelectedCurrencyCode(): void {
    },
    updateTargetCurrencyInCurrentTransaction(): void {
    },
    updateRateExpiryInCurrentTransaction(): void {
    }
}

export const CurrentTransactionContext = createContext<TransactionDetailsContextType>(emptyContext);

type PropsType = {
    hoaCode: string;
    children: React.ReactNode;
};


export const CurrentTransactionContextProvider = (props: PropsType) => {

    const hoaCode = props.hoaCode;
    const [currentTransaction, setCurrentTransaction] = useState<TransactionDetails>(getCurrentTransaction());
    const [userSelectedCurrencyCode, setUserSelectedCurrencyCode1] = useState<string>();
    const [currentTransactionId, setCurrentTransactionId] = useState<string>("");
    const [currentTransactionCost, setCurrentTransactionCost] = useState<number>(0);
    const [currentTransactionRate, setCurrentTransactionRate] = useState<number>(0);
    const [currentTransactionRateExpiry, setCurrentTransactionRateExpiry] = useState<number>(0);
    const {countries} = useGetCountriesAndCurrencies();
    const homeLocationContext = useContext(HomeLocationContext);

    const setUserSelectedCurrencyCode = (currencyCode: string) => {
        setUserSelectedCurrencyCode1(currencyCode);
    }

    const saveCurrentTransaction = useCallback((transactionDetailsToSave: TransactionDetails): boolean => {
        const saved = saveTransactionDetailsToLocalStorage(transactionDetailsToSave);
        if (saved) {
            setCurrentTransaction(transactionDetailsToSave);
        }
        return saved;
    }, []);

    const updatePaymentReferenceInCurrentTransaction = useCallback((paymentReference: string): boolean => {
        const currentTransactionFromLocalStorage = getCurrentTransaction();
        if (paymentReference && (!currentTransactionFromLocalStorage.paymentReference || (paymentReference !== currentTransactionFromLocalStorage.paymentReference))) {
            const updatedTransaction: TransactionDetails = {
                ...currentTransactionFromLocalStorage,
                paymentReference
            };
            return saveCurrentTransaction(updatedTransaction);
        }
        return true;
    }, [saveCurrentTransaction]);

    const updateCountryInCurrentTransaction = useCallback((countryCode: string): boolean => {
        const updatedTransaction: TransactionDetails = {
            ...getCurrentTransaction(),
            homeCountry: countryCode
        };
        return saveCurrentTransaction(updatedTransaction);
    }, [saveCurrentTransaction]);

    const updateUsStateInCurrentTransaction = useCallback((stateCode: string): boolean => {
        const updatedTransaction: TransactionDetails = {
            ...getCurrentTransaction(),
            homeState: stateCode
        };
        return saveCurrentTransaction(updatedTransaction);
    }, [saveCurrentTransaction]);

    const updateAmountInCurrentTransaction = useCallback((amount: string): boolean => {
        const updatedTransaction: TransactionDetails = {
            ...getCurrentTransaction(),
            amount
        }
        return saveCurrentTransaction(updatedTransaction);
    }, [saveCurrentTransaction]);

    const updateHoaCodeInCurrentTransaction = useCallback((hoaCode: string): boolean => {
        const updatedTransaction: TransactionDetails = {
            ...getCurrentTransaction(),
            hoaCode
        }
        return saveCurrentTransaction(updatedTransaction);
    }, [saveCurrentTransaction]);

    const updateIdInCurrentTransaction = useCallback((transactionId: string) => {
        setCurrentTransactionId(transactionId);
    }, [setCurrentTransactionId]);

    const updateCostInCurrentTransaction = useCallback((cost: number) => {
        setCurrentTransactionCost(cost);
    }, [setCurrentTransactionCost]);

    const updateRateInCurrentTransaction = useCallback((rate: number) => {
        setCurrentTransactionRate(rate);
    }, [setCurrentTransactionRate]);


    const updateRateExpiryInCurrentTransaction = useCallback((expiryTime: number) => {
        setCurrentTransactionRateExpiry(expiryTime);
    }, [setCurrentTransactionRateExpiry]);

    const updateCurrencyInCurrentTransaction = useCallback((currencyCode: string): boolean => {
        const updatedTransaction: TransactionDetails = {
            ...getCurrentTransaction(),
            selectedAccountCurrency: currencyCode
        }
        return saveCurrentTransaction(updatedTransaction);
    }, [saveCurrentTransaction]);

    const updateTargetCurrencyInCurrentTransaction = useCallback((currencyCode: string): boolean => {
        const currentTransactionStored = getCurrentTransaction();
        if (currentTransactionStored.targetCurrencyCode && currentTransactionStored.targetCurrencyCode === currencyCode) {
            return true;
        }
        const updatedTransaction: TransactionDetails = {
            ...currentTransactionStored,
            targetCurrencyCode: currencyCode
        }
        return saveCurrentTransaction(updatedTransaction);
    }, [saveCurrentTransaction]);

    const resetCurrentTransaction = useCallback(() => {
        updateIdInCurrentTransaction("");
        updateCostInCurrentTransaction(0);
        updateRateInCurrentTransaction(0);
        saveCurrentTransaction(EMPTY_TRANSACTION);
    }, [updateRateInCurrentTransaction, updateIdInCurrentTransaction, updateCostInCurrentTransaction, saveCurrentTransaction]);

    useEffect(() => {
        const currentTransaction = getCurrentTransaction();
        const hoaCodeLower = hoaCode ? hoaCode.toLowerCase() : hoaCode;
        if (currentTransaction.hoaCode && currentTransaction.hoaCode.toLowerCase() !== hoaCodeLower) {
            resetCurrentTransaction();
        }
    }, [hoaCode, resetCurrentTransaction]);

    useEffect(() => {
        if (userSelectedCurrencyCode) {
            updateCurrencyInCurrentTransaction(userSelectedCurrencyCode);
        }
    }, [userSelectedCurrencyCode, updateCurrencyInCurrentTransaction]);


    const homeLocationContextCountryCode = homeLocationContext.countryCode;
    const homeLocationContextRegionCode = homeLocationContext.region?.regionCode;
    const currentTransactionHomeCountry = currentTransaction.homeCountry;

    useEffect(() => {
        if (countries && homeLocationContextCountryCode !== currentTransactionHomeCountry) {
            if (homeLocationContextCountryCode) {
                updateCountryInCurrentTransaction(homeLocationContextCountryCode);
                if (!userSelectedCurrencyCode) {
                    updateCurrencyInCurrentTransaction(getPaymentCurrency(homeLocationContextCountryCode));
                }
            }
            if (homeLocationContextRegionCode) {
                updateUsStateInCurrentTransaction(homeLocationContextRegionCode);
            }
        }
    }, [
        homeLocationContextCountryCode,
        homeLocationContextRegionCode,
        currentTransactionHomeCountry,
        countries,
        updateCountryInCurrentTransaction,
        updateUsStateInCurrentTransaction,
        updateCurrencyInCurrentTransaction,
        userSelectedCurrencyCode]);


    const completedContext: TransactionDetailsContextType = {
        currentTransactionId,
        currentTransactionCost,
        currentTransactionRate,
        currentTransaction,
        currentTransactionRateExpiry,
        updateIdInCurrentTransaction,
        updatePaymentReferenceInCurrentTransaction,
        updateCountryInCurrentTransaction,
        updateRegionInCurrentTransaction: updateUsStateInCurrentTransaction,
        updateAmountInCurrentTransaction,
        updateHoaCodeInCurrentTransaction,
        updateCostInCurrentTransaction,
        updateRateInCurrentTransaction,
        setUserSelectedCurrencyCode,
        updateTargetCurrencyInCurrentTransaction,
        updateRateExpiryInCurrentTransaction
    }

    return (
        <CurrentTransactionContext.Provider value={completedContext}>
            {props.children}
        </CurrentTransactionContext.Provider>
    );
}
