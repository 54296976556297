import {ShiftModal} from "../input/ShiftModal";
import React, {useState} from "react";
import {
    AutoPayConfigurationInput,
    useGetInstitutionByIdQuery,
    useSaveAutoPayConfigurationMutation
} from "../data/graphqlTypes";
import {Avatar, Divider, Grid, IconButton, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {getAutoPayConfigurations} from "../data/graphql/autopay.graphql";
import {makeStyles} from "@material-ui/core/styles";
import {
    CREDIT_CARD_AUTO_PAY_TERMS,
    CREDIT_CARD_AUTO_PAY_TERMS_TITLE,
    DIRECT_DEBIT_AUTO_PAY_TERMS,
    DIRECT_DEBIT_AUTO_PAY_TERMS_TITLE
} from "./autoPayTerms";
import {purple} from "@material-ui/core/colors";


const AUTO_PAY_QUESTION = (
    <Typography>
        Would you like for us to use this payment method to automatically pay future invoices when your HOA adds them to
        your account? We would send you a notice when we receive a payment request from your HOA and we'll also send you
        a receipt after processing the payment.
    </Typography>
);


const useStyles = makeStyles((theme) => ({
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    logoContainer: {
        flexShrink: 0,
        marginRight: '16px',
    },
    logo: {
        width: '100%',
        height: 'auto',
    },
    text: {
        flexGrow: 1,
        marginTop: 10
    },
    closeButton: {
        marginLeft: "auto",
        padding: 0,
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: "#913AFF",
        "&:hover": {
            backgroundColor: purple[700],
        },
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

type AutoPayModalInput = {
    autoPayTermsContent: JSX.Element;
    autoPayTermsTitle: JSX.Element;
    acceptText: string;
    closeText: string | undefined;
    acceptAction: () => void;
};

type PropsType = {
    open: boolean;
    close: () => void;
    autoPayConfigInput: AutoPayConfigurationInput;
};

const AutoPayModal = (props: PropsType) => {
    const isCreditCard: boolean = !!props.autoPayConfigInput.creditCardId;
    const [autoPayModalParams, setAutoPayModalParams] = useState<AutoPayModalInput>(getAutoPayPromptInput());

    const [saveAutoPayConfigurationMutation,
        {error: saveError}
    ] = useSaveAutoPayConfigurationMutation();

    const
        {
            data: institutionData,

        }
            = useGetInstitutionByIdQuery({
            variables: {institutionId: props.autoPayConfigInput.institutionId}
        });

    const CustomTitle = () => {
        const classes = useStyles();

        return (
            <div>
                <div className={classes.title}>
                    <Grid container direction={'column'}>
                        <Grid item xs={6}>
                            <div className={classes.logoContainer}>
                                <Avatar
                                    aria-label="Account Logo"
                                    variant="square"
                                    src={institutionData?.institution.logoUrl}
                                    className={classes.logo}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.text}>
                                {autoPayModalParams.autoPayTermsTitle}
                            </div>
                        </Grid>
                    </Grid>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={props.close}
                    >
                        <CloseIcon fontSize="large"/>
                    </IconButton>
                </div>
                <Divider className={classes.divider}/>
            </div>
        );
    };


    function getAutoPayPromptInput() {
        return isCreditCard
            ? getCreditCardAutoPayParams()
            : getDirectDebitAutoPayParams();
    }

    function getDirectDebitAutoPayParams(): AutoPayModalInput {
        return {
            autoPayTermsContent: AUTO_PAY_QUESTION,
            autoPayTermsTitle: <Typography> Transaction Successful! Want to Sign Up For Auto Pay?</Typography>,
            acceptText: "Yes",
            closeText: "No",
            acceptAction: () => setAutoPayModalParams(getAutoPayTermsParams)
        };
    }

    function getCreditCardAutoPayParams(): AutoPayModalInput {
        return {
            autoPayTermsContent: AUTO_PAY_QUESTION,
            autoPayTermsTitle: <Typography> Transaction Successful! Want to Sign Up For Auto Pay?</Typography>,
            acceptText: "Yes",
            closeText: "No",
            acceptAction: () => setAutoPayModalParams(getAutoPayTermsParams)
        };
    }

    const saveAutoPayConfig = () => saveAutoPayConfigurationMutation({
        variables: {
            autoPayConfigInput: props.autoPayConfigInput
        },
        refetchQueries: [{query: getAutoPayConfigurations}]
    })
        .then(() => {
                setAutoPayModalParams(getSaveAutoPayResultMessage());
            }
        )
        .catch(() => setAutoPayModalParams(getSaveAutoPayErrorResultParams()));

    function getAutoPayTermsParams() {
        const termsContent = isCreditCard ? (
            <Typography>{CREDIT_CARD_AUTO_PAY_TERMS}</Typography>
        ) : (
            <Typography>{DIRECT_DEBIT_AUTO_PAY_TERMS}</Typography>
        );
        const termsTitle = isCreditCard ? (
            <Typography>{CREDIT_CARD_AUTO_PAY_TERMS_TITLE}</Typography>
        ) : (
            <Typography>{DIRECT_DEBIT_AUTO_PAY_TERMS_TITLE}</Typography>
        );

        return {
            autoPayTermsContent: termsContent,
            autoPayTermsTitle: termsTitle,
            acceptText: "Accept",
            closeText: "Decline",
            acceptAction: saveAutoPayConfig
        };
    }

    function getSaveAutoPayErrorResultParams(): AutoPayModalInput {
        return {
            autoPayTermsContent: <Typography>{saveError}</Typography>,
            autoPayTermsTitle: <Typography>Error</Typography>,
            acceptText: "Got it",
            closeText: undefined,
            acceptAction: props.close
        };
    }

    function getSaveAutoPayResultMessage(): AutoPayModalInput {
        const prefix = "Way to go. Life just got a little easier. Future invoices will automatically be";
        const suffix = "Don't worry, we will always let you know before processing any invoices.";
        const savedAutoPayMessage = !!props.autoPayConfigInput.creditCardId
            ? `${prefix} charged to your credit card. ${suffix}`
            : `${prefix} withdrawn from your bank account. ${suffix}`;
        return {
            autoPayTermsContent: <Typography> {savedAutoPayMessage}</Typography>,
            autoPayTermsTitle: <Typography> Success </Typography>,
            acceptText: "Got it",
            closeText: undefined,
            acceptAction: props.close
        };
    }

    return (
        <ShiftModal
            open={props.open}
            accepted={autoPayModalParams.acceptAction}
            close={props.close}
            content={autoPayModalParams.autoPayTermsContent}
            title={<CustomTitle/>}
            acceptButtonText={autoPayModalParams.acceptText}
            closeButtonText={autoPayModalParams.closeText}
            maxWidth={"sm"}
            modalContentStyle={{
                minHeight: "70%",
                maxHeight: "70%",
                overflow: "scroll",
            }}
            closeOnButtonKeyOnly={true}
            buttonGridSm={4}
            buttonGridXs={6}
            buttonSize={"large"}
            fullWidth={true}
        />
    );
};

export default AutoPayModal;
