import {useEffect, useState} from "react";
import {
    BankAccountDetail,
    CreditCard,
    useGetBankAccountsQuery,
    useGetBlueSnapCardLazyQuery
} from "../data/graphqlTypes";
import useGetCustomer from "../context/EntityContext/useGetCustomer";

export type DirectDebitOrCreditCard = "DIRECT_DEBIT" | "CREDIT_CARD";

export type PaymentMethodCombined = {
    id: string;
    name: string;
    type: DirectDebitOrCreditCard;
    institutionId: string;
    bankAccount?: BankAccountDetail;
    creditCard?: CreditCard;
}
export const useGetAvailablePaymentMethods = (lastInstitutionId: string) => {

    const [institutionId, setInstitutionId] = useState<string>(lastInstitutionId)
    const [data, setData] = useState<Array<PaymentMethodCombined>>([]);

    const {
        customer,
        error: getCustomerError,
        loading: getCustomerLoading
    } = useGetCustomer();

    const {
        loading: accountsLoading,
        error: accountsError,
        data: accountsData,
        refetch: accountsDataRefresh
    } = useGetBankAccountsQuery({fetchPolicy: "no-cache"});

    const [getCC, {
        data: blueSnapCardData,
        loading: blueSnapCardLoading,
        error: blueSnapCardError,
        refetch: blueSnapCardRefresh
    }] = useGetBlueSnapCardLazyQuery();

    const customerAddress = customer?.address;

    useEffect(() => {
        if (!!lastInstitutionId && !!customerAddress) {
            getCC({
                variables: {
                    institutionId: lastInstitutionId
                }
            });
        }

    }, [lastInstitutionId, customerAddress]);

    useEffect(() => {
        if (!accountsLoading && !accountsError && !!accountsData) {

            const accountDetails = accountsData.accounts as Array<BankAccountDetail> || [];
            if (accountsData && accountDetails && accountDetails.length > 0) {
                const paymentMethods = accountDetails.map((account: BankAccountDetail) => {
                    if (account !== null) {
                        return ({
                            id: account.id,
                            name: `${account.bankName} (${account.accountNumber})`,
                            type: "DIRECT_DEBIT",
                            institutionId: institutionId,
                            bankAccount: account
                        } as PaymentMethodCombined);
                    }
                });
                if (paymentMethods) {
                    setData(prevState => {
                            const set = new Set(prevState);
                            paymentMethods
                                .filter(value => {
                                    return value && !set.has(value);
                                })
                                .forEach(value => {
                                    if (!!value) {
                                        set.add(value);

                                    }
                                });
                            return Array.from(set);
                        }
                    );
                }
            }
        }

    }, [accountsData, accountsError, accountsLoading]);
    useEffect(() => {
        if (!blueSnapCardLoading && !blueSnapCardError) {

            if (blueSnapCardData && blueSnapCardData.blueSnapCard) {
                const paymentMethod: PaymentMethodCombined = {
                    id: blueSnapCardData.blueSnapCard.creditCardId,
                    name: `${blueSnapCardData.blueSnapCard.cardBrand} - ${blueSnapCardData.blueSnapCard.cardNumber}`,
                    type: "CREDIT_CARD",
                    institutionId: institutionId,
                    creditCard: blueSnapCardData.blueSnapCard
                };
                setData(prevState => {
                    if (prevState?.find(val => val.id === paymentMethod.id && val.type === "CREDIT_CARD")) {
                        return prevState
                    }
                    return [...prevState, paymentMethod];
                });
            }
        }

    }, [blueSnapCardData, blueSnapCardError, blueSnapCardLoading]);


    return {
        error: accountsError || blueSnapCardError || getCustomerError,
        loading: accountsLoading || blueSnapCardLoading || getCustomerLoading,
        data,
        setInstitutionId: (institutionIdStr: string) => {
            setInstitutionId(institutionIdStr);
            getCC({variables: {institutionId: institutionIdStr}});

        },
        refetch: async () => {
            if (accountsDataRefresh) {
                await accountsDataRefresh();
                await blueSnapCardRefresh();
                return Promise.resolve()
            }
        },

    }
}