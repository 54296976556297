import React, {ReactNode} from "react";
import {useHistory} from "react-router-dom";
import {KindeProvider} from "@kinde-oss/kinde-auth-react";

interface MyProps {
    children: ReactNode
}

const AuthProvider = ({children}: MyProps): JSX.Element => {
    const domain = process.env.REACT_APP_AUTH_DOMAIN || '';
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID || '';
    const audience = process.env.REACT_APP_AUTH_AUDIENCE;

    const history = useHistory();

    // const onRedirectCallback = () => {
    //     history.push(window.location.pathname);
    // };

    return (
        <KindeProvider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={(user, app_state) => {
                // @ts-ignore
                if (app_state?.redirectTo) {
                    // @ts-ignore
                    // console.log(app_state?.redirectTo);
                    // @ts-ignore
                    history.push(app_state?.redirectTo);
                    return;
                }
                history.push(window.location.pathname);
            }}
            audience={audience}
        >
            {children}
        </KindeProvider>
    );
};

export default AuthProvider;