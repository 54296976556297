import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {AccountRoutingType} from "../data/graphqlTypes";
import {SelectedPaymentOptionType, SWIFT} from "../DirectDebit/PaymentTypes";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import PaymentOptionHeader from "./PaymentOptionHeader";
import WireInstructions from "./WireInstructions";
import useGetCurrencyFormatter from "../../utilities/useGetCurrencyFormatter";
import React from "react";
import {Payment} from "../Fees/useCalculateFees";


type PropsType = {
    selectedAccountRoutingType: AccountRoutingType | undefined;
    setAcceptRecurringPaymentTerms: (accepted: boolean) => void;
    acceptRecurringPaymentTerms: boolean;
    payment: Payment | undefined;
    handleChange: (routingType: SelectedPaymentOptionType) => void;
    transactionCurrency: string;
}

const WirePaymentOptionWithAccordion = (props: PropsType) => {

    const {currencyFormatter} = useGetCurrencyFormatter();
    if(!(process.env.REACT_APP_ALLOW_WIRE === 'true')){
        return <></>;
    }

    return <Accordion expanded={props.selectedAccountRoutingType === AccountRoutingType.Swift}
                      onChange={() => props.handleChange(SWIFT)}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-label="Expand"
            aria-controls="additional-actions3-content"
            id="additional-actions3-header"
        >
            {props.selectedAccountRoutingType === AccountRoutingType.Swift ? <CheckBoxIcon color={"primary"}/> :
                <CheckBoxOutlineBlankIcon color={"primary"}/>}
            <PaymentOptionHeader
                label={SWIFT.label}
                // feeDescription={"No Fee"}
            />
        </AccordionSummary>
        <AccordionDetails>
            <WireInstructions
                formatter={currencyFormatter(props.transactionCurrency)}
                payment={props.payment}
                setAcceptRecurringPaymentTerms={props.setAcceptRecurringPaymentTerms}
                acceptRecurringPaymentTerms={props.acceptRecurringPaymentTerms}
            />
        </AccordionDetails>
    </Accordion>;
}

export default WirePaymentOptionWithAccordion;