import {Card, CardActionArea, CardMedia, Fade, Grid, Paper, Typography} from "@material-ui/core";
import React from "react";
import {Loading} from "../components/layout";
import {Link} from "react-router-dom";
import ApolloGqlQueryDisplay from "../components/data/ApolloGqlQueryDisplay";
import {useGetInstitutionsByUser} from "../utilities/useGetInstitutionsByUser";


const styles = {
    cardContainer: {
        minWidth: 200,
        maxWidth: 800,
        height: 'auto',
        minHeight: '60vh',
        marginTop: '3vw',
        borderColor: '#000',

    },

    root: {

        borderColor: '#000',
        minWidth: '270px',
        minHeight: '200px',
        "&:lastChild": {
            paddingBottom: '0px'
        }
    },
    imageLogo: {
        width: "100%",
        height: "100%",
        maxWidth: '160px',
        maxHeight: '160px',
        margin: '10px',
        paddingTop: '25px',
        borderColor: 'transparent',
        "&:hover $focusHighlight": {
            opacity: 0
        }

    },

};

export function HoaSelect() {

    const {data: institutions, loading, error} = useGetInstitutionsByUser();

    return (
        <Fade in={true} timeout={1000}>
            <Paper>
                <Grid container direction='column' justifyContent="space-around" alignItems="center">
                    <Card style={styles.cardContainer} raised={true}>
                        <Grid container spacing={1} direction="column" justifyContent="space-evenly"
                              alignItems="stretch">
                            <Grid item xs={12}>
                                <Typography variant="h1" component="legend">Payments</Typography>
                                <ApolloGqlQueryDisplay loading={loading}
                                                       loadingMessage={'Loading Institutions'}
                                                       error={error}/>
                            </Grid>
                            <Grid container item spacing={2} xs={12} alignItems={"center"} justifyContent={"center"}>
                                {loading ? <Loading/> : institutions.map(hoa => {
                                    return <Grid item key={hoa.institutionId}>
                                        <Link key={hoa.institutionId} to={`/payment/${hoa.institutionId}`}>
                                            <Card style={styles.root}>
                                                <CardActionArea>
                                                    <Grid container direction="column" alignItems="center">
                                                        <CardMedia style={styles.imageLogo}
                                                                   component="img"
                                                                   alt={hoa.name}
                                                                   image={hoa.logoUrl}
                                                                   title={hoa.name}
                                                        />
                                                    </Grid>
                                                </CardActionArea>
                                            </Card>
                                        </Link>
                                    </Grid>
                                })}
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Paper>
        </Fade>
    );

}
